import React, { useContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, MenuItem, InputLabel, IconButton,
  Select, InputAdornment, TextField, Grid
} from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { CashierContext } from '../../../../components/CashierContext';
import { calcTotalCash } from '../../../../../utils/helpers';
import { MenuProps } from '../consts';

const ModalWindowEditCrossCourse = ({ order, keysNames, titles, setIsDisabled }) => {
  const { cashierCurrency } = useContext(CashierContext);
  const { currentOrder = {} } = order || {};
  const [isEdit, setIsEdit] = useState(false);
  const [currencyId, setCurrencyId] = useState('');
  const [currencyValue, setCurrencyValue] = useState('');
  const [secondCurrencyValue, setSecondCurrencyValue] = useState('');
  const [rate, setRate] = useState('');
  useEffect(() => {
    setCurrencyId(currentOrder[keysNames[2]]);
    setCurrencyValue(currentOrder[keysNames[3]]);
    setRate(currentOrder.rate);
    setSecondCurrencyValue(currentOrder[keysNames[1]] || currentOrder[keysNames[3]] * currentOrder.rate);
  }, [currentOrder, keysNames]);

  useEffect(() => {
    if (!currencyId || !currencyValue || !rate) {
      setIsDisabled(true);
    } else setIsDisabled(false);
  }, [setIsDisabled, currencyId, currencyValue, rate]);

  const renderCurrencies = useMemo(() => cashierCurrency?.filter((item) => item.title !== 'UAH')
    .map((item) => (
      <MenuItem
        key={item.id}
        value={item.id}
      >
        {item.title}
      </MenuItem>
    )),
  [cashierCurrency]
  );

  const handelEditField = () => {
    setIsEdit((prevState) => !prevState);
  };

  const adornmentTitle = (id) => {
    if (id) {
      return cashierCurrency.find((item) => item.id === id).title.toUpperCase();
    }
    return null;
  };

  return (
    <>
      <Grid item xs={6}>
        <FormControl fullWidth size="normal">
          <InputLabel>{titles[0]}</InputLabel>
          <Select
            required
            autoWidth
            name={keysNames[2]}
            label={titles[0]}
            placeholder={titles[0]}
            value={currencyId}
            MenuProps={MenuProps}
            onChange={(e) => {
              setCurrencyId(e.target.value);
              setRate(cashierCurrency.find((item) => item.id === e.target.value).buyRate);
              setSecondCurrencyValue(calcTotalCash(
                currencyValue,
                cashierCurrency.find((item) => item.id === e.target.value).buyRate));
            }}
          >
            {renderCurrencies}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth size="normal">
          <TextField
            type="number"
            onWheel={(e) => e.target.blur()}
            name={keysNames[4]}
            disabled={!isEdit}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handelEditField} disabled={!currencyId}>
                    <EditRoundedIcon color={isEdit ? 'primary' : 'disabled'} />
                  </IconButton>
                </InputAdornment>
              ),
              inputProps: {
                min: 0.01,
                step: 0.01
              }
            }}
            required
            label="Курс"
            value={rate}
            onChange={(e) => {
              setRate(e.target.value.replace(/[^0-9,.]/g, ''));
              setSecondCurrencyValue(
                calcTotalCash(currencyValue, e.target.value));
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth size="normal">
          <TextField
            required
            name={keysNames[3]}
            label="Сума"
            type="number"
            onWheel={(e) => e.target.blur()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{adornmentTitle(currencyId)}</InputAdornment>
              ),
              inputProps: {
                min: 1,
                step: 1
              }
            }}
            value={currencyValue}
            onChange={(e) => {
              setCurrencyValue(e.target.value.replace(/[^0-9,.]/g, ''));
              setSecondCurrencyValue(
                calcTotalCash(e.target.value.replace(/[^0-9,.]/g, ''), rate));
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth size="normal">
          <TextField
            className="buy-sell-page-totalCash"
            label={titles[1]}
            name={keysNames[1]}
            type="number"
            InputProps={{
              readOnly: true,
              startAdornment: <InputAdornment position="start">UAH</InputAdornment>,
              inputProps: {
                min: 0.01,
                step: 0.01
              }
            }}
            value={secondCurrencyValue}
          />
        </FormControl>
        <FormControl sx={{ display: 'none' }}>
          <TextField
            name={keysNames[0]}
            disabled
            value={1}
          />
        </FormControl>
      </Grid>
    </>
  );
};

ModalWindowEditCrossCourse.propTypes = {
  order: PropTypes.object,
  keysNames: PropTypes.array,
  titles: PropTypes.array,
  setIsDisabled: PropTypes.func
};

export default ModalWindowEditCrossCourse;
