import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, MenuItem, InputLabel, IconButton, Tooltip,
  Select, InputAdornment, TextField, Button, Stack, Grid
} from '@mui/material';
import { nanoid } from 'nanoid';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import isEmpty from 'lodash.isempty';
import { useDynamicForm } from '../../../../hooks/useDynamicForm';
import { calcTotalCash } from '../../../../utils/helpers';
import { MenuProps } from './consts';

const StepTwoCrossCourseOperation = ({
  cashierCurrency,
  onAdded,
  titles
}) => {
  const {
    data: { rate, currencyId, value, secondCurrencyId, secondValue },
    clearData: clearOperation,
    isAllEmpty,
    multiSet
  } = useDynamicForm({
    rate: '',
    currencyId: '',
    value: '',
    secondCurrencyId: '',
    secondValue: ''
  });

  const [isEdit, setIsEdit] = useState(false);

  const renderCurrencies = useCallback(() => {
    if (isEmpty(cashierCurrency)) {
      return null;
    }
    if (!isEmpty(cashierCurrency)) {
      return cashierCurrency
        .filter((item) => item.title !== 'UAH')
        .map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
          >
            {item.title}
          </MenuItem>
        ));
    }
  },
  [cashierCurrency]
  );

  const handelEditField = () => {
    setIsEdit((prevState) => !prevState);
  };

  const adornmentTitle = (id) => {
    if (id) {
      return cashierCurrency.find((item) => item.id === id).title.toUpperCase();
    }
    return null;
  };

  return (
    <Grid item xs={5}>
      <div className="department-filters cash-transit-create-request">
        <Stack spacing={4} sx={{ mb: 2 }}>
          <div className="department-filters-item">
            <div className="addUsers-two cross-course-two-inputs">
              <FormControl fullWidth size="normal">
                <InputLabel>{titles[0]}</InputLabel>
                <Select
                  required
                  autoWidth
                  className="buy-sell-page-currencyTitle"
                  label={titles[0]}
                  placeholder={titles[0]}
                  value={secondCurrencyId}
                  MenuProps={MenuProps}
                  onChange={(e) => {
                    multiSet({
                      secondCurrencyId: e.target.value,
                      rate: cashierCurrency.find((item) => item.id === e.target.value).buyRate,
                      currencyId: 1,
                      value: calcTotalCash(
                        secondValue,
                        cashierCurrency.find((item) => item.id === e.target.value).buyRate
                      )
                    });
                  }}
                >
                  {renderCurrencies()}
                </Select>
              </FormControl>
              <FormControl fullWidth size="normal">
                <TextField
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  disabled={!isEdit}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Редагування крос-курсу для однієї операції" placement="top">
                          <span>
                            <IconButton onClick={handelEditField} disabled={!secondCurrencyId}>
                              <EditRoundedIcon color={isEdit ? 'primary' : 'disabled'} />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </InputAdornment>
                    ),
                    inputProps: {
                      min: 0.01,
                      step: 0.01
                    }
                  }}
                  required
                  label="Курс"
                  value={rate}
                  onChange={(e) => {
                    multiSet({
                      rate: e.target.value.replace(/[^0-9,.]/g, ''),
                      value: calcTotalCash(secondValue, e.target.value)
                    });
                  }}
                />
              </FormControl>
            </div>
          </div>
        </Stack>
        <Stack spacing={4} sx={{ mb: 2 }}>
          <div className="department-filters-item">
            <div className="addUsers-two cross-course-two-inputs">
              <FormControl fullWidth size="normal">
                <TextField
                  required
                  label="Сума"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{adornmentTitle(secondCurrencyId)}</InputAdornment>
                    ),
                    inputProps: {
                      min: 1,
                      step: 1
                    }
                  }}
                  value={secondValue}
                  onChange={(e) => {
                    multiSet({
                      secondValue: e.target.value.replace(/[^0-9]/g, ''),
                      value: calcTotalCash(e.target.value.replace(/[^0-9]/g, ''), rate)
                    });
                  }}
                />
              </FormControl>
              <FormControl fullWidth size="normal">
                <TextField
                  className="buy-sell-page-totalCash"
                  label={titles[1]}
                  type="number"
                  disabled
                  InputProps={{
                    startAdornment: <InputAdornment position="start">UAH</InputAdornment>,
                    inputProps: {
                      min: 0.01,
                      step: 0.01
                    }
                  }}
                  value={value}
                />
              </FormControl>
            </div>
          </div>
        </Stack>
        <div className="department-filters-item">
          <div className="addUsers-two cross-course-two-inputs">
            <div>
              <Button
                sx={{ minWidth: 200 }}
                onClick={() => {
                  onAdded({
                    currencyId,
                    value,
                    secondCurrencyId,
                    secondValue,
                    rate,
                    localId: nanoid()
                  });
                  clearOperation();
                }}
                disabled={isAllEmpty || +secondValue === 0 || +rate === 0}
                variant="contained"
              >
                Додати
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};

StepTwoCrossCourseOperation.propTypes = {
  cashierCurrency: PropTypes.array,
  titles: PropTypes.array,
  onAdded: PropTypes.func.isRequired
};

export default StepTwoCrossCourseOperation;
