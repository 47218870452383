import React, { useContext, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, TableHead, TableBody, TableCell, TableRow,
  Button, IconButton, ButtonGroup, Collapse, Tooltip
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import moment from 'moment';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import { CashierContext } from '../../components/CashierContext';
import { DashboardContext } from '../../components/DashboardContext';
import ModalWindowStornoConfirm from './Components/ModalWindowStornoConfirm';
import { translations } from '../../../constants/OperationsTranslations';
import { OperationStatusButton } from '../../components/OperationStatusButton';

const DepartmentsItems = ({ item }) => {
  const { sessionData } = useContext(CashierContext);
  const { clearStornoErrors } = useContext(DashboardContext);
  const {
    id,
    type,
    rate,
    operator,
    time,
    comment,
    storno,
    arrival_value: arrivalValue,
    expense_value: expenseValue,
    arrival_currency: arrivalCurrency,
    expense_currency: expenseCurrency,
    session_id: sessionId
  } = item;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isStornoValue, setIsStornoValue] = useState('');
  const [isCurrenttime, setIsCurrentTime] = useState(moment());
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsCurrentTime(moment());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const onClickConfirmStorno = useCallback((e) => {
    setIsOpenModal(true);
    setIsStornoValue(e);
  }, []);

  const onClickCloseModal = useCallback(() => {
    setIsOpenModal(false);
    setIsStornoValue('');
    clearStornoErrors();
  }, [clearStornoErrors]);

  const itemTime = moment(time, 'DD.MM.YYYY hh:mm:ss');

  const stornoAndErrorColumn = () => {
    if (type === 'Инкассация') return '';
    if (storno === 'Нет') {
      if (sessionData.id !== sessionId) {
        return null;
      }
      if (type === 'Прочие расходы' || type === 'Прочие доходы') {
        return (
          <Tooltip title="Помилка">
            <Button onClick={() => (onClickConfirmStorno('Ошибка кассира'))} size="small" variant="contained" color="error">
              <CancelIcon />
            </Button>
          </Tooltip>
        );
      }
      return (
        <div className="operations-buttons">
          <ButtonGroup>
            <Tooltip title="Сторно">
              <Button onClick={() => (onClickConfirmStorno('Да'))} variant="contained" size="small" disabled={moment(isCurrenttime).diff(itemTime, 'minutes') > 14}><ReplayRoundedIcon /></Button>
            </Tooltip>
            <Tooltip title="Помилка">
              <Button onClick={() => (onClickConfirmStorno('Ошибка кассира'))} size="small" variant="contained" color="error"><CancelIcon /></Button>
            </Tooltip>
          </ButtonGroup>
        </div>
      );
    } return translations.storno[storno];
  };

  return (
    <>
      <TableRow>
        <TableCell align="left" sx={{ pl: 0 }}>{id}</TableCell>
        <TableCell align="left">
          <OperationStatusButton operationStatus={type} />
        </TableCell>
        <TableCell align="left">{`${arrivalValue || '-'} ${arrivalCurrency?.title || '-'}`}</TableCell>
        <TableCell align="left">{`${expenseValue || '-'} ${expenseCurrency?.title || '-'}`}</TableCell>
        <TableCell align="left">{rate}</TableCell>
        <TableCell align="left">{`${operator.firstname} ${operator.lastname}`}</TableCell>
        <TableCell align="left">{time}</TableCell>
        <TableCell align="center">
          {stornoAndErrorColumn()}
        </TableCell>
        <TableCell sx={{ p: 0 }} align="center">
          {!comment || (
          <Tooltip title="Подивитись коментар">
            <IconButton onClick={() => setOpen(!open)} color="primary" sx={{ mb: 1, mt: 1 }}><ChatRoundedIcon /></IconButton>
          </Tooltip>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: 0, borderBottom: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table sx={{ backgroundColor: 'var(--secondary-bg)' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Коментар</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{comment}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
      <ModalWindowStornoConfirm
        open={isOpenModal}
        handleClose={onClickCloseModal}
        operationId={id}
        stornoValue={isStornoValue}
      />
    </>
  );
};

DepartmentsItems.propTypes = {
  item: PropTypes.object
};

export default DepartmentsItems;
