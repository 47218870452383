import React, { useContext, useCallback, useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import {
  FormControl, InputLabel, Select, Grid, TextField, MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import MomentUtils from '@date-io/moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DashboardContext } from '../../../components/DashboardContext';
import { AutocompleteSelect } from '../../../components/AutocompleteSelect';
import { isNull } from '../../../../utils/isNull';
import { MenuProps } from './consts';
import { LimitTable } from './LimitTable';

const changeValue = (filterValue, setValue) => (e) => {
  setValue(
    (PrevState) => ({ ...PrevState, departmentId: '', [filterValue]: e.target.value })
  );
};

export const Limits = () => {
  const { filials, fetchFilials,
    departments, fetchDepartments,
    fetchReportLimit, reportLimit, isFetchingReportLimit, clearReportLimit } = useContext(DashboardContext);
  const [limitFilter, setLimitFilter] = useState(
    {
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      filialId: 'any',
      departmentId: ''
    }
  );

  useEffect(() => () => clearReportLimit(), [clearReportLimit]);

  useEffect(() => {
    fetchFilials();
  }, [fetchFilials]);

  useEffect(() => {
    fetchDepartments({
      filial_id: limitFilter.filialId === 'any' ? '' : limitFilter.filialId
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitFilter.filialId]);

  const renderFilials = useMemo(() => filials?.map(({ id, title }) => (
    <MenuItem key={id} value={id}>{title}</MenuItem>
  )),
  [filials]);

  const filteredDepartments = useMemo(() => departments?.map(({ id, title }) => ({ id, label: title })),
    [departments]);

  const getReportLimit = useCallback(() => {
    fetchReportLimit({
      date_from: moment(limitFilter.fromDate).format('YYYY-MM-DD'),
      date_to: moment(limitFilter.toDate).format('YYYY-MM-DD'),
      filial_id: limitFilter.filialId === 'any' ? '' : limitFilter.filialId,
      department_id: limitFilter.departmentId
    });
  },
  [fetchReportLimit, limitFilter]);
  return (
    <Grid sx={{ mt: 0, mb: 3, pt: 0 }} container spacing={4} alignItems="stretch">
      <Grid item md={6}>
        <FormControl className="operation-list-selector" fullWidth>
          <InputLabel>Регіон</InputLabel>
          <Select
            MenuProps={MenuProps}
            label="Регіон"
            value={limitFilter.filialId}
            onChange={changeValue('filialId', setLimitFilter)}
          >
            <MenuItem key="any" value="any">Всі</MenuItem>
            {renderFilials}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={6}>
        <AutocompleteSelect
          id="department-selector"
          label="Відділення"
          selectorList={filteredDepartments}
          setSelectedItem={setLimitFilter}
          multiplyState="departmentId"
        />
      </Grid>
      <Grid item md={3}>
        <div className="operation-list-selector">
          <LocalizationProvider dateAdapter={MomentUtils} locale="uk">
            <DatePicker
              fullWidth
              mask="__.__.____"
              label="Дати з"
              value={limitFilter.fromDate}
              maxDate={limitFilter.toDate}
              onChange={(newValue) => setLimitFilter(
                (PrevState) => ({ ...PrevState, fromDate: moment(newValue) })
              )}
              renderInput={(params) => <TextField mask="YYYY-MM-DD" {...params} />}
              inputFormat="DD.MM.YYYY"
              disableMaskedInput
            />
          </LocalizationProvider>
        </div>
      </Grid>
      <Grid item md={3}>
        <div className="operation-list-selector">
          <LocalizationProvider dateAdapter={MomentUtils} locale="uk">
            <DatePicker
              mask="__.__.____"
              label="Дати по"
              value={limitFilter.toDate}
              minDate={isNull(limitFilter.fromDate) ? null : limitFilter.fromDate}
              maxDate={moment()}
              onChange={(newValue) => setLimitFilter(
                (PrevState) => ({ ...PrevState, toDate: moment(newValue) })
              )}
              renderInput={(params) => <TextField mask="YYYY-MM-DD" {...params} />}
              inputFormat="DD.MM.YYYY"
              disableMaskedInput
            />
          </LocalizationProvider>
        </div>
      </Grid>
      <Grid item md={3}>
        <LoadingButton
          fullWidth
          variant="contained"
          onClick={getReportLimit}
          loading={isFetchingReportLimit}
        >
          Сформувати
        </LoadingButton>
      </Grid>
      {!!reportLimit.length && (
      <Grid item md={12}>
        <LimitTable reportLimit={reportLimit} />
      </Grid>
      )}
    </Grid>
  );
};
