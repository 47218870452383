import React, { useEffect, useContext, useMemo } from 'react';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Tooltip
} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { isUndefined } from '../../../utils/isUndefined';
import { DashboardContext } from '../DashboardContext';
import { isNull } from '../../../utils/isNull';
import { AuthorisationContext } from '../AuthorisationContext';
import { CashierContext } from '../CashierContext';
import './style.scss';

const currencyTitle = (id, cashierCurrency) => cashierCurrency.find((currency) => currency.id === id)?.title;

const OperationsTable = ({ sessionId = null, filter }) => {
  const {
    fetchOperationsTable,
    fetchCurrencies,
    currency,
    operationsTable: {
      currencies,
      encashment: CashInTransit,
      'cross-rate': crossCourse,
      'purchase-sale': buySell
    }
  } = useContext(DashboardContext);
  const { cashierCurrency, fetchCashierCurrency } = useContext(CashierContext);
  const { cashierData } = useContext(AuthorisationContext);

  useEffect(() => {
    if (cashierData) {
      fetchCashierCurrency({
        date: moment().format('YYYY-MM-DD'),
        id: cashierData.id
      });
    } else {
      fetchCurrencies({
        date: moment().format('YYYY-MM-DD')
      });
    }
  }, [fetchCashierCurrency, fetchCurrencies, cashierData]);
  useEffect(() => {
    fetchOperationsTable({
      date_to: moment(filter?.toDate).format('DD.MM.YYYY'),
      date_from: isNull(filter?.fromDate) ? '' : moment(filter?.fromDate).format('DD.MM.YYYY'),
      city_id: +filter?.city,
      filial_id: +filter?.filial,
      user_id: +filter?.userId,
      department_id: +filter?.department,
      operation_type: filter.type === 'all' ? '' : String(filter.type),
      currency_id: +filter?.currency,
      session_id: +filter?.session,
      ...(sessionId && { current_session: sessionId })
    });
  }, [fetchOperationsTable, sessionId, filter]);
  const renderTableValues = useMemo(() => {
    if (isUndefined(currencies)) {
      return null;
    }
    if (!isUndefined(currencies)) {
      return Object.values(currencies).map((item) => (
        <TableRow key={item}>
          <TableCell>{currencyTitle(item, cashierData ? cashierCurrency : currency)}</TableCell>
          <TableCell>{buySell.currencies[item]?.arrival || '—'}</TableCell>
          <TableCell>{buySell.currencies[item]?.expense || '—'}</TableCell>
          <TableCell>{CashInTransit.currencies[item]?.arrival || '—'}</TableCell>
          <TableCell>{CashInTransit.currencies[item]?.expense || '—'}</TableCell>
          <TableCell>{crossCourse.currencies[item]?.arrival || '—'}</TableCell>
          <TableCell>{crossCourse.currencies[item]?.expense || '—'}</TableCell>
        </TableRow>
      ));
    }
  },
  [CashInTransit?.currencies, buySell?.currencies, crossCourse?.currencies,
    currencies, cashierCurrency, currency, cashierData]);
  const tableTooltip = useMemo(() => {
    if (cashierData) {
      return (cashierData?.isCentral
        ? 'Таблиця не сортується по Сторно, Інших витратах та Інших доходах'
        : 'Таблиця не сортується по Сторно');
    } return 'Таблиця не сортується по Сторно, Інших витратах та Інших доходах';
  }, [cashierData]);

  return (
    <TableContainer>
      <Table sx={{ Width: '100%' }} aria-label="simple table" className="operations-table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Tooltip
                arrow
                placement="top"
                title={tableTooltip}
              >
                <InfoRoundedIcon />
              </Tooltip>
            </TableCell>
            <TableCell colSpan={2}>Купівля / Продаж</TableCell>
            <TableCell colSpan={2}>Інкасації</TableCell>
            <TableCell colSpan={2}>Крос-курс</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Валюта</TableCell>
            <TableCell>Прихід</TableCell>
            <TableCell>Розхід</TableCell>
            <TableCell>Прихід</TableCell>
            <TableCell>Розхід</TableCell>
            <TableCell>Прихід</TableCell>
            <TableCell>Розхід</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderTableValues}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

OperationsTable.propTypes = {
  sessionId: PropTypes.number,
  filter: PropTypes.object
};

export default OperationsTable;
