import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './style.scss';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import isEmpty from 'lodash.isempty';
import useFetch from '../../../hooks/useFetch';
import { CashierContext } from '../CashierContext';
import ModalWindow from '../ModalWindow/ModalWindow';
import OperationsService from '../../../services/OPERATIONS/OperationsService';
import { errorTranslation } from '../../../constants/ErrorsTranslations';

const ModalWindowCashier = ({ open, handleClose, data }) => {
  const history = useHistory();
  const { cashierCurrency } = useContext(CashierContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [isFetchingOperation, setIsFetchingOperation] = useState(false);
  const Cur = (CurId) => (!isEmpty(cashierCurrency) && !isEmpty(data)
    ? cashierCurrency.find((item) => item.id === CurId).title
    : null);

  const handleClick = useCallback(() => history.push('/cashier-operations-list'), [history]);

  const onGetResponse = useCallback((res) => {
    if (!res.success) {
      setIsFetchingOperation(false);
      if (res.message === 'Validation Error.') {
        const message = Object.values(res.data).join(', ');
        setErrorMessage(message);
        return null;
      }
      setErrorMessage(res.message);
    }
    if (res.data) {
      setIsFetchingOperation(false);
      setErrorMessage('');
      handleClose();
      handleClick();
    }
  }, [handleClose, handleClick]);

  const { fetch: fetchSellBuyOperation } = useFetch({
    requestFunction: OperationsService.postRequest,
    setResponse: onGetResponse
  });

  const onConfirmTransaction = useCallback(() => {
    setIsFetchingOperation(true);
    fetchSellBuyOperation(data);
  }, [fetchSellBuyOperation, data]);

  return (
    <>
      <ModalWindow
        open={open}
        onClose={handleClose}
        maxWidth="500px"
      >
        <div className="addUsers">
          <CancelPresentationRoundedIcon className="close-btn" onClick={handleClose} />
          <div className="new-date">Підтвердіть операцію</div>
          <div className="modal-window-data">
            <div className="set-time-text">{`Прихід: ${data.arrival_value} ${Cur(data.arrival_currency_id)}`}</div>
            <div className="set-time-text">{`По курсу: ${data.rate}`}</div>
            <div className="set-time-text">{`Витрата: ${data.expense_value} ${Cur(data.expense_currency_id)}`}</div>
            <div className="set-time-text">{`Коментар: ${data.comment || ''}`}</div>
            {errorMessage.length ? <p className="errorText">{errorMessage in errorTranslation ? errorTranslation[errorMessage] : errorMessage}</p> : ''}
          </div>
          <div className="addUsers-actions">
            <LoadingButton
              loading={isFetchingOperation}
              loadingPosition="end"
              endIcon={<SendRoundedIcon />}
              onClick={onConfirmTransaction}
              variant="contained"
            >
              Підтвердити
            </LoadingButton>
          </div>
        </div>
      </ModalWindow>
    </>
  );
};

ModalWindowCashier.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.object
};

export default ModalWindowCashier;
