import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import ModalWindow from '../../../components/ModalWindow/ModalWindow';
import { CashierContext } from '../../../components/CashierContext';
import { DashboardContext } from '../../../components/DashboardContext';

const ModalWindowStornoConfirm = ({ open, handleClose, operationId: id, stornoValue }) => {
  const { fetchOperationsStorno, isFetchingOperationsStorno, stornoError } = useContext(DashboardContext);
  const { sessionData: { id: sessionDataId } } = useContext(CashierContext);

  const onStornoId = useCallback(() => {
    fetchOperationsStorno({ id, stornoValue, sessionDataId }).then((resp) => {
      if (resp === 'success') {
        handleClose();
      }
    });
  }, [fetchOperationsStorno, id, stornoValue, sessionDataId, handleClose]);

  return (
    <>
      <ModalWindow
        open={open}
        onClose={handleClose}
        maxWidth="500px"
      >
        <div className="addUsers">
          <CancelPresentationRoundedIcon className="close-btn" onClick={handleClose} />
          <div className="new-date">Підтвердіть операцію</div>
          <div className="new-date">{stornoValue === 'Да' ? 'Сторно' : 'Помилка касира'}</div>
          {stornoError ? <p className="errorText">{stornoError}</p> : ''}
          <div className="addUsers-actions">
            <LoadingButton
              loading={isFetchingOperationsStorno}
              loadingPosition="end"
              endIcon={<SendRoundedIcon />}
              onClick={onStornoId}
              variant="contained"
            >
              Підтвердити
            </LoadingButton>
          </div>
        </div>
      </ModalWindow>
    </>
  );
};

ModalWindowStornoConfirm.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  operationId: PropTypes.number,
  stornoValue: PropTypes.string
};

export default ModalWindowStornoConfirm;
