import { API_URLS } from '../../constants/urls';
import { addParamsToUrl } from '../../utils/helpers';
import CRUDService from '../CRUDService';

class BalancesService extends CRUDService {
  constructor() {
    super(API_URLS.BALANCE);
    this.getRequestForBalance = async (params) => this.APIService.apiGet(addParamsToUrl(`${this.pathname}/department/${params.id}`, params));
    this.getRequestFilials = async (params) => this.APIService.apiGet(addParamsToUrl(`${this.pathname}/filials`, params));
    this.getRequestFilial = async (params) => this.APIService.apiGet(addParamsToUrl(`${this.pathname}/filials/${params.id}`, params));
  }
}

export default new BalancesService();
