import { SET_ERROR, SET_NOTIFICATION, SET_UNREAD, SET_UNREAD_REQUIRE, SET_ORDER_UNREAD_REQUIRE } from './const';

export function setCashierNotification(payload) {
  return {
    type: SET_NOTIFICATION,
    payload
  };
}

export function setError(payload) {
  return {
    type: SET_ERROR,
    payload
  };
}

export function setCashierUnreadNotification(payload) {
  return {
    type: SET_UNREAD,
    payload
  };
}

export function setCashierUnreadRequireNotification(payload) {
  return {
    type: SET_UNREAD_REQUIRE,
    payload
  };
}

export function setCashierOrderUnreadRequireNotification(payload) {
  return {
    type: SET_ORDER_UNREAD_REQUIRE,
    payload
  };
}
