import React, { useContext, useCallback } from 'react';
import './style.scss';
import { useTheme } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useHistory, useLocation } from 'react-router-dom';
import { Tooltip, Container } from '@mui/material';
import CurrentTime from '../CurrentTime/CurrentTime';
import { AuthorisationContext } from '../AuthorisationContext';
import { ColorModeContext } from '../../context/ThemeProvider/ColorModeProvider';
import { renderLocation } from './const';

const Header = () => {
  const { logout, updateUserThemeColor } = useContext(AuthorisationContext);
  const history = useHistory();
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const { pathname } = useLocation();
  const handleClick = useCallback(() => {
    colorMode.toggleColorMode();
    const newThemeColor = theme.palette.mode === 'dark' ? 'white' : 'black';
    updateUserThemeColor({ color_scheme: newThemeColor });
    const html = document.querySelector('html');
    html.setAttribute('data-theme', theme.palette.mode === 'dark' ? 'light' : 'dark');
  }, [colorMode, theme, updateUserThemeColor]);

  return (
    <Container className="headerTop">
      <CurrentTime />
      <p className="current-time">
        {renderLocation[pathname]}
      </p>
      <div className="headerTop-actions">
        <button onClick={handleClick}>
          <Tooltip title={theme.palette.mode === 'dark' ? 'Темна тема' : 'Світла тема'}>
            {theme.palette.mode === 'dark' ? <Brightness4Icon /> : <Brightness7Icon /> }
          </Tooltip>
        </button>
        <button onClick={() => history.push('/')}>
          <Tooltip title="Додому">
            <HomeIcon />
          </Tooltip>
        </button>
        <button onClick={() => history.push('/profile')}>
          <Tooltip title="Переглянути профіль">
            <AccountCircleIcon />
          </Tooltip>
        </button>
        <button onClick={logout}>
          <Tooltip title="Вийти з системи">
            <LogoutIcon />
          </Tooltip>
        </button>
      </div>
    </Container>
  );
};

export default Header;
