import * as yup from 'yup';

export const validationCurrency = (validationTitles) => (yup.object({
  title: yup
    .string()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      'Тільки латинські символи'
    )
    .max(3, 'Не більше 3-х символів')
    .notOneOf(validationTitles, 'Така валюта вже існує')
    .required('Обов\'язково до заповнення'),
  buyRate: yup
    .number()
    .transform((_value, originalValue) => Number(String(originalValue).replace(/,/, '.')))
    .max(999.9999, 'Максимальне значення 999.9999')
    .typeError('Поле має містити лише цифри')
    .positive('Число має бути більше 0')
    .test('4symbolsAfterDot', 'Не більше 4-х символів після коми',
      (value) => (value?.toString().split('.').at(-1).length || 0) <= 4)
    .required('Обов\'язково до заповнення'),
  sellRate: yup
    .number()
    .transform((_value, originalValue) => Number(String(originalValue).replace(/,/, '.')))
    .max(999.9999, 'Максимальне значення 999.9999')
    .typeError('Поле має містити лише цифри')
    .positive('Число має бути більше 0')
    .test('4symbolsAfterDot', 'Не більше 4-х символів після коми',
      (value) => (value?.toString().split('.').at(-1).length || 0) <= 4)
    .required('Обов\'язково до заповнення')
}));

export const validationCurrencyNBU = (validationTitles) => (yup.object({
  title: yup
    .string()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      'Тільки латинські символи'
    )
    .max(3, 'Не більше 3-х символів')
    .notOneOf(validationTitles, 'Така валюта вже існує')
    .required('Обов\'язково до заповнення'),
  buyRate: yup
    .number()
    .transform((_value, originalValue) => Number(String(originalValue).replace(/,/, '.')))
    .max(999.9999, 'Максимальне значення 999.9999')
    .typeError('Поле має містити лише цифри')
    .positive('Число має бути більше 0')
    .test('4symbolsAfterDot', 'Не більше 4-х символів після коми',
      (value) => (value?.toString().split('.').at(-1).length || 0) <= 4)
    .required('Обов\'язково до заповнення')
}));
