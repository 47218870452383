export const addParamsToUrl = (urlPath, params = {}) => {
  let paramCurrentCount = 1;
  let url = urlPath;

  const keys = Object.keys(params).filter(
    (key) => (typeof params[key] === 'object' && params[key].length > 0)
      || (typeof params[key] === 'string' && params[key] !== '')
      || (typeof params[key] === 'number' && params[key] > 0)
  );

  keys.forEach((key) => {
    if (paramCurrentCount === 1) {
      url += '?';
    }

    url += `${key}=${encodeURIComponent(params[key])}`;

    if (paramCurrentCount !== Object.keys(keys).length) {
      url += '&';
    }

    paramCurrentCount += 1;
  });

  return url;
};

export const calcCrossCourse = (byeId, sellId, currencyData) => {
  if (byeId && sellId) {
    const currencyTitleSellRate = currencyData?.find((item) => (
      item.id === byeId
    )).sellRate;
    const currencyTitleBuyRate = currencyData?.find((item) => (
      item.id === sellId
    )).buyRate;
    const totalSum = currencyTitleSellRate / currencyTitleBuyRate;
    return totalSum.toFixed(5);
  }
  return '';
};

export const calcTotalCash = (sum, cross) => {
  if (sum && cross) {
    const totalSum = sum * cross;
    return totalSum.toFixed(2);
  }
  return '';
};

export const localizeErrors = {
  'The login has already been taken.': 'Користувача з таким логіном вже зареєстровано.',
  'The phone has already been taken.': 'Користувача з таким номером телефону вже зареєстровано.',
  'The currencies field is required.': 'Має бути обрана принаймні одна валюта.',
  'tokens.0 already exists twice in system': 'Перший токен вже двічі задіяний у системі.',
  'tokens.1 already exists twice in system': 'Другий токен вже двічі задіяний у системі.',
  'The title has already been taken.': 'Така назва вже зайнята',
  'Department doesn\'t have active session.': 'Відділення немає активної сесії для можливості змінювати баланс.',
  'Wrong login or password': 'Неправильний логін або пароль',
  'Can\'t deleted - has a relationship': 'Не можливо видалити. До міста прив\'язаний користувач',
  'The login field is required.': 'Поле логін обов\'язкове до заповнення',
  'The start date is not a valid date.': 'Невалідне поле "Дата оформлення"',
  'The start date does not match the format Y-m-d.': 'Дата оформлення має відповідати формату ДД.ММ.РРРР',
  'The start date must be a date after 2000-01-01.': 'Дата оформлення має бути не раніше 01.01.2000',
  'Не хватает средств на балансе отделения для проведения этой заявки.': 'Бракує коштів на балансі відділення для проведення цієї заявки.',
  'Low balance in department for this operation': 'Недостатній баланс у відділі для цієї операції',
  'The tokens must not have more than 2 items.': "До відділеня не може бути прив'язано більше двох токенів"
};

export const AddDepScheduleFormat = {
  '08:00:00': '08:00',
  '12:00:00': '12:00',
  '23:59:00': '23:59'
};

export const departmentSchedule = {
  '08:00': '8 годин',
  '12:00': '12 годин',
  '23:59': '24 годин'
};

export const ms = {
  '08:00': 28800000,
  '12:00': 43200000,
  '24:00': 86400000,
  5: 300000,
  30: 1800000,
  60: 3600000
};
