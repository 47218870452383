import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, TableCell, TableRow, Tooltip } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';

const InkassatorsItem = ({ user }) => {
  const cities = user.cities.map((city) => city.title).join(', ');
  const fullName = `${user.lastname} ${user.firstname} ${user.patronymic}`;
  const userStatus = user.status ? 'Активний' : 'Неактивний';

  const history = useHistory();
  const onClickProfile = useCallback(() => history.push(`/settings/users/${user.id}`), [history, user]);
  const onClickBalance = useCallback(() => history.push(`/settings/inkassators/${user.id}`), [history, user]);

  return (
    <TableRow>
      <TableCell>{user.id}</TableCell>
      <TableCell>{fullName}</TableCell>
      <TableCell>{cities}</TableCell>
      <TableCell>{userStatus}</TableCell>
      <TableCell>
        <ButtonGroup>
          <Tooltip title="Редагувати">
            <Button variant="contained" onClick={onClickProfile}>
              <BorderColorOutlinedIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Баланси">
            <Button variant="contained" color="warning" onClick={onClickBalance}>
              <AccountBalanceWalletIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>
      </TableCell>
    </TableRow>
  );
};

InkassatorsItem.propTypes = {
  user: PropTypes.object
};

export default InkassatorsItem;
