import React, { useCallback, useContext } from 'react';
import SimCardDownloadRoundedIcon from '@mui/icons-material/SimCardDownloadRounded';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { DashboardContext } from '../../../components/DashboardContext';

export const BalanceReport = () => {
  const { fetchReportBalance } = useContext(DashboardContext);

  const downloadReportBalance = useCallback(() => {
    fetchReportBalance();
  },
  [fetchReportBalance]);
  return (
    <Grid sx={{ mt: 0, mb: 3, pt: 0 }} container spacing={4} alignItems="stretch">
      <Grid item md={3}>
        <LoadingButton
          fullWidth
          sx={{ height: '58px!important' }}
          variant="contained"
          onClick={downloadReportBalance}
        >
          <SimCardDownloadRoundedIcon sx={{ marginRight: '5px' }} />
          Завантажити
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
