import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, TableHead, TableBody, TableCell, TableRow, Tooltip, IconButton, Collapse } from '@mui/material';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import { translations } from '../../../../constants/OperationsTranslations';
import { OperationStatusButton } from '../../../components/OperationStatusButton';

const AdminOperationsItem = ({ item }) => {
  const {
    id,
    type,
    rate,
    storno,
    comment,
    department: { title: departmentTitle },
    operator: {
      lastname,
      firstname,
      patronymic
    },
    arrival_value: arrivalValue,
    expense_value: expenseValue,
    arrival_currency: arrivalCurrency,
    expense_currency: expenseCurrency,
    time
  } = item;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell align="left" padding="checkbox" sx={{ pl: 0 }}>{id}</TableCell>
        <TableCell align="left" sx={{ minWidth: 200 }}>
          <OperationStatusButton operationStatus={type} />
        </TableCell>
        <TableCell align="left">{`${arrivalValue || '-'} ${arrivalCurrency?.title || '-'}`}</TableCell>
        <TableCell align="left">{`${expenseValue || '-'} ${expenseCurrency?.title || '-'}`}</TableCell>
        <TableCell align="left">{rate}</TableCell>
        <TableCell align="left">{departmentTitle}</TableCell>
        <TableCell align="left">{`${lastname} ${firstname} ${patronymic}`}</TableCell>
        <TableCell align="left">{time}</TableCell>
        <TableCell align="center">{translations.storno[storno]}</TableCell>
        <TableCell align="center">
          {!comment || (
          <Tooltip title="Подивитись коментар">
            <IconButton onClick={() => setOpen(!open)} color="primary" sx={{ mb: 1, mt: 1 }}><ChatRoundedIcon /></IconButton>
          </Tooltip>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: 0, borderBottom: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table className="admin-operations-sub-table">
              <TableHead>
                <TableRow>
                  <TableCell>Коментар</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{comment}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

AdminOperationsItem.propTypes = {
  item: PropTypes.object
};

export default AdminOperationsItem;
