import React, { useContext, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { CashierContext } from '../../components/CashierContext';
import { Report } from './Report';

export const AdminSessionReport = () => {
  const { id } = useParams();
  const { fetchCashierReportFile,
    fetchCashierReport, cashierReport } = useContext(CashierContext);

  useEffect(() => {
    fetchCashierReport({ id });
  }, [fetchCashierReport, id]);

  const downloadReportSession = useCallback(() => {
    fetchCashierReportFile({
      id
    });
  },
  [id, fetchCashierReportFile]);

  return (
    <>
      <Report
        cashierReport={cashierReport}
        isCentral
        downloadReportSession={downloadReportSession}
      />
    </>
  );
};
