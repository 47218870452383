import React, { useCallback, useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import {
  FormControl, MenuItem, InputLabel,
  Select, InputAdornment, TextField,
  Stack, Grid
} from '@mui/material';
import isEmpty from 'lodash.isempty';
import { CashierContext } from '../../../../components/CashierContext';

const ModalWindowEditBuySell = ({
  order,
  setIsDisabled,
  selectedTransitType, keyName, keyValue }) => {
  const { cashierCurrency } = useContext(CashierContext);
  const { currentOrder = {}, orderItems = [] } = order || {};
  const [currencyId, setCurrencyId] = useState('');
  const [currencyValue, setCurrencyValue] = useState('');
  const onChangeCurrencyId = useCallback((e) => {
    setCurrencyId(e.target.value);
    setIsDisabled(false);
  }, [setIsDisabled]);
  const onChangeCurrencyValue = useCallback((e) => setCurrencyValue(e.target.value.replace(/[^0-9,.]/g, '')), []);
  useEffect(() => {
    setCurrencyId(currentOrder[keyName]);
    setCurrencyValue(currentOrder[keyValue]);
    if (currentOrder[keyName] === null) setIsDisabled(true);
  }, [currentOrder, keyName, keyValue, setIsDisabled]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 3.2 + ITEM_PADDING_TOP,
        width: 200
      }
    }
  };

  const renderCurrencies = useMemo(() => {
    if (isEmpty(cashierCurrency)) {
      return null;
    }
    if (!isEmpty(cashierCurrency)) {
      const filteredOrders = orderItems?.filter((item) => item.order_item_type === selectedTransitType && item.arrival_status !== 'Отменено' && item.expense_status !== 'Отменено');
      if (filteredOrders) {
        return cashierCurrency.map((item) => (
          <MenuItem
            key={nanoid()}
            value={item.id}
            disabled={filteredOrders.map((elm) => elm[keyName]).includes(item.id)}
          >
            {item.title}
          </MenuItem>
        ));
      }
      return cashierCurrency.map((item) => (
        <MenuItem key={nanoid()} value={item.id}>{item.title}</MenuItem>
      ));
    }
  }, [cashierCurrency, orderItems, selectedTransitType, keyName]);

  return (
    <>
      <Grid item xs={6}>
        <Stack spacing={4} sx={{ mb: 2 }}>
          <FormControl sx={{ minWidth: 200 }} size="normal">
            <InputLabel>Валюта</InputLabel>
            <Select
              required
              autoWidth
              name={keyName}
              label="Валюта"
              placeholder="Валюта"
              value={currencyId}
              MenuProps={MenuProps}
              onChange={onChangeCurrencyId}
            >
              {renderCurrencies}
            </Select>
          </FormControl>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack spacing={4} sx={{ mb: 2 }}>
          <FormControl sx={{ minWidth: 160 }} size="normal">
            <TextField
              required
              label="Сума"
              type="number"
              onWheel={(e) => e.target.blur()}
              name={keyValue}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currencyId ? cashierCurrency.find((item) => item.id === currencyId).title.toUpperCase() : ''}
                  </InputAdornment>
                ),
                inputProps: {
                  min: 0.01,
                  step: 0.01
                }
              }}
              value={currencyValue}
              onChange={(e) => {
                onChangeCurrencyValue(e);
                if (!e.target.value || +e.target.value === 0) {
                  setIsDisabled(true);
                } else setIsDisabled(false);
              }}
            />
          </FormControl>
        </Stack>
      </Grid>
    </>
  );
};

ModalWindowEditBuySell.propTypes = {
  selectedTransitType: PropTypes.string,
  order: PropTypes.object,
  keyName: PropTypes.string,
  setIsDisabled: PropTypes.func,
  keyValue: PropTypes.string
};

export default ModalWindowEditBuySell;
