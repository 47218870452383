import React, { useContext, useState, useMemo, useCallback, useEffect } from 'react';
import { Button, Collapse, Box, Stepper, Step, StepLabel, Tooltip } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { useDynamicForm } from '../../../../hooks/useDynamicForm';
import { AuthorisationContext } from '../../../components/AuthorisationContext';
import { DashboardContext } from '../../../components/DashboardContext';
import { NotificationContext } from '../../../components/NotificationContext/notificationContext';
import StepOneCentralDep from './StepOneCentralDep';
import StepOneRegionalDep from './StepOneRegionalDep';
import StepTwo from './StepTwo';
import { steps } from './consts';
import './style.scss';
import { errorTranslation } from '../../../../constants/ErrorsTranslations';

const CashTransitCashierCreateRequest = (
  { sessionDataId, cashierCurrency, setReFetch, setSnackbar, inkassators }
) => {
  const { cashierData: {
    id: depId,
    isCentral,
    title: departmentTitle,
    filial_id: filialId
  } } = useContext(AuthorisationContext);
  const { createNewOrderNotification } = useContext(NotificationContext);
  const { fetchPostOrder, isFetchingPostOrder } = useContext(DashboardContext);
  const [open, setOpen] = useState(false);
  const [errorMassage, setErrorMassage] = useState('');
  const [orderName, setOrderName] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [isEditOperation, setIsEditOperation] = useState('');
  const {
    data: cashTransfer,
    updateSelectField: setCashTransit,
    clearData: clearSelected,
    multiSet,
    isAllEmpty
  } = useDynamicForm({
    department_receiver_id: '',
    filial_id: filialId,
    inkas_id: '',
    order_items: []
  });
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setErrorMassage('');
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    setOrderName(`localOrderOfDep_id${depId}`);
    handleReset();
    if (localStorage.getItem(orderName)) {
      const gettedOrder = JSON.parse(localStorage.getItem(orderName));
      multiSet({ ...JSON.parse(localStorage.getItem(orderName)) });
      if (
        (isCentral && gettedOrder.inkas_id !== '' && gettedOrder.department_receiver_id !== '')
      || (!isCentral && gettedOrder.department_receiver_id !== '')
      ) {
        setActiveStep(1);
      }
    } else clearSelected();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderName, depId]);

  useEffect(() => {
    if (orderName) localStorage.setItem(orderName, JSON.stringify(cashTransfer));
  }, [cashTransfer, orderName]);

  const removeOperation = useCallback(
    (index) => {
      multiSet({
        order_items: cashTransfer.order_items.filter((_, i) => i !== index)
      });
    }, [cashTransfer.order_items, multiSet]);
  const editOperation = useCallback(
    (updateOrder, index) => {
      multiSet({
        order_items: cashTransfer.order_items.map(
          (order, i) => (i === index ? { ...order, ...updateOrder } : order)
        )
      });
    }, [cashTransfer.order_items, multiSet]);

  const onPostOrder = useCallback(async (transferData = {}) => {
    if (!transferData.inkas_id) {
      // eslint-disable-next-line no-param-reassign
      delete transferData.inkas_id;
    }
    const messages = await fetchPostOrder(transferData);
    setErrorMassage(messages || '');
    if (!messages) {
      createNewOrderNotification({
        department_id: transferData.department_receiver_id,
        message: `У Вас нова заявка від відділення "${departmentTitle}".`
      });
      localStorage.removeItem(orderName);
      clearSelected();
      setReFetch((_) => !_);
      setSnackbar({ open: true, massage: 'Заявку успішно створено' });
      handleReset();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchPostOrder, clearSelected, orderName
  ]);

  const onAddedToOperationList = useCallback((operation) => {
    multiSet({
      order_items: [...cashTransfer.order_items, operation]
    });
  }, [multiSet, cashTransfer.order_items]);

  const stepsBody = useMemo(() => {
    if (activeStep === 0) {
      if (isCentral) {
        return (
          <StepOneCentralDep
            defaultFilial={filialId}
            inkassators={inkassators}
            {...{ sessionDataId, cashTransfer, setCashTransit, clearSelected, depId }}
          />
        );
      } return (
        <StepOneRegionalDep
          {...{ sessionDataId, cashTransfer, setCashTransit }}
        />
      );
    }
    if (activeStep === 1) {
      return (
        <StepTwo
          {...{
            cashTransfer,
            onAddedToOperationList,
            isCentral,
            removeOperation,
            cashierCurrency,
            editOperation,
            setErrorMassage,
            setIsEditOperation,
            isEditOperation
          }}
        />
      );
    }
  }, [activeStep, isCentral, inkassators,
    sessionDataId, cashTransfer, setCashTransit,
    clearSelected, onAddedToOperationList, depId, isEditOperation,
    removeOperation, cashierCurrency, editOperation, filialId]);

  const onNextButton = () => {
    if (activeStep === steps.length - 1) {
      onPostOrder({
        session_id: sessionDataId,
        ...cashTransfer
      });
    } else handleNext();
  };

  const isNextStepDisabled = useMemo(() => {
    if (activeStep === 0) {
      return ((isCentral || !cashTransfer.department_receiver_id) && isAllEmpty);
    }
    if (activeStep === 1) {
      return !cashTransfer.order_items.length;
    }
  }, [activeStep, cashTransfer.department_receiver_id, isAllEmpty, cashTransfer.order_items.length, isCentral]);

  return (
    <div className="create-request">
      <Button
        fullWidth
        onClick={() => setOpen(!open)}
        startIcon={<AddCircleRoundedIcon />}
        endIcon={open ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
        variant="outlined"
        size="large"
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        Створити заявку

      </Button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ width: '100%',
          backgroundColor: 'var(--secondary-bg)',
          margin: '0 0 1rem 0',
          padding: '1rem 0.7rem',
          borderRadius: '0 0 5px 5px' }}
        >
          <Stepper activeStep={activeStep}>
            {steps.map((label) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step sx={{ color: 'var(--primary-text)' }} key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Box sx={{ mt: 4, mb: 1 }}>{stepsBody}</Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', pt: 2 }}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
              variant="contained"
            >
              <ArrowBackIosRoundedIcon fontSize="small" />
            </Button>
            <Box sx={{ flex: '1 1 auto', pl: '1rem' }}>
              {errorMassage in errorTranslation ? errorTranslation[errorMassage] : errorMassage}
            </Box>
            <Tooltip title={activeStep === steps.length - 1 ? 'Створити заявку' : ''} placement="left">
              <span>
                <LoadingButton
                  loading={isFetchingPostOrder}
                  onClick={onNextButton}
                  disabled={isNextStepDisabled || isEditOperation !== ''}
                  variant="contained"
                >
                  {activeStep === steps.length - 1 ? <SendRoundedIcon fontSize="small" /> : <ArrowForwardIosRoundedIcon fontSize="small" />}
                </LoadingButton>
              </span>
            </Tooltip>
          </Box>
        </Box>
      </Collapse>
    </div>
  );
};

CashTransitCashierCreateRequest.propTypes = {
  sessionDataId: PropTypes.number,
  cashierCurrency: PropTypes.array,
  inkassators: PropTypes.array,
  setReFetch: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired

};

export default CashTransitCashierCreateRequest;
