import { API_URLS } from '../../constants/urls';
import CRUDService from '../CRUDService';

class DepartmentsService extends CRUDService {
  constructor() {
    super(API_URLS.DEPARTMENTS);
    this.postToken = async (body) => this.APIService.apiPost(`${this.pathname}/token`, { body });
  }
}

export default new DepartmentsService();
