import { API_URLS } from '../../constants/urls';
// import { addParamsToUrl } from '../../utils/helpers';
import CRUDService from '../CRUDService';

class SalaryService extends CRUDService {
  constructor() {
    super(API_URLS.SALARY);
  }
}

export default new SalaryService();
