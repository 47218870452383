import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@mui/material';
import { translations } from '../../../../../constants/CashTransitTranslations';
import { OrderStatusButton } from '../../../../components/OrderStatusButton';

const RenderOrder = ({ elm }) => {
  const {
    id, rate,
    order_item_type: orderType,
    arrival_currency_title: arrivalCurrencyTitle,
    arrival_value: arrivalValue,
    expense_currency_title: expenseCurrencyTitle,
    expense_value: expenseValue,
    current_status: currentStatus,
    phase_number: phase
  } = elm;
  const [status, setStatus] = useState('');

  useEffect(() => {
    if (orderType !== 'Выкуп') {
      if (elm.expense_status) {
        setStatus(elm.expense_status);
      } else {
        setStatus(elm.arrival_status);
      }
    } else if (phase === 0) {
      setStatus(elm.arrival_status || elm.expense_status);
    } else {
      setStatus(elm[currentStatus]);
    }
  }, [orderType, elm, currentStatus, phase]);

  return (
    <TableRow key={id}>
      <TableCell>{id}</TableCell>
      <TableCell>{translations.orderType[orderType]}</TableCell>
      <TableCell>{rate}</TableCell>
      <TableCell>{expenseValue ? `${expenseValue} ${expenseCurrencyTitle}` : ''}</TableCell>
      <TableCell>{arrivalValue ? `${arrivalValue} ${arrivalCurrencyTitle}` : ''}</TableCell>
      <TableCell sx={{ maxWidth: 140 }}>
        <OrderStatusButton status={status} />
      </TableCell>
    </TableRow>
  );
};
RenderOrder.propTypes = {
  elm: PropTypes.object
};

export default RenderOrder;
