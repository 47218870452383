import { API_URLS } from '../../constants/urls';
import { addParamsToUrl } from '../../utils/helpers';
import CRUDService from '../CRUDService';

class OrdersService extends CRUDService {
  constructor() {
    super(API_URLS.ORDERS);
    this.getInkassators = async (params) => this.APIService.apiGet(addParamsToUrl(`${this.pathname}/getInkassators/`, params));
    this.putRequestOrderStatus = async (body) => this.APIService.apiPut(`${this.pathname}/${body.id}/status`, { body });
    this.putRequestOrderInkas = async (body) => this.APIService.apiPut(`${this.pathname}/${body.id}/inkasator`, { body });
  }
}

export default new OrdersService();
