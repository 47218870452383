import React, { useEffect, useContext, useState, useCallback, useRef } from 'react';
import './style.scss';
import {
  Button, FormControl, FormHelperText, MenuItem, IconButton,
  InputLabel, InputAdornment, Select, TextField, Grid, Stack, Tooltip
} from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ImportExportRoundedIcon from '@mui/icons-material/ImportExportRounded';
import { useFormik } from 'formik';
import moment from 'moment';
import { nanoid } from 'nanoid';
import validationSchemaCross from './validateForm';
import PageWrapper from '../../common/PageWrapper/PageWrapper';
import { CashierContext } from '../../components/CashierContext';
import { AuthorisationContext } from '../../components/AuthorisationContext';
import ModalWindowCashierConfirm from '../../components/ModalWindowCashierConfirm/ModalWindowCashierConfirm';
import { calcCrossCourse, calcTotalCash } from '../../../utils/helpers';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 3.2 + ITEM_PADDING_TOP,
      width: 215
    }
  }
};

const CrossCoursePage = () => {
  const { cashierData } = useContext(AuthorisationContext);
  const { cashierCurrency, fetchCashierCurrency, sessionData } = useContext(CashierContext);
  const [isEdit, setIsEdit] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    fetchCashierCurrency({
      department_id: cashierData.id,
      date: moment().format('YYYY-MM-DD'),
      id: cashierData.id
    });
  }, [fetchCashierCurrency, cashierData]);

  const formData = useRef({});
  const onSubmitForm = useCallback((values) => {
    const { currencyTitleBuy, currencyTitleSell, comment, crossCourse, sum, totalCash } = values;
    setIsOpenModal(true);
    formData.current = {
      rate: crossCourse,
      current_session: sessionData.id,
      arrival_value: Number(sum),
      operation_type: 'Кросс-курс',
      expense_value: Number(totalCash),
      expense_currency_id: currencyTitleSell,
      arrival_currency_id: currencyTitleBuy
    };
    if (comment !== '') { formData.current.comment = comment; }
  }, [setIsOpenModal, sessionData.id]);

  const formik = useFormik({
    initialValues: {
      currencyTitleBuy: '',
      sum: '',
      currencyTitleSell: '',
      crossCourse: '',
      totalCash: '',
      comment: ''
    },
    validationSchema: validationSchemaCross,
    onSubmit: (values) => {
      onSubmitForm(values);
    }
  });

  const changeTitles = () => {
    const a = formik.values.currencyTitleSell;
    formik.setFieldValue('currencyTitleSell', formik.values.currencyTitleBuy);
    formik.setFieldValue('currencyTitleBuy', a);
    formik.setFieldValue('crossCourse', calcCrossCourse(formik.values.currencyTitleSell, formik.values.currencyTitleBuy, cashierCurrency));
    formik.setFieldValue('totalCash', calcTotalCash(formik.values.sum, calcCrossCourse(formik.values.currencyTitleSell, formik.values.currencyTitleBuy, cashierCurrency)));
  };

  const renderCurrencies = useCallback((isBuy = true) => {
    const disabledItem = isBuy ? formik.values.currencyTitleSell : formik.values.currencyTitleBuy;
    return cashierCurrency?.filter((item) => (item.title !== 'UAH')).map((item) => (
      <MenuItem key={nanoid()} value={item.id} disabled={item.id === disabledItem}>{item.title}</MenuItem>
    ));
  }, [formik, cashierCurrency]);

  const handelEditField = () => {
    setIsEdit((prevState) => !prevState);
  };

  const adornmentTitle = (id) => {
    if (id) {
      return cashierCurrency.find((item) => item.id === id).title;
    } return null;
  };

  useEffect(() => {
    formik.handleReset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashierData]);

  return (
    <PageWrapper>
      <div className="buy-sell-page-title">Крос-курс</div>
      <form className="buy-sell-page-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item md={1}>
            <Tooltip title="Поміняти валюти місцями" placement="left">
              <IconButton onClick={() => changeTitles()} disabled={!formik.values.currencyTitleBuy || !formik.values.currencyTitleSell} sx={{ p: 0 }} variant="outlined" color="primary" size="small">
                <ImportExportRoundedIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item md={5}>
            <Stack spacing={2}>
              <FormControl fullWidth size="normal">
                <InputLabel>Прихід:</InputLabel>
                <Select
                  required
                  autoWidth
                  className="buy-sell-page-currencyTitle"
                  id="currencyTitleBuy"
                  name="currencyTitleBuy"
                  label="Прихід:"
                  placeholder="Прихід"
                  value={formik.values.currencyTitleBuy}
                  MenuProps={MenuProps}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.values.crossCourse = calcCrossCourse(
                      e.target.value,
                      formik.values.currencyTitleSell,
                      cashierCurrency
                    );
                    formik.values.totalCash = calcTotalCash(formik.values.sum, formik.values.crossCourse);
                  }}
                  error={formik.touched.currencyTitleBuy && Boolean(formik.errors.currencyTitleBuy)}
                >
                  {renderCurrencies(true)}
                </Select>
                {(formik.touched.currencyTitle && formik.errors.currencyTitle) && (
                <FormHelperText error>{formik.errors.currencyTitle}</FormHelperText>)}
              </FormControl>
              <FormControl fullWidth size="normal">
                <InputLabel>Витрата:</InputLabel>
                <Select
                  required
                  autoWidth
                  className="buy-sell-page-currencyTitle"
                  id="currencyTitleSell"
                  name="currencyTitleSell"
                  label="Витрата:"
                  placeholder="Витрата"
                  value={formik.values.currencyTitleSell}
                  MenuProps={MenuProps}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.values.crossCourse = calcCrossCourse(
                      formik.values.currencyTitleBuy,
                      e.target.value,
                      cashierCurrency);
                    formik.values.totalCash = calcTotalCash(formik.values.sum, formik.values.crossCourse);
                  }}
                  error={formik.touched.currencyTitleSell && Boolean(formik.errors.currencyTitleSell)}
                >
                  {renderCurrencies(false)}
                </Select>
                {(formik.touched.currencyTitle && formik.errors.currencyTitle) && (
                <FormHelperText error>{formik.errors.currencyTitle}</FormHelperText>)}
              </FormControl>
            </Stack>
          </Grid>
          <Grid item md={6}>
            <Stack spacing={2}>
              <FormControl fullWidth size="normal">
                <TextField
                  required
                  id="sum"
                  name="sum"
                  label="Сума"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{adornmentTitle(formik.values.currencyTitleBuy)}</InputAdornment>,
                    inputProps: {
                      min: 0,
                      step: 0.01
                    }
                  }}
                  value={formik.values.sum}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.values.totalCash = calcTotalCash(e.target.value, formik.values.crossCourse);
                  }}
                  error={formik.touched.sum && Boolean(formik.errors.sum)}
                  helperText={formik.touched.sum && formik.errors.sum}
                />
              </FormControl>
              <FormControl fullWidth size="normal">
                <TextField
                  className="buy-sell-page-totalCash"
                  id="totalCash"
                  name="totalCash"
                  label="Підсумок:"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  InputProps={{
                    readOnly: true,
                    startAdornment: <InputAdornment position="start">{adornmentTitle(formik.values.currencyTitleSell)}</InputAdornment>,
                    inputProps: {
                      min: 0
                    }
                  }}
                  value={formik.values.totalCash}
                  onChange={formik.handleChange}
                  error={formik.touched.totalCash && Boolean(formik.errors.totalCash)}
                  helperText={formik.touched.totalCash && formik.errors.totalCash}
                />
              </FormControl>

            </Stack>
          </Grid>
          <Grid item md={12}>
            <FormControl fullWidth size="normal">
              <TextField
                type="number"
                onWheel={(e) => e.target.blur()}
                disabled={!isEdit}
                InputProps={{
                  endAdornment:
  <InputAdornment position="end">
    <Tooltip title="Редагування крос-курсу для однієї операції" placement="right">
      <IconButton
        onClick={handelEditField}
        disabled={!formik.values.currencyTitleSell || !formik.values.currencyTitleBuy}
      >
        <EditRoundedIcon color={isEdit ? 'primary' : 'disabled'} />
      </IconButton>
    </Tooltip>
  </InputAdornment>,
                  inputProps: {
                    min: 0,
                    step: 0.00000000001
                  }
                }}
                required
                id="CrossCourse"
                name="CrossCourse"
                label={`Крос-курс на ${moment().format('D.MM.YYYY')}`}
                value={formik.values.crossCourse}
                onChange={(e) => {
                  formik.values.crossCourse = e.target.value;
                  formik.handleChange(e);
                  formik.values.totalCash = calcTotalCash(formik.values.sum, e.target.value);
                }}
                error={formik.touched.crossCourse && Boolean(formik.errors.crossCourse)}
                helperText={formik.touched.crossCourse && formik.errors.crossCourse}
              />
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <FormControl fullWidth size="normal">
              <TextField
                type="text"
                multiline
                rows={4}
                id="comment"
                name="comment"
                label="Коментар"
                value={formik.values.comment}
                onChange={formik.handleChange}
                error={formik.touched.comment && Boolean(formik.errors.comment)}
                helperText={formik.touched.comment && formik.errors.comment}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className="addUsers-actions">
          <Button disabled={formik.values.sum === 0 || !formik.values.sum || !formik.values.crossCourse || +formik.values.crossCourse === 0} variant="contained" type="submit">
            Оформити
          </Button>
        </div>
      </form>
      {isOpenModal
      && (
      <ModalWindowCashierConfirm
        open={isOpenModal}
        data={formData.current}
        handleClose={() => setIsOpenModal(false)}
      />
      )}
    </PageWrapper>
  );
};

export default CrossCoursePage;
