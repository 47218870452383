import React, { useState, useCallback } from 'react';
import { AuthorisationContext, defaultContext } from './authorisationContext';
import LogoutService from '../../../services/AUTH/LogoutService';
import UsersService from '../../../services/USERS/UsersService';
import AlertSnackbar from '../../common/Snackbar/AlertSnackbar';
import { clearLocalStorageWithoutToken } from '../../../utils/clearLocalStorageWhitOutPcToken';

const AuthorisationProvider = ({ children }) => {
  const [token, setToken] = useState(() => localStorage.getItem('secret'));
  const [user, setUser] = useState(() => JSON.parse(localStorage.getItem('user')));
  const [cashierData, setCashierData] = useState(() => JSON.parse(localStorage.getItem('cashier')));
  const [availableDepartments, setAvailableDepartments] = useState(() => JSON.parse(localStorage.getItem('availableDepartments') || '[]'));
  const userRole = localStorage.getItem('role');
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [snackbarType, setSnackbarType] = useState(false);

  const login = useCallback((tokenToSet) => {
    setToken(tokenToSet);
    localStorage.setItem('secret', tokenToSet);
  }, []);

  const updateUser = useCallback((data) => {
    const { role: { title } } = data;
    setUser(data);
    localStorage.setItem('role', title);
    localStorage.setItem('user', JSON.stringify(data));
  }, []);

  const selectCashierByIndex = useCallback((index) => {
    setCashierData(availableDepartments[index]);
    localStorage.setItem('cashier', JSON.stringify(availableDepartments[index]));
  }, [availableDepartments]);

  const updateCashier = useCallback((data) => {
    const { departments } = data;
    setCashierData((cashier) => (
      departments.find((department) => department.id === cashier?.id) || departments[0]
    ));
    setAvailableDepartments(departments);
    localStorage.setItem('cashier', JSON.stringify(departments[0]));
    localStorage.setItem('availableDepartments', JSON.stringify(departments));
  }, []);

  const logout = useCallback(async () => {
    try {
      const response = await LogoutService.postRequest();
      if (response.data === 'Success') {
        setToken(defaultContext.token);
        clearLocalStorageWithoutToken();
      }
    // eslint-disable-next-line no-empty
    } catch {}
  }, []);

  const fetchUpdateUserPassword = async (data, id) => {
    try {
      const response = await UsersService.postSetPasswordRequest(data, id);
      if (response?.code === 200 && response?.data === 'Success') {
        updateUser({
          ...user,
          change_password: false
        });
        setSnackbarMsg('Пароль встановлено');
        setSnackbarType(true);
        return 'success';
      }
      if ('success' in response && !response.success) {
        setSnackbarMsg(response.message);
        setSnackbarType(false);
      }
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      setIsOpenSnackbar(true);
    }
  };

  const updateUserThemeColor = async (data) => {
    try {
      const response = await UsersService.putThemeColor(data);
      if (response?.data) {
        updateUser(response.data);
        setSnackbarMsg('Тему змінено');
        setSnackbarType(true);
        return 'success';
      }
      if ('success' in response && !response.success) {
        setSnackbarMsg(response.message);
        setSnackbarType(false);
      }
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      setIsOpenSnackbar(true);
    }
  };

  const contextData = {
    user,
    token,
    login,
    logout,
    userRole,
    updateUser,
    cashierData,
    updateCashier,
    availableDepartments,
    selectCashierByIndex,
    fetchUpdateUserPassword,
    updateUserThemeColor
  };

  if (children instanceof Function) {
    return children(contextData);
  }

  return (
    <>
      <AuthorisationContext.Provider value={contextData}>
        {children}
      </AuthorisationContext.Provider>
      <AlertSnackbar
        onClose={() => setIsOpenSnackbar(false)}
        isOpen={isOpenSnackbar}
        success={snackbarType}
        message={snackbarMsg}
      />
    </>
  );
};

export default AuthorisationProvider;
