import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Button, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { translations } from '../../../../constants/CashTransitTranslations';
import { OrderStatusButton } from '../../../components/OrderStatusButton';

const RenderAndEditOrder = ({
  elm, onClickConfirm, onClickEdit, isCentral, inkassator,
  sessionData, departmentReceiver, departmentCreator, cashierCurrency, orderItems }) => {
  const [currentStatus, setCurrentStatus] = useState('');
  const [secondStatus, setSecondStatus] = useState('');
  const [statusKey, setStatusKey] = useState('');
  const [secondStatusKey, setSecondStatusKey] = useState('');
  const checkValue = useCallback((value, currencyId) => {
    if (value && currencyId) {
      const currencyTitle = cashierCurrency?.find((currency) => currency.id === currencyId)?.title;
      return `${value} ${currencyTitle}`;
    } return '';
  }, [cashierCurrency]);

  useEffect(() => {
    if (elm.order_item_type !== 'Выкуп') {
      if (elm.expense_status) {
        setCurrentStatus(elm.expense_status);
        setStatusKey('expense_status');
      } else {
        setCurrentStatus(elm.arrival_status);
        setStatusKey('arrival_status');
      }
    } else {
      if (elm.phase_number === 0) {
        if (elm.arrival_status !== 'Ожидает подтверждения') {
          setCurrentStatus(departmentCreator.id === sessionData.department.id
            ? elm.expense_status
            : elm.arrival_status);
          setStatusKey(departmentCreator.id === sessionData.department.id
            ? 'expense_status'
            : 'arrival_status');
        } else {
          setCurrentStatus(elm.arrival_status);
          setStatusKey('arrival_status');
        }
      }
      if (elm.phase_number === 1) {
        setCurrentStatus(elm[elm.current_status]);
        setStatusKey(elm.current_status);
        setSecondStatus(elm[elm.current_status] === elm.arrival_status ? elm.expense_status : elm.arrival_status);
        setSecondStatusKey(elm.current_status === 'expense_status' ? 'arrival_status' : 'expense_status');
      }
      if (elm.phase_number === 2) {
        setCurrentStatus(elm[elm.current_status]);
        setStatusKey(elm.current_status);
      }
    }
  }, [elm, departmentCreator.id, sessionData.department.id]);

  const renderCurrentStatus = useMemo(() => <OrderStatusButton status={currentStatus} />,
  // eslint-disable-next-line react-hooks/exhaustive-deps
    [elm, departmentCreator.id, sessionData.department.id, currentStatus]);

  const sendBtnDisabledStatus = (status) => {
    if (isCentral) {
      if (elm.swap) {
        if (elm.order_item_type === 'Инкассация') {
          if (status === 'Отменено'
          || (sessionData.department.id !== departmentReceiver.id && status === 'Ожидает подтверждения')
          || (!departmentCreator.isCentral && status === 'Подтверждено')
          || (departmentCreator.isCentral && status === 'Едет в кассу')
          || (!departmentCreator.isCentral && status === 'Возврат-едет в кассу')
          || status === 'Возвращено'
          || status === 'Выполнено') return true;
        }
        if (elm.order_item_type === 'Подкрепление') {
          if (
            (!departmentCreator.isCentral && status === 'Едет в кассу')
          || status === 'Отменено'
          || status === 'Возвращено'
          || status === 'Выполнено'
          || (departmentReceiver.isCentral && departmentCreator.isCentral && status === 'Подтверждено')
          || (departmentCreator.isCentral && status === 'Возврат-едет в кассу')
          ) return true;
        }
        if (elm.order_item_type === 'Выкуп') {
          if (
            (elm.phase_number === 2 && elm.current_status === 'arrival_status' && status === 'Едет в кассу')
          || (elm.current_status === 'expense_status' && status === 'Возврат-едет в кассу')
          || status === 'Возвращено'
          || status === 'Выполнено'
          || (elm.current_status === 'arrival_status' && status === 'Едет в кассу')
          || status === 'Отменено'
          ) return true;
        }
      } else {
        if (elm.order_item_type === 'Инкассация') {
          if (status === 'Отменено'
        || (sessionData.department.id !== departmentReceiver.id && status === 'Ожидает подтверждения')
        || (sessionData.department.id !== departmentReceiver.id && status === 'Подтверждено')
        || (sessionData.department.id === departmentReceiver.id && status === 'Едет в кассу')
        || (sessionData.department.id !== departmentReceiver.id && status === 'Возврат-едет в кассу')
        || status === 'Возвращено'
        || status === 'Выполнено') return true;
        }
        if (elm.order_item_type === 'Подкрепление') {
          if (status === 'Едет в кассу'
        || status === 'Отменено'
        || status === 'Возвращено'
        || status === 'Выполнено'
          ) return true;
        }
        if (elm.order_item_type === 'Выкуп') {
          if (status === 'Ожидает подтверждения'
          || (status === 'Едет в кассу' && elm.arrival_status === 'Выполнено')
          || (status === 'Едет в кассу' && elm.arrival_status === 'Подтверждено')
          || status === 'Выполнено'
          || (elm.current_status === 'arrival_status' && status === 'Возврат-едет в кассу')
          || status === 'Возвращено'
          || status === 'Отменено'
          ) return true;
        }
      }
    }
    if (!isCentral) {
      if (elm.order_item_type === 'Инкассация') {
        if (status === 'Отменено'
        || status === 'Едет в кассу'
        || (!inkassator && status === 'Подтверждено')
        || (sessionData.department.id !== departmentReceiver.id && status === 'Ожидает подтверждения')
        || (sessionData.department.id === departmentReceiver.id && status === 'Едет в кассу')
        || status === 'Возвращено'
        || status === 'Выполнено') return true;
      }
      if (elm.order_item_type === 'Подкрепление') {
        if (status === 'Отменено'
        || (sessionData.department.id !== departmentReceiver.id && status === 'Ожидает подтверждения')
        || (status === 'Подтверждено')
        // || status === 'Едет в кассу'
        // || (sessionData.department_id === departmentId.id && status === 'Едет в кассу')
        || status === 'Возвращено'
        || status === 'Выполнено'
        || status === 'Возврат-едет в кассу'
        ) return true;
      }
      if (elm.order_item_type === 'Выкуп') {
        if ((sessionData.department.id !== departmentReceiver.id && status === 'Ожидает подтверждения')
        || (!inkassator && status === 'Подтверждено')
        || (elm.phase_number === 1 && elm.current_status === 'expense_status' && departmentCreator.id === sessionData.department.id && status === 'Едет в кассу')
        || (elm.phase_number === 1 && elm.current_status === 'arrival_status' && departmentCreator.id !== sessionData.department.id && status === 'Едет в кассу')
        || (elm.phase_number === 2 && elm.current_status === 'expense_status' && departmentCreator.id === sessionData.department.id && status === 'Едет в кассу')
        || status === 'Выполнено'
        || status === 'Возвращено'
        || (elm.phase_number === 2 && departmentCreator.id !== sessionData.department.id && elm.current_status === 'arrival_status')
        || (departmentCreator.id !== sessionData.department.id && elm.expense_status === 'Возврат-едет в кассу')
        || (departmentCreator.id === sessionData.department.id && elm.arrival_status === 'Возврат-едет в кассу')
        || status === 'Отменено'
        ) return true;
      }
    }
  };

  const editBtnDisabledStatus = (status) => (
    status === 'Отменено'
    || status === 'Едет в кассу'
    || status === 'Возврат-едет в кассу'
    || status === 'Возвращено'
    || status === 'Выполнено'
  );
  const cancelBtnDisabledStatus = (status) => (
    status === 'Отменено'
    || status === 'Возврат-едет в кассу'
    || status === 'Возвращено'
    || status === 'Выполнено'
    || (elm.order_item_type === 'Выкуп' && !isCentral && status === 'Едет в кассу')
    || (elm.phase_number === 2)
  );

  const renderValuesNCurrency = useMemo(() => {
    if (elm.swap) {
      return (
        <>
          <TableCell>{checkValue(elm.arrival_value, elm.arrival_currency_id)}</TableCell>
          <TableCell>{checkValue(elm.expense_value, elm.expense_currency_id)}</TableCell>
        </>
      );
    }
    return (
      <>
        <TableCell>{checkValue(elm.expense_value, elm.expense_currency_id)}</TableCell>
        <TableCell>{checkValue(elm.arrival_value, elm.arrival_currency_id)}</TableCell>
      </>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elm, checkValue, cashierCurrency]);

  return (
    <TableRow key={elm.id}>
      <TableCell>{elm.id}</TableCell>
      <TableCell>{translations.orderType[elm.order_item_type]}</TableCell>
      <TableCell>{elm.rate}</TableCell>
      {renderValuesNCurrency}
      <TableCell sx={{ width: 290 }}>{renderCurrentStatus}</TableCell>
      <TableCell sx={{ paddingRight: 0.1, paddingLeft: 0.5 }}>
        <Tooltip title="Оновити статус">
          <Button
            onClick={() => onClickConfirm(
              true,
              currentStatus,
              secondStatus,
              statusKey,
              secondStatusKey,
              elm.id,
              sessionData.department.id,
              departmentCreator,
              departmentReceiver,
              inkassator
            )}
            disabled={sendBtnDisabledStatus(currentStatus)}
            variant="contained"
            color="success"
            size="small"
          >
            {currentStatus === 'Возврат-едет в кассу' || currentStatus === 'Едет в кассу' ? (
              <CheckRoundedIcon fontSize="small" />
            ) : (
              <SendRoundedIcon fontSize="small" />
            )}
          </Button>
        </Tooltip>
      </TableCell>
      {isCentral && (
        <TableCell sx={{ padding: 0.1 }}>
          <Tooltip title="Редагувати">
            <Button
              onClick={() => onClickEdit(elm, departmentCreator, orderItems,
                departmentReceiver, sessionData.department.id)}
              variant="contained"
              color="warning"
              size="small"
              disabled={editBtnDisabledStatus(currentStatus)}
            >
              <EditRoundedIcon fontSize="small" />
            </Button>
          </Tooltip>
        </TableCell>
      )}
      <TableCell sx={{ paddingLeft: 0.1, paddingRight: 0.1 }}>
        <Tooltip title="Скасувати">
          <Button
            onClick={() => onClickConfirm(
              false,
              currentStatus,
              secondStatus,
              statusKey,
              secondStatusKey,
              elm.id,
              sessionData.department.id,
              departmentCreator,
              departmentReceiver
            )}
            variant="contained"
            color="error"
            size="small"
            disabled={cancelBtnDisabledStatus(currentStatus)}
          >
            {currentStatus === 'Едет в кассу' || currentStatus === 'Возврат-едет в кассу' ? (
              <ReplayRoundedIcon fontSize="small" />
            ) : (
              <CancelIcon fontSize="small" />
            )}
          </Button>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};
RenderAndEditOrder.propTypes = {
  elm: PropTypes.object,
  inkassator: PropTypes.object,
  isCentral: PropTypes.bool,
  onClickConfirm: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  sessionData: PropTypes.object,
  departmentReceiver: PropTypes.object,
  departmentCreator: PropTypes.object,
  cashierCurrency: PropTypes.array,
  orderItems: PropTypes.array
};

export default RenderAndEditOrder;
