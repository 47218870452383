import { Button } from '@mui/material';
import React from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import moment from 'moment';

export const Notification = (
  { messages, userID, departmentID, notificationRead, getNotification, page, perPage, setReFetchOrders }) => (
    <div className="modalNotification">
      <div className="modalNotification__count">
        {'Кількість повідомлень: '}
        {messages.length}
      </div>
      <p className="modalNotification__title">{messages[0]?.name}</p>
      <div className="modalNotification__text">{messages[0]?.message}</div>
      <div className="modalNotification__footer">
        <div className="modalNotification__date">{moment(messages[0]?.startDate).format('DD.MM.YYYY')}</div>
        <div className="btn">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              notificationRead(messages[0]?.id, departmentID, userID);
              getNotification(page, perPage);
              if (setReFetchOrders) {
                setReFetchOrders((prev) => !prev);
              }
            }}
          >
            Прочитано
          </Button>
        </div>
      </div>
    </div>
);

Notification.propTypes = {
  notificationRead: PropTypes.func,
  getNotification: PropTypes.func,
  setReFetchOrders: PropTypes.func,
  messages: PropTypes.array,
  userID: PropTypes.number,
  departmentID: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number
};
