import React from 'react';
import './style.scss';
import { NavLink } from 'react-router-dom';
import { Button, Container } from '@mui/material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddCardIcon from '@mui/icons-material/AddCard';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import LocationCityRoundedIcon from '@mui/icons-material/LocationCityRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import HomeWorkRoundedIcon from '@mui/icons-material/HomeWorkRounded';

const links = [
  { link: '/balance', text: 'Баланси', icon: <AccountBalanceWalletIcon /> },
  { link: '/admin/operations', text: 'Операції', icon: <AccountTreeIcon /> },
  { link: '/reports/balance', text: 'Звіти', icon: <SummarizeIcon /> },
  { link: '/admin/sessions', text: 'Зміни', icon: <WorkHistoryIcon /> },
  { link: '/admin/currency', text: 'Курси валют НБУ', icon: <CurrencyExchangeIcon /> },
  { link: '/admin/salary', text: 'Зарплати', icon: <AddCardIcon /> },
  { link: '/cash-in-transit', text: 'Інкасації', icon: <AccountBalanceIcon /> },
  { link: '/settings/filials', text: 'Регіони', icon: <LocationCityRoundedIcon /> },
  { link: '/settings/users', text: 'Користувачі', icon: <PeopleRoundedIcon /> },
  { link: '/settings/departments', text: 'Відділення', icon: <HomeWorkRoundedIcon /> }
];

export const SideBarAccountantMenu = () => (
  <Container className="sideBar">
    <div className="sideBar-userRole">Бухгалтер</div>
    <div className="sideBar-menu">
      {links.map(({ link, text, icon }) => (
        <Button
          key={link}
          component={NavLink}
          to={link}
          activeClassName="sideBar-menu-item-active"
          className="sideBar-menu-item"
        >
          {icon}
          <span>{text}</span>
        </Button>
      ))}
    </div>
  </Container>
);
