import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow
} from '@mui/material';
import StepTwoRenderOperationRowBuySell from './StepTwoRenderOperationRowBuySell';
import StepTwoRenderOperationRowCrossCourse from './StepTwoRenderOperationRowCrossCourse';

const StepTwoTotalTable = ({ orderItems, cashierCurrency, removeOperation,
  editOperation, setErrorMassage, setIsEditOperation, isEditOperation }) => {
  const renderOperationRow = useMemo(() => (orderItems?.map((order, index) => {
    if (order.order_item_type === 'Выкуп') {
      return (
        <StepTwoRenderOperationRowCrossCourse
          order={order}
          index={index}
          key={order.localId}
          removeOperation={removeOperation}
          editOperation={editOperation}
          cashierCurrency={cashierCurrency}
          setErrorMassage={setErrorMassage}
          setIsEditOperation={setIsEditOperation}
          isEditOperation={isEditOperation}
        />
      );
    }
    return (
      <StepTwoRenderOperationRowBuySell
        orderItems={orderItems}
        order={order}
        index={index}
        key={order.localId}
        removeOperation={removeOperation}
        editOperation={editOperation}
        cashierCurrency={cashierCurrency}
        setErrorMassage={setErrorMassage}
        setIsEditOperation={setIsEditOperation}
        isEditOperation={isEditOperation}
      />
    );
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [orderItems, removeOperation, cashierCurrency, editOperation, setErrorMassage, isEditOperation]);

  return (
    <div className="users-table">
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ pl: 0 }}>Тип</TableCell>
              <TableCell>Курс</TableCell>
              <TableCell>Прихід</TableCell>
              <TableCell>Розхід</TableCell>
              <TableCell colSpan={2} align="center">Дія</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderOperationRow}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

StepTwoTotalTable.propTypes = {
  orderItems: PropTypes.array,
  cashierCurrency: PropTypes.array,
  removeOperation: PropTypes.func.isRequired,
  setErrorMassage: PropTypes.func.isRequired,
  setIsEditOperation: PropTypes.func.isRequired,
  isEditOperation: PropTypes.string,
  editOperation: PropTypes.func.isRequired
};

export default StepTwoTotalTable;
