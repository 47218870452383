import React, { useContext, useEffect, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import './style.scss';
import PageWrapper from '../../common/PageWrapper/PageWrapper';
import BalanceCDCashier from './BalanceCDCashier';
import { AuthorisationContext } from '../../components/AuthorisationContext';
import { CashierContext } from '../../components/CashierContext';
import { HorizontalScrollWrapper } from '../../components/HorizontalScrollWrapper';

const BalanceCashierPage = () => {
  const { cashierData } = useContext(AuthorisationContext);
  const { sessionData, fetchCashierBalance, cashierBalance } = useContext(CashierContext);

  useEffect(() => {
    fetchCashierBalance({
      id: cashierData.id,
      current_session: sessionData.id
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashierData.id, sessionData.id]);

  const renderBalanceRow = useMemo(() => {
    const cellGenerator = (key, value, component = 'td') => (
      <TableCell key={key} component={component} align="left">
        {value}
      </TableCell>
    );
    const titleRow = [cellGenerator('title', 'Період', 'th')];
    const startBalanceRow = [cellGenerator('start', 'На ранок:')];
    const currentBalanceRow = [cellGenerator('current', 'Зараз:')];
    const summaryRow = [cellGenerator('summary', 'Різниця:')];
    cashierBalance.forEach(({ currency, balance_at_beginning_session: balanceBegin, balance }, i) => {
      titleRow.push(cellGenerator(`title-${currency.title}-${i}`, currency.title, 'th'));
      startBalanceRow.push(cellGenerator(`start-${currency.title}-${i}`, balanceBegin));
      currentBalanceRow.push(cellGenerator(`current-${currency.title}-${i}`, balance));
      summaryRow.push(cellGenerator(`summary-${currency.title}-${i}`, (balance - balanceBegin).toFixed(2)));
    });
    return { titleRow, startBalanceRow, currentBalanceRow, summaryRow };
  }, [cashierBalance]);

  return (
    <PageWrapper>
      <div className="buy-sell-page-title">Баланси Відділення</div>
      <HorizontalScrollWrapper className="users-table balanceCashier-container balanceCashier-department">
        <Table className="balance-cashier-table" sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>{renderBalanceRow.titleRow}</TableRow>
          </TableHead>
          <TableBody>
            <TableRow>{renderBalanceRow.startBalanceRow}</TableRow>
            <TableRow>{renderBalanceRow.currentBalanceRow}</TableRow>
            <TableRow>{renderBalanceRow.summaryRow}</TableRow>
          </TableBody>
        </Table>
      </HorizontalScrollWrapper>
      {cashierData.isCentral
      && (
      <div className="balanceCashier-filial">
        <div className="buy-sell-page-title">Баланси регіону</div>
        <BalanceCDCashier />
      </div>
      )}
    </PageWrapper>
  );
};

export default BalanceCashierPage;
