import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import FiberNewRoundedIcon from '@mui/icons-material/FiberNewRounded';
import AccessAlarmRoundedIcon from '@mui/icons-material/AccessAlarmRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';

import { translations } from '../../../constants/CashTransitTranslations';
import { ButtonNewStyles, ButtonCancelStyles, ButtonProcessStyles, ButtonCompleteStyles } from '../../../constants/StatusButtons';

const ButtonNew = styled(Button)(ButtonNewStyles);
const ButtonCancel = styled(Button)(ButtonCancelStyles);
const ButtonProcess = styled(Button)(ButtonProcessStyles);
const ButtonComplete = styled(Button)(ButtonCompleteStyles);

export const StatusButton = ({ orderStatus }) => {
  switch (orderStatus) {
    case 'Новая': {
      return (
        <>
          <ButtonNew fullWidth startIcon={<FiberNewRoundedIcon />}>
            {translations.orderStatus[orderStatus]}
          </ButtonNew>
        </>
      );
    }
    case 'В процессе': {
      return (
        <>
          <ButtonProcess fullWidth startIcon={<AccessAlarmRoundedIcon />}>
            {translations.orderStatus[orderStatus]}
          </ButtonProcess>
        </>
      );
    }
    case 'Отменена': {
      return (
        <>
          <ButtonCancel fullWidth startIcon={<DeleteForeverRoundedIcon />}>
            {translations.orderStatus[orderStatus]}
          </ButtonCancel>
        </>
      );
    }
    case 'Завершена': {
      return (
        <>
          <ButtonComplete fullWidth startIcon={<VerifiedRoundedIcon />}>
            {translations.orderStatus[orderStatus]}
          </ButtonComplete>
        </>
      );
    }
    default: {
      return translations.orderStatus[orderStatus];
    }
  }
};

StatusButton.propTypes = {
  orderStatus: PropTypes.string
};
