import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, TextField, Autocomplete, InputAdornment } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

const setItem = (newValue, setSelectedItem, multiplyState, pagination) => {
  if (multiplyState) {
    setSelectedItem((prev) => (
      { ...prev,
        [multiplyState]: newValue,
        ...(pagination && { [pagination]: 1 })
      }));
  } else setSelectedItem(newValue);
};

export const AutocompleteSelect = ({ id, label, selectorList, setSelectedItem, multiplyState = '', pagination = '', isDisable = false }) => {
  const [selectorFilter, setSelectorFilter] = useState('');
  const [key, setKey] = useState(Math.random());

  const onChangeFilterInput = useCallback((e, value) => {
    if (value) {
      setSelectorFilter(value);
    } else {
      setSelectorFilter('');
      setItem('', setSelectedItem, multiplyState, pagination);
    }
  }, [setSelectedItem, multiplyState, pagination]);

  useEffect(() => {
    setKey(Math.random());
    setSelectorFilter('');
  }, [selectorList]);

  return (
    <FormControl fullWidth className="operation-list-selector">
      <Autocomplete
        key={key}
        id={id}
        disablePortal
        disabled={isDisable}
        inputValue={selectorFilter}
        variant="outlined"
        options={selectorList}
        onInputChange={onChangeFilterInput}
        onChange={(e, value) => setItem(value?.id || '', setSelectedItem, multiplyState, pagination)}
        noOptionsText="Немає співпадінь"
        renderOption={(props, option) => (
          <li
            {...props}
            key={option.id}
          >
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRoundedIcon />
                </InputAdornment>
              )
            }}
            label={label}
          />
        )}
      />
    </FormControl>
  );
};

AutocompleteSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  selectorList: PropTypes.array,
  setSelectedItem: PropTypes.func,
  multiplyState: PropTypes.string,
  pagination: PropTypes.string,
  isDisable: PropTypes.bool

};

export default AutocompleteSelect;
