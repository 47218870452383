import { addParamsToUrl } from '../../utils/helpers';
import { clearLocalStorageWithoutToken } from '../../utils/clearLocalStorageWhitOutPcToken';

export * from './socket';

async function authFetch(method, url, options = {}, raw = false) {
  const token = localStorage.getItem('secret');
  const { body, ...fetchOptions } = options;
  if (body instanceof FormData) {
    fetchOptions.body = options.body;
  } else if (typeof body !== 'undefined') {
    fetchOptions.body = JSON.stringify(options.body);
    fetchOptions.headers = { 'Content-Type': 'application/json' };
  }

  const authOptions = {
    method,
    ...fetchOptions,
    headers: {
      ...fetchOptions.headers,
      Authorization: `Bearer ${token}`,
      'X-XSS-Protection': '1; mode=block',
      'X-Frame-Options': 'DENY'
    }
  };

  const response = await fetch(`${process.env.REACT_APP_NOTIFICATION_URL}/exchange-messages/v1${url}`, authOptions);
  if (raw) {
    return response;
  }

  if (response.status === 401) {
    clearLocalStorageWithoutToken();
    window.location.href = `${window.location.origin}/auth`;
    throw new Error('Not Authenticated!');
  }
  if (method === 'PATCH') {
    return response;
  }
  if (method.toUpperCase() === 'DELETE') {
    return response;
  }
  if (response.status === 201) {
    return response?.json().catch(() => response);
  }
  return response?.json().catch(() => response);
}

export class NotificationApi {
  static createNotificationNewOrder(body = {}, params = {}) {
    return authFetch('post', addParamsToUrl('/notification/admin/auto', params), { body });
  }

  static getNotificationAdmin(params = {}) {
    return authFetch('get', addParamsToUrl('/notification/admin', params));
  }

  static createNotificationCashier(body = {}, params = {}) {
    return authFetch('post', addParamsToUrl('/notification/admin', params), { body });
  }

  static deleteNotification(params = {}) {
    return authFetch('delete', addParamsToUrl('/notification/admin', params));
  }

  static updateNotification(body = {}, params = {}) {
    return authFetch('put', addParamsToUrl('/notification/admin', params), { body });
  }

  static getNotificationCashier(params = {}) {
    return authFetch('get', addParamsToUrl('/notification/cashier', params));
  }

  static actionNotification(body = {}, type = 'read') {
    return authFetch('post', addParamsToUrl(`/notification/cashier/${type}`), { body });
  }
}
