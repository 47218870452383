import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import './style.scss';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import {
  Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField,
  FormControlLabel, Checkbox
} from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import MomentUtils from '@date-io/moment';
import { nanoid } from 'nanoid';
import { DashboardContext } from '../DashboardContext';
import ModalWindow from '../ModalWindow/ModalWindow';
import validationSchemaNewUser from './validateForm';
import { AuthorisationContext } from '../AuthorisationContext';
import { localizeErrors } from '../../../utils/helpers';
import { translations } from '../../../constants/RolesTranslations';
import { MenuProps } from './consts';

const AddUser = ({ open, isEdit, isSelfEdit, handleClose }) => {
  const {
    roles, cities, fetchRoles, fetchCities, selectedUser,
    fetchAddUser, addUsersErrors, fetchEditUser, clearUsersErrors
  } = useContext(DashboardContext);
  const { user, userRole, updateUser } = useContext(AuthorisationContext);
  const history = useHistory();
  const [userData, setUserData] = useState({});
  const isAdmin = userRole === 'Администратор';
  const [changePass, setChangePass] = useState(false);

  useEffect(() => {
    fetchCities();
    fetchRoles();
    return clearUsersErrors();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEdit && isEmpty(userData)) {
      if (isSelfEdit) {
        setUserData(() => ({
          ...user,
          cities: user.cities.map((city) => city.id),
          telegram_chat_id: user.telegram_chat_id === null ? '' : user.telegram_chat_id,
          additional_contact: user.additional_contact === null ? '' : user.additional_contact
        }));
      } else {
        setUserData(() => ({
          ...selectedUser,
          cities: selectedUser.cities.map((city) => city.id),
          telegram_chat_id: selectedUser.telegram_chat_id === null ? '' : selectedUser.telegram_chat_id,
          additional_contact: selectedUser.additional_contact === null ? '' : selectedUser.additional_contact
        }));
      }
    }
  }, [isEdit, userData, isSelfEdit, selectedUser, user]);

  const onSubmitForm = useCallback((value, resetForm) => {
    const data = {
      lastname: value.lastname,
      firstname: value.firstname,
      patronymic: value.patronymic,
      start_date: moment(value.startDate).format('YYYY-MM-DD'),
      phone: value.phone,
      role_id: value.userType,
      city_ids: value.cityIds,
      telegram_chat_id: value.telegram,
      additional_contact: value.additionalContact,
      access: value.access,
      login: value.login,
      status: value.status
    };
    if (isEdit) {
      fetchEditUser({
        ...data,
        id: userData.id,
        change_password: changePass
      }).then((resp) => {
        if (resp.status === 'success') {
          if (isSelfEdit) {
            updateUser(resp.data);
          }
          resetForm();
          handleClose();
          setUserData({});
        }
      });
    } else {
      fetchAddUser(data).then((resp) => {
        if (resp.data) {
          resetForm();
          handleClose();
          setUserData({});
          history.push(`/settings/users/${resp.data.id}`);
        }
      });
    }
  }, [changePass, fetchAddUser, fetchEditUser, handleClose, isEdit, isSelfEdit, updateUser, userData, history]);

  const formik = useFormik({
    initialValues: {
      startDate: isEdit ? userData.start_date : moment().format('DD.MM.YYYY'),
      login: isEdit ? userData.login : '',
      phone: isEdit ? userData.phone : '',
      status: isEdit ? Number(userData.status) : '',
      access: isEdit ? Number(userData.access) : '',
      cityIds: isEdit ? userData.cities : [],
      userType: isEdit ? userData.role?.id : '',
      telegram: isEdit ? userData.telegram_chat_id : '',
      lastname: isEdit ? userData.lastname : '',
      firstname: isEdit ? userData.firstname : '',
      patronymic: isEdit ? userData.patronymic : '',
      additionalContact: isEdit ? userData.additional_contact : ''
    },
    enableReinitialize: isEdit,
    validationSchema: validationSchemaNewUser,
    onSubmit: (values, { resetForm }) => {
      onSubmitForm(values, resetForm);
    }
  });
  const renderErrors = useMemo(
    () => addUsersErrors.map((item) => {
      if (Array.isArray(item)) {
        return item.map((err) => (
          <p key={nanoid()} className="errorText">
            {localizeErrors[err] || err}
          </p>
        ));
      } return (
        <p key={nanoid()} className="errorText">
          {localizeErrors[item] || item}
        </p>
      );
    }
    ),
    [addUsersErrors]
  );
  const onClickReset = () => {
    formik.resetForm();
    handleClose();
    setUserData({});
  };

  return (
    <ModalWindow
      open={open}
      maxWidth="700px"
      onClose={handleClose}
    >
      <div className="addUsers addPopup">
        <form onSubmit={formik.handleSubmit}>
          <FormControl size="small" fullWidth>
            <TextField
              size="small"
              id="lastname"
              label="Прізвище"
              name="lastname"
              disabled={!isAdmin}
              placeholder="Прізвище"
              value={formik.values.lastname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lastname && Boolean(formik.errors.lastname)}
              helperText={formik.touched.lastname && formik.errors.lastname}
            />
          </FormControl>
          <FormControl fullWidth size="small">
            <TextField
              label="Ім'я"
              size="small"
              id="firstname"
              name="firstname"
              placeholder="Ім'я"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstname}
              error={formik.touched.firstname && Boolean(formik.errors.firstname)}
              helperText={formik.touched.firstname && formik.errors.firstname}
            />
          </FormControl>
          <FormControl fullWidth size="small">
            <TextField
              size="small"
              id="patronymic"
              label="По-батькові"
              name="patronymic"
              placeholder="По-батькові"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.patronymic}
              error={formik.touched.patronymic && Boolean(formik.errors.patronymic)}
              helperText={formik.touched.patronymic && formik.errors.patronymic}
            />
          </FormControl>
          <div className="addUsers-two">
            <FormControl fullWidth size="small">
              <LocalizationProvider dateAdapter={MomentUtils} locale="uk">
                <DatePicker
                  value={formik.values.startDate}
                  closeOnSelect
                  mask="__.__.____"
                  okText="Обрати"
                  cancelText="Скасувати"
                  minDate={moment('01.01.2010')}
                  maxDate={moment()}
                  label="Дата оформлення"
                  onChange={(value) => formik.setFieldValue('startDate', moment(value))}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      mask="YYYY-MM-DD"
                      error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                    />
                  )}
                  inputFormat="DD.MM.YYYY"
                />
              </LocalizationProvider>
              {(formik.touched.startDate && formik.errors.startDate) && (
                <FormHelperText error>{formik.errors.startDate}</FormHelperText>)}
            </FormControl>
            <FormControl fullWidth size="small">
              <TextField
                size="small"
                id="telegram"
                name="telegram"
                label="Telegram"
                placeholder="Телеграм"
                value={formik.values.telegram || userData?.telegram_chat_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </FormControl>
          </div>
          <div className="addUsers-two">
            <FormControl fullWidth size="small">
              <TextField
                id="phone"
                size="small"
                name="phone"
                label="Номер телефону *"
                placeholder="Телефон"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </FormControl>
            <FormControl fullWidth size="small">
              <TextField
                size="small"
                id="additionalContact"
                name="additionalContact"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Додатковий спосіб зв'язку"
                value={formik.values.additionalContact}
                placeholder="Додатковий спосіб зв'язку"
                error={formik.touched.additionalContact && Boolean(formik.errors.additionalContact)}
                helperText={formik.touched.additionalContact && formik.errors.additionalContact}
              />
            </FormControl>
          </div>
          <div className="addUsers-two">
            <FormControl
              fullWidth
              size="small"
              error={formik.touched.userType && Boolean(formik.errors.userType)}
            >
              <InputLabel>Тип користувача *</InputLabel>
              <Select
                size="small"
                id="userType"
                name="userType"
                disabled={!isAdmin}
                label="Тип користувача *"
                value={formik.values.userType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Оберіть тип користувача"
              >
                {roles.map(({ id, title }) => (
                  <MenuItem key={id} value={id}>{translations.roles[title]}</MenuItem>
                ))}
              </Select>
              {(formik.touched.userType && formik.errors.userType) && (
                <FormHelperText error>{formik.errors.userType}</FormHelperText>)}
            </FormControl>
            <FormControl
              fullWidth
              size="small"
              error={formik.touched.cityIds && Boolean(formik.errors.cityIds)}
            >
              <InputLabel>Місто *</InputLabel>
              <Select
                multiple
                size="small"
                id="cityIds"
                label="Місто *"
                name="cityIds"
                MenuProps={MenuProps}
                disabled={!isAdmin}
                placeholder="Оберіть місто"
                value={formik.values.cityIds}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {cities.map((item) => (
                  <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                ))}
              </Select>
              {(formik.touched.cityIds && formik.errors.cityIds) && (
              <FormHelperText error>{formik.errors.cityIds}</FormHelperText>)}
            </FormControl>
          </div>
          <div className="addUsers-two">
            <FormControl
              fullWidth
              size="small"
              error={formik.touched.access && Boolean(formik.errors.access)}
            >
              <InputLabel>Доступ *</InputLabel>
              <Select
                id="access"
                size="small"
                name="access"
                label="Доступ *"
                disabled={!isAdmin}
                placeholder="Доступ *"
                value={formik.values.access}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value === 1) {
                    formik.values.status = 1;
                  }
                  if (value === 0) {
                    formik.values.status = 0;
                  }
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
              >
                <MenuItem value={1}>Дозволити</MenuItem>
                <MenuItem value={0}>Заборонити</MenuItem>
              </Select>
              {(formik.touched.access && formik.errors.access) && (
                <FormHelperText error>{formik.errors.access}</FormHelperText>)}
            </FormControl>
            <FormControl
              fullWidth
              size="small"
              error={formik.touched.status && Boolean(formik.errors.status)}
            >
              <InputLabel>Статус *</InputLabel>
              <Select
                id="status"
                size="small"
                name="status"
                label="Статус *"
                disabled={!isAdmin}
                placeholder="Статус"
                value={formik.values.status}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem value={1}>Активний</MenuItem>
                <MenuItem value={0}>Неактивний</MenuItem>
              </Select>
              {(formik.touched.status && formik.errors.status) && (
                <FormHelperText error>{formik.errors.status}</FormHelperText>)}
            </FormControl>
          </div>
          <FormControl fullWidth size="small">
            <TextField
              id="login"
              size="small"
              name="login"
              disabled={!isAdmin}
              label={isEdit ? 'Логін' : 'Якщо залишити це поле порожнім - система створить унікальний логін'}
              placeholder="Введіть логін"
              value={formik.values.login}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.login && Boolean(formik.errors.login)}
            />
            {(formik.touched.login && formik.errors.login) && (
              <FormHelperText error>{formik.errors.login}</FormHelperText>)}
          </FormControl>
          {isEdit && !isSelfEdit
          && (
          <FormControl fullWidth size="small" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <FormControlLabel control={<Checkbox checked={changePass} onChange={() => setChangePass(true)} />} label="Скинути пароль" />
            {userData.change_password && <span style={{ color: 'red', fontStyle: 'italic', fontSize: '14px' }}>Користувач не встановив пароль</span>}
          </FormControl>
          )}
          {renderErrors}
          <div className="addUsers-actions">
            <Button variant="contained" type="submit">Зберегти</Button>
            <Button variant="contained" color="error" type="reset" onClick={onClickReset}>Скасувати</Button>
          </div>
        </form>
      </div>
    </ModalWindow>
  );
};

AddUser.propTypes = {
  open: PropTypes.bool,
  isEdit: PropTypes.bool,
  isSelfEdit: PropTypes.bool,
  handleClose: PropTypes.func
};

export default AddUser;
