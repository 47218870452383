import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import ModalWindow from '../ModalWindow/ModalWindow';
import { AuthorisationContext } from '../AuthorisationContext';
import { CashierContext } from '../CashierContext';
import CurrentTime from '../CurrentTime/CurrentTime';

const ModalWindowCashier = ({ open, handleClose }) => {
  const { userRole,
    user: { id: userId },
    cashierData: { id: depId, department_active_session: departmentAtvSession }
  } = useContext(AuthorisationContext);
  const { sessionData, fetchEndSession } = useContext(CashierContext);
  const isAnotherUserLeftSession = departmentAtvSession?.user.id && (userId !== departmentAtvSession.user.id);
  const isCashier = userRole === 'Кассир';
  const handleClick = useCallback(() => window.open(`/cashier-report/${depId}`, '_blank'), [depId]);

  const onConfirmEndSession = useCallback(() => {
    if (!isAnotherUserLeftSession) {
      localStorage.setItem(`lastActiveSessionDep${depId}`, sessionData.id);
      handleClick();
    }
    fetchEndSession();
    handleClose(true);
  }, [fetchEndSession, handleClose, sessionData, depId, handleClick, isAnotherUserLeftSession]);

  return (
    <ModalWindow
      open={open}
      onClose={handleClose}
      maxWidth="500px"
    >
      <div className="addUsers">
        <CancelPresentationRoundedIcon className="close-btn" onClick={handleClose} />
        <div className="new-date">Бажаєте закрити сесію?</div>
        <div className="set-time">
          <div className="set-time-text">Час закриття:</div>
          <CurrentTime />
        </div>
        <div className="addUsers-actions">
          <Button onClick={onConfirmEndSession} variant="contained" disabled={!isCashier}>Закрити день</Button>
        </div>
      </div>
    </ModalWindow>
  );
};

ModalWindowCashier.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

export default ModalWindowCashier;
