import React, { useContext, useEffect, useMemo, useState } from 'react';
import { MenuItem, FormControl, Select, InputLabel,
  Grid, TextField, Table, TableHead, TableCell, TableRow, TableBody,
  Pagination, TablePagination
} from '@mui/material';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MomentUtils from '@date-io/moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'moment/locale/uk';
import { nanoid } from 'nanoid';
import isEmpty from 'lodash.isempty';
import { DashboardContext } from '../../components/DashboardContext';
import { MenuProps } from './consts';
import PageWrapper from '../../common/PageWrapper/PageWrapper';
import { SessionItem } from './SessionItem';
import { AutocompleteSelect } from '../../components/AutocompleteSelect';

export const Sessions = () => {
  const { filials, fetchFilials,
    departments, fetchDepartments,
    fetchSession, sessions, sessionsCount,
    users, fetchUsers
  } = useContext(DashboardContext);
  const [selectedValues, setSelectedValues] = useState(
    {
      page: 1,
      perPage: 5,
      filialId: 'all',
      departmentId: 'all',
      userId: 'all',
      dateFrom: moment().add('days', -1),
      dateTo: moment()
    });

  useEffect(() => {
    fetchFilials();
    fetchUsers({ role_id: 3 });
  }, [fetchFilials, fetchUsers]);

  useEffect(() => {
    fetchSession({
      page: selectedValues.page,
      per_page: selectedValues.perPage,
      department_id: selectedValues.departmentId === 'all' ? '' : selectedValues.departmentId,
      user_id: selectedValues.userId === 'all' ? '' : selectedValues.userId,
      date_from: moment(selectedValues.dateFrom).format('YYYY-MM-DD'),
      date_to: moment(selectedValues.dateTo).format('YYYY-MM-DD')
    });
  }, [selectedValues, fetchSession]);

  useEffect(() => {
    fetchDepartments({
      filial_id: selectedValues.filialId === 'all' ? '' : selectedValues.filialId
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues.filialId]);

  const changeFilialValue = (filterValue) => (e) => {
    setSelectedValues(
      (PrevState) => ({ ...PrevState, [filterValue]: e.target.value, departmentId: '' })
    );
  };

  const handleDateChange = (key) => (value) => {
    setSelectedValues((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleChangePage = (event, page) => {
    setSelectedValues((prevState) => ({ ...prevState, page }));
  };

  const handleChangeRowsPerPage = (event) => {
    setSelectedValues((prevState) => (
      { ...prevState, page: 1, perPage: parseInt(event.target.value, 10) })
    );
  };

  const filteredDepartments = useMemo(() => departments?.map(({ id, title }) => ({ id, label: title })),
    [departments]);

  const filteredUsers = useMemo(() => users?.map(({ id, lastname, firstname, patronymic }) => ({ id, label: `${lastname} ${firstname} ${patronymic}` })),
    [users]);

  const renderFilials = useMemo(() => filials?.map(
    ({ id, title }) => <MenuItem key={id} value={id}>{title}</MenuItem>),
  [filials]);

  const renderSessions = useMemo(() => sessions?.map(
    (item) => <SessionItem key={nanoid()} item={item} />),
  [sessions]);

  const renderTableData = useMemo(() => {
    if (sessions.length === 0) return <TableRow><TableCell align="center" colSpan={7}>Не знайдено жодної сессії</TableCell></TableRow>;
    return renderSessions;
  }, [sessions.length, renderSessions]);

  const paginationPerPage = useMemo(() => {
    const arr = [5, 10, 25, 50, 100].filter((pages) => sessionsCount / pages >= 1);
    return isEmpty(arr) ? [5] : arr;
  }, [sessionsCount]);

  return (
    <PageWrapper>
      <Grid sx={{ mt: 0, pt: 0 }} container spacing={2} alignItems="center" justifyContent="center">
        <Grid item md={6}>
          <FormControl className="operation-list-selector" fullWidth>
            <InputLabel>Регіон</InputLabel>
            <Select
              MenuProps={MenuProps}
              label="Регіон"
              value={selectedValues.filialId}
              onChange={changeFilialValue('filialId')}
            >
              <MenuItem key="all" value="all">Всі</MenuItem>
              {renderFilials}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <AutocompleteSelect
            id="department-selector"
            label="Відділення"
            selectorList={filteredDepartments}
            setSelectedItem={setSelectedValues}
            multiplyState="departmentId"
          />
        </Grid>
        <Grid item md={6}>
          <AutocompleteSelect
            id="user-selector"
            label="Касир"
            selectorList={filteredUsers}
            setSelectedItem={setSelectedValues}
            multiplyState="userId"
          />
        </Grid>
        <Grid item md={3}>
          <div className="operation-list-selector">
            <LocalizationProvider dateAdapter={MomentUtils} locale="uk">
              <DatePicker
                fullWidth
                label="Зміни з"
                mask="__.__.____"
                value={selectedValues.dateFrom}
                maxDate={selectedValues.dateTo}
                onChange={handleDateChange('dateFrom')}
                renderInput={(params) => <TextField mask="YYYY-MM-DD" {...params} />}
                inputFormat="DD.MM.YYYY"
                disableMaskedInput
              />
            </LocalizationProvider>
          </div>
        </Grid>
        <Grid item md={3}>
          <div className="operation-list-selector">
            <LocalizationProvider dateAdapter={MomentUtils}>
              <DatePicker
                fullWidth
                label="Зміни по"
                mask="__.__.____"
                value={selectedValues.dateTo}
                minDate={selectedValues.dateFrom}
                maxDate={moment()}
                onChange={handleDateChange('dateTo')}
                renderInput={(params) => <TextField mask="YYYY-MM-DD" {...params} />}
                inputFormat="DD.MM.YYYY"
                disableMaskedInput
              />
            </LocalizationProvider>
          </div>
        </Grid>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Користувач</TableCell>
            <TableCell>Відділення</TableCell>
            <TableCell>Початок зміни</TableCell>
            <TableCell>Кінець зміни</TableCell>
            <TableCell align="center">Операції</TableCell>
            <TableCell align="center">Звіти</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderTableData}
        </TableBody>
      </Table>
      {sessionsCount > 5 && (
      <div className="users-pagination">
        <Pagination
          page={selectedValues.page}
          shape="rounded"
          color="primary"
          onChange={handleChangePage}
          count={Math.ceil(sessionsCount / selectedValues.perPage)}
        />
        <TablePagination
          count={sessionsCount}
          page={selectedValues.page}
          component="div"
          rowsPerPage={selectedValues.perPage}
          labelRowsPerPage="Відображати:"
          onPageChange={handleChangePage}
          rowsPerPageOptions={paginationPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      )}
    </PageWrapper>
  );
};
