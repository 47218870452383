import React, { useContext } from 'react';
import { DashboardProvider } from '../../components/DashboardContext';
import { ColorModeProvider } from '../../context/ThemeProvider/ColorModeProvider';
import { CashierProvider } from '../../components/CashierContext';
import { AdminProvider } from '../../components/AdminContext';
import { NotificationProvider } from '../../components/NotificationContext';
import { AuthorisationContext } from '../../components/AuthorisationContext';
import { Modal } from '../../components/Modal/Modal';
import { ChangePassword } from '../../components/ChangePassword/ChangePassword';
import { PrivateRoutes as Routes } from './PrivateRoutes';
import { UserMessage } from '../../components/UserMessage/UserMessage';
import { useSessionEndMessage } from '../../../hooks/useSessionEndMessage';

const formatDate = (date) => {
  if (date) {
    const splitStart = date.split(' ');
    const splitStartDate = splitStart[0].split('-');
    return `${splitStartDate[1]}-${splitStartDate[0]}-${splitStartDate[2]} ${splitStart[1]}`;
  }
  return '';
};

const timeToMs = (time) => {
  const tempArr = time.split(':');
  return tempArr[0] * 3600000 + tempArr[1] * 60000;
};

const PrivateRoutes = () => {
  const { user, fetchUpdateUserPassword, availableDepartments } = useContext(AuthorisationContext);
  const currentSessions = availableDepartments?.map(
    (dep) => dep.department_active_session)?.map(
    (dep) => (dep !== null ? (
      { department: dep?.department?.title,
        sessionLength: timeToMs(dep?.session_length),
        start: new Date(formatDate(dep?.start_time))
      })
      : {})).filter((item) => Object.keys(item).length !== 0);
  const [openModal, setOpenModal, userMessage] = useSessionEndMessage(currentSessions);

  return (
    <DashboardProvider>
      <ColorModeProvider>
        <AdminProvider>
          <CashierProvider>
            <NotificationProvider>
              <Routes />
              {user?.change_password && (
              <Modal>
                <ChangePassword user={user} fetchUpdateUserPassword={fetchUpdateUserPassword} />
              </Modal>
              )}
              {user?.role?.slug === 'cashier' && openModal && (
              <Modal>
                <UserMessage message={userMessage} handleClose={() => setOpenModal(false)} />
              </Modal>
              )}
            </NotificationProvider>
          </CashierProvider>
        </AdminProvider>
      </ColorModeProvider>
    </DashboardProvider>
  );
};

export default PrivateRoutes;
