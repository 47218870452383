import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import moment from 'moment';
import isEmpty from 'lodash.isempty';
import {
  Table, TableBody, TableCell, Pagination, TablePagination, TableContainer, TableHead,
  TableRow, TextField, MenuItem, FormControl, InputLabel, Select, Grid
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MomentUtils from '@date-io/moment';
import 'moment/locale/uk';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { isNull } from '../../../utils/isNull';
import PageWrapper from '../../common/PageWrapper/PageWrapper';
import CashTransitCashierTableItem from './Components/CashTransitCashierTableItem';
import CashTransitCashierCreateRequest from './Components/CashTransitCashierCreateRequest';
import { DashboardContext } from '../../components/DashboardContext';
import { CashierContext } from '../../components/CashierContext';
import { AuthorisationContext } from '../../components/AuthorisationContext';
import { NotificationContext } from '../../components/NotificationContext/notificationContext';
import ModalWindowStatusChangeConfirm from './Components/ModalWindows/ModalWindowStatusChangeConfirm';
import ModalWindowEditOrder from './Components/ModalWindows/ModalWindowEditOrder';
import ModalWindowAddEditInkas from './Components/ModalWindows/ModalWindowAddEditInkas';
import AlertSnackbar from '../../common/Snackbar/AlertSnackbar';
import { FilterDepartments } from './Components/FilterDepartments';

moment.locale('uk');

const CashTransitCahierPage = () => {
  const { fetchOrders, orders, ordersCount, fetchInkassators, inkassators } = useContext(DashboardContext);
  const { sessionData, cashierCurrency, fetchCashierCurrency } = useContext(CashierContext);
  const { reFetchOrders } = useContext(NotificationContext);
  const {
    cashierData: { id: cashierDataId, isCentral }
  } = useContext(AuthorisationContext);

  const [perPage, setPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());
  const [filterDepartmentId, setFilterDepartmentId] = useState('');
  const [orderStatus, setOrderStatus] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenAddEditModalInkas, setIsOpenAddEditModalInkas] = useState(false);
  const [statusValue, setStatusValue] = useState({});
  const [editedOrder, setEditedOrder] = useState({});
  const [editedInkas, setEditedInkas] = useState({});
  const [reFetch, setReFetch] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, massage: '' });

  useEffect(() => {
    fetchCashierCurrency({
      date: moment().format('YYYY-MM-DD'),
      id: cashierDataId
    });
  }, [fetchCashierCurrency, cashierDataId]);

  useEffect(() => {
    fetchOrders({
      page,
      per_page: perPage,
      current_session: sessionData.id,
      order_status: orderStatus === [] ? '' : orderStatus,
      date_from: isNull(fromDate) ? '' : moment(fromDate).format('DD.MM.YYYY'),
      date_to: moment(toDate).format('DD.MM.YYYY'),
      department_id: isNull(filterDepartmentId) ? '' : filterDepartmentId
    });
  }, [fetchOrders, sessionData.id, orderStatus, fromDate, toDate, page, perPage,
    reFetch, reFetchOrders, filterDepartmentId]);

  useEffect(() => {
    fetchInkassators({
      current_session: sessionData.id
    });
  }, [fetchInkassators, sessionData.id]);

  const onClickConfirm = useCallback((check, status, secondStatus, statusKey, secondStatusKey,
    orderId, departmentId, departmentCreator, departmentReceiver, inkassator) => {
    setIsOpenModal(true);
    setStatusValue({ check,
      status,
      secondStatus,
      statusKey,
      secondStatusKey,
      orderId,
      departmentId,
      departmentCreator,
      departmentReceiver,
      inkassator });
  }, []);

  const onClickEdit = useCallback(
    (currentOrder, departmentCreator, orderItems, departmentReceiver, departmentId) => {
      setEditedOrder({ currentOrder, departmentCreator, orderItems, departmentReceiver, departmentId });
      setIsOpenEditModal(true);
    },
    []
  );

  const onClickAddEditInkas = useCallback((id, inkassator) => {
    setEditedInkas({ id, inkassator });
    setIsOpenAddEditModalInkas(true);
  }, []);

  const onClickCloseModal = useCallback(() => {
    setIsOpenModal(false);
    setStatusValue({});
  }, []);

  const onClickCloseEditModal = useCallback(() => {
    setIsOpenEditModal(false);
    setEditedOrder({});
  }, []);

  const onClickCloseAddEditModalInkas = useCallback(() => {
    setIsOpenAddEditModalInkas(false);
    setEditedOrder({});
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const renderOperations = useMemo(() => {
    if (isEmpty(orders)) {
      return <TableRow><TableCell colSpan={7} align="center">Список заявок порожній</TableCell></TableRow>;
    }
    if (!isEmpty(orders)) {
      return orders.map((item) => (
        <CashTransitCashierTableItem
          item={item}
          key={item.id}
          cashierCurrency={cashierCurrency}
          sessionData={sessionData}
          isCentral={isCentral}
          onClickConfirm={onClickConfirm}
          onClickEdit={onClickEdit}
          onClickAddEditInkas={onClickAddEditInkas}
        />
      ));
    }
  }, [orders, cashierCurrency, sessionData, onClickConfirm, onClickEdit, onClickAddEditInkas, isCentral]);

  return (
    <>
      <PageWrapper>
        <CashTransitCashierCreateRequest
          sessionDataId={sessionData.id}
          cashierCurrency={cashierCurrency}
          setReFetch={setReFetch}
          setSnackbar={setSnackbar}
          inkassators={inkassators}
        />
        <Grid container spacing={2} alignItems="center">
          <Grid item md={3}>
            <FormControl className="operation-list-selector" fullWidth>
              <InputLabel>Статус</InputLabel>
              <Select label="Статус" multiple value={orderStatus} onChange={(e) => setOrderStatus(e.target.value)}>
                <MenuItem value="all">Всі</MenuItem>
                <MenuItem value="Новая">Нова</MenuItem>
                <MenuItem value="В процессе">В процесі</MenuItem>
                <MenuItem value="Завершена">Завершена</MenuItem>
                <MenuItem value="Отменена">Відмінена</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <div className="operation-list-selector">
              <LocalizationProvider dateAdapter={MomentUtils} locale="uk">
                <DatePicker
                  label="Дати з"
                  mask="__.__.____"
                  value={fromDate}
                  maxDate={toDate}
                  onChange={(newValue) => {
                    setFromDate(moment(newValue));
                  }}
                  renderInput={(params) => <TextField mask="YYYY-MM-DD" {...params} />}
                />
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid item md={3}>
            <div className="operation-list-selector">
              <LocalizationProvider className="operation-list-selector" dateAdapter={MomentUtils} locale="uk">
                <DatePicker
                  label="Дати по"
                  mask="__.__.____"
                  value={toDate}
                  minDate={isNull(fromDate) ? null : fromDate}
                  maxDate={moment()}
                  onChange={(newValue) => {
                    setToDate(moment(newValue));
                  }}
                  renderInput={(params) => <TextField mask="YYYY-MM-DD" {...params} />}
                />
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid item md={6}>
            <FilterDepartments
              setFilterDepartmentId={setFilterDepartmentId}
            />
          </Grid>
        </Grid>
        <div className="users-table">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ pl: 0 }}>ID</TableCell>
                  <TableCell sx={{ minWidth: 100, width: 120, paddingLeft: 0 }}>Час</TableCell>
                  <TableCell sx={{ minWidth: 100, width: 120 }}>Статус</TableCell>
                  <TableCell sx={{ minWidth: 100, width: 230 }}>Відправник</TableCell>
                  <TableCell sx={{ minWidth: 100, width: 230 }}>Отримувач</TableCell>
                  <TableCell sx={{ paddingLeft: 0 }}>Інкасатор</TableCell>
                  <TableCell align="right" sx={{ width: 100, paddingRight: 0, paddingLeft: 0 }}>Показати</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderOperations}</TableBody>
            </Table>
          </TableContainer>
          {isEmpty(orders)
          || (
          <div className="users-pagination">
            <Pagination
              page={page}
              shape="rounded"
              onChange={handleChangePage}
              count={Math.ceil(ordersCount / perPage)}
              color="primary"
            />
            <TablePagination
              count={100}
              page={page}
              component="div"
              rowsPerPage={perPage}
              labelRowsPerPage="Відображати:"
              onPageChange={handleChangePage}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          )}
        </div>
        {isOpenModal && (
        <ModalWindowStatusChangeConfirm
          open={isOpenModal}
          isCentral={isCentral}
          handleClose={onClickCloseModal}
          statusValue={statusValue}
          inkassators={inkassators}
          currentSession={sessionData.id}
          setReFetch={setReFetch}
          setSnackbar={setSnackbar}
        />
        )}
        {isOpenEditModal && (
        <ModalWindowEditOrder
          open={isOpenEditModal}
          isCentral={isCentral}
          handleClose={onClickCloseEditModal}
          order={editedOrder}
          currentSession={sessionData.id}
          setReFetch={setReFetch}
          setSnackbar={setSnackbar}
        />
        )}
        {isOpenAddEditModalInkas && (
        <ModalWindowAddEditInkas
          open={isOpenAddEditModalInkas}
          handleClose={onClickCloseAddEditModalInkas}
          inkassators={inkassators}
          currentSession={sessionData.id}
          setReFetch={setReFetch}
          editedInkas={editedInkas}
          setSnackbar={setSnackbar}
        />
        )}
      </PageWrapper>
      <AlertSnackbar
        onClose={() => setSnackbar({ open: false, massage: '' })}
        isOpen={snackbar.open}
        success
        message={snackbar.massage}
      />
    </>
  );
};

export default CashTransitCahierPage;
