import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';

export const DepartmentsSelector = (
  { filledFilials, handleSelectDep, selectedDepartments, setSelectedDepartments }) => {
  const [selectedFilials, setSelectedFilials] = useState([]);

  useEffect(() => {
    handleSelectDep(selectedDepartments);
  }, [handleSelectDep, selectedDepartments]);

  const onSelectDepartment = (departmentID, e) => {
    if (e.target.checked) {
      setSelectedDepartments((prev) => {
        if (!prev?.find((id) => id === departmentID)) {
          return prev.concat(departmentID);
        }
        return prev;
      });
    } else {
      setSelectedDepartments((prev) => {
        if (prev?.find((id) => id === departmentID)) {
          return prev.filter((id) => id !== departmentID);
        }
        return prev;
      });
    }
  };

  const onSelectFilial = useCallback(
    (filialID, e) => {
      const selectedFilial = filledFilials
        .find((filial) => filial.id === filialID);
      if (e.target.checked) {
        setSelectedFilials((prev) => {
          if (!prev?.find((filial) => filial === selectedFilial.id)) {
            return prev.concat(selectedFilial.id);
          }
          return prev;
        });
        setSelectedDepartments((prev) => {
          const temp = selectedFilial.departments.map((department) => department.id);
          return prev.filter((dep) => !temp.includes(dep)).concat(temp);
        });
      } else {
        setSelectedFilials((prev) => {
          if (prev?.find((filial) => filial === selectedFilial.id)) {
            return prev.filter((filial) => filial !== selectedFilial.id);
          }
          return prev;
        });
        setSelectedDepartments((prev) => {
          const temp = selectedFilial.departments.map((department) => department.id);
          return prev.filter((dep) => !temp.includes(dep));
        });
      }
    }, [filledFilials, setSelectedDepartments]);

  useEffect(() => {
    const temp = filledFilials?.map((filial) => ({
      id: filial.id,
      checked: !filial.departments?.map((department) => department.checked).includes(false)
    }));
    temp.forEach((filial) => {
      if (filial.checked) {
        setSelectedFilials((prev) => prev.filter((fil) => filial.id !== fil).concat(filial.id));
      } else {
        setSelectedFilials((prev) => prev.filter((fil) => filial.id !== fil));
      }
    });
  }, [filledFilials, selectedDepartments]);

  const renderDepartments = useCallback((input) => input.map((department) => (
    <div className="departmentsSelector__item" key={department.id}>
      <Checkbox
        className="departmentsSelector__checkbox"
        checked={department.checked}
        onChange={(e) => onSelectDepartment(department.id, e)}
        sx={{ color: 'var(--icon)', padding: '0 5px 5px;' }}
      />
      <span className="departmentsSelector__title">{department.title}</span>
    </div>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  )), []);

  const renderfilials = useMemo(
    () => filledFilials.map(
      (filial) => (
        <div className="departmentsSelector__group" key={filial.id}>
          <div className="departmentsSelector__item">
            <Checkbox
              className="departmentsSelector__checkbox"
              checked={selectedFilials.includes(filial.id)}
              onChange={(e) => onSelectFilial(filial.id, e)}
              sx={{ color: 'var(--icon)', padding: '0 5px 5px;' }}
            />
            <span className="departmentsSelector__title">{filial.title}</span>
          </div>
          <div className="departmentsSelector__items">{renderDepartments(filial.departments)}</div>
        </div>
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [filledFilials, selectedDepartments, selectedFilials]);

  const onClickAll = useCallback(
    (e) => {
      if (e.target.checked) {
        setSelectedFilials(filledFilials.map((filial) => filial.id));
        setSelectedDepartments(
          filledFilials
            .map((filial) => filial.departments.map((department) => department.id))
            .reduce((prev, current) => prev.concat(current), [])
        );
      } else {
        setSelectedFilials([]);
        setSelectedDepartments([]);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filledFilials]);

  return (
    <div className="departmentsSelector">
      <div className="departmentsSelector__group">
        <div className="departmentsSelector__item">
          <Checkbox
            className="departmentsSelector__checkbox"
            checked={selectedFilials.length === filledFilials.length}
            onChange={(e) => onClickAll(e)}
            sx={{ color: 'var(--icon)', padding: '0 5px 5px;' }}
          />
          <span className="departmentsSelector__title">Усі</span>
        </div>
      </div>
      {renderfilials}
    </div>
  );
};

DepartmentsSelector.propTypes = {
  filledFilials: PropTypes.array,
  selectedDepartments: PropTypes.array,
  setSelectedDepartments: PropTypes.func,
  handleSelectDep: PropTypes.func
};
