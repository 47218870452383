import React, { useCallback, useState } from 'react';
import { AdminContext } from './AdminContext';
import SalaryService from '../../../services/SALARY/SalaryService';
import VariableService from '../../../services/VARIABLE/VariableService';
import DepartmentsService from '../../../services/DEPARTMENTS/DepartmentsService';
import AlertSnackbar from '../../common/Snackbar/AlertSnackbar';
import CurrenciesService from '../../../services/CURRENCIES/CurrenciesService';

function download(blob, filename) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
}

const AdminProvider = ({ children }) => {
  const [variables, setVariables] = useState([]);
  const [variablesErrors, setVariablesErrors] = useState([]);
  const [depEditErrors, setDepEditErrors] = useState([]);
  const [isEditDep, setIsEditDep] = useState(false);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState({
    message: '',
    type: false
  });
  const [salary, setSalary] = useState([]);
  const [isFetchingVariables, setIsFetchingVariables] = useState(false);
  const [isFetchingSalary, setIsFetchingSalary] = useState(false);
  const [isPostingSalary, setIsPostingSalary] = useState(false);
  const [isPostingToken, setIsPostingToken] = useState(false);
  const [isFetchingCurrencies, setIsFetchingCurrencies] = useState(false);
  const [isEditCurrencies, setIsEditCurrencies] = useState(false);
  const [currency, setCurrency] = useState([]);

  const getVariables = useCallback(async (data) => {
    try {
      setIsFetchingVariables(true);
      const response = await VariableService.getRequest(data);
      if (!response.message) {
        setVariables(response.data);
      }
    } catch {
      setVariables([]);
    } finally {
      setIsFetchingVariables(false);
    }
  }, []);

  const addVariable = useCallback(async (data) => {
    try {
      setIsFetchingVariables(true);
      const response = await VariableService.postRequest(data);
      if ('success' in response && !response.success) {
        setSnackbarMsg({ massage: 'Помилка при створенні змінної', type: false });
        setVariablesErrors(Object.values(response.data) || [response?.message]);
      } else {
        setSnackbarMsg({ massage: 'Змінну успішно створено', type: true });
        getVariables();
        setVariablesErrors([]);
      }
      return 'success';
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      setIsFetchingVariables(false);
      setIsOpenSnackbar(true);
    }
  }, [getVariables]);

  const editVariable = useCallback(async (data) => {
    try {
      setIsFetchingVariables(true);
      const response = await VariableService.putRequest(data);
      if ('success' in response && !response.success) {
        setSnackbarMsg({ massage: 'Помилка при редагуванні змінної', type: false });
        setVariablesErrors(Object.values(response.data) || [response?.message]);
      } else {
        setSnackbarMsg({ massage: 'Змінну успішно відредаговано', type: true });
        getVariables();
        setVariablesErrors([]);
      }
      return 'success';
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      setIsFetchingVariables(false);
      setIsOpenSnackbar(true);
    }
  }, [getVariables]);

  const deleteVariable = useCallback(async (data) => {
    try {
      setIsFetchingVariables(true);
      const response = await VariableService.delete(data);
      if ('success' in response && !response.success) {
        setSnackbarMsg({ massage: 'Помилка при видаленні змінної', type: false });
        setVariablesErrors(Object.values(response.data) || [response?.message]);
      } else {
        setSnackbarMsg({ massage: 'Змінну успішно видалено', type: true });
        getVariables();
        setVariablesErrors([]);
      }
      return 'success';
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      setIsFetchingVariables(false);
      setIsOpenSnackbar(true);
    }
  }, [getVariables]);

  const getAllSalary = useCallback(async (data) => {
    try {
      setIsFetchingSalary(true);
      const response = await SalaryService.getRequest(data);
      if (!response.message) {
        setSalary(response.data);
        setIsFetchingSalary(false);
      }
    } catch {
      setIsFetchingSalary(false);
      setSalary([]);
    }
  }, []);

  const getCashierSalary = useCallback(async (data) => {
    try {
      setIsFetchingSalary(true);
      const response = await SalaryService.getRequestWithID(data);
      if (!response.message) {
        setSalary(response.data);
      }
    } catch {
      setSalary([]);
    } finally {
      setIsFetchingSalary(false);
    }
  }, []);

  const postSalary = useCallback(async (data) => {
    try {
      setIsPostingSalary(true);
      const response = await SalaryService.postRequest(data);
      download(await response.blob(), data.id ? `salary-${data.id}.xlsx` : 'total-salary.xlsx');
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      setIsPostingSalary(false);
    }
  }, []);

  const fetchEditDep = useCallback(async (data) => {
    try {
      setDepEditErrors([]);
      setIsEditDep(true);
      const response = await DepartmentsService.putRequest(data);
      if ('success' in response && !response.success) {
        setSnackbarMsg({ massage: 'Помилка при редагуванні відділення', type: false });
        if (response.message) {
          setDepEditErrors([response.message]);
        } else {
          setDepEditErrors(Object.values(response.data).reduce((target, key) => target.concat(key), []));
        }
      } else {
        setSnackbarMsg({ massage: 'Відділення відредаговано', type: true });
        return 'success';
      }
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      setIsEditDep(false);
      setIsOpenSnackbar(true);
    }
  }, []);

  const addToken = useCallback(async (data) => {
    try {
      setIsPostingToken(true);
      const response = await DepartmentsService.postToken(data);
      if ('success' in response && !response.success) {
        setSnackbarMsg({ massage: 'Не вдалось прив\'язати токен', type: false });
      } else {
        setSnackbarMsg({ massage: 'Токен успішно прив\'язано', type: true });
      }
      return 'success';
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      setIsPostingToken(false);
      setIsOpenSnackbar(true);
    }
  }, []);

  const fetchCurrencies = useCallback(async ({ date }) => {
    try {
      setIsFetchingCurrencies(true);
      const response = await CurrenciesService.getRequest({ date });
      if (response.data) {
        setCurrency(response.data);
      }
      if (!response.data) {
        setCurrency([]);
      }
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      setIsFetchingCurrencies(false);
    }
  }, []);

  const fetchEditCurrency = useCallback(async (data, { date }) => {
    try {
      setIsEditCurrencies(true);
      const response = await CurrenciesService.putWithoutIdRequest(data);
      if (!response.message) {
        fetchCurrencies({ date });
        setSnackbarMsg({ massage: 'Курс валют успшіно змінено', type: true });
      } else {
        setSnackbarMsg({ massage: 'Помилка при зміненні курсу валют', type: false });
      }
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      setIsEditCurrencies(false);
      setIsOpenSnackbar(true);
    }
  }, [fetchCurrencies]);

  const contextData = {
    isFetchingSalary,
    isPostingSalary,
    isFetchingVariables,
    isPostingToken,
    getVariables,
    addVariable,
    editVariable,
    deleteVariable,
    variablesErrors,
    getAllSalary,
    getCashierSalary,
    postSalary,
    addToken,
    salary,
    variables,
    fetchCurrencies,
    currency,
    isFetchingCurrencies,
    fetchEditCurrency,
    isEditCurrencies,
    fetchEditDep,
    depEditErrors,
    isEditDep
  };

  if (children instanceof Function) {
    return children(contextData);
  }

  return (
    <AdminContext.Provider value={contextData}>
      {children}
      <AlertSnackbar
        onClose={() => setIsOpenSnackbar(false)}
        isOpen={isOpenSnackbar}
        message={snackbarMsg.massage}
        success={snackbarMsg.type}
      />
    </AdminContext.Provider>
  );
};

export default AdminProvider;
