const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export const reportsLabel = {
  balance: 0,
  filial: 1,
  operations: 2,
  sessions: 3,
  limits: 4
};
