import { Button } from '@mui/material';
import './style.scss';
import React from 'react';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import PropTypes from 'prop-types';
import moment from 'moment';

export const ViewMessage = ({ message, handleOKViewMessage, viewMode }) => (
  <div className="viewMessage">
    <div className="viewMessage__header">
      <span className="viewMessage__title">{viewMode ? <>{message.name}</> : <>Назва повідомлення</>}</span>
      <span className="viewMessage__date">{viewMode ? <>{moment(message.startDate).format('DD.MM.YYYY')}</> : <></>}</span>
    </div>
    <div className="viewMessage__body">
      {viewMode ? <>{message.message}</> : <>Оберіть повідомлення для перегляду</>}
    </div>
    <div className="viewMessage__footer">
      <Button
        variant="contained"
        color="success"
        className="viewMessage__btn"
        onClick={() => {
          handleOKViewMessage();
        }}
        disabled={!viewMode}
      >
        <DoneOutlineIcon sx={{ marginRight: '10px' }} />
        OK
      </Button>
    </div>
  </div>
);

ViewMessage.propTypes = {
  message: PropTypes.object,
  viewMode: PropTypes.bool,
  handleOKViewMessage: PropTypes.func
};
