import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import './styles.scss';
import isEmpty from 'lodash.isempty';
import {
  Button,
  FormControl, MenuItem,
  Pagination, Select, Table,
  TableBody, TableCell,
  TableContainer, TableHead,
  TablePagination, TableRow, InputLabel
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PageWrapper from '../../../common/PageWrapper/PageWrapper';
import SettingsBackwardBtn from '../../../components/SettingsBackwardBtn/SettingsBackwardBtn';
import { DashboardContext } from '../../../components/DashboardContext';
import DepartmentsItems from './DepartmentsItems';
import AddDepartments from '../../../components/AddDepartments/AddDepartments';
import AlertSnackbar from '../../../common/Snackbar/AlertSnackbar';
import { MenuProps } from './consts';
import { AuthorisationContext } from '../../../components/AuthorisationContext';

const Department = () => {
  const {
    cities, filials, departments, fetchCities, fetchFilials, departmentsCount, fetchDepartments
  } = useContext(DashboardContext);
  const { userRole } = useContext(AuthorisationContext);
  const [isActive, setIsActive] = useState('1');
  const [isCentral, setIsCentral] = useState('all');
  const [selectedFilial, setSelectedFilial] = useState('all');
  const [selectedCity, setSelectedCity] = useState('all');
  const [page, setPage] = useState(Number(localStorage.getItem('departmentsPage')) || 1);
  const [perPage, setPerPage] = useState(Number(localStorage.getItem('departmentsPerPage')) || 5);
  const [openModal, setOpenModal] = useState(false);
  const [selectedDep, setSelectedDep] = useState({});
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [snackbarType, setSnackbarType] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const selectorMaxWidth = userRole === 'Бухгалтер' ? '100%' : '200px';

  const setAlertNotification = (msg, success) => {
    setSnackbarMsg(msg);
    setIsOpenSnackbar(true);
    setSnackbarType(success);
  };

  useEffect(() => {
    if (!openModal) {
      fetchDepartments({
        page,
        per_page: perPage,
        city_id: selectedCity === 'all' ? '' : selectedCity,
        filial_id: selectedFilial === 'all' ? '' : selectedFilial,
        isActive: String(isActive),
        isCentral: isCentral === 'all' ? '' : String(isCentral)
      });
    }
  }, [fetchDepartments, page, perPage, isActive, selectedCity, selectedFilial, isCentral, openModal]);

  useEffect(() => {
    if (isEmpty(filials)) {
      fetchFilials();
    }
  }, [filials, fetchFilials]);

  useEffect(() => {
    if (isEmpty(cities)) {
      fetchCities();
    }
  }, [cities, fetchCities]);

  const handleOpenModal = useCallback(() => setOpenModal(true), []);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setEditMode(false);
    setSelectedDep({});
  }, []);

  const paginationPerPage = useMemo(() => {
    const arr = [5, 10, 25, 50, 100].filter((pages) => departmentsCount / pages >= 1);
    return isEmpty(arr) ? [5] : arr;
  }, [departmentsCount]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem('departmentsPage', newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    localStorage.setItem('departmentsPerPage', String(parseInt(event.target.value, 10)));
    setPage(1);
  };

  const onSelectDep = useCallback(
    (item) => {
      setSelectedDep(item);
      setEditMode(true);
      handleOpenModal();
    }, [handleOpenModal]);

  const renderDepartments = useMemo(() => {
    if (!isEmpty(departments)) {
      return departments.map((item) => <DepartmentsItems item={item} key={item.id} onSelectDep={onSelectDep} />);
    }
  }, [departments, onSelectDep]);

  return (
    <PageWrapper>
      <div className="department">
        <div className={userRole === 'Бухгалтер' ? 'department-actions filters-only' : 'department-actions'}>
          {userRole === 'Администратор' && <SettingsBackwardBtn />}
          <div className="users-filters-item">
            <div className="users-filters-item-input">
              <FormControl fullWidth sx={{ maxWidth: selectorMaxWidth }}>
                <InputLabel className="inputLabel" id="selectedFilial">Регіон</InputLabel>
                <Select
                  label="Регіон"
                  id="selectedFilial"
                  value={selectedFilial}
                  MenuProps={MenuProps}
                  onChange={(e) => {
                    handleChangePage(e, 1);
                    setSelectedFilial(e.target.value);
                  }}
                >
                  <MenuItem value="all">Усі</MenuItem>
                  {filials.map((item) => (
                    <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="users-filters-item">
            <div className="users-filters-item-input">
              <FormControl fullWidth sx={{ maxWidth: selectorMaxWidth }}>
                <InputLabel className="inputLabel">Місто</InputLabel>
                <Select
                  label="Місто"
                  value={selectedCity}
                  MenuProps={MenuProps}
                  onChange={(e) => {
                    handleChangePage(e, 1);
                    setSelectedCity(e.target.value);
                  }}
                >
                  <MenuItem value="all">Усі</MenuItem>
                  {cities.map((item) => (
                    <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="users-filters-item">
            <div className="users-filters-item-input">
              <FormControl fullWidth>
                <InputLabel className="inputLabel">Статус</InputLabel>
                <Select
                  label="Статус"
                  value={isActive}
                  onChange={(e) => {
                    handleChangePage(e, 1);
                    setIsActive(e.target.value);
                  }}
                >
                  <MenuItem value="any">Усі</MenuItem>
                  <MenuItem value={1}>Активні</MenuItem>
                  <MenuItem value={0}>Неактивні</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="users-filters-item">
            <div className="users-filters-item-input">
              <FormControl fullWidth>
                <InputLabel className="inputLabel">Тип</InputLabel>
                <Select
                  label="Тип"
                  value={isCentral}
                  onChange={(e) => {
                    handleChangePage(e, 1);
                    setIsCentral(e.target.value);
                  }}
                >
                  <MenuItem value="all">Усі</MenuItem>
                  <MenuItem value={1}>Центральне</MenuItem>
                  <MenuItem value={0}>Регіональне</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          { userRole === 'Администратор'
            && (
            <Button variant="contained" onClick={handleOpenModal} sx={{ minWidth: '240px' }}>
              <AddCircleIcon fontSize="small" sx={{ mr: '5px' }} />
              Додати відділення
            </Button>
            )}
        </div>
        <div className="users-table">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell component="th" align="left" padding="checkbox">ID</TableCell>
                  <TableCell component="th" align="left">Регіон</TableCell>
                  <TableCell component="th" align="left">Місто</TableCell>
                  <TableCell component="th" align="left">Назва</TableCell>
                  <TableCell component="th" align="left">Статус</TableCell>
                  { userRole === 'Администратор'
                    && (<TableCell component="th" align="center" padding="checkbox">Дія</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {renderDepartments}
              </TableBody>
            </Table>
            {departmentsCount === 0 && (
            <div className="operations-emptyOperations">Немає відділень до відображення</div>
            )}
            {departmentsCount > 5 && (
            <div className="users-pagination">
              <Pagination
                page={page}
                shape="rounded"
                onChange={handleChangePage}
                count={Math.ceil(departmentsCount / perPage)}
                color="primary"
              />
              <TablePagination
                count={departmentsCount}
                page={page}
                component="div"
                rowsPerPage={perPage}
                labelRowsPerPage="Відображати:"
                onPageChange={handleChangePage}
                rowsPerPageOptions={paginationPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
            )}
          </TableContainer>
        </div>
        <AddDepartments
          selectedDep={selectedDep}
          open={openModal}
          onClose={handleCloseModal}
          setAlertNotification={setAlertNotification}
          editMode={editMode}
        />
        <AlertSnackbar
          onClose={() => setIsOpenSnackbar(false)}
          isOpen={isOpenSnackbar}
          success={snackbarType}
          message={snackbarMsg}
        />
      </div>
    </PageWrapper>
  );
};

export default Department;
