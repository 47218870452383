import React, { useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import {
  Button, createFilterOptions,
  FormControl,
  InputLabel, Autocomplete,
  MenuItem,
  Select, Checkbox,
  TextField, OutlinedInput, Box, Chip, FormHelperText, FormControlLabel, Grid
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import SendIcon from '@mui/icons-material/Send';
import { nanoid } from 'nanoid';
import { useFormik } from 'formik';
import { DashboardContext } from '../DashboardContext';
import { AdminContext } from '../AdminContext';
import ModalWindow from '../ModalWindow/ModalWindow';
import useFetch from '../../../hooks/useFetch';
import DepartmentsService from '../../../services/DEPARTMENTS/DepartmentsService';
import { isNull } from '../../../utils/isNull';
import { AddDepScheduleFormat, localizeErrors, departmentSchedule } from '../../../utils/helpers';
import validationSchemaNewDepartment from './validateDepartment';
import { MenuProps } from './consts';

const filter = createFilterOptions();

const AddDepartments = ({ open, onClose, selectedDep, setAlertNotification, editMode }) => {
  const {
    cities, filials, fetchCities, fetchFilials, fetchCurrencies, currency
  } = useContext(DashboardContext);
  const { fetchEditDep, depEditErrors, isEditDep } = useContext(AdminContext);
  const [errorMessage, setErrorMessage] = useState([]); // backend errors
  const [tokensArr, setTokensArr] = useState([]);
  useEffect(() => {
    if (isEmpty(currency) && open) {
      fetchCurrencies({ date: moment().format('YYYY-MM-DD') });
    }
  }, [currency, fetchCurrencies, open]);

  const currencyList = useMemo(() => currency.map((item) => ({ id: item.id, title: item.title })), [currency]);

  useEffect(() => {
    if (editMode) {
      setTokensArr(selectedDep.tokens);
    }
  }, [editMode, selectedDep]);

  const closeForm = () => {
    onClose();
    setTokensArr([]);
    setErrorMessage([]);
  };

  const { fetch: fetchNewDep, loading: loadingNew } = useFetch({
    requestFunction: DepartmentsService.postRequest,
    withLoading: true,
    setResponse: (response) => {
      if (('success' in response) && !response.success) {
        setAlertNotification('Помилка при створенні відділення', false);
        setErrorMessage(Object.values(response.data).reduce((target, key) => target.concat(key), []));
      } else {
        setAlertNotification('Відділення створено', true);
        closeForm();
      }
    }
  });

  useEffect(() => {
    if (isEmpty(filials) && open) {
      fetchFilials();
    }
  }, [fetchFilials, open, filials]);

  useEffect(() => {
    if (isEmpty(cities) && open) {
      fetchCities();
    }
  }, [fetchCities, open, cities]);

  const selectedComment = useMemo(
    () => editMode && (selectedDep.comment === null ? '' : selectedDep.comment),
    [editMode, selectedDep.comment]);

  const formik = useFormik({
    initialValues: {
      title: editMode ? selectedDep.title : '',
      filialId: editMode ? selectedDep.filial[0].id : '',
      cityId: editMode ? selectedDep.city[0].id : '',
      address: editMode ? selectedDep.addres : '',
      comment: editMode ? selectedComment : '',
      tokensArr: [],
      salary: editMode ? selectedDep.salary : '',
      schedule: editMode ? AddDepScheduleFormat[selectedDep.approx_session_length] : '',
      isCentral: editMode ? Number(selectedDep.isCentral) : '',
      isEditableRates: editMode ? selectedDep.isEditableRates : '',
      isActive: editMode ? selectedDep.isActive : '',
      limit: editMode ? selectedDep.limit : '',
      currencies: editMode ? selectedDep.currencies.map((item) => item.id) : []
    },
    enableReinitialize: true,
    validationSchema: validationSchemaNewDepartment,
    onSubmit: (values) => {
      const data = {
        title: values.title,
        city_id: Number(values.cityId),
        filial_id: Number(values.filialId),
        isCentral: Boolean(values.isCentral),
        salary: Number(values.salary),
        tokens: values.tokensArr,
        isEditableRates: Boolean(values.isEditableRates),
        limit: Number(values.limit),
        addres: values.address,
        comment: values.comment ? String(values.comment) : null,
        isActive: Boolean(values.isActive),
        approx_session_length: values.schedule,
        currencies: values.currencies
      };
      if (editMode) {
        fetchEditDep({ ...data, id: selectedDep.id }).then((resp) => {
          if (resp === 'success') {
            closeForm();
          } else setErrorMessage(depEditErrors);
        });
      } else {
        fetchNewDep(data);
      }
    }
  });

  useEffect(() => {
    formik.setFieldValue('tokensArr', tokensArr);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokensArr]);

  const renderErrors = useMemo(() => {
    if (!isEmpty(errorMessage)) {
      return errorMessage.map((item) => <p key={nanoid()} className="errorText">{localizeErrors[item] || item}</p>);
    }
  }, [errorMessage]);

  useEffect(() => {
    formik.resetForm();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <ModalWindow
      open={open}
      maxWidth="750px"
      onClose={closeForm}
      onBackdropClick={closeForm}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid className="addPopup" sx={{ mt: 0, pt: 0 }} container columnSpacing={2} alignItems="center" justifyContent="center">
          <Grid item md={12} className="addUsers-grid">
            <FormControl fullWidth>
              <TextField
                error={formik.errors.title && formik.touched.title}
                helperText={formik.touched.title && formik.errors.title}
                size="small"
                id="title"
                name="title"
                label="Назва відділення"
                placeholder="Введіть назву відділення"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} className="addUsers-grid">
            <FormControl
              size="small"
              fullWidth
              error={formik.errors.filialId && formik.touched.filialId}
            >
              <InputLabel>Оберіть регіон *</InputLabel>
              <Select
                size="small"
                id="filialId"
                name="filialId"
                label="Оберіть регіон"
                placeholder="Оберіть регіон"
                MenuProps={MenuProps}
                value={formik.values.filialId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {filials.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
              {formik.errors.filialId && formik.touched.filialId && (
              <FormHelperText>{formik.errors.filialId}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item md={6} className="addUsers-grid">
            <FormControl
              size="small"
              fullWidth
              error={formik.errors.cityId && formik.touched.cityId}
            >
              <InputLabel>Оберіть місто *</InputLabel>
              <Select
                size="small"
                id="cityId"
                name="cityId"
                label="Оберіть місто"
                placeholder="Оберіть місто"
                MenuProps={MenuProps}
                value={formik.values.cityId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {cities.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
              {formik.errors.cityId && formik.touched.cityId && (
              <FormHelperText>{formik.errors.cityId}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item md={12} className="addUsers-grid">
            <FormControl size="small" fullWidth>
              <TextField
                error={formik.errors.address && formik.touched.address}
                helperText={formik.touched.address && formik.errors.address}
                size="small"
                id="address"
                label="Адреса"
                name="address"
                placeholder="Адреса"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </FormControl>
          </Grid>
          <Grid item md={12} sx={{ pb: '20px' }}>
            <FormControl size="small" fullWidth>
              <TextField
                error={formik.errors.comment && formik.touched.comment}
                helperText={formik.touched.comment && formik.errors.comment}
                multiline
                rows={2}
                size="small"
                id="comment"
                name="comment"
                label="Коментар"
                placeholder="Коментар"
                value={formik.values.comment}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </FormControl>
          </Grid>
          <Grid item md={12} sx={{ pb: '20px' }}>
            <FormControl
              size="small"
              fullWidth
              error={formik.errors.tokensArr && formik.touched.tokensArr}
            >
              <Autocomplete
                multiple
                name="tokensArr"
                id="tokensArr"
                value={tokensArr}
                onChange={(event, newValue) => {
                  if (isNull(newValue)) {
                    return null;
                  }
                  if (typeof newValue === 'string') {
                    setTokensArr({
                      token: newValue.replace(/\s/g, '')
                    });
                  } else if (newValue && newValue.inputValue) {
                    setTokensArr({
                      token: newValue.inputValue.replace(/\s/g, '')
                    });
                  } else {
                    setTokensArr(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  const isExisting = options.some((option) => inputValue === option.token);
                  if (inputValue !== '' && !isExisting && tokensArr.length < 2) {
                    filtered.push(inputValue);
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                size="small"
                handleHomeEndKeys
                options={[]}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.token;
                }}
                renderOption={(props, option) => (tokensArr.includes(option.trim()) ? (
                  <li style={{ padding: '7px 15px', color: 'red' }}>
                    Токен
                    {option}
                    {' '}
                    вже доданий
                  </li>
                ) : (
                  <li {...props}>
                    Додати токен
                    {' '}
                    {option}
                  </li>
                ))}
                freeSolo
                placeholder="Токен"
                label="Токен"
                renderInput={(params) => <TextField {...params} label="Токен" />}
              />
              {formik.errors.tokensArr && formik.touched.tokensArr && (
              <FormHelperText>{formik.errors.tokensArr}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item md={4} className="addUsers-grid">
            <FormControl
              size="small"
              fullWidth
              error={formik.errors.isCentral && formik.touched.isCentral}
            >
              <InputLabel>Тип відділення *</InputLabel>
              <Select
                size="small"
                id="isCentral"
                label="Тип відділення"
                name="isCentral"
                placeholder="Тип відділення"
                value={formik.values.isCentral}
                onChange={(e) => {
                  formik.setFieldValue('isEditableRates', 1);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
              >
                <MenuItem value={1}>Центральне</MenuItem>
                <MenuItem value={0}>Регіональне</MenuItem>
              </Select>
              {formik.errors.isCentral && formik.touched.isCentral && (
              <FormHelperText>{formik.errors.isCentral}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item md={4} className="addUsers-grid">
            <FormControl
              size="small"
              fullWidth
              error={formik.errors.isActive && formik.touched.isActive}
            >
              <InputLabel>Статус *</InputLabel>
              <Select
                size="small"
                id="isActive"
                label="Статус"
                name="isActive"
                placeholder="Статус"
                value={formik.values.isActive}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem value={1}>Активне</MenuItem>
                <MenuItem value={0}>Неактивне</MenuItem>
              </Select>
              {formik.errors.isActive && formik.touched.isActive && (
              <FormHelperText>{formik.errors.isActive}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item md={4} className="addUsers-grid">
            <FormControl
              size="small"
              fullWidth
              error={formik.errors.isEditableRates && formik.touched.isEditableRates}
            >
              <InputLabel>Зміна курсу *</InputLabel>
              <Select
                size="small"
                id="isEditableRates"
                label="Зміна курсу *"
                name="isEditableRates"
                placeholder="Зміна курсу"
                value={formik.values.isEditableRates}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem value={1}>Дозволити</MenuItem>
                <MenuItem value={0} disabled={formik.values.isCentral === 1}>
                  Заборонити
                </MenuItem>
              </Select>
              {formik.errors.isEditableRates && formik.touched.isEditableRates && (
              <FormHelperText>{formik.errors.isEditableRates}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item md={4} className="addUsers-grid">
            <FormControl
              size="small"
              fullWidth
              error={formik.errors.schedule && formik.touched.schedule}
            >
              <InputLabel>Графік роботи *</InputLabel>
              <Select
                size="small"
                id="schedule"
                label="Графік роботи"
                name="schedule"
                placeholder="Графік роботи"
                value={formik.values.schedule}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {Object.entries(departmentSchedule).map((schedule) => (
                  <MenuItem key={`${schedule[0]}-${schedule[1]}`} value={schedule[0]}>{schedule[1]}</MenuItem>
                ))}
              </Select>
              {formik.errors.schedule && formik.touched.schedule && (
              <FormHelperText>{formik.errors.schedule}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item md={4} className="addUsers-grid">
            <FormControl size="small" fullWidth>
              <TextField
                error={formik.errors.salary && formik.touched.salary}
                helperText={formik.touched.salary && formik.errors.salary}
                size="small"
                id="salary"
                label="ЗП"
                name="salary"
                type="number"
                onWheel={(e) => e.target.blur()}
                placeholder="ЗП"
                value={formik.values.salary}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} className="addUsers-grid">
            <FormControl size="small" fullWidth>
              <TextField
                error={formik.errors.limit && formik.touched.limit}
                helperText={formik.touched.limit && formik.errors.limit}
                id="limit"
                size="small"
                name="limit"
                value={formik.values.limit}
                type="number"
                onWheel={(e) => e.target.blur()}
                label="Ліміт каси"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Ліміт каси"
              />
            </FormControl>
          </Grid>
          <Grid item md={12} className="addUsers-grid">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                sx={{ minWidth: '135px' }}
                label="Обрати усі"
                control={<Checkbox size="small" />}
                checked={currencyList?.length === formik.values.currencies?.length}
                onChange={(e) => {
                  if (e.target.checked) {
                    formik.setFieldValue('currencies', currencyList.map((item) => item.id));
                  } else {
                    formik.setFieldValue('currencies', []);
                  }
                }}
              />
              <FormControl
                fullWidth
                size="small"
                error={formik.errors.currencies && formik.touched.currencies}
                sx={{ mb: '0 !important' }}
              >
                <InputLabel>Валюти *</InputLabel>
                <Select
                  sx={{ width: '100%' }}
                  id="currencies"
                  name="currencies"
                  size="small"
                  multiple
                  MenuProps={MenuProps}
                  value={formik.values.currencies}
                  input={<OutlinedInput label="Currency" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={currencyList.find((item) => item.id === value)?.title} />
                      ))}
                    </Box>
                  )}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {currencyList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
                {formik.errors.currencies && formik.touched.currencies && (
                <FormHelperText>{formik.errors.currencies}</FormHelperText>
                )}
              </FormControl>
            </div>
          </Grid>
        </Grid>
        <div className="addUsers addDep">
          {renderErrors}
          <div className="addUsers-actions">
            <LoadingButton
              loading={loadingNew || isEditDep}
              variant="contained"
              color="success"
              type="submit"
              size="large"
            >
              <SendIcon fontSize="small" sx={{ marginRight: '5px' }} />
              {editMode ? 'Редагувати' : 'Створити'}
            </LoadingButton>
            <Button
              variant="contained"
              color="error"
              onClick={closeForm}
              size="large"
            >
              <DisabledByDefaultIcon sx={{ marginRight: '5px' }} />
              Скасувати
            </Button>
          </div>
        </div>
      </form>
    </ModalWindow>
  );
};

AddDepartments.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setAlertNotification: PropTypes.func,
  selectedDep: PropTypes.object,
  editMode: PropTypes.bool
};

export default AddDepartments;
