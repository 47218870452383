import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { DashboardContext } from './DashboardContext';
import UsersService from '../../../services/USERS/UsersService';
import InkasatorsService from '../../../services/INKASATORS/InkasatorsService';
import CitiesService from '../../../services/CITIES/CitiesService';
import ExpensesService from '../../../services/EXPENSES/ExpensesService';
import SessionService from '../../../services/SESSIONS/SessionService';
import RolesService from '../../../services/ROLES/RolesService';
import ReportsService from '../../../services/REPORTS/ReportsService';
import FilialService from '../../../services/FILIAL/FilialService';
import DepartmentsService from '../../../services/DEPARTMENTS/DepartmentsService';
import CurrenciesService from '../../../services/CURRENCIES/CurrenciesService';
import OperationsService from '../../../services/OPERATIONS/OperationsService';
import BalancesService from '../../../services/BALANCE/BalancesService';
import OrdersService from '../../../services/ORDERS/OrdersService';
import AlertSnackbar from '../../common/Snackbar/AlertSnackbar';
import ArrivalsService from '../../../services/ARRIVAL/ArrivalsService';

function download(blob, filename) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
}
class DashboardProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      users: [],
      addUsersErrors: [],
      inkasatorUsers: [],
      allInkasatorUsers: [],
      zoDep: [],
      cities: [],
      citiesErrors: [],
      filials: [],
      filialsErrors: [],
      expensesErrors: [],
      arrivalsErrors: [],
      selectedFilialDeps: [],
      selectedFilialDepCount: 0,
      currency: [],
      balanceFilials: [],
      balanceDepartments: [],
      balanceDepartmentsCount: 0,
      operations: [],
      sessionList: [],
      sessions: [],
      sessionsCount: null,
      orders: [],
      inkassators: [],
      ordersCount: [],
      departments: [],
      expenses: [],
      arrivals: [],
      reportLimit: [],
      isFetchingReportLimit: false,
      selectedUser: {},
      selectedFilialID: '',
      usersCount: null,
      inkasatorUsersCount: null,
      isCoFetched: false,
      operationsFilters: [],
      operationsTable: [],
      operationsCount: null,
      isFetchingUser: false,
      isUserUpdating: false,
      departmentsCount: null,
      isFetchingUsers: false,
      isFetchingExpenses: false,
      isFetchingArrivals: false,
      isFetchingInkasatorUsers: false,
      isFetchingRoles: false,
      isFetchingCities: false,
      isFetchingFilials: false,
      isFetchingOperations: false,
      isFetchingSessionList: false,
      isFetchingSessions: false,
      isFetchingOperationsFilter: false,
      isFetchingOperationsTable: false,
      isFetchingOperationsStorno: false,
      stornoError: null,
      isFetchingOrders: false,
      isFetchingPostOrder: false,
      fetchPostOrderMassage: [],
      fetchPutOrderMassage: [],
      fetchPutOrderStatusMassage: [],
      isFetchingPutOrder: false,
      isFetchingPutOrderStatus: false,
      isFetchingPutOrderInkas: false,
      isFetchingInkassators: false,
      isFetchingCurrencies: false,
      isFetchingDepartments: false,
      isFetchingBalanceFilials: false,
      isFetchingBalanceDepartments: false,
      isFetchingReportBalance: false,
      isFetchingReportOperations: false,
      isFetchingReportFilialIncome: false,
      isOpenSnackbar: false,
      snackbarMsg: '',
      snackbarType: false,
      selectedBalanceDepartment: [],
      selectedBalanceDepartmentErrors: []
    };
  }

  fetchBalanceFilials = async () => {
    try {
      this.setState({
        isFetchingBalanceFilials: true
      });
      const response = await BalancesService.getRequestFilials();
      if (!response.message) {
        this.setState({
          balanceFilials: response.data,
          isFetchingBalanceFilials: false
        });
      }
    } catch {
      this.setState({
        isFetchingBalanceFilials: false
      });
    }
  };

  fetchBalanceDepartments = async (data) => {
    try {
      this.setState({
        isFetchingBalanceDepartments: true
      });
      const response = await BalancesService.getRequestFilial(data);
      if (!response.message) {
        this.setState({
          balanceDepartments: response.data,
          isFetchingBalanceDepartments: false,
          balanceDepartmentsCount: response.meta.total
        });
      }
    } catch {
      this.setState({
        isFetchingBalanceFilials: false
      });
    }
  };

  fetchSelectedBalanceDepartment = async (data) => {
    try {
      const response = await DepartmentsService.getRequestWithID(data);
      if (!response.error) {
        this.setState({
          selectedBalanceDepartment: response.data
        });
      } else {
        this.setState({
          isOpenSnackbar: true,
          snackbarMsg: response.error,
          snackbarType: false,
          selectedBalanceDepartment: []
        });
      }
    // eslint-disable-next-line no-empty
    } catch (e) {
    }
  };

  fetchUpdateDepartmentBalance = async (data, departmentId) => {
    try {
      const response = await BalancesService.putRequestForBalance(data);
      if (('success' in response) && !response.success) {
        this.setState({
          isOpenSnackbar: true,
          snackbarMsg: 'Помилка при оновленні балансу',
          snackbarType: false,
          selectedBalanceDepartmentErrors: [response.message]
        });
      } else {
        this.setState({
          isOpenSnackbar: true,
          snackbarMsg: 'Баланс оновлено успішно',
          snackbarType: true,
          selectedBalanceDepartmentErrors: []
        });
        this.fetchSelectedBalanceDepartment({ id: departmentId });
      }
    // eslint-disable-next-line no-empty
    } catch (e) {
    }
  };

  clearBalanceDepartmentErrors = () => {
    this.setState({
      selectedBalanceDepartmentErrors: []
    });
  };

  fetchReportBalance = async () => {
    try {
      this.setState({
        isFetchingReportBalance: true
      });
      const response = await ReportsService.getReportBalance();
      download(await response.blob(), 'balance.xlsx');
      this.setState({
        isFetchingReportBalance: false
      });
    } catch {
      this.setState({
        isFetchingReportBalance: false
      });
    }
  };

  fetchReportOperations = async (data) => {
    try {
      this.setState({
        isFetchingReportOperations: true
      });
      const response = await ReportsService.getReportOperations(data);
      download(await response.blob(), 'report-operations.xlsx');
      this.setState({
        isFetchingReportOperations: false
      });
    } catch {
      this.setState({
        isFetchingReportOperations: false
      });
    }
  };

  fetchReportFilialIncome = async (data) => {
    try {
      this.setState({
        isFetchingReportFilialIncome: true
      });
      const response = await ReportsService.getReportFilialIncome(data);
      download(await response.blob(), 'balance.xlsx');
      this.setState({
        isFetchingReportFilialIncome: false
      });
    } catch {
      this.setState({
        isFetchingReportFilialIncome: false
      });
    }
  };

  fetchReportLimit = async (data) => {
    try {
      this.setState({
        isFetchingReportLimit: true
      });
      const response = await ReportsService.getReportLimit(data);
      if (!response.message) {
        this.setState({
          reportLimit: response
        });
      }
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isFetchingReportLimit: false
      });
    }
  };

  clearReportLimit = () => {
    this.setState({
      reportLimit: []
    });
  };

  fetchSessionList = async (data) => {
    try {
      this.setState({
        isFetchingSessionList: true
      });
      const response = await SessionService.getSessionsList(data);
      if (!response.message) {
        this.setState({
          sessionList: response.data,
          isFetchingSessionList: false
        });
      }
    } catch {
      this.setState({
        isFetchingSessionList: false
      });
    }
  };

  fetchSession = async (data) => {
    try {
      this.setState({
        isFetchingSessions: true
      });
      const response = await SessionService.getRequest(data);
      if (!response.message) {
        this.setState({
          sessions: response.data,
          sessionsCount: response.meta?.total ?? response.data.length
        });
      }
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isFetchingSessions: false
      });
    }
  };

  fetchOperations = async (data) => {
    try {
      this.setState({
        isFetchingOperations: true
      });
      const response = await OperationsService.getRequest(data);
      if (!response.message) {
        this.setState({
          operations: response.data,
          isFetchingOperations: false,
          operationsCount: response.meta.total
        });
      }
    } catch {
      this.setState({
        isFetchingOperations: false
      });
    }
  };

  fetchOperationsFilter = async (data) => {
    try {
      this.setState({
        isFetchingOperationsFilter: true
      });
      const response = await OperationsService.getRequestFilter(data);
      if (!response.message) {
        this.setState({
          operationsFilters: response.data,
          isFetchingOperationsFilter: false
        });
      }
    } catch {
      this.setState({
        isFetchingOperationsFilter: false
      });
    }
  };

  fetchOperationsTable = async (data) => {
    try {
      this.setState({
        isFetchingOperationsTable: true
      });
      const response = await OperationsService.getRequestTable(data);
      if (!response.message) {
        this.setState({
          operationsTable: response.data ? response.data : [],
          isFetchingOperationsTable: false
        });
      }
    } catch {
      this.setState({
        isFetchingOperationsTable: false
      });
    }
  };

  fetchOperationsStorno = async ({ id, stornoValue: storno, sessionDataId }) => {
    try {
      this.setState({
        isFetchingOperationsStorno: true,
        stornoError: null
      });
      const response = await OperationsService.putRequest({
        id,
        storno,
        current_session: sessionDataId
      });
      if ('success' in response && !response.success) {
        this.setState({
          snackbarMsg: 'Помилка при скасуванні операції. Бракує коштів на балансі',
          snackbarType: false,
          stornoError: 'Бракує коштів на балансі для відміни операції'
        });
      } else {
        this.setState({
          snackbarMsg: 'Операцію успішно скасовано',
          snackbarType: true,
          stornoError: null
        });
        this.setState(
          (prevState) => ({
            operations: prevState.operations.map((e) => (e.id === id ? { ...e, storno } : e))
          })
        );
        return 'success';
      }
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isOpenSnackbar: true,
        isFetchingOperationsStorno: false
      });
    }
  };

  clearStornoErrors = () => {
    this.setState({
      stornoError: null
    });
  };

  fetchOrders = async (data) => {
    try {
      this.setState({
        isFetchingOrders: true
      });
      const response = await OrdersService.getRequest(data);
      if (!response.message) {
        this.setState({
          orders: response.data,
          ordersCount: response.meta.total,
          isFetchingOrders: false
        });
      }
    } catch {
      this.setState({
        isFetchingOrders: false
      });
    }
  };

  fetchPutOrder = async (data) => {
    try {
      this.setState({
        isFetchingPutOrder: true
      });
      const response = await OrdersService.putRequest(data);
      this.setState({
        fetchPutOrderMassage: response.message || []
      });
      return response.message;
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isFetchingPutOrder: false
      });
    }
  };

  fetchPutOrderStatus = async (data) => {
    try {
      this.setState({
        isFetchingPutOrderStatus: true
      });
      const response = await OrdersService.putRequestOrderStatus(data.formData.current);
      this.setState({
        fetchPutOrderStatusMassage: response.message || []
      });
      return response.message;
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isFetchingPutOrderStatus: false
      });
    }
  };

  fetchPutOrderInkas = async (data) => {
    try {
      this.setState({
        isFetchingPutOrderInkas: true
      });
      const response = await OrdersService.putRequestOrderInkas(data);
      if (!response.message) {
        this.setState({
          isFetchingPutOrderInkas: false
        });
      }
    } catch {
      this.setState({
        isFetchingPutOrderInkas: false
      });
    }
  };

  fetchPostOrder = async (data) => {
    try {
      this.setState({
        isFetchingPostOrder: true
      });
      const response = await OrdersService.postRequest(data);
      this.setState({
        fetchPostOrderMassage: response.message || []
      });
      return response.message;
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isFetchingPostOrder: false
      });
    }
  };

  fetchInkassators = async (data) => {
    try {
      this.setState({
        isFetchingInkassators: true
      });
      const response = await OrdersService.getInkassators(data);
      if (!response.message) {
        this.setState({
          inkassators: response,
          isFetchingInkassators: false
        });
      }
    } catch {
      this.setState({
        isFetchingInkassators: false
      });
    }
  };

  fetchRoles = async () => {
    try {
      this.setState({
        isFetchingRoles: true
      });
      const response = await RolesService.getRequest();
      if (response.data) {
        this.setState({
          roles: response.data,
          isFetchingRoles: true
        });
      }
      if (!response.data) {
        this.setState({
          roles: [],
          isFetchingRoles: false
        });
      }
    } catch {
      this.setState({
        roles: [],
        isFetchingRoles: false
      });
    }
  };

  fetchCities = async () => {
    try {
      this.setState({
        isFetchingCities: true
      });
      const response = await CitiesService.getRequest();
      if (response.data) {
        this.setState({
          cities: response.data
        });
      }
      if (!response.data) {
        this.setState({
          cities: []
        });
      }
    } catch {
      this.setState({
        cities: []
      });
    } finally {
      this.setState({
        isFetchingCities: false
      });
    }
  };

  fetchAddCities = async (data) => {
    try {
      this.setState({
        isFetchingCities: true
      });
      const response = await CitiesService.postRequest(data);
      if ('success' in response && !response.success) {
        this.setState({
          snackbarMsg: 'Помилка при додаванні міста',
          snackbarType: false,
          citiesErrors: Object.values(response.data) || [response?.message]
        });
      } else {
        this.setState({
          snackbarMsg: 'Місто успішно додано',
          snackbarType: true,
          citiesErrors: []
        });
        this.fetchCities();
        return 'success';
      }
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isOpenSnackbar: true,
        isFetchingCities: false
      });
    }
  };

  fetchEditCities = async (data) => {
    try {
      this.setState({
        isFetchingCities: true
      });
      const response = await CitiesService.putRequest(data);
      if ('success' in response && !response.success) {
        this.setState({
          snackbarMsg: 'Помилка при редагуванні міста',
          snackbarType: false,
          citiesErrors: Object.values(response.data) || [response?.message]
        });
      } else {
        this.setState({
          snackbarMsg: 'Місто успішно відредаговано',
          snackbarType: true,
          citiesErrors: []
        });
        this.fetchCities();
        return 'success';
      }
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isOpenSnackbar: true,
        isFetchingCities: false
      });
    }
  };

  fetchDeleteCities = async (data) => {
    try {
      const response = await CitiesService.delete(data);
      if (('error' in response)) {
        this.setState({
          isOpenSnackbar: true,
          snackbarMsg: response.error === "Can't deleted - has a relationship"
            ? "Не можливо видалити. До міста прив'язаний користувач"
            : 'Помилка при видаленні міста',
          snackbarType: false
        });
      } else {
        this.setState({
          filials: response.data,
          isOpenSnackbar: true,
          snackbarMsg: 'Місто успішно видалено',
          snackbarType: true
        });
        this.fetchCities();
        return 'success';
      }
    // eslint-disable-next-line no-empty
    } catch (e) {
    }
  };

  clearCitiesErrors = () => {
    this.setState({
      citiesErrors: []
    });
  };

  fetchFilials = async () => {
    try {
      this.setState({
        isFetchingFilials: true
      });
      const response = await FilialService.getRequest();
      if (response.data) {
        this.setState({
          filials: response.data
        });
      }
      if (!response.data) {
        this.setState({
          filials: []
        });
      }
    } catch {
      this.setState({
        filials: []
      });
    } finally {
      this.setState({
        isFetchingFilials: false
      });
    }
  };

  fetchAddFilials = async (data) => {
    try {
      this.setState({
        isFetchingFilials: true
      });
      const response = await FilialService.postRequest(data);
      if ('success' in response && !response.success) {
        this.setState({
          snackbarMsg: 'Помилка при створенні регіону',
          snackbarType: false,
          filialsErrors: Object.values(response.data) || [response?.message]
        });
      } else {
        this.setState({
          snackbarMsg: 'Регіон успішно створено',
          snackbarType: true,
          filialsErrors: []
        });
        this.fetchFilials();
        return 'success';
      }
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isOpenSnackbar: true,
        isFetchingFilials: false
      });
    }
  };

  fetchEditFilials = async (data) => {
    try {
      this.setState({
        isFetchingFilials: true
      });
      const response = await FilialService.putRequest(data);
      if ('success' in response && !response.success) {
        this.setState({
          snackbarMsg: 'Помилка при редагуванні регіону',
          snackbarType: false,
          filialsErrors: Object.values(response.data) || [response?.message]
        });
      } else {
        this.setState({
          snackbarMsg: 'Регіон успішно відредаговано',
          snackbarType: true,
          filialsErrors: []
        });
        this.fetchFilials();
        return 'success';
      }
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isOpenSnackbar: true,
        isFetchingFilials: false
      });
    }
  };

  fetchSelectedFilial = async (data) => {
    try {
      const response = await FilialService.getRequestWithID(data);
      if (response.data) {
        this.setState({
          selectedFilialDeps: response.data.departments,
          selectedFilialDepCount: response.data.departments?.length || 0
        });
      }
    // eslint-disable-next-line no-empty
    } catch (e) {
    }
  };

  fetchDeleteFilials = async (data) => {
    try {
      const response = await FilialService.delete(data);
      if (('success' in response) && !response.success) {
        this.setState({
          isOpenSnackbar: true,
          snackbarMsg: response.message,
          snackbarType: false
        });
      } else {
        this.setState({
          filials: response.data,
          isOpenSnackbar: true,
          snackbarMsg: 'Регіон успішно видалено',
          snackbarType: true
        });
      }
    // eslint-disable-next-line no-empty
    } catch (e) {
    }
  };

  clearFilialsErrors = () => {
    this.setState({
      filialsErrors: []
    });
  };

  fetchDepartments = async (data) => {
    try {
      this.setState({
        isFetchingDepartments: true
      });
      const response = await DepartmentsService.getRequest(data);
      if (response.data) {
        this.setState({
          departments: response.data,
          isFetchingDepartments: true,
          departmentsCount: response.meta.total
        });
      }
      if (!response.data) {
        this.setState({
          departments: [],
          isFetchingDepartments: false
        });
      }
    } catch {
      this.setState({
        departments: [],
        isFetchingDepartments: false
      });
    }
  };

  fetchZoDepart = async () => {
    try {
      const response = await DepartmentsService.getRequest({ isCentral: 1, page: 1, per_page: 100 });
      if (response.data) {
        this.setState({
          zoDep: response.data,
          isCoFetched: true
        });
      }
      if (!response.data) {
        this.setState({ zoDep: [] });
      }
    } catch {
      this.setState({ zoDep: [] });
    }
  };

  fetchUsers = async (data) => {
    try {
      this.setState({
        isFetchingUsers: true
      });
      const response = await UsersService.getRequest(data);
      if (response.data) {
        this.setState({
          users: response.data,
          usersCount: response.meta?.total ?? response.data.length,
          isFetchingUsers: true
        });
      }
      if (!response.data) {
        this.setState({
          users: [],
          isFetchingUsers: false
        });
      }
    } catch {
      this.setState({
        users: [],
        isFetchingUsers: false
      });
    }
  };

  fetchAddUser = async (data) => {
    try {
      this.setState({
        isFetchingUsers: true
      });
      const response = await UsersService.postRequest(data);
      if (('success' in response) && !response.success) {
        this.setState({
          isOpenSnackbar: true,
          snackbarMsg: 'Помилка при створенні користувача',
          snackbarType: false,
          addUsersErrors: Object.values(response.data)
        });
        return 'error';
      }
      if (!('success' in response) && response.data) {
        this.setState({
          isOpenSnackbar: true,
          snackbarMsg: 'Користувач успішно створений',
          snackbarType: true,
          addUsersErrors: []
        });
        return response;
      }
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isFetchingUsers: false
      });
    }
  };

  fetchEditUser = async (data) => {
    try {
      this.setState({
        isFetchingUsers: true
      });
      const response = await UsersService.putRequest(data);
      if (('success' in response) && !response.success) {
        this.setState({
          isOpenSnackbar: true,
          snackbarMsg: 'Помилка при редагуванні користувача',
          snackbarType: false,
          addUsersErrors: Object.values(response.data)
        });
        return 'error';
      }
      if (!('success' in response) && response.data) {
        this.setState({
          isOpenSnackbar: true,
          snackbarMsg: 'Користувач успішно відредагований',
          snackbarType: true,
          addUsersErrors: []
        });
        return {
          data: response.data,
          status: 'success'
        };
      }
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isFetchingUsers: false
      });
    }
  };

  clearUsersErrors = () => {
    this.setState({
      addUsersErrors: []
    });
  };

  fetchInkasatorsUsers = async (data) => {
    try {
      this.setState({
        isFetchingInkasatorUsers: true
      });
      const response = await InkasatorsService.getRequest(data);
      const all = await InkasatorsService.getRequest({});
      if (response.data) {
        this.setState({
          inkasatorUsers: response.data,
          allInkasatorUsers: all.data,
          inkasatorUsersCount: response.meta.total,
          isFetchingInkasatorUsers: true
        });
      }
      if (!response.data) {
        this.setState({
          inkasatorUsers: [],
          allInkasatorUsers: [],
          isFetchingInkasatorUsers: false
        });
      }
    } catch {
      this.setState({
        inkasatorUsers: [],
        allInkasatorUsers: [],
        isFetchingInkasatorUsers: false
      });
    }
  };

  fetchOtherExpenses = async (data) => {
    try {
      this.setState({
        isFetchingExpenses: true
      });
      const response = await ExpensesService.getRequest(data);
      if (response.data) {
        this.setState({
          expenses: response.data,
          isFetchingExpenses: false
        });
      }
      if (!response.data) {
        this.setState({
          expenses: [],
          isFetchingExpenses: false
        });
      }
    } catch {
      this.setState({
        expenses: [],
        isFetchingExpenses: false
      });
    }
  };

  fetchAddExpensesCategory = async (data) => {
    try {
      this.setState({
        isFetchingExpenses: true
      });
      const response = await ExpensesService.postCategory(data);
      if ('success' in response && !response.success) {
        this.setState({
          snackbarMsg: 'Помилка при створенні категорії витрат',
          snackbarType: false,
          expensesErrors: Object.values(response.data) || [response?.message]
        });
      } else {
        this.setState({
          snackbarMsg: 'Категорію витрат успішно створено',
          snackbarType: true,
          expensesErrors: []
        });
        this.fetchOtherExpenses();
        return 'success';
      }
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isOpenSnackbar: true,
        isFetchingExpenses: false
      });
    }
  };

  fetchEditExpensesCategory = async (data) => {
    try {
      this.setState({
        isFetchingExpenses: true
      });
      const response = await ExpensesService.putCategory(data);
      if ('success' in response && !response.success) {
        this.setState({
          snackbarMsg: 'Помилка при редагуванні категорії витрат',
          snackbarType: false,
          expensesErrors: Object.values(response.data) || [response?.message]
        });
      } else {
        this.setState({
          snackbarMsg: 'Категорію витрат успішно відредаговано',
          snackbarType: true,
          expensesErrors: []
        });
        this.fetchOtherExpenses();
        return 'success';
      }
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isOpenSnackbar: true,
        isFetchingExpenses: false
      });
    }
  };

  fetchDeleteExpensesCategory = async (data) => {
    try {
      const response = await ExpensesService.deleteCategory(data);
      if (('success' in response) && !response.success) {
        this.setState({
          isOpenSnackbar: true,
          snackbarMsg: response.message,
          snackbarType: false
        });
      } else {
        this.setState({
          filials: response.data,
          isOpenSnackbar: true,
          snackbarMsg: 'Категорію витрат успішно видалено',
          snackbarType: true
        });
        this.fetchOtherExpenses();
        return 'success';
      }
    // eslint-disable-next-line no-empty
    } catch (e) {
    }
  };

  clearExpensesErrors = () => {
    this.setState({
      expensesErrors: []
    });
  };

  fetchAddExpenses = async (data) => {
    try {
      this.setState({
        isFetchingExpenses: true
      });
      const response = await ExpensesService.postRequest(data);
      if ('success' in response && !response.success) {
        this.setState({
          snackbarMsg: 'Помилка при створенні позиції витрати',
          snackbarType: false,
          expensesErrors: Object.values(response.data) || [response?.message]
        });
      } else {
        this.setState({
          snackbarMsg: 'Нову позицію витрат успішно створено',
          snackbarType: true,
          expensesErrors: []
        });
        this.fetchOtherExpenses();
        return 'success';
      }
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isOpenSnackbar: true,
        isFetchingExpenses: false
      });
    }
  };

  fetchEditExpenses = async (data) => {
    try {
      this.setState({
        isFetchingExpenses: true
      });
      const response = await ExpensesService.putRequest(data);
      if ('success' in response && !response.success) {
        this.setState({
          snackbarMsg: 'Помилка при редагуванні позиції витрат',
          snackbarType: false,
          expensesErrors: Object.values(response.data) || [response?.message]
        });
      } else {
        this.setState({
          snackbarMsg: 'Позицію витрат успішно змінено',
          snackbarType: true,
          expensesErrors: []
        });
        this.fetchOtherExpenses();
        return 'success';
      }
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isOpenSnackbar: true,
        isFetchingExpenses: false
      });
    }
  };

  fetchDeleteExpenses = async (data) => {
    try {
      const response = await ExpensesService.delete(data);
      if (('success' in response) && !response.success) {
        this.setState({
          isOpenSnackbar: true,
          snackbarMsg: response.message,
          snackbarType: false
        });
      } else {
        this.setState({
          filials: response.data,
          isOpenSnackbar: true,
          snackbarMsg: 'Позицію витрат успішно видалено',
          snackbarType: true
        });
        this.fetchOtherExpenses();
        return 'success';
      }
    // eslint-disable-next-line no-empty
    } catch (e) {
    }
  };

  fetchBuyExpenses = async (data) => {
    try {
      this.setState({
        isFetchingExpenses: true
      });
      const response = await ExpensesService.postExpenseBuy(data);
      if ('success' in response && !response.success) {
        this.setState({
          snackbarMsg: 'Помилка при створенні витрати',
          snackbarType: false,
          expensesErrors: [response?.message]
        });
      } else {
        this.setState({
          snackbarMsg: 'Нову витрату успішно оформлено',
          snackbarType: true,
          expensesErrors: []
        });
        return 'success';
      }
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isOpenSnackbar: true,
        isFetchingExpenses: false
      });
    }
  };

  fetchOtherArrivals = async (data) => {
    try {
      this.setState({
        isFetchingArrivals: true
      });
      const response = await ArrivalsService.getRequest(data);
      if (response.data) {
        this.setState({
          arrivals: response.data,
          isFetchingArrivals: false
        });
      }
      if (!response.data) {
        this.setState({
          arrivals: [],
          isFetchingArrivals: false
        });
      }
    } catch {
      this.setState({
        arrivals: [],
        isFetchingArrivals: false
      });
    }
  };

  addArrivalCategory = async (data) => {
    try {
      this.setState({
        isFetchingArrivals: true
      });
      const response = await ArrivalsService.postCategory(data);
      if ('success' in response && !response.success) {
        this.setState({
          snackbarMsg: 'Помилка при створенні категорії доходів',
          snackbarType: false,
          arrivalsErrors: Object.values(response.data) || [response?.message]
        });
      } else {
        this.setState({
          snackbarMsg: 'Категорію доходів успішно створено',
          snackbarType: true,
          arrivalsErrors: []
        });
        this.fetchOtherArrivals();
        return 'success';
      }
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isOpenSnackbar: true,
        isFetchingArrivals: false
      });
    }
  };

  editArrivalsCategory = async (data) => {
    try {
      this.setState({
        isFetchingArrivals: true
      });
      const response = await ArrivalsService.putCategory(data);
      if ('success' in response && !response.success) {
        this.setState({
          snackbarMsg: 'Помилка при редагуванні категорії доходів',
          snackbarType: false,
          arrivalsErrors: Object.values(response.data) || [response?.message]
        });
      } else {
        this.setState({
          snackbarMsg: 'Категорію доходів успішно відредаговано',
          snackbarType: true,
          arrivalsErrors: []
        });
        this.fetchOtherArrivals();
        return 'success';
      }
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isOpenSnackbar: true,
        isFetchingArrivals: false
      });
    }
  };

  clearArrivalErrors = () => {
    this.setState({
      arrivalsErrors: []
    });
  };

  deleteArrivalCategory = async (data) => {
    try {
      const response = await ArrivalsService.deleteCategory(data);
      if (('success' in response) && !response.success) {
        this.setState({
          isOpenSnackbar: true,
          snackbarMsg: response.message,
          snackbarType: false
        });
      } else {
        this.setState({
          // why filials
          filials: response.data,
          isOpenSnackbar: true,
          snackbarMsg: 'Категорію доходів успішно видалено',
          snackbarType: true
        });
        this.fetchOtherArrivals();
        return 'success';
      }
    // eslint-disable-next-line no-empty
    } catch (e) {
    }
  };

  addArrivalItem = async (data) => {
    try {
      this.setState({
        isFetchingArrivals: true
      });
      const response = await ArrivalsService.postRequest(data);
      if ('success' in response && !response.success) {
        this.setState({
          snackbarMsg: 'Помилка при створенні позиції доходів',
          snackbarType: false,
          arrivalsErrors: Object.values(response.data) || [response?.message]
        });
      } else {
        this.setState({
          snackbarMsg: 'Нову позицію доходів успішно створено',
          snackbarType: true,
          arrivalsErrors: []
        });
        this.fetchOtherArrivals();
        return 'success';
      }
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isOpenSnackbar: true,
        isFetchingArrivals: false
      });
    }
  };

  editArrivalItem = async (data) => {
    try {
      this.setState({
        isFetchingArrivals: true
      });
      const response = await ArrivalsService.putRequest(data);
      if ('success' in response && !response.success) {
        this.setState({
          snackbarMsg: 'Помилка при редагуванні позиції доходів',
          snackbarType: false,
          arrivalsErrors: Object.values(response.data) || [response?.message]
        });
      } else {
        this.setState({
          snackbarMsg: 'Позицію доходів успішно змінено',
          snackbarType: true,
          arrivalsErrors: []
        });
        this.fetchOtherArrivals();
        return 'success';
      }
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isOpenSnackbar: true,
        isFetchingArrivals: false
      });
    }
  };

  deleteArrivalItem = async (data) => {
    try {
      const response = await ArrivalsService.delete(data);
      if (('success' in response) && !response.success) {
        this.setState({
          isOpenSnackbar: true,
          snackbarMsg: response.message,
          snackbarType: false
        });
      } else {
        this.setState({
          // why filials?
          filials: response.data,
          isOpenSnackbar: true,
          snackbarMsg: 'Позицію доходів успішно видалено',
          snackbarType: true
        });
        this.fetchOtherArrivals();
        return 'success';
      }
    // eslint-disable-next-line no-empty
    } catch (e) {
    }
  };

  handleArrival = async (data) => {
    try {
      this.setState({
        isFetchingArrivals: true
      });
      const response = await ArrivalsService.postArrivalHandle(data);
      if ('success' in response && !response.success) {
        this.setState({
          snackbarMsg: 'Помилка при створенні доходу',
          snackbarType: false,
          arrivalsErrors: [response?.message]
        });
      } else {
        this.setState({
          snackbarMsg: 'Новий дохід успішно оформлено',
          snackbarType: true,
          arrivalsErrors: []
        });
        return 'success';
      }
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setState({
        isOpenSnackbar: true,
        isFetchingArrivals: false
      });
    }
  };

  fetchCurrencies = async ({ date }) => {
    try {
      this.setState({
        isFetchingCurrencies: true
      });
      const response = await CurrenciesService.getRequest({ date });
      if (response.data) {
        this.setState({
          currency: response.data,
          isFetchingCurrencies: false
        });
      }
      if (!response.data) {
        this.setState({
          currency: [],
          isFetchingCurrencies: false
        });
      }
    } catch {
      this.setState({
        currency: [],
        isFetchingCurrencies: false
      });
    }
  };

  fetchSelectedUser = async (data) => {
    try {
      this.setState({ isFetchingUser: true });
      const response = await UsersService.getRequestWithID(data);
      this.setState({
        selectedUser: response.data,
        isFetchingUser: false
      });
    } catch {
      this.setState({ isFetchingUser: false });
    }
  };

  updateSelectedUser = async (data) => {
    try {
      this.setState({ isUserUpdating: true });
      this.setState({
        selectedUser: data
      });
    } catch {
      this.setState({ isUserUpdating: false });
    }
  };

  updateSelectedFilialID = (data) => {
    this.setState({
      selectedFilialID: data
    });
  };

  getProviderValues = () => {
    const {
      roles,
      users,
      addUsersErrors,
      inkasatorUsers,
      allInkasatorUsers,
      zoDep,
      cities,
      citiesErrors,
      orders,
      inkassators,
      ordersCount,
      filials,
      filialsErrors,
      expensesErrors,
      arrivalsErrors,
      selectedFilialDeps,
      selectedFilialDepCount,
      currency,
      sessionList,
      sessions,
      sessionsCount,
      operations,
      balanceFilials,
      balanceDepartments,
      selectedBalanceDepartment,
      selectedBalanceDepartmentErrors,
      balanceDepartmentsCount,
      usersCount,
      inkasatorUsersCount,
      isCoFetched,
      departments,
      expenses,
      arrivals,
      selectedUser,
      selectedFilialID,
      reportLimit,
      isFetchingUser,
      isUserUpdating,
      isFetchingUsers,
      isFetchingExpenses,
      isFetchingArrivals,
      isFetchingInkasatorUsers,
      isFetchingRoles,
      operationsCount,
      departmentsCount,
      isFetchingCities,
      isFetchingFilials,
      operationsFilters,
      operationsTable,
      isFetchingSessionList,
      isFetchingSessions,
      isFetchingOperations,
      isFetchingOperationsFilter,
      isFetchingOperationsTable,
      isFetchingOperationsStorno,
      stornoError,
      isFetchingOrders,
      isFetchingPutOrder,
      isFetchingPutOrderStatus,
      isFetchingPutOrderInkas,
      isFetchingPostOrder,
      fetchPostOrderMassage,
      fetchPutOrderMassage,
      fetchPutOrderStatusMassage,
      isFetchingInkassators,
      isFetchingCurrencies,
      isFetchingBalanceFilials,
      isFetchingBalanceDepartments,
      isFetchingReportBalance,
      isFetchingReportOperations,
      isFetchingReportFilialIncome,
      isFetchingDepartments,
      isFetchingReportLimit
    } = this.state;
    return {
      roles,
      users,
      addUsersErrors,
      inkasatorUsers,
      allInkasatorUsers,
      zoDep,
      orders,
      cities,
      citiesErrors,
      filials,
      filialsErrors,
      expensesErrors,
      arrivalsErrors,
      selectedFilialDeps,
      selectedFilialDepCount,
      currency,
      operations,
      sessionList,
      sessions,
      sessionsCount,
      balanceFilials,
      balanceDepartments,
      selectedBalanceDepartment,
      selectedBalanceDepartmentErrors,
      balanceDepartmentsCount,
      usersCount,
      inkasatorUsersCount,
      inkassators,
      ordersCount,
      departments,
      expenses,
      arrivals,
      isCoFetched,
      selectedUser,
      selectedFilialID,
      isFetchingUser,
      isUserUpdating,
      isFetchingRoles,
      operationsCount,
      reportLimit,
      isFetchingUsers,
      isFetchingInkasatorUsers,
      departmentsCount,
      isFetchingCities,
      isFetchingFilials,
      isFetchingExpenses,
      isFetchingArrivals,
      operationsFilters,
      operationsTable,
      isFetchingSessionList,
      isFetchingSessions,
      isFetchingOperations,
      isFetchingOperationsFilter,
      isFetchingOperationsTable,
      isFetchingOperationsStorno,
      stornoError,
      isFetchingReportBalance,
      isFetchingReportFilialIncome,
      isFetchingReportOperations,
      isFetchingOrders,
      isFetchingPutOrder,
      isFetchingPutOrderStatus,
      isFetchingPutOrderInkas,
      isFetchingPostOrder,
      fetchPostOrderMassage,
      fetchPutOrderMassage,
      fetchPutOrderStatusMassage,
      isFetchingInkassators,
      isFetchingCurrencies,
      isFetchingBalanceFilials,
      isFetchingBalanceDepartments,
      isFetchingDepartments,
      isFetchingReportLimit,
      fetchRoles: this.fetchRoles,
      fetchUsers: this.fetchUsers,
      fetchAddUser: this.fetchAddUser,
      fetchEditUser: this.fetchEditUser,
      clearUsersErrors: this.clearUsersErrors,
      fetchInkasatorsUsers: this.fetchInkasatorsUsers,
      fetchOrders: this.fetchOrders,
      fetchPostOrder: this.fetchPostOrder,
      fetchPutOrder: this.fetchPutOrder,
      fetchPutOrderStatus: this.fetchPutOrderStatus,
      fetchPutOrderInkas: this.fetchPutOrderInkas,
      fetchInkassators: this.fetchInkassators,
      fetchCities: this.fetchCities,
      fetchAddCities: this.fetchAddCities,
      fetchEditCities: this.fetchEditCities,
      fetchDeleteCities: this.fetchDeleteCities,
      clearCitiesErrors: this.clearCitiesErrors,
      fetchFilials: this.fetchFilials,
      fetchAddFilials: this.fetchAddFilials,
      fetchEditFilials: this.fetchEditFilials,
      fetchSelectedFilial: this.fetchSelectedFilial,
      fetchDeleteFilials: this.fetchDeleteFilials,
      clearFilialsErrors: this.clearFilialsErrors,
      fetchZoDepart: this.fetchZoDepart,
      fetchCurrencies: this.fetchCurrencies,
      fetchSessionList: this.fetchSessionList,
      fetchSession: this.fetchSession,
      fetchOperations: this.fetchOperations,
      fetchReportBalance: this.fetchReportBalance,
      fetchReportOperations: this.fetchReportOperations,
      fetchReportFilialIncome: this.fetchReportFilialIncome,
      fetchOperationsFilter: this.fetchOperationsFilter,
      fetchOperationsTable: this.fetchOperationsTable,
      fetchOperationsStorno: this.fetchOperationsStorno,
      clearStornoErrors: this.clearStornoErrors,
      fetchDepartments: this.fetchDepartments,
      fetchOtherExpenses: this.fetchOtherExpenses,
      fetchAddExpensesCategory: this.fetchAddExpensesCategory,
      fetchEditExpensesCategory: this.fetchEditExpensesCategory,
      fetchDeleteExpensesCategory: this.fetchDeleteExpensesCategory,
      fetchBuyExpenses: this.fetchBuyExpenses,
      clearExpensesErrors: this.clearExpensesErrors,
      fetchAddExpenses: this.fetchAddExpenses,
      fetchEditExpenses: this.fetchEditExpenses,
      fetchDeleteExpenses: this.fetchDeleteExpenses,
      fetchOtherArrivals: this.fetchOtherArrivals,
      addArrivalCategory: this.addArrivalCategory,
      editArrivalsCategory: this.editArrivalsCategory,
      deleteArrivalCategory: this.deleteArrivalCategory,
      clearArrivalErrors: this.clearArrivalErrors,
      addArrivalItem: this.addArrivalItem,
      editArrivalItem: this.editArrivalItem,
      deleteArrivalItem: this.deleteArrivalItem,
      handleArrival: this.handleArrival,
      fetchSelectedUser: this.fetchSelectedUser,
      updateSelectedUser: this.updateSelectedUser,
      fetchBalanceFilials: this.fetchBalanceFilials,
      fetchBalanceDepartments: this.fetchBalanceDepartments,
      updateSelectedFilialID: this.updateSelectedFilialID,
      fetchUpdateDepartmentBalance: this.fetchUpdateDepartmentBalance,
      fetchSelectedBalanceDepartment: this.fetchSelectedBalanceDepartment,
      clearBalanceDepartmentErrors: this.clearBalanceDepartmentErrors,
      fetchReportLimit: this.fetchReportLimit,
      clearReportLimit: this.clearReportLimit
    };
  };

  render() {
    const { children } = this.props;
    const { isOpenSnackbar, snackbarMsg, snackbarType } = this.state;
    return (
      <DashboardContext.Provider value={this.getProviderValues()}>
        {children}
        <AlertSnackbar
          onClose={() => {
            this.setState({
              isOpenSnackbar: false
            });
          }}
          isOpen={isOpenSnackbar}
          message={snackbarMsg}
          success={snackbarType}
        />
      </DashboardContext.Provider>
    );
  }
}

DashboardProvider.propTypes = {
  children: PropTypes.object
};

export default withRouter(DashboardProvider);
