import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import { Table, TableHead, TableBody, TableCell, TableRow, Grid, Tooltip,
  Collapse, Box, Button } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import RenderAndEditOrder from './RenderAndEditOrder';
import { StatusButton } from '../../../components/StatusButton';

const CashTransitCashierTableItem = ({
  item,
  cashierCurrency,
  sessionData,
  isCentral,
  onClickConfirm,
  onClickEdit,
  onClickAddEditInkas
}) => {
  const {
    id,
    inkassator,
    order_status: orderStatus,
    created_at: time,
    department_receiver: departmentReceiver,
    department_creator: departmentCreator,
    order_items: orderItems,
    change_inkasator: changeInkasator,
    sum_in_hryvnia: {
      arrival: arrivalUAH,
      expense: expenseUAH,
      swap: swapUAH
    }
  } = item;
  const [isOpen, setOpen] = useState(false);

  const renderOrderItems = useMemo(() => {
    if (orderItems) {
      return orderItems?.map((elm) => (
        <RenderAndEditOrder
          key={nanoid()}
          elm={elm}
          isCentral={isCentral}
          onClickConfirm={onClickConfirm}
          onClickEdit={onClickEdit}
          inkassator={inkassator}
          sessionData={sessionData}
          departmentReceiver={departmentReceiver}
          departmentCreator={departmentCreator}
          cashierCurrency={cashierCurrency}
          orderItems={orderItems}
        />
      )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItems, cashierCurrency]);

  const renderIncassator = useMemo(() => {
    if (changeInkasator && isCentral) {
      if (inkassator) {
        return (
          <Grid container spacing={0.5} alignItems="center">
            <Grid item md={10}>
              {inkassator.name}
            </Grid>
            <Grid item md={2}>
              <Tooltip title="Змінити інкасатора">
                <Button onClick={() => onClickAddEditInkas(id, inkassator)}>
                  <EditRoundedIcon fontSize="small" />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        );
      }
      return (
        <Grid container spacing={0.5}>
          <Grid item md={10} />
          <Grid item md={2}>
            <Tooltip title="Призначити інкасатора">
              <Button onClick={() => onClickAddEditInkas(id)}>
                <AddCircleOutlineRoundedIcon fontSize="small" />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      );
    }
    return inkassator?.name;
  }, [inkassator, changeInkasator, onClickAddEditInkas, id, isCentral]);

  const renderSumUah = useMemo(() => {
    if (isCentral && (arrivalUAH !== 0 || expenseUAH !== 0)) {
      const firstValue = swapUAH ? arrivalUAH : expenseUAH;
      const secondValue = swapUAH ? expenseUAH : arrivalUAH;
      return (
        <TableRow>
          <TableCell colSpan={3}>Загалом</TableCell>
          <TableCell>{firstValue !== 0 ? `${firstValue} UAH` : ''}</TableCell>
          <TableCell>{secondValue !== 0 ? `${secondValue} UAH` : ''}</TableCell>
          <TableCell colSpan={4} />
        </TableRow>
      );
    } return '';
  }, [isCentral, arrivalUAH, expenseUAH, swapUAH]);

  return (
    <>
      <TableRow>
        <TableCell sx={{ pl: 0 }}>{id}</TableCell>
        <TableCell sx={{ maxWidth: 180, paddingLeft: 0 }}>{time}</TableCell>
        <TableCell sx={{ minWidth: 200 }}>
          <StatusButton orderStatus={orderStatus} />
        </TableCell>
        <TableCell>{departmentCreator.title}</TableCell>
        <TableCell>{departmentReceiver.title}</TableCell>
        <TableCell sx={{ maxWidth: 180, paddingLeft: 0 }}>{renderIncassator}</TableCell>
        <TableCell align="right" sx={{ paddingRight: 0 }}>
          <Button onClick={() => setOpen(!isOpen)} variant="contained" size="small">
            {isOpen ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: 0, borderBottom: 0 }} colSpan={7}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: '8px 0' }}>
              <Table className="collapse-table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell sx={{ minWidth: 120 }}>Тип</TableCell>
                    <TableCell>Курс</TableCell>
                    <TableCell sx={{ minWidth: 120 }}>Розхід</TableCell>
                    <TableCell sx={{ minWidth: 120 }}>Прихід</TableCell>
                    <TableCell>Статус</TableCell>
                    <TableCell colSpan={isCentral ? 3 : 2} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderOrderItems}
                  {renderSumUah}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

CashTransitCashierTableItem.propTypes = {
  item: PropTypes.object,
  sessionData: PropTypes.object,
  isCentral: PropTypes.bool,
  onClickConfirm: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickAddEditInkas: PropTypes.func.isRequired,
  cashierCurrency: PropTypes.array
};

export default CashTransitCashierTableItem;
