import { API_URLS } from '../../constants/urls';
import { addParamsToUrl } from '../../utils/helpers';
import CRUDService from '../CRUDService';

class ArrivalsService extends CRUDService {
  constructor() {
    super(API_URLS.ARRIVAL);
    this.postCategory = async (params) => this.APIService.apiPost(addParamsToUrl(`${this.pathname}/categories`, params));
    this.putCategory = async (params) => this.APIService.apiPut(addParamsToUrl(`${this.pathname}/categories/${params.id}`, params));
    this.postArrivalHandle = async (params) => this.APIService.apiPost(addParamsToUrl(`${this.pathname}/handle/${params.id}`, params));
    this.deleteCategory = async (params) => this.APIService.apiDelete(addParamsToUrl(`${this.pathname}/categories/${params.id}`, params));
  }
}

export default new ArrivalsService();
