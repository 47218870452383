import React, { createContext, useMemo, useState, useContext } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, GlobalStyles } from '@mui/material';
import { lightTheme, darkTheme } from '../../../styles/theme';
import { globalStyles } from '../../../styles/theme/globalStyles';
import { AuthorisationContext } from '../../components/AuthorisationContext';

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

export const ColorModeProvider = ({ children }) => {
  const { user } = useContext(AuthorisationContext);
  const [currentMode, setMode] = useState(user?.color_scheme === 'white' ? 'light' : 'dark');

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      }
    }),
    []
  );

  const theme = useMemo(
    () => {
      const html = document.querySelector('html');
      html.setAttribute('data-theme', currentMode === 'light' ? 'light' : 'dark');
      return createTheme(currentMode === 'light' ? lightTheme : darkTheme);
    }, [currentMode]);
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles styles={globalStyles} />
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
