import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  TextField
} from '@mui/material';

export const SalaryTextField = ({ initialValue, onBlur }) => {
  const [cellValue, setCellValue] = useState(initialValue);

  return (
    <FormControl className="operation-list-selector" fullWidth size="normal">
      <TextField
        required
        size="small"
        id="sum"
        type="number"
        onWheel={(e) => e.target.blur()}
        InputProps={{
          inputProps: {
            min: 0.01,
            step: 0.01
          }
        }}
        value={cellValue}
        // TODO add regular
        onChange={(e) => setCellValue(e.target.value !== '' ? parseFloat(e.target.value, 2) : 0)}
        onBlur={() => cellValue !== initialValue && onBlur(cellValue)}
      />
    </FormControl>
  );
};

SalaryTextField.propTypes = {
  initialValue: PropTypes.number,
  onBlur: PropTypes.func
};
