import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import SimCardDownloadRoundedIcon from '@mui/icons-material/SimCardDownloadRounded';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button
} from '@mui/material';
import './style.scss';
import { nanoid } from 'nanoid';
import isEmpty from 'lodash.isempty';
import { HorizontalScrollWrapper } from '../../components/HorizontalScrollWrapper';

export const Report = ({ cashierReport, downloadReportSession }) => {
  const [currencyAmount, setCurrencyAmount] = useState(null);

  useEffect(() => {
    if (!isEmpty(cashierReport)) {
      setCurrencyAmount(cashierReport[3].row.length - 1);
    }
  }, [cashierReport]);

  const renderCells = (element) => (
    <TableRow>
      {element.map((cell) => <TableCell key={nanoid()}>{cell}</TableCell>)}
    </TableRow>
  );

  const renderReportInfo = useMemo(() => {
    if (!isEmpty(cashierReport)) {
      return (
        <Table sx={{ maxWidth: 500 }} aria-label="simple table">
          <TableBody>
            {renderCells(cashierReport[0].row)}
            {renderCells(cashierReport[1].row)}
          </TableBody>
        </Table>
      );
    }
  }, [cashierReport]);

  const renderReportData = useMemo(() => {
    if (!isEmpty(cashierReport)) {
      return (
        cashierReport.map(({ row, type }, index) => {
          if (index > 1) {
            if (type === 'title') {
              return (
                <TableRow className={`row-${type}`} key={nanoid()}>
                  <TableCell key={nanoid()}>{row[0]}</TableCell>
                  <TableCell key={nanoid()} colSpan={currencyAmount}>{row[1]}</TableCell>
                  {row.length > 2 && (<TableCell key={nanoid()}>{row[row.length - 1]}</TableCell>)}
                </TableRow>
              );
            } return (
              <>
                <TableRow className={`row-${type}`} key={nanoid()}>
                  {row.map((cell) => <TableCell key={nanoid()}>{cell}</TableCell>)}
                </TableRow>
                {type === 'total' && index < (cashierReport.length - 1) && (
                  <TableRow>
                    <TableCell key={nanoid()} colSpan={currencyAmount + 1} sx={{ height: '30px' }} />
                  </TableRow>
                )}
              </>
            );
          } return '';
        })
      );
    }
  }, [cashierReport, currencyAmount]);

  return (
    <>
      <div className="header-container">
        <div>
          {renderReportInfo}
        </div>
        <div>
          <Button onClick={downloadReportSession} variant="contained">
            <SimCardDownloadRoundedIcon sx={{ marginRight: '5px' }} />
            Завантажити
          </Button>
        </div>
      </div>
      <HorizontalScrollWrapper className="users-table reportCashier-container">
        <Table sx={{ mt: 4 }} aria-label="simple table">
          <TableBody className="report-table">
            {renderReportData}
          </TableBody>
        </Table>
      </HorizontalScrollWrapper>
    </>
  );
};

Report.propTypes = {
  cashierReport: PropTypes.array,
  downloadReportSession: PropTypes.func
};
