import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, TableCell, TableRow, Tooltip } from '@mui/material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { AuthorisationContext } from '../../../components/AuthorisationContext';

const CitiesItems = ({ item, onChangeCity, onOpenConfirmDelete, onSelectCities, selectedCity }) => {
  const { userRole } = useContext(AuthorisationContext);
  const handleSelectRow = useCallback(() => {
    onSelectCities(item.id);
  }, [item, onSelectCities]);

  const isSelected = useMemo(() => item?.id === selectedCity, [item, selectedCity]);

  return (
    <TableRow
      sx={[
        isSelected && {
          backgroundColor: 'var(--menu-button-active-bg) !important'
        }]}
    >
      <TableCell
        onClick={handleSelectRow}
        style={{ cursor: 'pointer' }}
        sx={[
          isSelected && {
            fontWeight: '600 !important',
            color: '#000 !important'
          }
        ]}
      >
        {item.id}
      </TableCell>
      <TableCell
        align="left"
        onClick={handleSelectRow}
        style={{ cursor: 'pointer' }}
        sx={[
          isSelected && {
            fontWeight: '600 !important',
            color: '#000 !important'
          }
        ]}
      >
        {item.title}
      </TableCell>
      {userRole === 'Администратор' && (
      <TableCell
        padding="checkbox"
        sx={[
          isSelected && {
            fontWeight: '600 !important',
            color: '#000 !important'
          }
        ]}
      >
        <ButtonGroup variant="contained">
          <Tooltip title="Редагувати">
            <Button onClick={() => onChangeCity(item)} color="primary">
              <BorderColorOutlinedIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Видалити">
            <Button
              color="error"
              onClick={() => onOpenConfirmDelete({ id: item.id })}
            >
              <DeleteForeverIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>
      </TableCell>
      )}
    </TableRow>
  );
};

CitiesItems.propTypes = {
  item: PropTypes.object,
  onChangeCity: PropTypes.func,
  onSelectCities: PropTypes.func,
  onOpenConfirmDelete: PropTypes.func,
  selectedCity: PropTypes.number || undefined
};

export default CitiesItems;
