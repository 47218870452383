export const steps = ['Оберіть відділення', 'Додайте потрібні проводки'];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 3.2 + ITEM_PADDING_TOP,
      width: 200
    }
  }
};

export const MenuPropsInTableRow = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 3.2 + ITEM_PADDING_TOP,
      width: 80
    }
  }
};

export const MenuPropsInStepOne = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.2 + ITEM_PADDING_TOP,
      width: 80
    }
  }
};
