import React, { useEffect, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import './styles.scss';
import isEmpty from 'lodash.isempty';
import { nanoid } from 'nanoid';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import SettingsBackwardBtn from '../../../components/SettingsBackwardBtn/SettingsBackwardBtn';
import PageWrapper from '../../../common/PageWrapper/PageWrapper';
import DepartmentBalanceItem from './DepartmentBalanceItem';
import { DashboardContext } from '../../../components/DashboardContext';
import { localizeErrors } from '../../../../utils/helpers';

const DepartmentBalance = () => {
  const { id } = useParams();
  const {
    fetchSelectedBalanceDepartment, selectedBalanceDepartment, selectedBalanceDepartmentErrors,
    clearBalanceDepartmentErrors
  } = useContext(DashboardContext);

  useEffect(() => {
    fetchSelectedBalanceDepartment({ id });
    return clearBalanceDepartmentErrors();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const renderCurrency = useMemo(() => {
    if (!isEmpty(selectedBalanceDepartment)) {
      const { currencies } = selectedBalanceDepartment;
      return currencies.map((item) => (
        <DepartmentBalanceItem item={item} key={item.id} departmentId={Number(id)} />
      ));
    }
  }, [id, selectedBalanceDepartment]);

  const renderErrors = useMemo(
    () => selectedBalanceDepartmentErrors?.map((item) => (
      <p key={nanoid()} className="errorText">
        {localizeErrors[item] || item}
      </p>
    )), [selectedBalanceDepartmentErrors]);

  return (
    <PageWrapper>
      <div className="selectedDepartment">
        <div className="department-backward">
          <SettingsBackwardBtn />
        </div>
        <p className="selectedDepartment-title">{selectedBalanceDepartment?.title || 'Заголовок відсутній'}</p>
        <div className="selectedDepartment-table">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Валюта</TableCell>
                  <TableCell component="th" align="left">Баланс</TableCell>
                  <TableCell component="th" align="right">Дія</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderCurrency}</TableBody>
            </Table>
          </TableContainer>
        </div>
        {renderErrors}
      </div>
    </PageWrapper>
  );
};

export default DepartmentBalance;
