import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import '../Cities/style.scss';
import {
  Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import isEmpty from 'lodash.isempty';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PageWrapper from '../../../common/PageWrapper/PageWrapper';
import SettingsBackwardBtn from '../../../components/SettingsBackwardBtn/SettingsBackwardBtn';
import { DashboardContext } from '../../../components/DashboardContext';
import FilialsItems from './FilialsItems';
import AddFilials from '../../../components/AddFilials/AddFilials';
import ConfirmDelete from '../../../components/ConfirmDelete/ConfirmDelete';
import ConnectedCity from './ConnectedCity';
import { AuthorisationContext } from '../../../components/AuthorisationContext';

const Filials = () => {
  const { userRole } = useContext(AuthorisationContext);
  const {
    filials, selectedFilialDeps, selectedFilialDepCount,
    fetchFilials, fetchSelectedFilial, fetchDeleteFilials,
    clearFilialsErrors
  } = useContext(DashboardContext);
  const [selectedFilial, setSelectedFilial] = useState({});
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [deleteCandidateId, setDeleteCandidateId] = useState('');
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    fetchFilials();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHandleClose = () => {
    setEditMode(false);
    setOpen(false);
    setSelectedFilial({});
    clearFilialsErrors();
  };

  const onChangeFilial = useCallback((item) => {
    setEditMode(true);
    setSelectedFilial(item);
    setOpen(true);
  }, []);

  const onOpenConfirmDelete = useCallback((id) => {
    setDeleteCandidateId(id);
    setOpenConfirm(true);
  }, []);

  const deleteFilials = useCallback(() => {
    fetchDeleteFilials(deleteCandidateId);
    setOpenConfirm(false);
  }, [fetchDeleteFilials, deleteCandidateId]);

  const onSelectFilial = useCallback((id) => {
    fetchSelectedFilial({ id });
    setSelectedFilial(() => filials.find((filial) => filial.id === id));
  }, [fetchSelectedFilial, filials]);

  const renderFilials = useMemo(() => {
    if (!isEmpty(filials)) {
      return filials.map((item) => (
        <FilialsItems
          item={item}
          key={item.id}
          onSelectFilial={onSelectFilial}
          onChangeFilial={onChangeFilial}
          onOpenConfirmDelete={onOpenConfirmDelete}
          selectedFilial={selectedFilial}
        />
      ));
    }
  }, [filials, onSelectFilial, onChangeFilial, onOpenConfirmDelete, selectedFilial]);

  const renderDepart = useMemo(() => (
    !isEmpty(selectedFilial) && selectedFilialDeps?.map((item) => (
      <ConnectedCity
        item={item}
        key={item.id}
      />
    ))), [selectedFilial, selectedFilialDeps]);

  return (
    <PageWrapper>
      <div className="cities">
        <div className="cities-main">
          {userRole === 'Администратор' && (
          <div className="cities-actions">
            <SettingsBackwardBtn />
            <Button
              variant="contained"
              onClick={() => {
                setOpen(true);
                setEditMode(false);
                setSelectedFilial({});
              }}
              size="large"
            >
              <AddCircleIcon fontSize="small" sx={{ mr: '5px' }} />
              Додати регіон
            </Button>
          </div>
          )}
          <div className="cities-table">
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell component="th" align="left">Регіон</TableCell>
                    {userRole === 'Администратор' && (<TableCell component="th" padding="checkbox" align="center">Дія</TableCell>)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderFilials}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="cities-table">
            <TableContainer>
              <Table sx={{ minWidth: 650, marginTop: '50px' }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell component="th" align="left">Місто</TableCell>
                    <TableCell component="th" align="center">Відділення</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderDepart}
                </TableBody>
              </Table>
              {(isEmpty(selectedFilial) || (selectedFilialDepCount < 1)) && (
              <div className="operations-emptyOperations">Немає відділень до відображення</div>
              )}
            </TableContainer>
          </div>
        </div>
        <AddFilials
          editMode={editMode}
          open={open}
          selectedFilial={selectedFilial}
          handleClose={onHandleClose}
        />
        <ConfirmDelete
          open={openConfirm}
          onClose={() => {
            setDeleteCandidateId('');
            setOpenConfirm(false);
            setSelectedFilial({});
          }}
          onClickButton={deleteFilials}
          isDisabled={selectedFilialDepCount > 0}
          label={
          selectedFilialDepCount > 0
            ? 'Цей регіон має прикріплені до нєї відділення. Прикріпіть ці відділення до іншого регіону перед видаленням.'
            : 'Ви впевнені, що хочете видалити цей регіон?'
          }
        />
      </div>
    </PageWrapper>
  );
};

export default Filials;
