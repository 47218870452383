import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import './style.scss';
import {
  FormControl, InputLabel, MenuItem, Pagination, Select,
  Table, TableBody, TableCell, TableContainer, TableHead,
  TablePagination, TableRow, TextField, Grid
} from '@mui/material';
import isEmpty from 'lodash.isempty';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { nanoid } from 'nanoid';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MomentUtils from '@date-io/moment';
import 'moment/locale/uk';
import PageWrapper from '../../../common/PageWrapper/PageWrapper';
import AdminOperationsItem from './AdminOperationsItem';
import { DashboardContext } from '../../../components/DashboardContext';
import { translations } from '../../../../constants/OperationsTranslations';
import { MenuProps } from './consts';
import { AutocompleteSelect } from '../../../components/AutocompleteSelect';
import OperationsTable from '../../../components/OperationsTable/OperationsTable';

moment.locale('uk');

const renderTemplate = (operationsFilters) => {
  if (!isEmpty(operationsFilters)) {
    return Object.keys(operationsFilters).map((id) => (
      <MenuItem key={id} value={id}>
        {operationsFilters[id]}
      </MenuItem>
    ));
  }
};

const AdminOperations = () => {
  const { id: sessionID } = useParams();
  const {
    operations, operationsFilters,
    operationsCount, fetchOperations, fetchOperationsFilter
  } = useContext(DashboardContext);
  const [operationFilterValues, setFilter] = useState({
    operationId: '',
    userId: '',
    fromDate: moment(),
    toDate: moment(),
    type: 'all',
    storno: 'all',
    currency: 'all',
    department: 'all',
    city: 'all',
    filial: 'all',
    page: 1,
    perPage: 5
  });
  const handleChange = useCallback((idx) => (e) => {
    setFilter((prev) => ({ ...prev, [idx]: e.target.value, page: 1 }));
  }, []);

  const handleChangeDate = useCallback((idx) => (newValue) => {
    setFilter((prev) => ({ ...prev, [idx]: newValue, page: 1 }));
  }, []);

  const handleChangePage = (event, newPage) => {
    setFilter((prev) => ({ ...prev, page: newPage }));
  };
  const handleChangeRowsPerPage = (event) => {
    setFilter((prev) => ({ ...prev, page: 1, perPage: parseInt(event.target.value, 10) }));
  };

  useEffect(() => {
    if (sessionID) {
      fetchOperations({
        session_id: sessionID
      });
    } else {
      const { operationId, type, storno, currency, city,
        filial, department, userId, fromDate, toDate, page, perPage } = operationFilterValues;
      fetchOperations({
        id: operationId,
        operation_type: type === 'all' ? '' : String(type),
        storno: storno === 'all' ? '' : storno,
        currency_id: currency === 'all' ? '' : currency,
        city_id: city === 'all' ? '' : city,
        filial_id: filial === 'all' ? '' : filial,
        department_id: department === 'all' ? '' : department,
        user_id: userId,
        page,
        per_page: perPage,
        date_from: moment(fromDate).format('DD.MM.YYYY'),
        date_to: moment(toDate).format('DD.MM.YYYY')
      });
    }
  }, [operationFilterValues, fetchOperations, sessionID]);

  useEffect(() => {
    fetchOperationsFilter();
  }, [fetchOperationsFilter]);

  const renderOperations = useMemo(
    () => (!isEmpty(operations)
      ? operations.map((item) => <AdminOperationsItem item={item} key={item.id} />)
      : <TableRow><TableCell colSpan={10} sx={{ padding: '10px!important' }} align="center">Список операцій порожній</TableCell></TableRow>),
    [operations]);
  const userList = useMemo(
    () => (!isEmpty(operationsFilters)
      ? Object.entries(operationsFilters.users).map((item) => ({ id: item[0], label: item[1] }))
      : []), [operationsFilters]);

  const departmentList = useMemo(
    () => (!isEmpty(operationsFilters)
      ? Object.entries(operationsFilters.departments).map((item) => ({ id: item[0], label: item[1] }))
      : []), [operationsFilters]);

  const renderCurrencies = useMemo(() => operationsFilters?.currency?.map(({ id, title }) => (
    <MenuItem key={id} value={id}>
      {title}
    </MenuItem>
  )), [operationsFilters]);
  const renderCities = useMemo(() => renderTemplate(operationsFilters.cities), [operationsFilters]);
  const renderFilials = useMemo(() => renderTemplate(operationsFilters.filials), [operationsFilters]);

  const renderStorno = useMemo(() => operationsFilters?.storno?.map((item) => (
    <MenuItem key={nanoid()} value={item}>
      {translations.storno[item]}
    </MenuItem>
  )), [operationsFilters]);

  const renderTypes = useMemo(() => operationsFilters?.type?.map((item) => (
    <MenuItem key={nanoid()} value={item}>
      {translations.type[item]}
    </MenuItem>
  )), [operationsFilters]);

  const paginationPerPage = useMemo(() => {
    const pPage = [5, 10, 25, 50, 100];
    const arr = pPage.filter(
      (item) => operationsCount
        && Number.isInteger(operationsCount / item >= 1 && Math.floor(operationsCount / item))
        && item
    );
    if (isEmpty(arr)) {
      return [5];
    }
    return arr;
  }, [operationsCount]);

  return (
    <PageWrapper>
      <div className="adminOperations">
        {!sessionID && (
        <Grid container spacing={2}>
          <Grid item md={5}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <FormControl fullWidth className="operation-list-selector">
                  <TextField
                    label="ID операції"
                    placeholder="ID"
                    value={operationFilterValues.operationId}
                    variant="outlined"
                    onChange={handleChange('operationId')}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className="operation-list-selector">
                  <InputLabel>Тип</InputLabel>
                  <Select
                    label="Тип"
                    value={operationFilterValues.type}
                    onChange={handleChange('type')}
                  >
                    <MenuItem value="all">Всі</MenuItem>
                    {renderTypes}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className="operation-list-selector">
                  <InputLabel>Сторно</InputLabel>
                  <Select
                    label="Сторно"
                    value={operationFilterValues.storno}
                    onChange={handleChange('storno')}
                  >
                    <MenuItem value="all">Всі</MenuItem>
                    {renderStorno}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className="operation-list-selector">
                  <InputLabel>Валюта</InputLabel>
                  <Select
                    label="Валюта"
                    value={operationFilterValues.currency}
                    MenuProps={MenuProps}
                    onChange={handleChange('currency')}
                  >
                    <MenuItem value="all">Всі</MenuItem>
                    {renderCurrencies}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className="operation-list-selector">
                  <InputLabel>Місто</InputLabel>
                  <Select
                    label="Місто"
                    value={operationFilterValues.city}
                    MenuProps={MenuProps}
                    onChange={handleChange('city')}
                  >
                    <MenuItem value="all">Всі</MenuItem>
                    {renderCities}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className="operation-list-selector">
                  <InputLabel>Регіон</InputLabel>
                  <Select
                    label="Регіон"
                    value={operationFilterValues.filial}
                    MenuProps={MenuProps}
                    onChange={handleChange('filial')}
                  >
                    <MenuItem value="all">Всі</MenuItem>
                    {renderFilials}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <FormControl fullWidth className="operation-list-selector">
                  <AutocompleteSelect
                    id="department-filter"
                    label="Відділення"
                    selectorList={departmentList}
                    setSelectedItem={setFilter}
                    multiplyState="department"
                    pagination="page"
                  />
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <FormControl fullWidth className="operation-list-selector">
                  <AutocompleteSelect
                    id="user-filte"
                    label="Користувач"
                    selectorList={userList}
                    setSelectedItem={setFilter}
                    multiplyState="userId"
                    pagination="page"

                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <div className="operation-list-selector">
                  <LocalizationProvider dateAdapter={MomentUtils} locale="uk">
                    <DatePicker
                      label="Зміни з"
                      mask="__.__.____"
                      value={operationFilterValues.fromDate}
                      minDate={moment(operationsFilters.date_from, 'DD.MM.YYYY')}
                      maxDate={operationFilterValues.toDate}
                      onChange={handleChangeDate('fromDate')}
                      renderInput={(params) => <TextField mask="YYYY-MM-DD" {...params} />}
                      inputFormat="DD.MM.YYYY"
                      disableMaskedInput
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="operation-list-selector">
                  <LocalizationProvider dateAdapter={MomentUtils}>
                    <DatePicker
                      label="Зміни по"
                      mask="__.__.____"
                      value={operationFilterValues.toDate}
                      minDate={operationFilterValues.fromDate}
                      maxDate={moment()}
                      onChange={handleChangeDate('toDate')}
                      renderInput={(params) => <TextField mask="YYYY-MM-DD" {...params} />}
                      inputFormat="DD.MM.YYYY"
                      disableMaskedInput
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={7}>
            <OperationsTable filter={operationFilterValues} />
          </Grid>
        </Grid>
        )}
        <div className="adminOperations-table">
          <TableContainer>
            <Table sx={{ minWidth: 650, mt: '20px' }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell component="th" align="center" size="small" sx={{ pl: 0, minWidth: 50, maxWidth: 90 }} padding="checkbox">
                    ID
                  </TableCell>
                  <TableCell component="th" align="left" size="small" sx={{ width: 130 }}>
                    Тип
                  </TableCell>
                  <TableCell component="th" align="left" size="small" sx={{ minWidth: 150 }}>
                    Прихід
                  </TableCell>
                  <TableCell component="th" align="left" size="small" sx={{ minWidth: 150 }}>
                    Розхід
                  </TableCell>
                  <TableCell component="th" align="left" size="small" sx={{ minWidth: 100 }}>
                    Курс
                  </TableCell>
                  <TableCell component="th" align="left" size="small">
                    Відділення
                  </TableCell>
                  <TableCell component="th" align="left" size="small">
                    Касир
                  </TableCell>
                  <TableCell component="th" align="left" size="small">
                    Дата/Час
                  </TableCell>
                  <TableCell className="storno" component="th" align="center" size="small" sx={{ px: 0 }}>
                    Сторно
                  </TableCell>
                  <TableCell sx={{ width: 41 }} />
                </TableRow>
              </TableHead>
              <TableBody>{renderOperations}</TableBody>
            </Table>
            {!sessionID && operationsCount > 5 && (
              <div className="users-pagination">
                <Pagination
                  page={operationFilterValues.page}
                  shape="rounded"
                  onChange={handleChangePage}
                  count={Math.ceil(operationsCount / operationFilterValues.perPage)}
                  color="primary"
                />
                <TablePagination
                  count={operationsCount}
                  page={operationFilterValues.page}
                  component="div"
                  rowsPerPage={operationFilterValues.perPage}
                  labelRowsPerPage="Відображати:"
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={paginationPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            )}
          </TableContainer>
        </div>
      </div>
    </PageWrapper>
  );
};

export default AdminOperations;
