import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import {
  Grid, FormControl, Select, MenuItem, TextField, InputLabel, Button
} from '@mui/material';
import './style.scss';
import moment from 'moment';
import PageWrapper from '../../common/PageWrapper/PageWrapper';
import { DashboardContext } from '../../components/DashboardContext';
import { CashierContext } from '../../components/CashierContext';
import { AuthorisationContext } from '../../components/AuthorisationContext';
import { MenuProps } from './const';
import { ModalWindowOtherExpensesConfirm } from '../../components/ModalWindowOtherExpensesConfirm/ModalWindowOtherExpensesConfirm';

export const OtherArrivalsCashierPage = () => {
  const { cashierData } = useContext(AuthorisationContext);
  const { fetchOtherArrivals, arrivals, clearArrivalErrors } = useContext(DashboardContext);
  const { cashierCurrency, fetchCashierCurrency, sessionData } = useContext(CashierContext);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [values, setValues] = useState({
    category: '',
    categoryItem: '',
    value: '',
    currency_id: '',
    currencyTitle: '',
    comment: ''
  });

  useEffect(() => {
    fetchCashierCurrency({
      department_id: cashierData.id,
      date: moment().format('YYYY-MM-DD'),
      id: cashierData.id
    });
  }, [fetchCashierCurrency, cashierData]);

  useEffect(() => {
    fetchOtherArrivals({
      current_session: sessionData.id
    });
  }, [fetchOtherArrivals, sessionData]);

  const renderCurrencies = useMemo(() => cashierCurrency?.map(({ id, title }) => (
    <MenuItem key={id} value={id}>{title}</MenuItem>
  )), [cashierCurrency]);

  const renderCategories = useMemo(() => (arrivals?.map(
    ({ id, title }) => <MenuItem key={id} value={id}>{title}</MenuItem>
  )), [arrivals]);

  const renderArrivals = useMemo(() => {
    if (arrivals) {
      const arrivalsItems = arrivals.find((item) => item.id === values.category)?.items;
      return arrivalsItems?.map(
        ({ id, title }) => <MenuItem key={id} value={id}>{title}</MenuItem>
      );
    }
  }, [arrivals, values.category]);

  const disableArrivalsSelector = useMemo(() => {
    if (values.category === '') return true;
    if (arrivals) {
      if (arrivals.find((item) => item.id === values.category).items.length === 0) return true;
    }
  }, [values.category, arrivals]);

  const handleClose = useCallback(() => {
    setIsOpenModal(false);
    clearArrivalErrors();
  }, [clearArrivalErrors]);

  return (
    <PageWrapper>
      <Grid className="input-adornment" sx={{ mt: 4, mb: 3 }} container spacing={4} alignItems="center" justifyContent="center">
        <Grid item md={2} />
        <Grid item md={4}>
          <FormControl className="operation-list-selector" fullWidth>
            <InputLabel>Категорія доходів</InputLabel>
            <Select
              id="id-category"
              label="Категорія доходів"
              value={values.category}
              MenuProps={MenuProps}
              onChange={(e) => setValues({ category: e.target.value, categoryItem: '', value: '', currency_id: '', comment: '' })}
            >
              {renderCategories}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <FormControl className="operation-list-selector" fullWidth>
            <InputLabel>
              {arrivals.find((item) => item.id === values.category)?.items.length === 0 ? 'Категорія порожня' : 'Позиція доходів'}
            </InputLabel>
            <Select
              id="id-expenses-item"
              label="Позиція доходів"
              disabled={disableArrivalsSelector}
              value={values.categoryItem}
              MenuProps={MenuProps}
              onChange={(e) => setValues((prev) => ({ ...prev, categoryItem: e.target.value, value: '', currency_id: '', comment: '' }))}
            >
              {renderArrivals}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} />
        <Grid item md={4}>
          <FormControl className="operation-list-selector" fullWidth size="normal">
            <TextField
              type="number"
              onWheel={(e) => e.target.blur()}
              id="price"
              name="price"
              label="Сума"
              disabled={values.categoryItem === ''}
              value={values.value}
              onChange={(e) => setValues((prev) => ({ ...prev, value: e.target.value }))}
              InputProps={{
                inputProps: {
                  min: 0.01,
                  step: 0.01
                }
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <FormControl className="operation-list-selector" fullWidth size="normal">
            <InputLabel>Валюта</InputLabel>
            <Select
              required
              autoWidth
              className="buy-sell-page-currencyTitle"
              id="currencyTitle"
              name="currencyTitle"
              label="Валюта"
              placeholder="Валюта"
              disabled={values.categoryItem === ''}
              value={values.currency_id}
              MenuProps={MenuProps}
              onChange={(e) => {
                const value = cashierCurrency.find(({ id }) => id === e.target.value)?.title;
                setValues((prev) => ({ ...prev, currency_id: e.target.value, currencyTitle: value }));
              }}
            >
              {renderCurrencies}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={8}>
          <FormControl className="operation-list-selector" fullWidth size="normal">
            <TextField
              type="text"
              multiline
              rows={2}
              id="comment"
              name="comment"
              label="Коментар"
              disabled={values.categoryItem === ''}
              value={values.comment}
              inputProps={{ maxLength: 255 }}
              onChange={(e) => setValues((prev) => ({ ...prev, comment: e.target.value }))}
            />
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <Grid container justifyContent="center">
            <Button
              disabled={values.value === '' || values.currency_id === ''}
              variant="contained"
              onClick={() => setIsOpenModal(true)}
            >
              Оформити
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {isOpenModal
      && (
      <ModalWindowOtherExpensesConfirm
        open={isOpenModal}
        data={values}
        handleClose={handleClose}
      />
      )}
    </PageWrapper>
  );
};
