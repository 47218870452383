import { css } from '@mui/material/styles';

export const globalStyles = css`
  :root {
    --menu-button: #000;
    --menu-button-hover: #e7eef8!important;
    --menu-button-active: #2196f3;
    --menu-button-active-bg: #e7eef8cc;
    --menu-button-open-day-hover: #1976d2;

    --dropdown-text: #383838;
    --dropdown-active-text: #ebebeb;
    --dropdown-bg: #d4e0f2;

    --notification-bg: #f2f2f2;

    --balance-button: #2195f3be;
    --report-title-bg: #e7eef8eb;
    --report-header-bg: #acc5e7cc;

    --main-bg: #eeeeee;
    --secondary-bg: #f2f2f2;
    --main-popup-bg: #fff;
    --container-bg: #fff;
    --primary-text: #383838;
    --disabled-text: #383838;

    --table-text: #383838;
    --icon: #2196f3;
    --text-field-border: #383838;

    --button: #2196f3;
    --disable-button: #d4e0f2;
    --disable-button-secondary-bg: #b0c6e8;

    --button-status-cash-transit: #8e02df;
    --button-status-cash-transit-hover: #7d00c5;
    --button-status-complete: #0084c2;
    --button-status-complete-hover: #0273a8;

    --calendar-icon: #2196f3;
    --calendar-weekDayLabel: #a6a6a6;

  }

  [data-theme="dark"] {
    --menu-button: #fff;
    --menu-button-hover: #232328!important;
    --menu-button-active-bg: #fff;
    --menu-button-active: #000;
    --menu-button-open-day-hover: #fff;

    --dropdown-text: #ebebeb;
    --dropdown-active-text: #ebebeb;
    --dropdown-bg: #242731;

    --notification-bg: #242731;

    --balance-button: #1976d2;
    --report-title-bg: #353845;
    --report-header-bg: #181a20;

    --main-bg: #1e2027;
    --secondary-bg: #1f2128;
    --main-popup-bg: #f2f2f2;
    --container-bg: #14151A;
    --primary-text: #fafafa;
    --disabled-text: #fff;

    --table-text: #e7eef8;
    --icon: #F8F8F8;
    --text-field-border:#dddddd3b;

    --button: #F8F8F8;
    --disable-button: #495057;
    --disable-button-secondary-bg: #5d666f;

    --button-status-cash-transit: #DF02C1;
    --button-status-cash-transit-hover: #B2019A;
    --button-status-complete: #df6802;
    --button-status-complete-hover: #B25301;

    --calendar-icon: #1e2027;
    --calendar-weekDayLabel: #9e9e9e;
  
  }
`;
