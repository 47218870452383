export const translations = {
  orderStatus: {
    Завершена: 'Завершена',
    'В процессе': 'В процесі',
    Новая: 'Нова',
    Отменена: 'Відмінена'
  },
  orderType: {
    Инкассация: 'Інкасація',
    Подкрепление: 'Підкріплення',
    Выкуп: 'Викуп'
  },
  currentStatus: {
    'Ожидает подтверждения': 'Чекає на підтверд...',
    Подтверждено: 'Підтверджено',
    'Едет в кассу': 'Їде до каси',
    'Возврат-едет в кассу': 'Повернення',
    Возвращено: 'Повернено',
    Выполнено: 'Виконано',
    Отменено: 'Скасовано'
  }
};
