import HTTPService from './HTTPService';
import { addParamsToUrl } from '../utils/helpers';

export default class CRUDService {
  constructor(pathname) {
    this.pathname = pathname;
    this.APIService = new HTTPService();
    this.delete = async (params) => this.APIService.apiDelete(addParamsToUrl(`${this.pathname}/${params.id}`, params));

    this.update = async (body) => this.APIService.apiPatch(this.pathname, { body });

    this.putRequest = async (body) => this.APIService.apiPut(`${this.pathname}/${body.id}`, { body });

    this.putRequestForBalance = async (body) => this.APIService.apiPut(`${this.pathname}/department/${body.departmentId}/${body.currencyId}`, { body });

    this.putWithoutIdRequest = async (body) => this.APIService.apiPut(this.pathname, { body });

    this.getRequest = async (params) => this.APIService.apiGet(addParamsToUrl(this.pathname, params));

    this.getRequestFilter = async (params) => this.APIService.apiGet(addParamsToUrl(`${this.pathname}/filter/`, params));

    this.getRequestTable = async (params) => this.APIService.apiGet(addParamsToUrl(`${this.pathname}/table/`, params));

    this.getRequestWithID = async (params) => this.APIService.apiGet(addParamsToUrl(`${this.pathname}/${params.id}`, params));

    this.postRequest = async (body) => this.APIService.apiPost(this.pathname, { body });

    this.postPublicRequest = async (body) => this.APIService.apiPostPublic(this.pathname, { body });

    this.postSetPasswordRequest = async (body, id) => this.APIService.apiPost(`${this.pathname}/${id}/password`, { body });
  }
}
