import React, { useContext } from 'react';
import './style.scss';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import CompareArrowsRoundedIcon from '@mui/icons-material/CompareArrowsRounded';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import isEmpty from 'lodash.isempty';
import { CashierContext } from '../CashierContext';
import { AuthorisationContext } from '../AuthorisationContext';

const links = [
  { link: '/cashier-buy', text: 'Купівля', icon: <ArrowCircleDownRoundedIcon /> },
  { link: '/cashier-sell', text: 'Продаж', icon: <ArrowCircleUpRoundedIcon /> },
  { link: '/cashier-cross-course', text: 'Крос-курс', icon: <CompareArrowsRoundedIcon /> }
];

const SideBarCashierMenuOperations = () => {
  const { sessionData } = useContext(CashierContext);
  const {
    user: { id: userId },
    cashierData: { department_active_session: departmentAtvSession }
  } = useContext(AuthorisationContext);
  const isAnotherUserLeftSession = departmentAtvSession?.user.id && (userId !== departmentAtvSession.user.id);
  const isDisabled = isEmpty(sessionData) || isAnotherUserLeftSession;

  return (
    <div className="cashier-menu">
      {links.map(({ link, text, icon }) => (
        <Button
          key={link}
          component={NavLink}
          to={link}
          activeClassName="sideBar-menu-item-active"
          className={cx('sideBar-menu-item', {
            disabledMenu: isDisabled
          })}
        >
          {icon}
          <span>{text}</span>
        </Button>
      ))}
    </div>
  );
};

export default SideBarCashierMenuOperations;
