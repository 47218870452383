import React, { useContext, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { CashierContext } from '../../components/CashierContext';
import { AuthorisationContext } from '../../components/AuthorisationContext';
import { Report } from './Report';

export const CashierReport = () => {
  const { id: depId } = useParams();
  const { availableDepartments } = useContext(AuthorisationContext);
  const { fetchCashierReportFile,
    fetchCashierReport, cashierReport } = useContext(CashierContext);
  const sessionId = localStorage.getItem(`lastActiveSessionDep${depId}`)
    || availableDepartments.find(({ id }) => id === +depId)?.department_active_session.id;
  useEffect(() => {
    fetchCashierReport({
      id: sessionId
    });
  }, [fetchCashierReport, sessionId]);

  const downloadReportSession = useCallback(() => {
    fetchCashierReportFile({
      id: sessionId
    });
  },
  [sessionId, fetchCashierReportFile]);

  return (
    <>
      <Report
        cashierReport={cashierReport}
        downloadReportSession={downloadReportSession}
      />
    </>
  );
};
