import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { Table, TableBody, TableCell,
  TableHead, TableRow, Button, Grid
} from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { AdminContext } from '../../../components/AdminContext';
import PageWrapper from '../../../common/PageWrapper/PageWrapper';
import SettingsBackwardBtn from '../../../components/SettingsBackwardBtn/SettingsBackwardBtn';
import { AddEditVariable } from '../../../components/AddVariable/AddVariable';
import ConfirmDelete from '../../../components/ConfirmDelete/ConfirmDelete';
import { VariableItem } from './VariableItem';

export const Variable = () => {
  const { getVariables, deleteVariable, variables } = useContext(AdminContext);
  useEffect(() => { getVariables(); }, [getVariables]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [deleteCandidateId, setDeleteCandidateId] = useState('');
  const [addEditButton, setAddEditButton] = useState({
    open: false,
    editMode: false,
    selectedVariable: null
  });

  const onHandleCloseExpense = () => {
    setAddEditButton({
      open: false,
      editMode: false,
      selectedVariable: null
    });
  };

  const onOpenConfirmDelete = useCallback((id) => {
    setOpenConfirm(true);
    setDeleteCandidateId(id);
  }, []);

  const deleteSelected = useCallback(() => {
    deleteVariable({ id: deleteCandidateId }).then(() => {
      setOpenConfirm(false);
    });
  }, [deleteVariable, deleteCandidateId]);

  const renderVariables = useMemo(() => variables?.map(
    (item) => (
      <VariableItem
        key={item.id}
        variable={item}
        onOpenConfirmDelete={onOpenConfirmDelete}
        setAddEditButton={setAddEditButton}
      />
    )
  ), [variables, onOpenConfirmDelete]);

  return (
    <PageWrapper>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item>
          <SettingsBackwardBtn />
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              setAddEditButton(
                {
                  open: true,
                  editMode: false,
                  selectedVariable: null
                }
              );
            }}
            variant="contained"
            startIcon={<AddCircleRoundedIcon />}
          >
            Додати змінну
          </Button>
        </Grid>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Назва</TableCell>
            <TableCell>Запис у системі</TableCell>
            <TableCell>Значення</TableCell>
            <TableCell sx={{ width: '176px' }} align="left">Дія</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderVariables}
        </TableBody>
      </Table>
      <AddEditVariable
        open={addEditButton.open}
        handleClose={onHandleCloseExpense}
        selectedVariableId={addEditButton.selectedVariable}
        editMode={addEditButton.editMode}
      />
      <ConfirmDelete
        open={openConfirm}
        onClose={() => {
          setOpenConfirm(false);
        }}
        onClickButton={deleteSelected}
        isDisabled={false}
        label="Ви впевнені, що хочете видалити цю категорію?"
      />
    </PageWrapper>
  );
};
