import React, { useState, useContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, Grid, Stack,
  MenuItem, InputLabel, Select
} from '@mui/material';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import ModalWindow from '../../../../components/ModalWindow/ModalWindow';
import { DashboardContext } from '../../../../components/DashboardContext';
import ModalWindowEditBuySell from './ModalWindowEditBuySell';
import ModalWindowEditCrossCourse from './ModalWindowEditCrossCourse';
import { sender } from '../../../../../services/NOTIFICATION/NotificationService';

const ModalWindowEditOrder = ({
  open,
  isCentral,
  handleClose,
  order,
  currentSession,
  setReFetch,
  setSnackbar
}) => {
  const { currentOrder = {}, departmentCreator = {}, departmentReceiver = {}, departmentId = '' } = order || {};
  const { fetchPutOrder, isFetchingPutOrder } = useContext(DashboardContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedTransitType, setSelectedTransitType] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  const senderReceiver = departmentId === departmentReceiver.id ? departmentCreator : departmentReceiver;
  const senderPayloadMsg = `Проводка №${currentOrder.id} від "${senderReceiver.title}" була змінена`;
  useEffect(() => {
    setSelectedTransitType(currentOrder?.order_item_type);
  }, [currentOrder]);

  const selectedOperation = useMemo(() => {
    if (isCentral) {
      if (currentOrder.swap && !departmentCreator.isCentral) {
        if (selectedTransitType === 'Инкассация') return <ModalWindowEditBuySell setIsDisabled={setIsDisabled} order={order} selectedTransitType={selectedTransitType} keyName="expense_currency_id" keyValue="expense_value" />;
        if (selectedTransitType === 'Подкрепление') return <ModalWindowEditBuySell setIsDisabled={setIsDisabled} order={order} selectedTransitType={selectedTransitType} keyName="arrival_currency_id" keyValue="arrival_value" />;
        if (selectedTransitType === 'Выкуп') {
          return (
            <ModalWindowEditCrossCourse
              order={order}
              titles={['Валюта на прихід:', 'Розхід:']}
              keysNames={['arrival_currency_id', 'arrival_value', 'expense_currency_id', 'expense_value', 'rate']}
              setIsDisabled={setIsDisabled}
            />
          );
        }
      } else {
        if (selectedTransitType === 'Инкассация') return <ModalWindowEditBuySell setIsDisabled={setIsDisabled} order={order} selectedTransitType={selectedTransitType} keyName="arrival_currency_id" keyValue="arrival_value" />;
        if (selectedTransitType === 'Подкрепление') return <ModalWindowEditBuySell setIsDisabled={setIsDisabled} order={order} selectedTransitType={selectedTransitType} keyName="expense_currency_id" keyValue="expense_value" />;
        if (selectedTransitType === 'Выкуп') {
          return (
            <ModalWindowEditCrossCourse
              order={order}
              titles={['Валюта на прихід:', 'Розхід:']}
              keysNames={['expense_currency_id', 'expense_value', 'arrival_currency_id', 'arrival_value', 'rate']}
              setIsDisabled={setIsDisabled}
            />
          );
        }
      }
    } else if (currentOrder.swap) {
      if (selectedTransitType === 'Инкассация') return <ModalWindowEditBuySell setIsDisabled={setIsDisabled} order={order} selectedTransitType={selectedTransitType} keyName="arrival_currency_id" keyValue="arrival_value" />;
      if (selectedTransitType === 'Подкрепление') return <ModalWindowEditBuySell setIsDisabled={setIsDisabled} order={order} selectedTransitType={selectedTransitType} keyName="expense_currency_id" keyValue="expense_value" />;
      if (selectedTransitType === 'Выкуп') {
        return (
          <ModalWindowEditCrossCourse
            order={order}
            titles={['Розхід:', 'Прихід:']}
            keysNames={['expense_currency_id', 'expense_value', 'arrival_currency_id', 'arrival_value', 'rate']}
            setIsDisabled={setIsDisabled}
          />
        );
      }
    } else {
      if (selectedTransitType === 'Инкассация') return <ModalWindowEditBuySell setIsDisabled={setIsDisabled} order={order} selectedTransitType={selectedTransitType} keyName="expense_currency_id" keyValue="expense_value" />;
      if (selectedTransitType === 'Подкрепление') return <ModalWindowEditBuySell setIsDisabled={setIsDisabled} order={order} selectedTransitType={selectedTransitType} keyName="arrival_currency_id" keyValue="arrival_value" />;
      if (selectedTransitType === 'Выкуп') {
        return (
          <ModalWindowEditCrossCourse
            order={order}
            titles={['Розхід:', 'Прихід:']}
            keysNames={['arrival_currency_id', 'arrival_value', 'expense_currency_id', 'expense_value', 'rate']}
            setIsDisabled={setIsDisabled}

          />
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTransitType]);

  const onClose = () => {
    handleClose();
    setIsDisabled(false);
  };

  const checkKeys = (key) => {
    if (isCentral) {
      if (currentOrder.swap && !departmentCreator.isCentral) {
        if (key === 'Инкассация') return ['expense_currency_id', 'expense_value'];
        if (key === 'Подкрепление') return ['arrival_currency_id', 'arrival_value'];
        if (selectedTransitType === 'Выкуп') return ['arrival_currency_id', 'arrival_value', 'expense_currency_id', 'expense_value', 'rate'];
      } else {
        if (key === 'Инкассация') return ['arrival_currency_id', 'arrival_value'];
        if (key === 'Подкрепление') return ['expense_currency_id', 'expense_value'];
        if (selectedTransitType === 'Выкуп') return ['expense_currency_id', 'expense_value', 'arrival_currency_id', 'arrival_value', 'rate'];
      }
    } else if (currentOrder.swap) {
      if (key === 'Инкассация') return ['arrival_currency_id', 'arrival_value'];
      if (key === 'Подкрепление') return ['expense_currency_id', 'expense_value'];
      if (selectedTransitType === 'Выкуп') return ['expense_currency_id', 'expense_value', 'arrival_currency_id', 'arrival_value', 'rate'];
    } else {
      if (key === 'Инкассация') return ['expense_currency_id', 'expense_value'];
      if (key === 'Подкрепление') return ['arrival_currency_id', 'arrival_value'];
      if (selectedTransitType === 'Выкуп') return ['arrival_currency_id', 'arrival_value', 'expense_currency_id', 'expense_value', 'rate'];
    }
    return [];
  };

  const renderInkasSelectItem = useMemo(() => {
    if (currentOrder.swap) {
      return departmentCreator.isCentral ? 'Інкасація (Розхід)' : 'Інкасація (Прихід)';
    }
    return departmentCreator.isCentral ? 'Інкасація (Прихід)' : 'Інкасація (Розхід)';
  }, [departmentCreator.isCentral, currentOrder.swap]);

  const renderReinforceSelectItem = useMemo(() => {
    if (currentOrder.swap) {
      return departmentCreator.isCentral ? 'Підкріплення (Прихід)' : 'Підкріплення (Розхід)';
    }
    return departmentCreator.isCentral ? 'Підкріплення (Розхід)' : 'Підкріплення (Прихід)';
  }, [currentOrder.swap, departmentCreator.isCentral]);

  return (
    <>
      <ModalWindow open={open} onClose={() => onClose()} maxWidth="500px">
        <form onSubmit={async (e) => {
          e.preventDefault();
          const fieldsKeys = checkKeys(e.target.type?.value);
          const data = {
            order_item_type: e.target.type?.value,
            current_session: currentSession,
            id: currentOrder?.id
          };
          fieldsKeys.forEach((key) => {
            data[key] = e.target[key]?.value;
          });
          const errorMassage = await fetchPutOrder(data);
          setStatusMessage(errorMassage);
          if (!errorMassage) {
            setSnackbar({ open: true, massage: 'Зміст заявки було успішно змінено' });
            sender('peerToPeerConnection', { departmentId: senderReceiver.id, payload: senderPayloadMsg });
            handleClose();
            setReFetch((prevState) => !prevState);
            setIsDisabled(false);
          }
        }}
        >
          <div className="addUsers addPopup">
            <CancelPresentationRoundedIcon className="close-btn" onClick={() => onClose()} />
            <div className="new-date">Змінити проводку</div>
            <div className="filters-table-wrapper addPopup">
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <Stack spacing={4}>
                    {selectedTransitType
                      ? (
                        <FormControl sx={{ minWidth: 160 }}>
                          <InputLabel>Тип заявки</InputLabel>
                          <Select label="Тип заявки" name="type" value={selectedTransitType} onChange={(e) => setSelectedTransitType(e.target.value)}>
                            <MenuItem value="Инкассация">{renderInkasSelectItem}</MenuItem>
                            <MenuItem value="Подкрепление">{renderReinforceSelectItem}</MenuItem>
                            <MenuItem value="Выкуп">Викуп(Обмін)</MenuItem>
                          </Select>
                        </FormControl>
                      )
                      : null}
                  </Stack>
                </Grid>
                {selectedOperation}
              </Grid>
            </div>
            <div className="new-date">
              {statusMessage ? <p className="errorText">{statusMessage}</p> : ''}
            </div>
            <div className="addUsers-actions">
              <LoadingButton
                type="submit"
                loading={isFetchingPutOrder}
                loadingPosition="end"
                endIcon={<SendRoundedIcon />}
                disabled={isDisabled}
                variant="contained"
              >
                Підтвердити
              </LoadingButton>
            </div>
          </div>
        </form>
      </ModalWindow>
    </>
  );
};

ModalWindowEditOrder.propTypes = {
  open: PropTypes.bool,
  order: PropTypes.object,
  handleClose: PropTypes.func,
  setReFetch: PropTypes.func,
  setSnackbar: PropTypes.func,
  currentSession: PropTypes.number,
  isCentral: PropTypes.bool
};

export default ModalWindowEditOrder;
