import { API_URLS } from '../../constants/urls';
import { addParamsToUrl } from '../../utils/helpers';
import CRUDService from '../CRUDService';

class ReportsService extends CRUDService {
  constructor() {
    super(API_URLS.REPORTS);
    this.getRequestBySession = async (params) => this.APIService.apiGet(addParamsToUrl(`${this.pathname}/session/${params.id}`, params));
    this.getRequestBySessionFile = async (params) => this.APIService.apiGet(addParamsToUrl(`${this.pathname}/session/${params.id}/file`, params));
    this.getReportBalance = async () => this.APIService.apiGet(`${this.pathname}/balance`);
    this.getReportLimit = async (params) => this.APIService.apiGet(addParamsToUrl(`${this.pathname}/limit`, params));
    this.getReportOperations = async (params) => this.APIService.apiGet(addParamsToUrl(`${this.pathname}/operation`, params));
    this.getReportFilialIncome = async (params) => this.APIService.apiGet(addParamsToUrl(`${this.pathname}/filial-income/`, params));
  }
}

export default new ReportsService();
