import React, { useCallback, useContext, useState, useEffect, useMemo } from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { Button, TextField, Tooltip } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import { useFormik } from 'formik';
import moment from 'moment';
import { nanoid } from 'nanoid';
import ModalWindow from '../ModalWindow/ModalWindow';
import { AuthorisationContext } from '../AuthorisationContext';
import { CashierContext } from '../CashierContext';

const ModalWindowCashier = ({ open, handleClose }) => {
  const { userRole, cashierData } = useContext(AuthorisationContext);
  const { fetchStartSession } = useContext(CashierContext);
  const [errorMessage, setErrorMessage] = useState({});
  const [currentTime, setCurrentTime] = useState(moment());
  const [isEdit, setIsEdit] = useState(false);
  const isCashier = userRole === 'Кассир';

  useEffect(() => {
    if (!isEdit) {
      const timer = setInterval(() => {
        setCurrentTime(moment());
      }, 59000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [isEdit]);

  const onSubmitForm = useCallback((value) => {
    if (+value.endSessionsTime < +currentTime) {
      value.endSessionsTime.add(1, 'day');
    }
    fetchStartSession({
      pc_token: cashierData.pc_token,
      department_id: cashierData.id,
      session_length: moment(value.endSessionsTime.diff(currentTime)).utc().format('HH:mm')
    });
    handleClose();
  }, [fetchStartSession, handleClose, cashierData, currentTime]);
  const formik = useFormik({
    initialValues: {
      endSessionsTime: moment(currentTime).add(cashierData.approx_session_length, 'hh:mm')
    },
    onSubmit: (values, { resetForm }) => {
      if (localStorage.getItem(`lastActiveSessionDep${cashierData.id}`)) {
        localStorage.removeItem(`lastActiveSessionDep${cashierData.id}`);
      }
      onSubmitForm(values);
      resetForm();
    }
  });

  const renderErrors = useMemo(() => {
    if (!isEmpty(errorMessage)) {
      return errorMessage.map((item) => <p key={nanoid()} className="errorText">{item}</p>);
    }
  }, [errorMessage]);

  const onClickReset = () => {
    formik.resetForm();
    handleClose();
    setErrorMessage({});
  };

  return (
    <ModalWindow
      open={open}
      onClose={handleClose}
      maxWidth="500px"
    >
      <div className="addUsers addPopup">
        <form onSubmit={formik.handleSubmit}>
          <CancelPresentationRoundedIcon className="close-btn" onClick={onClickReset} />
          <div className="new-date">{moment(currentTime).format('D.MM.YYYY HH:mm')}</div>
          <div className="set-time">
            <div className="set-time-text">Виберіть час закриття зміни</div>
            <div className="set-time-container">
              <TextField
                name="endSessionsTime"
                label="Час закриття зміни"
                type="time"
                disabled={!isCashier || !isEdit}
                value={formik.values.endSessionsTime.format('HH:mm')}
                onChange={(e) => {
                  const temp = e.target.value.split(':');
                  formik.setFieldValue('endSessionsTime', moment().set({ hours: temp[0], minutes: temp[1] }));
                }}
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 300 }}
                sx={{ width: '150px' }}
              />
              <Tooltip title="Редагувати">
                <Button
                  color={isEdit ? 'warning' : 'primary'}
                  variant="contained"
                  onClick={() => setIsEdit((prevState) => !prevState)}
                >
                  <EditRoundedIcon />
                </Button>
              </Tooltip>
            </div>
            {renderErrors}
          </div>
          <div className="addUsers-actions">
            <Button variant="contained" type="submit" disabled={isEdit}>Відкрити день</Button>
          </div>
        </form>
      </div>
    </ModalWindow>
  );
};

ModalWindowCashier.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

export default ModalWindowCashier;
