import { grey, blue, green } from '@mui/material/colors';

const menuItemButton = {
  MuiButton: {
    styleOverrides: {
      root: {
        '&.sideBar-menu-item': {
          width: '100%',
          fontWeight: '500',
          color: 'var(--menu-button)',
          fontSize: '18px',
          textTransform: 'capitalize',
          padding: '10px',
          borderRadius: '15px',
          transition: 'all 0.2s ease-in-out',
          justifyContent: 'start',
          '&:hover': {
            backgroundColor: 'var(--menu-button-hover)',
            boxShadow: 'none'
          },
          '&-active, &-active:hover': {
            color: 'var(--menu-button-active)',
            backgroundColor: 'var(--menu-button-active-bg)'
          },
          '$ svg': {
            color: 'inherit'
          }
        }
      }
    }
  }

};

const muiLoadingButton = {
  MuiLoadingButton: {
    styleOverrides: {
      root: {
        '& svg.MuiSvgIcon-root': {
          fill: '#fff'
        }
      }
    }
  }
};

const muiTextField = {
  MuiFormControl: {
    styleOverrides: {
      root: {
        color: 'var(--primary-text)'
      }
    }
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        color: 'var(--primary-text)'
      }
    }
  }
};

const muiCalendar = {
  MuiCalendarPicker: {
    styleOverrides: {
      root: {
        '& svg': {
          fill: 'var(--calendar-icon)'
        },
        '& .MuiDayPicker-weekDayLabel': {
          color: 'var(--calendar-weekDayLabel)'
        },
        '& .MuiButtonBase-root.MuiPickersDay-root.Mui-disabled': {
          color: '#00000061'
        }
      }
    }
  }
};

const muiContainer = {
  MuiContainer: {
    styleOverrides: {
      root: {
        borderRadius: '20px',
        padding: '20px',
        backgroundColor: 'var(--container-bg)',
        color: 'var(--primary-text)'
      }
    }
  }
};

export const lightTheme = {
  palette: {
    mode: 'light',
    primary: {
      ...blue
    },
    background: {
      default: 'var(--main-bg)',
      paper: 'var(--main-popup-bg)'
    },
    secondary: {
      ...green,
      background: {
        default: '#f2f2f2',
        paper: '#f2f2f2'
      }
    },
    warning: {
      main: '#ffa726',
      contrastText: '#fff'
    },
    text: {
      primary: grey[900],
      secondary: grey[800]
    }
  },
  typography: {
    fontFamily: "'Roboto', sans-serif"
  },
  components: {
    ...muiTextField,
    ...muiContainer,
    ...muiLoadingButton,
    ...menuItemButton,
    ...muiCalendar
  }
};

export const darkTheme = {
  palette: {
    mode: 'dark',
    primary: {
      ...blue,
      main: blue[600]
    },
    background: {
      default: 'var(--main-bg)',
      paper: 'var(--main-popup-bg)'
    },
    secondary: {
      ...green,
      background: {
        default: '#1e2027',
        paper: '#f2f2f2'
      }
    },
    warning: {
      main: '#ffa726',
      contrastText: '#fff'
    },
    text: {
      primary: grey[900],
      secondary: '#fff'
    }
  },
  typography: {
    fontFamily: "'Roboto', sans-serif"
  },
  components: {
    ...muiTextField,
    ...muiContainer,
    ...muiLoadingButton,
    ...menuItemButton,
    ...muiCalendar
  }
};
