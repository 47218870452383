import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
  FormControl, InputLabel, MenuItem, Select, Grid, TextField
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import MomentUtils from '@date-io/moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { isNull } from '../../../../utils/isNull';
import { DashboardContext } from '../../../components/DashboardContext';
import { MenuProps } from './consts';
import { AutocompleteSelect } from '../../../components/AutocompleteSelect';

export const ReportBySession = () => {
  const { filials, fetchFilials,
    departments, fetchDepartments,
    sessionList, fetchSessionList
  } = useContext(DashboardContext);
  const [selectedValues, setSelectedValues] = useState(
    {
      filialId: '',
      departmentId: '',
      sessionId: '',
      fromDate: '',
      toDate: moment()
    });

  const changeValue = (filterValue) => (e) => {
    setSelectedValues(
      (PrevState) => ({ ...PrevState, [filterValue]: e.target.value })
    );
    if (filterValue === 'filialId') {
      setSelectedValues(
        (PrevState) => ({ ...PrevState, departmentId: '', sessionId: '' })
      );
    }
  };

  useEffect(() => {
    fetchFilials();
  }, [fetchFilials]);

  useEffect(() => {
    fetchDepartments({
      filial_id: selectedValues.filialId
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues.filialId]);

  useEffect(() => {
    if (selectedValues.departmentId) {
      fetchSessionList({
        department_id: selectedValues?.departmentId,
        date_from: selectedValues.fromDate ? moment(selectedValues.fromDate).format('YYYY-MM-DD') : '',
        date_to: moment(selectedValues.toDate).format('YYYY-MM-DD')
      });
    }
  }, [selectedValues.departmentId, selectedValues.fromDate, selectedValues.toDate, fetchSessionList]);

  const renderFilials = useMemo(() => filials?.map(({ id, title }) => (
    <MenuItem key={id} value={id}>{title}</MenuItem>
  )),
  [filials]);

  const filteredDepartments = useMemo(() => departments?.map(({ id, title }) => ({ id, label: title })),
    [departments]);

  const renderSessons = useMemo(() => sessionList?.map(({ id, title }) => (
    <MenuItem key={id} value={id}>{title}</MenuItem>
  )),
  [sessionList]);

  return (
    <Grid sx={{ mt: 0, mb: 3, pt: 0 }} container spacing={4} alignItems="stretch">
      <Grid item md={6}>
        <FormControl className="operation-list-selector" fullWidth>
          <InputLabel>Регіон</InputLabel>
          <Select
            MenuProps={MenuProps}
            label="Регіон"
            value={selectedValues.filialId}
            onChange={changeValue('filialId')}
          >
            {renderFilials}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={6}>
        <AutocompleteSelect
          id="department-selector"
          label="Відділення"
          selectorList={filteredDepartments}
          setSelectedItem={setSelectedValues}
          multiplyState="departmentId"
        />
      </Grid>
      <Grid item md={3}>
        <div className="operation-list-selector">
          <LocalizationProvider dateAdapter={MomentUtils} locale="uk">
            <DatePicker
              disabled={!selectedValues.departmentId}
              fullWidth
              mask="__.__.____"
              label="Дати з"
              value={selectedValues.fromDate}
              maxDate={selectedValues.toDate}
              onChange={(newValue) => setSelectedValues(
                (PrevState) => ({ ...PrevState, fromDate: moment(newValue) })
              )}
              renderInput={(params) => <TextField mask="YYYY-MM-DD" {...params} />}
              inputFormat="DD.MM.YYYY"
              disableMaskedInput
            />
          </LocalizationProvider>
        </div>
      </Grid>
      <Grid item md={3}>
        <div className="operation-list-selector">
          <LocalizationProvider dateAdapter={MomentUtils} locale="uk">
            <DatePicker
              disabled={!selectedValues.departmentId}
              mask="__.__.____"
              label="Дати по"
              value={selectedValues.toDate}
              minDate={isNull(selectedValues.fromDate) ? null : selectedValues.fromDate}
              maxDate={moment()}
              onChange={(newValue) => setSelectedValues(
                (PrevState) => ({ ...PrevState, toDate: moment(newValue) })
              )}
              renderInput={(params) => <TextField mask="YYYY-MM-DD" {...params} />}
              inputFormat="DD.MM.YYYY"
              disableMaskedInput
            />
          </LocalizationProvider>
        </div>
      </Grid>
      <Grid item md={6}>
        <FormControl className="operation-list-selector" fullWidth>
          <InputLabel>Сесія</InputLabel>
          <Select
            MenuProps={MenuProps}
            disabled={!selectedValues.departmentId}
            label="Сессія"
            value={selectedValues.sessionId}
            onChange={changeValue('sessionId')}
          >
            {renderSessons}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={3}>
        <NavLink
          style={{ pointerEvents: !selectedValues.sessionId ? 'none' : '' }}
          to={`/session-report/${selectedValues.sessionId}`}
          target="_blank"
        >
          <LoadingButton
            fullWidth
            disabled={!selectedValues.sessionId}
            variant="contained"
          >
            Відкрити
          </LoadingButton>
        </NavLink>
      </Grid>
    </Grid>
  );
};
