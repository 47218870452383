import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import './style.scss';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import isEmpty from 'lodash.isempty';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PageWrapper from '../../../common/PageWrapper/PageWrapper';
import SettingsBackwardBtn from '../../../components/SettingsBackwardBtn/SettingsBackwardBtn';
import { DashboardContext } from '../../../components/DashboardContext';
import CitiesItems from './CitiesItems';
import AddCities from '../../../components/AddCities/AddCities';
import ConfirmDelete from '../../../components/ConfirmDelete/ConfirmDelete';
import ConnectedFills from './ConnectedFills';
import { AuthorisationContext } from '../../../components/AuthorisationContext';

const Cities = () => {
  const {
    cities, fetchCities, fetchDeleteCities, clearCitiesErrors
  } = useContext(DashboardContext);
  const { userRole } = useContext(AuthorisationContext);
  const [open, setOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState({});
  const [selectedCityDeps, setSelectedCityDeps] = useState([]);
  const selectedCityDepCount = useMemo(() => selectedCityDeps?.length || 0, [selectedCityDeps]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [deleteCondidateId, setDeleteCondidateId] = useState('');
  const [editMode, setEditMode] = useState(false);

  const clearSelect = () => {
    setSelectedCity({});
    setSelectedCityDeps([]);
  };

  const onSelectCities = useCallback((id) => {
    const temp = cities.find((city) => city.id === id);
    setSelectedCity(temp);
    setSelectedCityDeps(temp.departments);
  }, [cities]);

  useEffect(() => {
    fetchCities();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHandleClose = () => {
    setOpen(false);
    setEditMode(false);
    clearSelect();
    clearCitiesErrors();
  };

  const onChangeCity = useCallback((item) => {
    setEditMode(true);
    onSelectCities(item.id);
    setOpen(true);
  }, [onSelectCities]);

  const onOpenConfirmDelete = useCallback((id) => {
    onSelectCities(id.id);
    setDeleteCondidateId(id);
    setOpenConfirm(true);
  }, [onSelectCities]);

  const onCloseConfirm = useCallback(() => {
    clearSelect();
    setDeleteCondidateId('');
    setOpenConfirm(false);
  }, []);

  const deleteCities = useCallback(() => {
    fetchDeleteCities(deleteCondidateId).then((resp) => {
      if (resp === 'success') {
        clearSelect();
        setDeleteCondidateId('');
        setOpenConfirm(false);
      }
    });
  }, [fetchDeleteCities, deleteCondidateId]);

  const renderCities = useMemo(() => {
    if (!isEmpty(cities)) {
      return cities.map((item) => (
        <CitiesItems
          item={item}
          key={item.id}
          onChangeCity={onChangeCity}
          onSelectCities={onSelectCities}
          onOpenConfirmDelete={onOpenConfirmDelete}
          selectedCity={selectedCity?.id}
        />
      ));
    }
  }, [cities, onChangeCity, onSelectCities, onOpenConfirmDelete, selectedCity]);

  const renderDepart = useMemo(() => {
    if (selectedCityDepCount !== 0) {
      return selectedCityDeps?.map((item) => (
        <ConnectedFills
          item={item}
          key={item.id}
        />
      ));
    }
    return (
      <TableRow>
        <TableCell align="center" colSpan={3}>
          {!isEmpty(selectedCity)
            ? 'Немає даних для відображення. Спробуйте вибрати інше місто.'
            : 'Оберіть місто щоб подивитись дані'}
        </TableCell>
      </TableRow>
    );
  }, [selectedCityDeps, selectedCityDepCount, selectedCity]);

  return (
    <PageWrapper>
      <div className="cities">
        <div className="cities-main">
          {userRole === 'Администратор' && (
          <div className="cities-actions">
            <SettingsBackwardBtn />
            <Button
              variant="contained"
              onClick={() => {
                clearSelect();
                setEditMode(false);
                setOpen(true);
              }}
              size="large"
            >
              <AddCircleIcon fontSize="small" sx={{ mr: '5px' }} />
              Додати місто
            </Button>
          </div>
          )}
          <div className="cities-table">
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell component="th" align="left">Місто</TableCell>
                    {userRole === 'Администратор'
                      && (<TableCell component="th" padding="checkbox" align="center">Дія</TableCell>)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderCities}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="cities-table">
            <TableContainer>
              <Table sx={{ minWidth: 650, marginTop: '50px' }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell component="th" align="left">Регіон</TableCell>
                    <TableCell component="th" align="center">Відділення</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderDepart}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <AddCities
          editMode={editMode}
          open={open}
          selectedCity={selectedCity}
          handleClose={onHandleClose}
        />
        <ConfirmDelete
          open={openConfirm}
          onClose={onCloseConfirm}
          onClickButton={deleteCities}
          isDisabled={selectedCityDepCount > 0}
          label={
          selectedCityDepCount > 0
            ? 'Це місто має прикріплені до нього відділення. Прикріпіть ці відділення до іншого міста перед видаленням.'
            : 'Ви впевнені, що хочете видалити це місто?'
          }
        />
      </div>
    </PageWrapper>
  );
};

export default Cities;
