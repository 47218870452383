import React, { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import isEmpty from 'lodash.isempty';
import {
  Table, TableBody, TableCell, Pagination, TablePagination, TableContainer, TableHead,
  TableRow, TextField, MenuItem, FormControl, InputLabel, Select, Button, Grid
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MomentUtils from '@date-io/moment';
import 'moment/locale/uk';
import { isNull } from '../../../../utils/isNull';
import PageWrapper from '../../../common/PageWrapper/PageWrapper';
import TableItem from './Components/TableItem';
import { DashboardContext } from '../../../components/DashboardContext';
import { MenuProps, MenuPropsCurrency } from './consts';
import { AutocompleteSelect } from '../../../components/AutocompleteSelect';

moment.locale('uk');

const CashTransit = () => {
  const { fetchOrders, orders, ordersCount,
    currency, fetchCurrencies,
    fetchInkasatorsUsers, allInkasatorUsers,
    fetchDepartments, departments
  } = useContext(DashboardContext);

  const [perPage, setPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [toDate, setToDate] = useState(moment());
  const [fromDate, setFromDate] = useState(null);
  const [orderStatus, setOrderStatus] = useState([]);
  const [orderItemType, setOrderItemType] = useState('all');
  const [orderItemStatus, setOrderItemStatus] = useState('all');
  const [selectedCurrency, setSelectedCurrency] = useState('all');
  const [selectedInkasator, setSelectedInkasator] = useState('all');
  const [departmentCreator, setDepartmentCreator] = useState('all');
  const [departmentReceiver, setDepartmentReceiver] = useState('all');
  const [reFetch, setReFetch] = useState(false);

  useEffect(() => {
    fetchCurrencies({ date: moment().format('YYYY-MM-DD') });
    fetchInkasatorsUsers();
    fetchDepartments();
  }, [fetchCurrencies, fetchInkasatorsUsers, fetchDepartments]);

  useEffect(() => {
    fetchOrders({
      page,
      per_page: perPage,
      order_status: orderStatus === [] ? '' : orderStatus,
      order_item_type: orderItemType === 'all' ? '' : orderItemType,
      order_item_status: orderItemStatus === 'all' ? '' : orderItemStatus,
      currency_ids: selectedCurrency === 'all' ? '' : selectedCurrency,
      inkas_id: selectedInkasator === 'all' ? '' : selectedInkasator,
      department_creator_id: departmentCreator === 'all' ? '' : departmentCreator,
      department_receiver_id: departmentReceiver === 'all' ? '' : departmentReceiver,
      date_from: isNull(fromDate) ? '' : moment(fromDate).format('DD.MM.YYYY'),
      date_to: moment(toDate).format('DD.MM.YYYY')
    });
  }, [fetchOrders, orderStatus, orderItemType, orderItemStatus, departmentCreator, departmentReceiver,
    fromDate, toDate, selectedCurrency, selectedInkasator, page, perPage, reFetch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const renderOperations = useMemo(() => {
    if (isEmpty(orders)) {
      return <TableRow><TableCell colSpan={7} align="center">Список заявок порожній</TableCell></TableRow>;
    }
    if (!isEmpty(orders)) {
      return orders?.map((item) => (
        <TableItem item={item} key={item.id} />
      ));
    }
  }, [orders]);

  const renderCurrencies = useMemo(() => currency?.map((item) => (
    <MenuItem value={item.id} key={item.id}>{item.title}</MenuItem>
  )), [currency]);

  const renderInkasators = useMemo(() => allInkasatorUsers?.map((item) => (
    <MenuItem value={item.id} key={item.id}>{`${item.lastname} ${item.firstname} ${item.patronymic}`}</MenuItem>
  )), [allInkasatorUsers]);

  const filteredDepartments = useMemo(() => departments?.map(({ id, title }) => ({ id, label: title })),
    [departments]);

  return (
    <PageWrapper>
      <Grid container spacing={2} alignItems="center">
        <Grid item md={2}>
          <FormControl className="operation-list-selector" fullWidth>
            <InputLabel>Статус заявки</InputLabel>
            <Select label="Статус заявки" multiple value={orderStatus} onChange={(e) => setOrderStatus(e.target.value)}>
              <MenuItem value="all">Всі</MenuItem>
              <MenuItem value="Новая">Нова</MenuItem>
              <MenuItem value="В процессе">В процесі</MenuItem>
              <MenuItem value="Завершена">Завершена</MenuItem>
              <MenuItem value="Отменена">Відмінена</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3}>
          <FormControl className="operation-list-selector" fullWidth>
            <InputLabel>Тип проводки</InputLabel>
            <Select label="Тип проводки" value={orderItemType} onChange={(e) => setOrderItemType(e.target.value)}>
              <MenuItem value="all">Всі</MenuItem>
              <MenuItem value="Выкуп">Викуп</MenuItem>
              <MenuItem value="Инкассация">Інкасація</MenuItem>
              <MenuItem value="Подкрепление">Підкріплення</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3}>
          <FormControl className="operation-list-selector" fullWidth>
            <InputLabel>Статус проводки</InputLabel>
            <Select label="Статус проводки" value={orderItemStatus} onChange={(e) => setOrderItemStatus(e.target.value)}>
              <MenuItem value="all">Всі</MenuItem>
              <MenuItem value="Ожидает подтверждения">Чекає на підтвердження</MenuItem>
              <MenuItem value="Подтверждено">Підтверджено</MenuItem>
              <MenuItem value="Едет в кассу">Їде до каси</MenuItem>
              <MenuItem value="Отменено">Скасовано</MenuItem>
              <MenuItem value="Выполнено">Виконано</MenuItem>
              <MenuItem value="Возврат-едет в кассу">Повернення-їде в касу</MenuItem>
              <MenuItem value="Возвращено">Повернено</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <FormControl className="operation-list-selector" fullWidth>
            <InputLabel>Інкасатори</InputLabel>
            <Select MenuProps={MenuProps} label="Інкасатори" value={selectedInkasator} onChange={(e) => setSelectedInkasator(e.target.value)}>
              <MenuItem value="all">Всі</MenuItem>
              {renderInkasators}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <FormControl className="operation-list-selector" fullWidth>
            <InputLabel>Валюта</InputLabel>
            <Select MenuProps={MenuPropsCurrency} label="Валюта" value={selectedCurrency} onChange={(e) => setSelectedCurrency(e.target.value)}>
              <MenuItem value="all">Всі</MenuItem>
              {renderCurrencies}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={5}>
          <AutocompleteSelect
            id="department-sender-selector"
            label="Відділення відправник"
            selectorList={filteredDepartments}
            setSelectedItem={setDepartmentCreator}
          />
        </Grid>
        <Grid item md={5}>
          <AutocompleteSelect
            id="department-receiver-selector"
            label="Відділення отримувач"
            selectorList={filteredDepartments}
            setSelectedItem={setDepartmentReceiver}
          />
        </Grid>
        <Grid item md={3}>
          <div className="operation-list-selector">
            <LocalizationProvider dateAdapter={MomentUtils} locale="uk">
              <DatePicker
                label="Дати з"
                mask="__.__.____"
                value={fromDate}
                maxDate={toDate}
                onChange={(newValue) => {
                  setFromDate(moment(newValue));
                }}
                renderInput={(params) => <TextField mask="YYYY-MM-DD" {...params} />}
              />
            </LocalizationProvider>
          </div>
        </Grid>
        <Grid item md={3}>
          <div className="operation-list-selector">
            <LocalizationProvider className="operation-list-selector" dateAdapter={MomentUtils} locale="uk">
              <DatePicker
                label="Дати по"
                mask="__.__.____"
                value={toDate}
                minDate={isNull(fromDate) ? null : fromDate}
                maxDate={moment()}
                onChange={(newValue) => {
                  setToDate(moment(newValue));
                }}
                renderInput={(params) => <TextField mask="YYYY-MM-DD" {...params} />}
              />
            </LocalizationProvider>
          </div>
        </Grid>
        <Grid item md={4}>
          <Button onClick={() => setReFetch(!reFetch)} variant="contained" sx={{ m: 2, maxWidth: 450 }}>Оновити список заявок</Button>
        </Grid>
      </Grid>
      <div className="users-table">
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell sx={{ minWidth: 100, width: 120 }}>Час</TableCell>
                <TableCell sx={{ minWidth: 100, width: 120 }}>Статус</TableCell>
                <TableCell sx={{ minWidth: 100, width: 230 }}>Відправник</TableCell>
                <TableCell sx={{ minWidth: 100, width: 230 }}>Отримувач</TableCell>
                <TableCell>Інкасатор</TableCell>
                <TableCell align="right" sx={{ width: 100 }}>Деталі</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderOperations}</TableBody>
          </Table>
          {isEmpty(orders) || (
          <div className="users-pagination">
            <Pagination
              page={page}
              shape="rounded"
              onChange={handleChangePage}
              count={Math.ceil(ordersCount / perPage)}
              color="primary"
            />
            <TablePagination
              count={100}
              page={page}
              component="div"
              rowsPerPage={perPage}
              labelRowsPerPage="Відображати:"
              onPageChange={handleChangePage}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          )}
        </TableContainer>
      </div>
    </PageWrapper>
  );
};

export default CashTransit;
