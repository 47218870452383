import React, { useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import PageWrapper from '../../../common/PageWrapper/PageWrapper';
import { DashboardContext } from '../../../components/DashboardContext';
import SettingsBackwardBtn from '../../../components/SettingsBackwardBtn/SettingsBackwardBtn';

export const Inkassator = () => {
  const { id } = useParams();
  const { selectedUser, fetchSelectedUser } = useContext(DashboardContext);
  useEffect(() => {
    if (id) {
      fetchSelectedUser({ id });
    }
  }, [fetchSelectedUser, id]);

  const renderCurrencies = useMemo(
    () => selectedUser?.currencies?.map((currency) => (
      <TableCell key={currency.id} component="th" align="center">
        {currency.title}
      </TableCell>
    )),
    [selectedUser]
  );
  const renderBalances = useMemo(
    () => selectedUser?.currencies?.map((currency) => (
      <TableCell key={currency.id} component="td" align="center">
        {currency.balance}
      </TableCell>
    )),
    [selectedUser]
  );

  return (
    <PageWrapper>
      <div className="inkassator">
        <div className="inkassator__head">
          <SettingsBackwardBtn />
        </div>
        <div className="inkassator__title">{`${selectedUser?.lastname} ${selectedUser?.firstname} ${selectedUser?.patronymic}`}</div>
        {(selectedUser?.currencies?.length > 0 && (
          <div className="users-table inkassator__table">
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell component="th" align="center" sx={{ width: '180px' }}>
                      Валюта
                    </TableCell>
                    {renderCurrencies}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="td" align="center">
                      Баланс
                    </TableCell>
                    {renderBalances}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )) || (
          <div className="inkassator__nothing">
            <hr />
            У інкасатора немає валюти
            <hr />
          </div>
        )}
      </div>
    </PageWrapper>
  );
};
