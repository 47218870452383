import React, { useEffect, useState } from 'react';
import './style.scss';
import { Button, MenuItem, Select, TextField } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ukLocale from 'date-fns/locale/uk';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment';
import { validationForm as validationSchema } from './validation';

export const AddMessage = (
  { handleSubmitForm, handleCancelEdit, editMode, editableMessage, selectedDepartments }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const formik = useFormik({
    initialValues: {
      msgName: editMode ? editableMessage.name : '',
      msgText: editMode ? editableMessage.message : '',
      period: editMode ? editableMessage.typeNotification : 'daily'
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: ({ msgText, msgName, period }, { resetForm }) => {
      if (editMode) {
        handleSubmitForm({
          id: editableMessage.id,
          name: msgName,
          message: msgText,
          date: moment(selectedDate).format('YYYY-MM-DD'),
          period
        });
      } else {
        handleSubmitForm({
          name: msgName,
          message: msgText,
          date: moment(selectedDate).format('YYYY-MM-DD'),
          period
        });
      }
      resetForm();
    }
  });

  useEffect(() => {
    if (editMode) {
      setSelectedDate(editableMessage.startDate);
    } else {
      setSelectedDate(new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  return (
    <div className="addMessage">
      <div className="addMessage-label">
        <span>Назва:</span>
        {formik.touched.msgName && formik.errors.msgName && (
          <p style={{ color: 'red', fontSize: '12px', lineHeight: 1.1 }}>{formik.errors.msgName}</p>
        )}
      </div>
      <div className="addMessage-name">
        <TextField
          fullWidth
          type="text"
          name="msgName"
          value={formik.values.msgName}
          onChange={formik.handleChange}
          sx={{ borderColor: '#fff', color: '#fff' }}
        />
      </div>
      <div className="addMessage-label">
        <span>Повідомлення:</span>
        {formik.touched.msgText && formik.errors.msgText && (
          <p style={{ color: 'red', fontSize: '12px', lineHeight: 1.1 }}>{formik.errors.msgText}</p>
        )}
      </div>
      <div className="addMessage-textarea">
        <TextField
          multiline
          fullWidth
          type="text"
          name="msgText"
          value={formik.values.msgText}
          onChange={formik.handleChange}
          rows="5"
        />
      </div>
      <div className="addMessage-label">
        <span>Період нагадування:</span>
      </div>
      <div className="addMessage-period">
        <Select name="period" value={formik.values.period} sx={{ width: '230px' }} onChange={formik.handleChange}>
          <MenuItem value="once">Одноразово</MenuItem>
          <MenuItem value="daily">Щодня</MenuItem>
          <MenuItem value="weekly">Щотижня</MenuItem>
          <MenuItem value="monthly">Щомісячно</MenuItem>
        </Select>
        {formik.touched.period && formik.errors.period && (
          <p style={{ color: 'red', fontSize: '14px' }}>{formik.errors.period}</p>
        )}
      </div>
      <div className="addMessage-label">
        <span>Дата початку періоду:</span>
      </div>
      <div className="addMessage-datePicker">
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ukLocale}>
          <MobileDatePicker
            value={selectedDate}
            closeOnSelect
            showTodayButton
            okText="Обрати"
            todayText="Сьогодні"
            cancelText="Скасувати"
            minDate={new Date()}
            label="Оберіть дату"
            onChange={(selected) => setSelectedDate(selected)}
            onAccept={(selected) => setSelectedDate(selected)}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="dd.MM.yyyy"
          />
        </LocalizationProvider>
      </div>
      <div className="addMessage-btn">
        <Button
          onClick={formik.handleSubmit}
          disabled={selectedDepartments.length < 1}
          size="large"
          variant="contained"
          color="success"
        >
          {editMode ? (
            <>
              <EditIcon sx={{ marginRight: '5px' }} />
              Редагувати
            </>
          ) : (
            <>
              <SendIcon sx={{ marginRight: '5px' }} />
              Надіслати
            </>
          )}
        </Button>
      </div>
      <div className="addMessage-btn">
        <Button
          onClick={() => {
            formik.handleReset();
            handleCancelEdit();
          }}
          size="large"
          variant="contained"
          color="error"
        >
          <DisabledByDefaultIcon sx={{ marginRight: '5px' }} />
          Скасувати
        </Button>
      </div>
    </div>
  );
};

AddMessage.propTypes = {
  handleSubmitForm: PropTypes.func,
  handleCancelEdit: PropTypes.func,
  editableMessage: PropTypes.object,
  selectedDepartments: PropTypes.array,
  editMode: PropTypes.bool
};
