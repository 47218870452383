import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import {
  FormControl, MenuItem, InputLabel,
  Select, InputAdornment, TextField,
  Button, Stack, Grid
} from '@mui/material';
import { useDynamicForm } from '../../../../hooks/useDynamicForm';

const StepTwoBuySellOperation = ({ cashierCurrency, onAdded, orderItems, selectedTransitType, keyName }) => {
  const {
    data: { currencyId, value },
    updateSelectField: setOperations,
    clearData: clearOperation,
    isAllEmpty,
    multiSet
  } = useDynamicForm({
    currencyId: '',
    value: ''
  });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 3.2 + ITEM_PADDING_TOP,
        width: 200
      }
    }
  };
  const renderCurrencies = useMemo(() => {
    const filteredOrders = orderItems?.filter((item) => item.order_item_type === selectedTransitType);
    if (filteredOrders) {
      return cashierCurrency?.map((item) => (
        <MenuItem
          key={nanoid()}
          value={item.id}
          disabled={filteredOrders.map((elm) => elm[keyName]).includes(item.id)}
        >
          {item.title}
        </MenuItem>
      ));
    }
    return cashierCurrency?.map((item) => (
      <MenuItem key={nanoid()} value={item.id}>{item.title}</MenuItem>
    ));
  }, [cashierCurrency, orderItems, selectedTransitType, keyName]);

  return (
    <>
      <Grid item xs={4}>
        <div className="department-filters cash-transit-create-request">
          <Stack spacing={4} sx={{ mb: 2 }}>
            <div className="department-filters-item">
              <div className="department-filters-item-input">
                <FormControl sx={{ minWidth: 200 }} size="normal">
                  <InputLabel>Валюта</InputLabel>
                  <Select
                    required
                    autoWidth
                    className="buy-sell-page-currencyTitle"
                    label="Валюта"
                    placeholder="Валюта"
                    value={currencyId}
                    MenuProps={MenuProps}
                    onChange={setOperations('currencyId')}
                  >
                    {renderCurrencies}
                  </Select>
                </FormControl>
              </div>
            </div>
          </Stack>
          <Stack spacing={4} sx={{ mb: 2 }}>
            <div className="department-filters-item">
              <div className="department-filters-item-input">
                <FormControl sx={{ minWidth: 160 }} size="normal">
                  <TextField
                    required
                    label="Сума"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {currencyId ? cashierCurrency.find((item) => item.id === currencyId).title.toUpperCase() : ''}
                        </InputAdornment>
                      ),
                      inputProps: {
                        min: 0.01,
                        step: 0.01
                      }
                    }}
                    value={value}
                    onChange={(e) => {
                      multiSet({
                        value: e.target.value.replace(/[^0-9,.]/g, '')
                      });
                    }}
                  />
                </FormControl>
              </div>
            </div>
          </Stack>
          <Stack spacing={4}>
            <div>
              <Button
                sx={{ minWidth: 200 }}
                onClick={() => {
                  onAdded({
                    currencyId,
                    value,
                    localId: nanoid()
                  });
                  clearOperation();
                }}
                disabled={isAllEmpty || +value <= 0}
                variant="contained"
              >
                Додати
              </Button>
            </div>
          </Stack>
        </div>
      </Grid>
      <Grid item xs={1} />
    </>
  );
};

StepTwoBuySellOperation.propTypes = {
  cashierCurrency: PropTypes.array,
  orderItems: PropTypes.array,
  selectedTransitType: PropTypes.string,
  keyName: PropTypes.string,
  onAdded: PropTypes.func.isRequired
};

export default StepTwoBuySellOperation;
