import React, { useEffect, useMemo, useState, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Pagination, TablePagination
} from '@mui/material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import isEmpty from 'lodash.isempty';
import PageWrapper from '../../../common/PageWrapper/PageWrapper';
import { DashboardContext } from '../../../components/DashboardContext';
import { HorizontalScrollWrapper } from '../../../components/HorizontalScrollWrapper';
import { AuthorisationContext } from '../../../components/AuthorisationContext';

const Balance = () => {
  const { userRole } = useContext(AuthorisationContext);
  const {
    balanceFilials, fetchBalanceFilials, fetchBalanceDepartments,
    balanceDepartments, balanceDepartmentsCount,
    selectedFilialID: filialID,
    updateSelectedFilialID: setFilialID
  } = useContext(DashboardContext);
  const [page, setPage] = useState(Number(localStorage.getItem('adBalancePage')) || 1);
  const [perPage, setPerPage] = useState(Number(localStorage.getItem('adBalancePerPage')) || 5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem('adBalancePage', newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    localStorage.setItem('adBalancePerPage', String(parseInt(event.target.value, 10)));
    setPage(1);
  };

  const onFilialClick = (id) => {
    if (id) {
      setFilialID(id);
      setPage(1);
      localStorage.setItem('adBalancePage', 1);
    }
  };

  const paginationPerPage = useMemo(() => {
    const arr = [5, 10, 25, 50];
    return arr;
  }, []);

  useEffect(() => {
    if (filialID) {
      fetchBalanceDepartments({ id: filialID, page, per_page: perPage });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filialID, page, perPage]);

  useEffect(() => {
    fetchBalanceFilials({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currencies = useMemo(
    () => balanceFilials.at(-1)?.currencies?.map((currency, index) => (index !== 0
      ? currency.currency_name
      : 'Загальний баланс у гривні')
    ),
    [balanceFilials]);

  const renderCurrencies = useMemo(
    () => currencies?.map((currency) => <TableCell align="center" key={currencies.indexOf(currency)}>{currency}</TableCell>),
    [currencies]);

  const renderCityCurrency = useCallback(
    (item) => balanceFilials.at(-1)?.currencies?.map((currency) => {
      const currentCurrency = item.find((itemCurrency) => itemCurrency.currency_id === currency.currency_id) || {
        value: '-'
      };
      return (
        <TableCell key={currency.currency_id} align="right">
          {currentCurrency.value}
        </TableCell>
      );
    }), [balanceFilials]);

  const renderDepartmentCurrency = useCallback(
    (item) => balanceFilials.at(-1)?.currencies?.map((currency) => {
      const currentCurrency = item.find((itemCurrency) => itemCurrency.currency_id === currency.currency_id) || {
        balance: '-'
      };
      return (
        <TableCell key={currency.currency_id} align="right">
          {currentCurrency.balance}
        </TableCell>
      );
    }), [balanceFilials]);

  const renderFilials = useMemo(
    () => balanceFilials?.map((filial, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <TableRow key={`${filial.filial_name}-${index}`}>
        <TableCell
          onClick={() => onFilialClick(filial.filial_id)}
          sx={[
            filial.filial_id
                && filialID === filial.filial_id && {
              backgroundColor: 'var(--balance-button)!important'
            }
          ]}
        >
          {filial.filial_name || 'Загальний баланс'}
        </TableCell>
        {renderCityCurrency(filial.currencies)}
      </TableRow>
    )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [balanceFilials, filialID]);

  const renderDepartments = useMemo(
    () => filialID
      && balanceDepartments?.map((department) => (
        <TableRow key={department.id}>
          {userRole === 'Администратор' && (
          <TableCell className="balance-edit">
            <Link to={`/balance/departments/${department.id}`} className="balance-edit-btn">
              <DriveFileRenameOutlineIcon />
            </Link>
          </TableCell>
          )}
          {/* <TableCell align="center">{department.id}</TableCell> */}
          <TableCell sx={{ maxWidth: '200px' }}>{department.title}</TableCell>
          {renderDepartmentCurrency(department.balances)}
        </TableRow>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [balanceDepartments]);

  return (
    <PageWrapper>
      <HorizontalScrollWrapper className="balance-filials balanceCashier-container balance-cashier-table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ verticalAlign: 'bottom' }}>
              <TableCell align="left" sx={{ maxWidth: '200px' }}>Регіон</TableCell>
              {renderCurrencies}
            </TableRow>
          </TableHead>
          <TableBody>{renderFilials}</TableBody>
        </Table>
      </HorizontalScrollWrapper>
      <HorizontalScrollWrapper className="balance-departments">
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" className="balance-cashier-table">
            <TableHead>
              <TableRow>
                {userRole === 'Администратор' && (<TableCell padding="checkbox" align="center">Дія</TableCell>)}
                {/* <TableCell padding="checkbox" align="center">ID</TableCell> */}
                <TableCell align="center" sx={{ maxWidth: '200px' }}>
                  Відділення
                </TableCell>
                {renderCurrencies}
              </TableRow>
            </TableHead>
            <TableBody>{renderDepartments}</TableBody>
          </Table>
          {(!filialID || isEmpty(balanceDepartments)) && (
            <div className="operations-emptyOperations">Оберіть місто для відображення балансів відділень</div>
          )}
          {balanceDepartmentsCount >= 5 && !isEmpty(balanceDepartments) && (
            <div className="users-pagination">
              <Pagination
                page={page}
                shape="rounded"
                onChange={handleChangePage}
                count={Math.ceil(balanceDepartmentsCount / perPage)}
                color="primary"
              />
              <TablePagination
                count={balanceDepartmentsCount || 1}
                page={page}
                component="div"
                rowsPerPage={perPage}
                labelRowsPerPage="Відображати:"
                onPageChange={handleChangePage}
                rowsPerPageOptions={paginationPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          )}
        </TableContainer>
      </HorizontalScrollWrapper>
    </PageWrapper>
  );
};

export default Balance;
