import React, { useMemo, useContext } from 'react';
import './style.scss';
import Button from '@mui/material/Button';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CommentBankIcon from '@mui/icons-material/CommentBank';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import isEmpty from 'lodash.isempty';
import { Container } from '@mui/material';
import SideBarCashierInfo from '../SideBarCashier/SideBarCashier';
import SideBarCashierMenuOperations from '../SideBarCashierMenu/SideBarCashierMenu';
import { AuthorisationContext } from '../AuthorisationContext';
import { CashierContext } from '../CashierContext';
import { SackDollar } from '../../../assets/sack-dollar-solid';

export const SideBarCashierMenu = () => {
  const {
    cashierData: { isCentral, id: depId, department_active_session: departmentAtvSession },
    user: { id: userId } } = useContext(AuthorisationContext);
  const { sessionData } = useContext(CashierContext);
  const isAnotherUserLeftSession = departmentAtvSession?.user.id && (userId !== departmentAtvSession.user.id);
  const isDisabled = isEmpty(sessionData) || isAnotherUserLeftSession;
  // TO DO Look how to optimize checking
  const isDisabledReport = isAnotherUserLeftSession || (isEmpty(departmentAtvSession) && isEmpty(localStorage.getItem(`lastActiveSessionDep${depId}`)));

  const renderSideBar = useMemo(() => (
    <div className="sideBar-menu">
      <Button component={NavLink} to="/cashier-operations-list" activeClassName="sideBar-menu-item-active" className={cx('sideBar-menu-item', { disabledMenu: isDisabled })}>
        <AccountTreeIcon />
        <span>Список операцій</span>
      </Button>
      <Button component={NavLink} to="/cashier-currency" activeClassName="sideBar-menu-item-active" className={cx('sideBar-menu-item', { disabledMenu: isDisabled })}>
        <CurrencyExchangeIcon />
        <span>Курси валют</span>
      </Button>
      <Button component={NavLink} to={isCentral ? '/cashier-balance-central' : '/cashier-balance'} activeClassName="sideBar-menu-item-active" className={cx('sideBar-menu-item', { disabledMenu: isDisabled })}>
        <AccountBalanceWalletIcon />
        <span>Баланси</span>
      </Button>
      {isCentral && (
      <>
        <Button component={NavLink} to="/cashier-other-expenses" activeClassName="sideBar-menu-item-active" className={cx('sideBar-menu-item', { disabledMenu: isDisabled })}>
          <ShoppingCartRoundedIcon />
          <span>Інші витрати</span>
        </Button>
        <Button component={NavLink} to="/cashier-other-arrivals" activeClassName="sideBar-menu-item-active" className={cx('sideBar-menu-item', { disabledMenu: isDisabled })}>
          <SackDollar />
          <span>Інші доходи</span>
        </Button>
      </>
      )}
      <Button component={NavLink} to="/cashier-cash-in-transit" activeClassName="sideBar-menu-item-active" className={cx('sideBar-menu-item', { disabledMenu: isDisabled })}>
        <AccountBalanceIcon />
        <span>Інкасації</span>
      </Button>
      <Button component={NavLink} to="/messages" activeClassName="sideBar-menu-item-active" className={cx('sideBar-menu-item', { disabledMenu: isDisabled })}>
        <CommentBankIcon />
        <span>Повідомлення</span>
      </Button>
      <Button component={NavLink} to={`/cashier-report/${depId}`} target="_blank" activeClassName="sideBar-menu-item-active" className={cx('sideBar-menu-item', { disabledMenu: isDisabledReport })}>
        <SummarizeIcon />
        <span>Звіти</span>
      </Button>
    </div>
  ), [isDisabled, isDisabledReport, depId, isCentral]);

  return (
    <Container className="sideBar">
      <div className="sideBar-userRole">{isCentral ? 'Касир ЦВ' : 'Касир РВ'}</div>
      <SideBarCashierInfo />
      <SideBarCashierMenuOperations />
      {renderSideBar}
      {/* <div className="sideBar-contacts">
        <p className="sideBar-contacts-title">Підтримка</p>
        <div className="sideBar-contacts-item">
          <PhoneEnabledIcon />
          <a href="tel:8000345677">8 000 34 56 77</a>
        </div>
        <div className="sideBar-contacts-item">
          <MailOutlineIcon />
          <a href="mailto:money@gmail.com">money@gmail.com</a>
        </div>
      </div> */}
    </Container>
  );
};
