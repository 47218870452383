import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import '../styles.scss';
import { Button, CircularProgress, Container } from '@mui/material';
import isEmpty from 'lodash.isempty';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import SettingsBackwardBtn from '../../../components/SettingsBackwardBtn/SettingsBackwardBtn';
import { isUndefined } from '../../../../utils/isUndefined';
import { DashboardContext } from '../../../components/DashboardContext';
import AddUser from '../../../components/AddUser/AddUser';
import useIsMounted from '../../../../hooks/useIsMounted';

const UserPage = () => {
  const isMounted = useIsMounted();
  const { id: userId } = useParams();
  const { selectedUser, isFetchingUser, fetchSelectedUser } = useContext(DashboardContext);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    if (isMounted.current) {
      fetchSelectedUser({ id: userId });
    }
  }, [fetchSelectedUser, isMounted, userId, reload]);
  const renderCity = useMemo(() => {
    if (!isEmpty(selectedUser)) {
      const { cities } = selectedUser;
      let str = '';
      cities.map((item) => {
        str += `${item.title}, `;
        return null;
      });
      return str.substr(0, str.length - 2);
    }
  }, [selectedUser]);

  if (isFetchingUser) {
    return (
      <div className="selectedUser">
        <CircularProgress />
      </div>
    );
  }

  const onHandleClose = () => {
    setIsOpenModal(false);
    setReload(!reload);
  };

  return (
    <Container className="selectedUser">
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div className="department-backward" style={{ marginRight: '10px' }}>
          <SettingsBackwardBtn />
        </div>
        <div className="selectedUser__head">
          <div className="selectedUser__name">
            <AccountCircleIcon sx={{ mr: '20px', fontSize: '30px' }} />
            <div className="selectedUser-title">
              {`${selectedUser.lastname} ${selectedUser.firstname} ${selectedUser.patronymic}`}
            </div>
          </div>
          <Button variant="contained" size="large" onClick={() => setIsOpenModal(true)}>
            <DriveFileRenameOutlineIcon sx={{ mr: '5px' }} />
            Редагувати профіль
          </Button>
        </div>

      </div>
      <div className="selectedUser-options">
        <div className="selectedUser-options-item">
          <span>Логін</span>
          <span className="selectedUser-options-item-value">{selectedUser.login || '-'}</span>
        </div>
        <hr />
        <div className="selectedUser-options-item">
          <span>Дата оформлення</span>
          <span className="selectedUser-options-item-value">{moment(selectedUser.start_date).format('DD.MM.YYYY') || '-'}</span>
        </div>
        <hr />
        <div className="selectedUser-options-item">
          <span>Місто</span>
          <span className="selectedUser-options-item-value">{renderCity || '-'}</span>
        </div>
        <hr />
        <div className="selectedUser-options-item">
          <span>Посада</span>
          <span className="selectedUser-options-item-value">{!isUndefined(selectedUser.role) ? selectedUser.role.title : '-'}</span>
        </div>
        <hr />
        <div className="selectedUser-options-item">
          <span>Телефон</span>
          <span className="selectedUser-options-item-value">{selectedUser.phone || '-'}</span>
        </div>
        <hr />
        <div className="selectedUser-options-item">
          <span>Телеграм</span>
          <span className="selectedUser-options-item-value">{selectedUser.telegram_chat_id || '-'}</span>
        </div>
        <hr />
        <div className="selectedUser-options-item">
          <span>Додатковий контакт</span>
          <span className="selectedUser-options-item-value">{selectedUser.additional_contact || '-'}</span>
        </div>
        <hr />
        <div className="selectedUser-options-item">
          <span>Доступ</span>
          <span className="selectedUser-options-item-value">{selectedUser.access ? 'Дозволено' : 'Заборонено'}</span>
        </div>
        <hr />
        <div className="selectedUser-options-item">
          <span>Статус</span>
          <span className="selectedUser-options-item-value">{selectedUser.status ? 'Активний' : 'Неактивний'}</span>
        </div>
      </div>
      <AddUser isEdit open={isOpenModal} handleClose={onHandleClose} />
    </Container>
  );
};

export default UserPage;
