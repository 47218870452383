import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import { Button, TableCell, TableRow, Collapse, Table, TableHead, TableBody, Tooltip } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CancelIcon from '@mui/icons-material/Cancel';

export const Category = ({ item, onChangeCategory,
  onOpenConfirmDelete, onAddItem, onChangeItem
}) => {
  const { id, title, items } = item;
  const [open, setOpen] = useState(false);

  const renderItems = useCallback((expenses) => (
    expenses?.map(({ id: itemId, title: itemTitle }) => (
      <TableRow key={nanoid()}>
        <TableCell>{itemId}</TableCell>
        <TableCell>{itemTitle}</TableCell>
        <TableCell align="right">
          <Tooltip title="Редагувати">
            <Button
              sx={{ mr: 2 }}
              onClick={() => onChangeItem({ id }, { id: itemId, title: itemTitle })}
              variant="contained"
              color="warning"
              size="small"
            >
              <EditRoundedIcon fontSize="small" />
            </Button>
          </Tooltip>
          <Tooltip title="Видалити">
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={() => onOpenConfirmDelete({ id: itemId }, false)}
            >
              <CancelIcon fontSize="small" />
            </Button>
          </Tooltip>
        </TableCell>
      </TableRow>
    ))
  ), [onChangeItem, onOpenConfirmDelete, id]);

  return (
    <>
      <TableRow>
        <TableCell>{id}</TableCell>
        <TableCell>{title}</TableCell>
        <TableCell align="right">
          <Tooltip title="Подивитись позиції">
            <Button sx={{ mr: 2 }} variant="contained" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
            </Button>
          </Tooltip>
          <Tooltip title="Редагувати категорію">
            <Button
              sx={{ mr: 2 }}
              variant="contained"
              color="warning"
              size="small"
              onClick={() => onChangeCategory({ id, title })}
            >
              <EditRoundedIcon fontSize="small" />
            </Button>
          </Tooltip>
          <Tooltip title={items.length > 0 ? 'Видалити категорію можна тільки якщо вона порожня' : 'Видалити категорію'}>
            <span>
              <Button
                variant="contained"
                color="error"
                size="small"
                disabled={items.length > 0}
                onClick={() => onOpenConfirmDelete({ id }, true)}
              >
                <CancelIcon fontSize="small" />
              </Button>
            </span>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: 0, borderBottom: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table sx={{ maxWidth: 1100, backgroundColor: 'var(--secondary-bg)' }}>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Назва</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      onClick={() => onAddItem({ id })}
                      startIcon={<AddCircleRoundedIcon />}
                    >
                      Додати Позицію
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderItems(items)}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

Category.propTypes = {
  item: PropTypes.object,
  onChangeCategory: PropTypes.func,
  onOpenConfirmDelete: PropTypes.func,
  onAddItem: PropTypes.func,
  onChangeItem: PropTypes.func
};
