import * as yup from 'yup';
import parseMobile from 'libphonenumber-js/mobile';

const isValidPhoneNumber = (phoneNumber) => {
  const parsedPhoneNumber = parseMobile(phoneNumber, {
    extract: false
  });

  if (!parsedPhoneNumber) {
    return false;
  }

  return parsedPhoneNumber.isValid() && parsedPhoneNumber.getType() === 'MOBILE';
};

const validationSchemaNewUser = yup.object({
  lastname: yup
    .string()
    .max(24, 'Максимально 24 символи')
    .required('Обов\'язково до заповнення'),
  firstname: yup
    .string()
    .max(24, 'Максимально 24 символи')
    .required('Обов\'язково до заповнення'),
  patronymic: yup
    .string()
    .max(24, 'Максимально 24 символи')
    .required('Обов\'язково до заповнення'),
  startDate: yup
    .string()
    .required('Обов\'язково до заповнення'),
  phone: yup
    .string()
    .trim()
    .required('Обов\'язково до заповнення')
    .min(6, 'Номер занадто короткий')
    .max(15, 'Номер занадто довгий')
    .test('isValid phone', 'Телефон не валідний', (value) => (value?.startsWith('+') && /^[\d+]+$/.test(value)) && isValidPhoneNumber(value)),
  additionalContact: yup
    .string()
    .max(48, 'Не більше 48 символів'),
  userType: yup
    .string()
    .required('Обов\'язково до заповнення'),
  cityIds: yup
    .array()
    .min(1, 'Обов\'язково до заповнення'),
  access: yup
    .string()
    .required('Обов\'язково до заповнення'),
  login: yup
    .string()
    .max(12, 'Має бути не більше 12 символів'),
  status: yup
    .string()
    .required('Обов\'язково до заповнення')
});

export default validationSchemaNewUser;
