import React, { useCallback, useContext, useState, useMemo } from 'react';
import * as yup from 'yup';
import './style.scss';
import browserSignature from 'browser-signature';
import { useCookies } from 'react-cookie';
import { Button, CircularProgress, TextField } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';
import { useFormik } from 'formik';
import useFetch from '../../../hooks/useFetch';
import LoginService from '../../../services/AUTH/LoginService';
import { AuthorisationContext } from '../../components/AuthorisationContext';
import { isUndefined } from '../../../utils/isUndefined';
import { localizeErrors } from '../../../utils/helpers';

const LoginPage = () => {
  const history = useHistory();
  const location = useLocation();
  const signature = browserSignature();
  const [cookie, setCookie] = useCookies(['pcTokenHardWrite']);
  if (location.state?.token) {
    localStorage.setItem('pcTokenHardWrite', location.state.token);
    setCookie('pcTokenHardWrite', location.state.token, { path: '/auth', maxAge: (60 * 60 * 24 * 365) });
  }
  const fingerPrint = useMemo(() => cookie.pcTokenHardWrite || localStorage.getItem('pcTokenHardWrite') || location.state?.token || signature,
    [signature, location, cookie]);
  const [errorMsg, setErrorMsg] = useState('');
  const {
    logout,
    updateUser,
    updateCashier,
    login: setToken
  } = useContext(AuthorisationContext);

  const onGetResponse = useCallback((resp) => {
    if ('error' in resp) {
      setErrorMsg(localizeErrors[resp.error] || resp.error);
      return null;
    }
    if (resp.data) {
      const { token, userData, departments } = resp.data;
      if (token === 0) {
        // TODO Need to re-wright after change departments in to an array
        if ('pc_token' in departments) {
          if (departments.pc_token === 0) {
            setErrorMsg('Токен відсутній');
          } else if (departments.message === 'You need to contact the administrator.') {
            setErrorMsg(`Цей браузер не підв'язаний до жодного відділення. Адміністратор має дати дозвіл до входу в систему. Ваш токен: ${fingerPrint} `);
          } else if (departments.message === 'The pc token is not valid.') {
            setErrorMsg('Ваш токен недійсний. Зверніться до Адміністратора.');
          }
          return null;
        }
        if (departments.every((dep) => dep.isActive === 0)) {
          setErrorMsg('Відсутні активні відділення. Зверніться до адміністратора');
        }
        return null;
      }
      if (!userData.access) {
        logout();
      }
      if (token !== 0 && userData.access) {
        if (!isUndefined(departments)) {
          updateCashier(resp.data);
        }
        setToken(token);
        updateUser(userData);
        setErrorMsg('');
        history.push('/');
      }
    }
  }, [setToken, logout, updateCashier, history, updateUser, fingerPrint]);

  const { fetch: fetchLogin, loading: isFetchingLogin } = useFetch({
    requestFunction: LoginService.postRequest,
    withLoading: true,
    setResponse: onGetResponse
  });

  const formik = useFormik({
    initialValues: {
      login: '',
      password: ''
    },
    validationSchema: yup.object({
      login: yup.string().required('Обов\'язково до заповнення'),
      password: yup.string().min(4, 'Не менше 4-х символів').max(8, 'Не більше 8 символів').required('Обов\'язково до заповнення')
    }),
    onSubmit: ({ login, password }) => {
      setErrorMsg('');
      if (fingerPrint === '') {
        setErrorMsg('Система не змогла згенерувати Токен. Будь ласка перезавантажте сторінку, або зверніться до адміністратора');
      } else {
        fetchLogin({
          login,
          password,
          pc_token: fingerPrint
        });
      }
    }
  }
  );

  const renderErrors = useMemo(() => <p className="errorText">{errorMsg}</p>, [errorMsg]);

  return (
    <div className="login">
      <div className="login-container">
        <p className="login-container-title">Авторизація</p>
        <p className="login-container-subTitle">Введіть свої дані</p>
        <div className="login-container-form">
          <TextField
            fullWidth
            value={formik.values.login}
            name="login"
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Логін"
            label="Логін"
            error={formik.errors.login && formik.touched.login}
            helperText={(formik.touched.login && formik.errors.login) || ' '}
          />
          <TextField
            fullWidth
            value={formik.values.password}
            type="password"
            name="password"
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Пароль"
            label="Пароль"
            error={formik.errors.password && formik.touched.password}
            helperText={(formik.touched.password && formik.errors.password) || ' '}
          />
          <div className="login-container-button">
            <Button
              variant="contained"
              onClick={formik.handleSubmit}
              disabled={isFetchingLogin || !formik.isValid}
              size="large"
              sx={{ width: '145px', height: '45px' }}
            >
              {!isFetchingLogin
                ? (
                  <>
                    <LoginIcon sx={{ mr: '10px' }} />
                    Увійти
                  </>
                )
                : <CircularProgress style={{ width: '20px', height: '20px', color: 'white' }} />}
            </Button>
          </div>
        </div>
        {renderErrors}
      </div>
    </div>
  );
};

export default LoginPage;
