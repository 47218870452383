import { API_URLS } from '../../constants/urls';
import { addParamsToUrl } from '../../utils/helpers';
import CRUDService from '../CRUDService';

class SessionService extends CRUDService {
  constructor() {
    super(API_URLS.SESSIONS);
    this.getSessionsList = async (params) => this.APIService.apiGet(addParamsToUrl(`${this.pathname}/list/`, params));
  }
}

export default new SessionService();
