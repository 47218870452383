import React, { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import { nanoid } from 'nanoid';
import './style.scss';
import { SalaryTextField } from './SalaryTextField';

export const SalaryTable = ({ salary, setEditedSalary }) => {
  const [editSalary, setEditSalary] = useState(() => salary);
  useEffect(() => {
    if (editSalary !== salary) {
      setEditedSalary(editSalary);
    }
  }, [setEditedSalary, editSalary, salary]);

  useEffect(() => {
    setEditSalary(salary);
    setEditedSalary(salary);
  }, [salary, setEditedSalary]);

  const handleInputChange = useCallback((rowIndex, cellIndex) => (e) => {
    setEditSalary(
      (prev) => {
        const newValue = [...prev];
        newValue[rowIndex].row[cellIndex] = e;
        const allSessionsSumValue = newValue.reduce((accum, item) => {
          if (item?.parent_id === newValue[rowIndex].parent_id) {
            return accum + item.row[cellIndex];
          } return accum;
        }, 0);
        const headerRowIndex = newValue.findIndex(({ id }) => id === newValue[rowIndex].parent_id);
        newValue[headerRowIndex].row[cellIndex] = allSessionsSumValue;
        newValue[headerRowIndex].row[7] = newValue[headerRowIndex].row[4]
        + newValue[headerRowIndex].row[5] - newValue[headerRowIndex].row[6];
        const totalId = newValue.findIndex((obj) => obj.type === 'total' && obj.user_id === newValue[rowIndex].user_id);
        const totalSum = newValue.filter((obj) => obj.type === 'row' && obj.user_id === newValue[rowIndex].user_id)
          .map((item) => item.row[cellIndex]).reduce((sum, elem) => sum + elem, 0);

        newValue[totalId].row[cellIndex] = totalSum;
        newValue[totalId].row[7] = newValue[totalId].row[4]
        + newValue[totalId].row[5] - newValue[totalId].row[6];

        newValue[rowIndex].row[7] = newValue[rowIndex].row[4]
        + newValue[rowIndex].row[5] - newValue[rowIndex].row[6];
        return newValue;
      }
    );
  }, []);

  const renderBody = useMemo(() => editSalary?.map(
    (row, rowIndex) => (
      <>
        <TableRow className={`row-${row.type}`} key={nanoid()}>
          {row.row.map(
            (cell, cellIndex) => {
              if ((row.type === 'row_details') && (cellIndex === 5 || cellIndex === 6)) {
                return (
                  <TableCell key={nanoid()}>
                    <SalaryTextField initialValue={cell} onBlur={handleInputChange(rowIndex, cellIndex)} />
                  </TableCell>
                );
              } if ((row.type === 'row_details' || row.type === 'header_details') && (cellIndex === 0)) {
                return <TableCell key={nanoid()}>{cell}</TableCell>;
              } if (row.type === 'empty_row') {
                return '';
              } return <TableCell key={nanoid()}>{cell}</TableCell>;
            }
          )}
        </TableRow>
        {(row.type === 'total' || row.type === 'row_total') && <TableRow key={nanoid()}><TableCell key={nanoid()} colSpan={8} sx={{ height: '70px' }} /></TableRow>}
      </>

    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [editSalary]);

  return (
    <Table>
      <TableBody>
        {renderBody}
      </TableBody>
    </Table>
  );
};

SalaryTable.propTypes = {
  salary: PropTypes.array,
  setEditedSalary: PropTypes.func
};
