import * as yup from 'yup';

const validationSchemaNewDepartment = yup.object({
  title: yup.string().max(128, 'Максимально 128 символів').required("Обов'язково до заповнення"),
  filialId: yup.string().required("Обов'язково до заповнення"),
  cityId: yup.string().required("Обов'язково до заповнення"),
  address: yup.string().required("Обов'язково до заповнення"),
  comment: yup.string().max(255, 'Максимально 255 символів'),
  tokenArray: yup.array().max(2, 'Максимум 2 токени'),
  salary: yup.number().min(0, 'Мінімально допустиме зачення - 0').required("Обов'язково до заповнення"),
  schedule: yup.string().oneOf(['08:00', '12:00', '23:59'], 'Хибне значення').required("Обов'язково до заповнення"),
  isCentral: yup.string().required("Обов'язково до заповнення"),
  isEditableRates: yup.string().required("Обов'язково до заповнення"),
  isActive: yup.string().required("Обов'язково до заповнення"),
  limit: yup.number().min(0, 'Мінімально допустиме зачення - 0'),
  currencies: yup.array().min(1, 'Обов\'язково до заповнення')
});

export default validationSchemaNewDepartment;
