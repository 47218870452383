import React, { useCallback, useMemo, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, TableCell, TableRow, TextField, Tooltip } from '@mui/material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import SaveIcon from '@mui/icons-material/Save';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { DashboardContext } from '../../../components/DashboardContext';

const DepartmentBalanceItem = ({ item, departmentId }) => {
  const { fetchUpdateDepartmentBalance } = useContext(DashboardContext);
  const { id, title, balance } = item;
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(balance);

  useEffect(() => {
    setValue(balance);
  }, [balance]);

  const onClickSave = useCallback(() => {
    fetchUpdateDepartmentBalance(
      {
        balance: String(value),
        departmentId: Number(departmentId),
        currencyId: Number(id)
      },
      departmentId
    );
    setIsEdit((prevState) => !prevState);
  }, [departmentId, fetchUpdateDepartmentBalance, id, value]);

  const renderButton = useMemo(() => {
    if (!isEdit) {
      return (
        <Tooltip title="Редагувати">
          <Button
            onClick={() => setIsEdit((prevState) => !prevState)}
            variant="contained"
            size="large"
          >
            <DriveFileRenameOutlineIcon />
          </Button>
        </Tooltip>
      );
    }
    if (isEdit) {
      return (
        <ButtonGroup variant="contained" size="large">
          <Tooltip title="Зберегти">
            <Button color="success" onClick={onClickSave} disabled={value === ''}>
              <SaveIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Скасувати">
            <Button
              color="error"
              onClick={() => setIsEdit((prevState) => !prevState)}
            >
              <DisabledByDefaultIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>
      );
    }
  }, [isEdit, onClickSave, value]);

  return (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell>
        <TextField
          type="number"
          onWheel={(e) => e.target.blur()}
          value={value}
          disabled={!isEdit}
          onChange={(e) => {
            if (e.target.value >= 0 && e.target.value <= 1000000000) {
              setValue(e.target.value);
            } else {
              setValue(balance);
            }
          }}
        />
      </TableCell>
      <TableCell align="right">{renderButton}</TableCell>
    </TableRow>
  );
};

DepartmentBalanceItem.propTypes = {
  item: PropTypes.object,
  departmentId: PropTypes.number
};

export default DepartmentBalanceItem;
