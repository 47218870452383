import React, { useCallback, useRef } from 'react';

export const HorizontalScrollWrapper = ({ children, ...props }) => {
  const ref = useRef();

  const nativeLock = useCallback((e) => {
    try {
      if (e.altKey) {
        e.stopPropagation();
        e.preventDefault();
      }
    } catch {
      return true;
    }
    return false;
  }, []);

  const onLock = useCallback(() => {
    window.addEventListener('wheel', nativeLock, { passive: false });
  }, [nativeLock]);

  const onUnlock = useCallback(() => {
    window.removeEventListener('wheel', nativeLock);
  }, [nativeLock]);

  const onWheel = (e) => {
    if (e.altKey && typeof ref.current.scrollLeft === 'number') {
      ref.current.scrollLeft += e.deltaY >= 0 ? 10 : -10;
    }
  };

  return (
    <div
      {...props}
      ref={ref}
      onWheel={onWheel}
      onMouseEnter={onLock}
      onMouseLeave={onUnlock}
    >
      {children}
    </div>
  );
};
