import { API_URLS } from '../../constants/urls';
import CRUDService from '../CRUDService';

class UsersService extends CRUDService {
  constructor() {
    super(API_URLS.USERS);
    this.putThemeColor = async (body) => this.APIService.apiPut(`${this.pathname}/color`, { body });
  }
}

export default new UsersService();
