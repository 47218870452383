import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, TableCell, TableRow, Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { AuthorisationContext } from '../../components/AuthorisationContext';

const UsersItem = ({ item }) => {
  const { userRole } = useContext(AuthorisationContext);
  const history = useHistory();
  const {
    id,
    role,
    login,
    access,
    firstname,
    lastname,
    patronymic
  } = item;
  const { title } = role;

  const onClickProfile = useCallback(() => history.push(`/settings/users/${id}`), [id, history]);

  return (
    <TableRow>
      <TableCell align="left">{id}</TableCell>
      <TableCell align="left">{login}</TableCell>
      <TableCell>{`${lastname} ${firstname} ${patronymic}`}</TableCell>
      <TableCell align="left">{title}</TableCell>
      <TableCell align="left">{access ? 'Eсть' : 'Нет'}</TableCell>
      { userRole === 'Администратор' && (
      <TableCell align="center">
        <Tooltip title="Переглянути профіль">
          <Button onClick={onClickProfile} variant="contained" color="warning">
            <AccountBoxIcon />
          </Button>
        </Tooltip>
      </TableCell>
      )}
    </TableRow>
  );
};

UsersItem.propTypes = {
  item: PropTypes.object
};

export default UsersItem;
