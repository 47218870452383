import React from 'react';
import { Container } from '@mui/material';
import PropTypes from 'prop-types';
import './style.scss';

const PageWrapper = ({ children }) => (
  <Container className="pageWrapper">
    {children}
  </Container>
);

PageWrapper.propTypes = {
  children: PropTypes.any
};

export default PageWrapper;
