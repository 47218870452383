import React, { useState, useContext, useMemo, useEffect } from 'react';
import './style.scss';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Pagination, TablePagination } from '@mui/material';
import moment from 'moment';
import PageWrapper from '../../common/PageWrapper/PageWrapper';
import { NotificationContext } from '../../components/NotificationContext/notificationContext';
import { ViewMessage } from '../AdminMessages/ViewMessage/ViewMessage';

export const UserMessages = () => {
  const {
    notificationHistory,
    totalNotifications,
    page,
    perPage,
    getNotification
  } = useContext(NotificationContext);
  const [dateSort, setDateSort] = useState('desc');
  const [viewMode, setViewMode] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState({});

  const paginationPerPage = useMemo(() => {
    const pPage = [5, 10, 25, 50, 100];
    const arr = pPage.filter(
      (item) => totalNotifications
        && Number.isInteger(totalNotifications / item >= 1 && Math.floor(totalNotifications / item))
        && item
    );

    if (arr.length === 0) {
      return [5];
    }

    return arr;
  }, [totalNotifications]);

  const renderMessages = useMemo(
    () => notificationHistory?.map((message) => (
      <TableRow key={message.id}>
        <TableCell align="center" padding="checkbox">{message.id}</TableCell>
        <TableCell
          className="userMessages__messageTitle"
          onClick={() => {
            setViewMode(true);
            setSelectedMessage(message);
          }}
          sx={{ cursor: 'pointer' }}
        >
          {message.name}
        </TableCell>
        <TableCell>{moment(message.startDate).format('DD.MM.YYYY')}</TableCell>
        <TableCell>Прочитано</TableCell>
      </TableRow>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    )), [notificationHistory]);

  useEffect(() => {
    getNotification(page, perPage, dateSort);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <div className="userMessages__table">
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell component="th" align="center" padding="checkbox">
                  ID
                </TableCell>
                <TableCell component="th" align="center">Назва</TableCell>
                {dateSort === 'desc' ? (
                  <TableCell
                    component="th"
                    align="center"
                    padding="checkbox"
                    onClick={() => {
                      setDateSort('ask');
                      getNotification(page, perPage, 'ask');
                    }}
                    sx={{ width: '115px', cursor: 'pointer' }}
                  >
                    Дата &darr;
                  </TableCell>
                ) : (
                  <TableCell
                    component="th"
                    align="center"
                    padding="checkbox"
                    onClick={() => {
                      setDateSort('desc');
                      getNotification(page, perPage, 'desc');
                    }}
                    sx={{ width: '115px', cursor: 'pointer' }}
                  >
                    Дата &uarr;
                  </TableCell>
                )}
                <TableCell component="th" align="center" padding="checkbox">
                  Статус
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderMessages}</TableBody>
          </Table>
          {notificationHistory?.length > 0 && (
            <div className="users-pagination">
              <Pagination
                page={page}
                shape="rounded"
                onChange={(event, newPage) => getNotification(newPage, perPage, dateSort)}
                count={Math.ceil(totalNotifications / perPage)}
                color="primary"
              />
              <TablePagination
                count={totalNotifications || 1}
                page={page}
                component="div"
                rowsPerPage={perPage}
                labelRowsPerPage="Відображати:"
                onPageChange={(event, newPage) => getNotification(newPage, perPage, dateSort)}
                rowsPerPageOptions={paginationPerPage}
                onRowsPerPageChange={(event) => getNotification(1, parseInt(event.target.value, 10), dateSort)}
              />
            </div>
          )}
        </TableContainer>
      </div>
      <div className="userMessages__viewMessage">
        <ViewMessage
          message={selectedMessage}
          handleOKViewMessage={() => setViewMode(false)}
          viewMode={viewMode}
        />
      </div>
    </PageWrapper>
  );
};
