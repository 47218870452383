export const renderLocation = {
  '/cashier-buy': 'Купівля валюти',
  '/cashier-sell': 'Продаж валюти',
  '/cashier-cross-course': 'Крос-курс',
  '/cashier-operations-list': 'Список операцій',
  '/cashier-currency': 'Курс валют',
  '/cashier-balance': 'Баланси відділення',
  '/cashier-balance-central': 'Баланси регіону',
  '/cashier-сash-in-transit': 'Інкасації',
  '/cashier-other-expenses': 'Інші витрати',
  '/cashier-other-arrivals': 'Інші доходи',
  '/admin/operations': 'Список операцій',
  '/settings': 'Налаштування',
  '/settings/users': 'Налаштування - Користувачі',
  '/settings/cities': 'Налаштування - Міста',
  '/settings/filials': 'Налаштування - Регіони',
  '/settings/departments': 'Налаштування - Відділення',
  '/settings/inkassators': 'Налаштування - Інскасатори',
  '/settings/variable': 'Налаштування -Змінні',
  '/settings/other-expenses': 'Налаштування - Інші витрати',
  '/settings/other-arrivals': 'Налаштування - Інші доходи',
  '/reports': 'Звіти',
  '/profile': 'Профіль користувача',
  '/balance': 'Баланси',
  '/admin/messages': 'Повідомлення',
  '/messages': 'Повідомлення',
  '/admin/salary': 'Зарплати',
  '/admin/currency': 'Курс валют НБУ',
  '/admin/sessions': 'Зміни',
  '/cash-in-transit': 'Інкасації',
  '/': null
};
