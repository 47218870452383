import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Button, FormControl, TextField } from '@mui/material';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import SaveIcon from '@mui/icons-material/Save';
import ModalWindow from '../ModalWindow/ModalWindow';
import { AdminContext } from '../AdminContext/AdminContext';

export const AddEditVariable = ({
  open, handleClose, selectedVariableId, editMode
}) => {
  const { addVariable, editVariable, variables, isFetchingVariables } = useContext(AdminContext);
  const selectedVariable = variables.find((item) => item.id === selectedVariableId);

  const submitForm = useCallback((values, resetForm) => {
    const { title, slug, value } = values;
    if (editMode) {
      editVariable({
        id: selectedVariableId,
        title,
        slug,
        value
      }).then((resp) => {
        if (resp === 'success') {
          handleClose();
          resetForm();
        }
      });
    } else {
      addVariable({ title, slug, value }).then((resp) => {
        if (resp === 'success') {
          handleClose();
          resetForm();
        }
      });
    }
  }, [editMode, editVariable, selectedVariableId, handleClose, addVariable]);

  const formik = useFormik({
    initialValues: {
      title: editMode ? selectedVariable?.title : '',
      slug: editMode ? selectedVariable?.slug : '',
      value: editMode ? selectedVariable?.value : ''
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      title: yup
        .string()
        .max(48, 'Максимально 48 символів')
        .required('Обов\'язково до заповнення'),
      slug: yup
        .string()
        .matches(/^([a-z0-9_]*)$/g, 'Можна використовувати тільки маленькі латинські літери, цифри, та нижнє підкреслення. Без пробілів')
        .max(48, 'Максимально 48 символів')
        .required('Обов\'язково до заповнення'),
      value: selectedVariable?.slug === 'default_password'
        ? yup
          .string()
          .min(4, 'Мініимально 4 символа')
          .max(8, 'Максимально 8 символів')
          .matches(/^([a-z0-9]*)$/ig, 'Можна використовувати тільки латинські літери та цифри.')
          .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*.*\d.*\d.*)/, 'Пароль повинен складатись мінімум з однієї великої літери, однієї маленької та двох цифр')
          .required('Обов\'язково до заповнення')
        : yup
          .string()
          .max(48, 'Максимально 48 символів')
          .required('Обов\'язково до заповнення')
    }),
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  const onClickReset = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <ModalWindow
      open={open}
      maxWidth="500px"
      onClose={handleClose}
      onBackdropClick={onClickReset}
    >
      <div className="addUsers addPopup">
        <form onSubmit={formik.handleSubmit}>
          <FormControl size="small" fullWidth>
            <TextField
              size="small"
              id="title"
              label="Назва*"
              name="title"
              placeholder="Введіть назву"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
          </FormControl>
          <FormControl size="small" fullWidth>
            <TextField
              size="small"
              id="slug"
              label={selectedVariable?.required ? 'Запис у системі залишається незмінним' : 'Запис у системі*'}
              name="slug"
              disabled={selectedVariable?.required}
              placeholder="Введіть назву позиції"
              value={formik.values.slug}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.slug && Boolean(formik.errors.slug)}
              helperText={formik.touched.slug && formik.errors.slug}
            />
          </FormControl>
          <FormControl size="small" fullWidth>
            <TextField
              size="small"
              id="value"
              label="Значення*"
              name="value"
              placeholder="Введіть значення"
              value={formik.values.value}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.value && Boolean(formik.errors.value)}
              helperText={formik.touched.value && formik.errors.value}
            />
          </FormControl>
          <div className="addUsers-actions">
            <LoadingButton
              variant="contained"
              loading={isFetchingVariables}
              type="submit"
            >
              <SaveIcon sx={{ marginRight: '5px' }} />
              Зберегти
            </LoadingButton>
            <Button type="reset" variant="contained" color="error" onClick={() => onClickReset()}>
              <DisabledByDefaultIcon sx={{ marginRight: '5px' }} />
              Скасувати
            </Button>
          </div>
        </form>
      </div>
    </ModalWindow>
  );
};

AddEditVariable.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedVariableId: PropTypes.number,
  editMode: PropTypes.bool
};
