import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Button, FormControl, TextField } from '@mui/material';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { nanoid } from 'nanoid';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import SaveIcon from '@mui/icons-material/Save';
import ModalWindow from '../ModalWindow/ModalWindow';
import { DashboardContext } from '../DashboardContext';
import { localizeErrors } from '../../../utils/helpers';

const AddFilials = ({ open, handleClose, selectedFilial, editMode }) => {
  const {
    fetchAddFilials, fetchEditFilials, filialsErrors, isFetchingFilials
  } = useContext(DashboardContext);

  const submitForm = useCallback((value, resetForm) => {
    const { title } = value;
    if (editMode) {
      fetchEditFilials({ id: selectedFilial.id, title }).then((resp) => {
        if (resp === 'success') {
          handleClose();
          resetForm();
        }
      });
    } else {
      fetchAddFilials({ title }).then((resp) => {
        if (resp === 'success') {
          handleClose();
          resetForm();
        }
      });
    }
  }, [editMode, fetchEditFilials, selectedFilial, handleClose, fetchAddFilials]);

  const formik = useFormik({
    initialValues: {
      title: editMode ? selectedFilial.title : ''
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      title: yup
        .string()
        .max(48, 'Максимально 48 символів')
        .required('Обов\'язково до заповнення')
    }),
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  const onClickReset = () => {
    formik.resetForm();
    handleClose();
  };

  const renderErrors = useMemo(() => filialsErrors?.map((item) => <p key={nanoid()} className="errorText">{localizeErrors[item] || item}</p>), [filialsErrors]);

  return (
    <ModalWindow
      open={open}
      maxWidth="700px"
      onClose={handleClose}
      onBackdropClick={onClickReset}
    >
      <div className="addUsers addPopup">
        <form onSubmit={formik.handleSubmit}>
          <FormControl size="small" fullWidth>
            <TextField
              size="small"
              id="title"
              label="Назва регіону *"
              name="title"
              placeholder="Введіть назву регіону"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
            {renderErrors}
          </FormControl>
          <div className="addUsers-actions">
            <LoadingButton
              variant="contained"
              loading={isFetchingFilials}
              type="submit"
              disabled={!formik.isValid}
            >
              <SaveIcon sx={{ marginRight: '5px' }} />
              Зберегти
            </LoadingButton>
            <Button type="reset" variant="contained" color="error" onClick={() => onClickReset()}>
              <DisabledByDefaultIcon sx={{ marginRight: '5px' }} />
              Скасувати
            </Button>
          </div>
        </form>
      </div>
    </ModalWindow>
  );
};

AddFilials.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedFilial: PropTypes.object,
  editMode: PropTypes.bool
};

export default AddFilials;
