const BaseButtonStyles = {
  justifyContent: 'start',
  gap: 10,
  boxShadow: 'none',
  textTransform: 'uppercase',
  color: 'var(--table-text)',
  cursor: 'unset',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5
};

export const ButtonNewStyles = {
  ...BaseButtonStyles,
  borderColor: '#12ba42',
  '& svg': {
    color: '#12ba42',
    fontSize: '25px!important'
  },
  '&:hover': {
    borderColor: '#0c7a2b',
    boxShadow: 'none'
  }
};

export const ButtonCancelStyles = {
  ...BaseButtonStyles,
  borderColor: '#e81717',
  '& svg': {
    color: '#e81717',
    fontSize: '25px!important'
  },
  '&:hover': {
    borderColor: '#ba1212',
    boxShadow: 'none'
  }
};

export const ButtonProcessStyles = {
  ...BaseButtonStyles,
  borderColor: '#dfc902',
  '& svg': {
    color: '#dfc902',
    fontSize: '25px!important'
  },
  '&:hover': {
    borderColor: '#b19f02',
    boxShadow: 'none'
  }
};

export const ButtonOnRoadStyles = {
  ...BaseButtonStyles,
  borderColor: '#0278de',
  '& svg': {
    color: '#0278de',
    fontSize: '25px!important'
  },
  '&:hover': {
    borderColor: '#025fb1',
    boxShadow: 'none'
  }
};

export const ButtonCrossCourseStyles = {
  ...BaseButtonStyles,
  border: 'none',
  '& svg': {
    color: '#dfc902',
    fontSize: '25px!important'
  },
  '&:hover': {
    borderColor: '#b19f02',
    boxShadow: 'none'
  },
  '&:before': {
    content: "''",
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    'border-radius': '4px',
    border: '3px solid transparent',
    background: 'linear-gradient(to left,#12ba42,#dfc902) border-box',
    '-webkit-mask':
    `linear-gradient(#fff 0 0) padding-box, 
    linear-gradient(#fff 0 0)`,
    '-webkit-mask-composite': 'destination-out',
    'mask-composite': 'exclude'
  }
};

export const ButtonCompleteStyles = {
  ...BaseButtonStyles,
  borderColor: 'var(--button-status-complete)',
  '& svg': {
    color: 'var(--button-status-complete)',
    fontSize: '25px!important'
  },
  '&:hover': {
    borderColor: 'var(--button-status-complete-hover)',
    boxShadow: 'none'
  }
};

export const ButtonOtherExpensesStyles = {
  ...BaseButtonStyles,
  borderColor: '#df6802',
  '& svg': {
    color: '#df6802',
    fontSize: '25px!important'
  },
  '&:hover': {
    borderColor: '#B25301',
    boxShadow: 'none'
  }
};

export const ButtonCashTransitStyles = {
  ...BaseButtonStyles,
  borderColor: 'var(--button-status-cash-transit)',
  '& svg': {
    color: 'var(--button-status-cash-transit)',
    fontSize: '25px!important'
  },
  '&:hover': {
    borderColor: 'var(--button-status-cash-transit-hover)',
    boxShadow: 'none'
  }
};

export const ButtonBuyStyles = {
  ...BaseButtonStyles,
  ...ButtonNewStyles,
  borderWidth: '3px'
};
export const ButtonSellStyles = {
  ...BaseButtonStyles,
  ...ButtonProcessStyles,
  borderWidth: '3px'
};
