import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MomentUtils from '@date-io/moment';
import { Grid, TextField, Checkbox, FormControlLabel } from '@mui/material';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import SimCardDownloadRoundedIcon from '@mui/icons-material/SimCardDownloadRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment/moment';
import isEmpty from 'lodash.isempty';
import PageWrapper from '../../common/PageWrapper/PageWrapper';
import { DashboardContext } from '../../components/DashboardContext';
import { AdminContext } from '../../components/AdminContext';
import { SalaryTable } from './SalaryTable';
import { AutocompleteSelect } from '../../components/AutocompleteSelect';

export const SalaryPage = () => {
  const { users, fetchUsers } = useContext(DashboardContext);
  const { getAllSalary, getCashierSalary, salary, postSalary,
    isFetchingSalary, isPostingSalary } = useContext(AdminContext);
  const [isAllUsers, setIsALlUsers] = useState(false);
  const [editedSalary, setEditedSalary] = useState('');
  const [values, setValues] = useState({
    dateFrom: moment().add('months', -1),
    dateTo: moment(),
    userId: ''
  });
  useEffect(() => {
    fetchUsers({
      role_id: 3
    });
  }, [fetchUsers]);

  const filteredUsers = useMemo(() => users?.map(({ id, lastname, firstname, patronymic }) => ({ id, label: `${lastname} ${firstname} ${patronymic}` })),
    [users]);

  const getSalary = useCallback(() => {
    setEditedSalary('');
    if (isAllUsers) {
      getAllSalary({
        date_from: moment(values.dateFrom).format('YYYY-MM-DD'),
        date_to: moment(values.dateTo).format('YYYY-MM-DD')
      });
    } else {
      getCashierSalary({
        id: values.userId,
        date_from: moment(values.dateFrom).format('YYYY-MM-DD'),
        date_to: moment(values.dateTo).format('YYYY-MM-DD')
      });
    }
  }, [isAllUsers, values, getAllSalary, getCashierSalary]);

  const clickPostSalary = useCallback(() => {
    postSalary({
      data: editedSalary,
      ...(!isAllUsers && { id: values.userId })
    });
  }, [postSalary, editedSalary, values, isAllUsers]);

  const renderTable = useMemo(() => {
    if (!isEmpty(salary)) {
      return (
        <Grid item md={12}>
          <SalaryTable salary={salary} setEditedSalary={setEditedSalary} />
        </Grid>
      );
    }
  }, [salary]);

  return (
    <PageWrapper>
      <Grid className="salary" sx={{ mt: 0, mb: 3, pt: 0 }} container spacing={4} alignItems="stretch" justifyContent="center">
        <Grid item md={3} />
        <Grid item md={3}>
          <div className="operation-list-selector">
            <LocalizationProvider dateAdapter={MomentUtils} locale="uk">
              <DatePicker
                label="Дата з"
                mask="__.__.____"
                value={values.dateFrom}
                maxDate={moment(values.dateTo).add('days', -1)}
                onChange={(newValue) => {
                  setValues((prev) => ({ ...prev, dateFrom: moment(newValue) }));
                }}
                renderInput={(params) => <TextField mask="YYYY-MM-DD" {...params} />}
                inputFormat="DD.MM.YYYY"
                disableMaskedInput
              />
            </LocalizationProvider>
          </div>
        </Grid>
        <Grid item md={3}>
          <div className="operation-list-selector">
            <LocalizationProvider dateAdapter={MomentUtils}>
              <DatePicker
                label="Дата по"
                mask="__.__.____"
                value={values.dateTo}
                minDate={values.dateFrom}
                maxDate={moment()}
                onChange={(newValue) => {
                  setValues((prev) => ({ ...prev, dateTo: moment(newValue) }));
                }}
                renderInput={(params) => <TextField mask="YYYY-MM-DD" {...params} />}
                inputFormat="DD.MM.YYYY"
                disableMaskedInput
              />
            </LocalizationProvider>
          </div>
        </Grid>
        <Grid item md={3} />
        <Grid item md={3} />
        <Grid item md={6}>
          <Grid container alignItems="center">
            <Grid item md={4}>
              <FormControlLabel
                className="operation-list-selector"
                control={
                  <Checkbox checked={isAllUsers} onChange={() => setIsALlUsers(!isAllUsers)} />
              }
                label="Всі касири"
              />
            </Grid>
            <Grid item md={8}>
              <AutocompleteSelect
                id="user-selector"
                label="Касир"
                selectorList={filteredUsers}
                setSelectedItem={setValues}
                multiplyState="userId"
                isDisable={isAllUsers}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3} />
        <Grid item md={3} />
        <Grid item md={3}>
          <LoadingButton
            sx={{ width: '100%' }}
            loading={isFetchingSalary}
            loadingPosition="end"
            endIcon={<SendRoundedIcon />}
            onClick={() => getSalary()}
            variant="contained"
            size="large"
            disabled={!isAllUsers && values.userId === ''}
          >
            Підтвердити
          </LoadingButton>

        </Grid>
        <Grid item md={3}>
          <LoadingButton
            sx={{ width: '100%' }}
            loading={isPostingSalary}
            loadingPosition="end"
            endIcon={<SimCardDownloadRoundedIcon />}
            disabled={isEmpty(salary)}
            onClick={() => clickPostSalary()}
            variant="contained"
            size="large"
          >
            Завантажити
          </LoadingButton>

        </Grid>
        <Grid item md={3} />
      </Grid>
      {renderTable}
    </PageWrapper>
  );
};
