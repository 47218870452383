import React, { useCallback, useContext, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SimCardDownloadRoundedIcon from '@mui/icons-material/SimCardDownloadRounded';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { isNull } from '../../../../utils/isNull';
import { DashboardContext } from '../../../components/DashboardContext';

export const ReportOperations = () => {
  const { fetchReportOperations, isFetchingReportOperations } = useContext(DashboardContext);
  const [operationsDates, setOperationsDates] = useState(
    {
      fromDate: moment(),
      toDate: moment()
    }
  );

  const downloadReportOperations = useCallback(() => {
    fetchReportOperations({
      date_from: moment(operationsDates.fromDate).format('YYYY-MM-DD'),
      date_to: moment(operationsDates.toDate).format('YYYY-MM-DD')
    });
  }, [fetchReportOperations, operationsDates]);

  return (
    <Grid sx={{ mt: 0, mb: 3, pt: 0 }} container spacing={4} alignItems="stretch">
      <Grid item md={3}>
        <div className="operation-list-selector">
          <LocalizationProvider dateAdapter={MomentUtils} locale="uk">
            <DatePicker
              fullWidth
              mask="__.__.____"
              label="Дати з"
              value={operationsDates.fromDate}
              maxDate={operationsDates.toDate}
              onChange={(newValue) => setOperationsDates(
                (PrevState) => ({ ...PrevState, fromDate: moment(newValue) })
              )}
              renderInput={(params) => <TextField mask="YYYY-MM-DD" {...params} />}
              inputFormat="DD.MM.YYYY"
              disableMaskedInput
            />
          </LocalizationProvider>
        </div>
      </Grid>
      <Grid item md={3}>
        <div className="operation-list-selector">
          <LocalizationProvider dateAdapter={MomentUtils} locale="uk">
            <DatePicker
              mask="__.__.____"
              label="Дати по"
              value={operationsDates.toDate}
              minDate={isNull(operationsDates.fromDate) ? null : operationsDates.fromDate}
              maxDate={moment()}
              onChange={
                  (newValue) => setOperationsDates(
                    (PrevState) => ({ ...PrevState, toDate: moment(newValue) })
                  )
                }
              renderInput={(params) => <TextField mask="YYYY-MM-DD" {...params} />}
              inputFormat="DD.MM.YYYY"
              disableMaskedInput
            />
          </LocalizationProvider>
        </div>
      </Grid>
      <Grid item md={3}>
        <LoadingButton
          fullWidth
          variant="contained"
          loading={isFetchingReportOperations}
          onClick={downloadReportOperations}
        >
          <SimCardDownloadRoundedIcon sx={{ marginRight: '5px' }} />
          Завантажити
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
