import React, { useContext, useCallback, useState, useMemo } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import isEmpty from 'lodash.isempty';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { AuthorisationContext } from '../AuthorisationContext';
import ModalWindowCashier from '../ModalWindowCashier/ModalWindowCashier';
import ModalWindowCashierClose from '../ModalWindowCashierClose/ModalWindowCashierClose';
import './style.scss';
import { CashierContext } from '../CashierContext';
import { isUndefined } from '../../../utils/isUndefined';
import AlertSnackbar from '../../common/Snackbar/AlertSnackbar';

const SideBarCashierInfo = () => {
  const {
    user: {
      lastname,
      firstname,
      id: userId
    },
    cashierData: {
      id,
      city: {
        title: cityTitle
      },
      title,
      address,
      isActive,
      department_active_session: departmentAtvSession
    },
    availableDepartments,
    selectCashierByIndex
  } = useContext(AuthorisationContext);
  const { sessionData } = useContext(CashierContext);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalEndDay, setIsOpenModalEndDay] = useState(false);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);

  const onClickSetTime = useCallback(() => setIsOpenModal(true), []);
  const onHandleClose = useCallback(() => setIsOpenModal(false), []);
  const onClinkCloseDay = useCallback(() => setIsOpenModalEndDay(true), []);

  const onHandleCloseDay = useCallback((isCloseDay) => {
    setIsOpenModalEndDay(false);
    setIsOpenSnackbar(isCloseDay && availableDepartments.filter((dp) => dp?.department_active_session).length > 1);
  }, [availableDepartments]);
  const isAnotherUserLeftSession = departmentAtvSession?.user.id && (userId !== departmentAtvSession.user.id);

  const renderCashierData = useCallback(() => {
    if (!isUndefined(cityTitle) && !isUndefined(title) && !isUndefined(address)) {
      return (
        <>
          <span>{`${cityTitle}, ${address}`}</span>
          {availableDepartments.length === 1
            ? (<span>{availableDepartments[0].title}</span>)
            : (
              <form>
                <FormControl size="small" fullWidth>
                  <InputLabel>Змінити відділення</InputLabel>
                  <Select
                    size="small"
                    id="filial"
                    name="filial"
                    label="Змінити відділення"
                    placeholder="Змінити відділення"
                    disabled={availableDepartments.length <= 1}
                    value={availableDepartments.findIndex((e) => e.id === id)}
                    onChange={({ target: { value } }) => selectCashierByIndex(value)}
                  >
                    {availableDepartments.map((item, index) => (
                      <MenuItem key={item.id} value={index}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </form>
            )}
        </>
      );
    }
    return null;
  }, [cityTitle, title, address, availableDepartments, id, selectCashierByIndex]);

  const openedSession = useMemo(
    () => {
      if (isEmpty(departmentAtvSession)) {
        return (
          <>
            <Button
              variant="outlined"
              className="sidebar-cashier-btn"
              disabled={!isActive}
              onClick={onClickSetTime}
            >
              Відкрити день
            </Button>
            {!isActive && (<span>Відділення неактивне</span>)}
            <div className="sidebar-cashier-address" />
          </>
        );
      } if (isAnotherUserLeftSession) {
        return (
          <>
            <Button
              variant="outlined"
              className="sidebar-cashier-btn-disabled"
              disabled
              sx={{ mb: 1.5 }}
            >
              Сесія вже відкрита
            </Button>
            {/* {isAnotherUserLeftSession && (<span>Відділення вже має відкриту сесію</span>)} */}
            <Button
              variant="outlined"
              className="sidebar-cashier-btn"
              onClick={onClinkCloseDay}
            >
              Примусово закрити сесію
            </Button>
            <div className="sidebar-cashier-address" />
          </>
        );
      } return (
        <>
          <Button variant="outlined" className="sidebar-cashier-btn" onClick={onClinkCloseDay}>
            Закрити день
          </Button>
          <div className="sidebar-cashier-address">
            <span>
              Сесія відкрита
              {/* {isAnotherUserLeftSession && ' другим пользователем'} */}
              :
              <br />
              {isEmpty(sessionData) ? departmentAtvSession.start_time : sessionData.start_time}
            </span>
          </div>
        </>
      );
    },
    [sessionData, onClinkCloseDay, departmentAtvSession, onClickSetTime, isActive, isAnotherUserLeftSession]
  );

  return (
    <>
      <div className="sidebar-cashier">
        <div className="sidebar-cashier-user">
          <PersonIcon />
          <div className="sidebar-cashier-name">{`${lastname} ${firstname}`}</div>
        </div>
        {openedSession}
        <div className="sidebar-cashier-address">{renderCashierData()}</div>
        {!isOpenModal || <ModalWindowCashier open={isOpenModal} handleClose={onHandleClose} />}
        {!isOpenModalEndDay || <ModalWindowCashierClose open={isOpenModalEndDay} handleClose={onHandleCloseDay} />}
      </div>
      <AlertSnackbar
        onClose={() => setIsOpenSnackbar(false)}
        isOpen={isOpenSnackbar}
        success={false}
        message="У вас є активна сесія на іншому відділенні"
      />
    </>
  );
};

export default SideBarCashierInfo;
