import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, TableCell, TableRow, Tooltip } from '@mui/material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { AuthorisationContext } from '../../../components/AuthorisationContext';

const FilialsItems = ({ item, onOpenConfirmDelete, onChangeFilial, onSelectFilial, selectedFilial }) => {
  const { userRole } = useContext(AuthorisationContext);
  const { id, title } = item;
  const isSelected = useMemo(() => selectedFilial?.id === id, [id, selectedFilial]);
  return (
    <TableRow
      sx={[
        isSelected && {
          backgroundColor: 'var(--menu-button-active-bg) !important'
        }
      ]}
    >
      <TableCell
        style={{ cursor: 'pointer' }}
        onClick={() => onSelectFilial(id)}
        sx={[
          isSelected && {
            fontWeight: '600 !important',
            color: '#000 !important'
          }
        ]}
      >
        {id}
      </TableCell>
      <TableCell
        style={{ cursor: 'pointer' }}
        onClick={() => onSelectFilial(id)}
        align="left"
        sx={[
          isSelected && {
            fontWeight: '600 !important',
            color: '#000 !important'
          }
        ]}
      >
        {title}
      </TableCell>
      {userRole === 'Администратор' && (
      <TableCell
        padding="checkbox"
      >
        <ButtonGroup variant="contained">
          <Tooltip title="Редагувати">
            <Button onClick={() => onChangeFilial(item)} color="primary">
              <BorderColorOutlinedIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Видалити">
            <Button
              color="error"
              onClick={() => {
                onOpenConfirmDelete({ id });
                onSelectFilial(id);
              }}
            >
              <DeleteForeverIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>
      </TableCell>
      )}
    </TableRow>
  );
};

FilialsItems.propTypes = {
  item: PropTypes.object,
  onChangeFilial: PropTypes.func,
  onSelectFilial: PropTypes.func,
  onOpenConfirmDelete: PropTypes.func,
  selectedFilial: PropTypes.object
};

export default FilialsItems;
