import React, { useEffect, useCallback, useContext, useMemo, useState } from 'react';
import {
  Button, Table,
  TableBody, TableCell,
  TableHead, TableRow, Grid
} from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { nanoid } from 'nanoid';
import PageWrapper from '../../../common/PageWrapper/PageWrapper';
import { Category } from '../../../components/OtherExpensesArrivals/index';
import { DashboardContext } from '../../../components/DashboardContext';
import { AddExpensesCategory } from '../../../components/AddExpensesCategory/AddExpensesCategory';
import { AddExpenses } from '../../../components/AddExpenses/AddExpenses';
import ConfirmDelete from '../../../components/ConfirmDelete/ConfirmDelete';
import SettingsBackwardBtn from '../../../components/SettingsBackwardBtn/SettingsBackwardBtn';

export const OtherExpenses = () => {
  const { fetchOtherExpenses, expenses,
    clearExpensesCategoryErrors, fetchDeleteExpensesCategory, fetchDeleteExpenses
  } = useContext(DashboardContext);
  const [deleteCandidateId, setDeleteCandidateId] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectToDelete, setSelectToDelete] = useState(true);
  const [editCategoryButton, setEditCategoryButton] = useState({
    open: false,
    editMode: false,
    selectedCategory: {}
  });
  const [editExpenseButton, setEditExpenseButton] = useState({
    open: false,
    editMode: false,
    selectedExpense: {},
    selectedCategory: {}
  });
  useEffect(() => {
    fetchOtherExpenses();
  }, [fetchOtherExpenses]);

  const onHandleCloseCategory = () => {
    setEditCategoryButton({
      open: false,
      editMode: false,
      selectedCategory: {}
    });
    clearExpensesCategoryErrors();
  };

  const onHandleCloseExpense = () => {
    setEditExpenseButton({
      open: false,
      editMode: false,
      selectedCategory: {}
    });
    clearExpensesCategoryErrors();
  };

  const onChangeCategory = useCallback((item) => {
    setEditCategoryButton({
      open: true,
      editMode: true,
      selectedExpense: {},
      selectedCategory: item
    });
  }, []);

  const onAddExpense = useCallback((item) => {
    setEditExpenseButton({
      open: true,
      editMode: false,
      selectedCategory: item
    });
  }, []);

  const onChangeExpense = useCallback((category, item) => {
    setEditExpenseButton({
      open: true,
      editMode: true,
      selectedCategory: category,
      selectedExpense: item
    });
  }, []);

  const onOpenConfirmDelete = useCallback((id, deleteCategory) => {
    setSelectToDelete(deleteCategory);
    setDeleteCandidateId(id);
    setOpenConfirm(true);
  }, []);

  const deleteSelected = useCallback(() => {
    if (selectToDelete) {
      fetchDeleteExpensesCategory(deleteCandidateId).then((resp) => {
        if (resp === 'success') {
          setOpenConfirm(false);
        }
      });
    } else {
      fetchDeleteExpenses(deleteCandidateId).then((resp) => {
        if (resp === 'success') {
          setOpenConfirm(false);
        }
      });
    }
  }, [fetchDeleteExpensesCategory, fetchDeleteExpenses, deleteCandidateId, selectToDelete]);

  const renderExpenses = useMemo(() => expenses?.map(
    (category) => (
      <Category
        key={nanoid()}
        item={category}
        onChangeCategory={onChangeCategory}
        onOpenConfirmDelete={onOpenConfirmDelete}
        onAddItem={onAddExpense}
        onChangeItem={onChangeExpense}
      />
    )
  ), [expenses, onChangeCategory, onOpenConfirmDelete, onAddExpense, onChangeExpense]);

  return (
    <PageWrapper>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item>
          <SettingsBackwardBtn />
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              setEditCategoryButton(
                {
                  open: true,
                  editMode: false,
                  selectedCategory: {}
                }
              );
            }}
            variant="contained"
            startIcon={<AddCircleRoundedIcon />}
          >
            Додати категорію
          </Button>
        </Grid>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: 50 }}>ID</TableCell>
            <TableCell>Категорія</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {renderExpenses}
        </TableBody>
      </Table>
      <AddExpensesCategory
        editMode={editCategoryButton.editMode}
        open={editCategoryButton.open}
        selectedCategory={editCategoryButton.selectedCategory}
        handleClose={onHandleCloseCategory}
        expense
      />
      {editExpenseButton.open && (
      <AddExpenses
        editMode={editExpenseButton.editMode}
        open={editExpenseButton.open}
        selectedCategory={editExpenseButton.selectedCategory}
        selectedExpense={editExpenseButton.selectedExpense}
        setEditExpenseButton={setEditExpenseButton}
        handleClose={onHandleCloseExpense}
        expense
      />
      )}
      <ConfirmDelete
        open={openConfirm}
        onClose={() => {
          setDeleteCandidateId('');
          setOpenConfirm(false);
        }}
        onClickButton={deleteSelected}
        isDisabled={false}
        label="Ви впевнені, що хочете видалити цю категорію?"
      />
    </PageWrapper>
  );
};
