import React, { useContext, useMemo } from 'react';
import isEmpty from 'lodash.isempty';
import { Route, Redirect, useLocation } from 'react-router-dom';
import Header from '../../components/Header/Header';
import HomePage from '../../pages/HomePage/HomePage';
import Profile from '../../pages/Profile/Profile';
import Settings from '../../pages/Settings/Settings';
import Users from '../../pages/Users/Users';
import { CashierContext } from '../../components/CashierContext';
import UserPage from '../../pages/Users/UserPage/UserPage';
import Cities from '../../pages/AdminSettings/Cities/Cities';
import Filials from '../../pages/AdminSettings/Filials/Filials';
import Inkassators from '../../pages/AdminSettings/Inkassators/Inkassators';
import { Inkassator } from '../../pages/AdminSettings/Inkassators/Inkassator';
import Department from '../../pages/AdminSettings/Department/Department';
import Currency from '../../pages/AdminSettings/Currency/Currency';
import CashTransit from '../../pages/AdminSettings/CashTransit/CashTransit';
import BuyAndSellPage from '../../pages/BuyAndSellPage/BuyAndSellPage';
import BalanceCashierPage from '../../pages/BalanceCashierPage/BalanceCashierPage';
import CrossCoursePage from '../../pages/CrossCoursePage/CrossCoursePage';
import OperationsListPage from '../../pages/OperationsListPage/OperationsListPage';
import DepartmentBalance from '../../pages/AdminSettings/Department/DepartmentBalance';
import AdminOperations from '../../pages/AdminSettings/AdminOperations/AdminOperations';
import CashTransitCahierPage from '../../pages/CashTransitCahierPage/CashTransitCahierPage';
import Balance from '../../pages/AdminSettings/Balance/Balance';
import AdminMessages from '../../pages/AdminMessages/AdminMessages';
import { UserMessages } from '../../pages/UserMessages/UserMessages';
import { AuthorisationContext } from '../../components/AuthorisationContext';
import { Reports } from '../../pages/AdminSettings/Reports/Reports';
import { CashierReport } from '../../pages/Report/CashierReport';
import { AdminSessionReport } from '../../pages/Report/AdminReport';
import { SideBarAdminMenu } from '../../components/SideBar/SideBarAdminMenu';
import { SideBarCashierMenu } from '../../components/SideBar/SideBarCashierMenu';
import { SideBarAccountantMenu } from '../../components/SideBar/SideBarAccountantMenu';
import { OtherExpenses } from '../../pages/AdminSettings/OtherExpenses/OtherExpenses';
import { OtherExpensesCashierPage } from '../../pages/OtherExpensesCashierPage/OtherExpensesCashierPage';
import { SalaryPage } from '../../pages/Salary/Salary';
import { Variable } from '../../pages/AdminSettings/Variable/Variable';
import { Sessions } from '../../pages/SessionsPage/Sessions';
import { OtherArrivals } from '../../pages/AdminSettings/OtherArrivals';
import { OtherArrivalsCashierPage } from '../../pages/OtherArrivalsCashierPage';
import { CashierCurrency } from '../../pages/Cashier/Currency';

const sessionOnRouts = [
  '/cashier-buy',
  '/cashier-sell',
  '/cashier-cross-course',
  '/cashier-operations-list',
  '/cashier-currency',
  '/cashier-balance',
  '/cashier-cash-in-transit',
  '/cashier-other-expenses',
  '/cashier-other-arrivals',
  '/messages'
];

export const PrivateRoutes = () => {
  const { sessionData } = useContext(CashierContext);
  const { token, userRole, cashierData } = useContext(AuthorisationContext);
  const { pathname } = useLocation();
  if (!token) {
    return <Redirect to="/auth" />;
  }
  if (userRole === 'Кассир' && isEmpty(sessionData) && sessionOnRouts.includes(pathname)) {
    return <Redirect to="/" />;
  }

  const renderSideBarMenu = useMemo(() => {
    if (userRole === 'Администратор') return <SideBarAdminMenu />;
    if (userRole === 'Бухгалтер') return <SideBarAccountantMenu />;
    if (userRole === 'Кассир') return <SideBarCashierMenu />;
    return '';
  }, [userRole]);

  return (
    <div style={{ padding: '0 20px', height: '100%' }}>
      <Route exact path="/cashier-report/:id" component={CashierReport} />
      <Route exact path="/session-report/:id" component={AdminSessionReport} />
      <div className="dashboard">
        {!['/session-report/', '/cashier-report'].some((path) => pathname.includes(path))
             && (
             <>
               <Header />
               {renderSideBarMenu}
             </>
             )}
        <div className="page" style={{ gridArea: 'page' }}>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/balance" component={Balance} />
          <Route exact path="/cashier-currency" component={CashierCurrency} />
          <Route exact path="/reports/:report?" component={Reports} />
          <Route exact path="/balance/departments/:id" component={DepartmentBalance} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/cash-in-transit" component={CashTransit} />
          <Route exact path="/settings/users" component={Users} />
          <Route exact path="/settings/inkassators" component={Inkassators} />
          <Route exact path="/settings/inkassators/:id" component={Inkassator} />
          <Route exact path="/settings/cities" component={Cities} />
          <Route exact path="/settings/filials" component={Filials} />
          <Route exact path="/settings/users/:id" component={UserPage} />
          <Route exact path="/settings/departments" component={Department} />
          <Route exact path="/settings/other-expenses" component={OtherExpenses} />
          <Route exact path="/settings/other-arrivals" component={OtherArrivals} />
          <Route exact path="/settings/variable" component={Variable} />
          <Route exact path="/admin/currency" component={Currency} />
          <Route exact path="/admin/operations/:id?" component={AdminOperations} />
          <Route exact path="/admin/sessions" component={Sessions} />
          <Route exact path="/admin/salary" component={SalaryPage} />
          <Route exact path="/settings/departments/:id" component={DepartmentBalance} />
          <Route exact path="/cashier-buy">
            <BuyAndSellPage isBuy />
          </Route>
          <Route exact path="/cashier-sell">
            <BuyAndSellPage />
          </Route>
          <Route exact path="/cashier-cross-course" component={CrossCoursePage} />
          <Route exact path="/cashier-operations-list" component={OperationsListPage} />
          <Route exact path={cashierData?.isCentral ? '/cashier-balance-central' : '/cashier-balance'} component={BalanceCashierPage} />
          <Route exact path="/cashier-other-expenses" component={OtherExpensesCashierPage} />
          <Route exact path="/cashier-other-arrivals" component={OtherArrivalsCashierPage} />
          <Route exact path="/cashier-cash-in-transit" component={CashTransitCahierPage} />
          <Route exact path="/admin/messages" component={AdminMessages} />
          <Route exact path="/messages" component={UserMessages} />
        </div>
      </div>
    </div>
  );
};
