import { useState, useCallback, useMemo } from 'react';

export const useDynamicForm = (defaultState = {}) => {
  const [data, setData] = useState({ ...defaultState });
  const updateSelectField = (key) => ({ target: { value } }) => {
    setData((prevState) => ({ ...prevState, [key]: value }));
  };
  const multiSet = (saveField = {}) => {
    setData((prevState) => ({ ...prevState, ...saveField }));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const clearData = useCallback((saveField = {}) => setData({ ...defaultState, ...saveField }), []);
  const isAllEmpty = useMemo(() => (Object.keys(data).some((key) => typeof data[key] !== 'number' && !data[key])), [data]);
  return { data, updateSelectField, clearData, isAllEmpty, multiSet };
};
