import { API_URLS } from '../../constants/urls';
import CRUDService from '../CRUDService';

class VariablesService extends CRUDService {
  constructor() {
    super(API_URLS.VARIABLE);
  }
}

export default new VariablesService();
