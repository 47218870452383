import React, { useState, useCallback, useContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, InputLabel, Select, MenuItem, Grid
} from '@mui/material';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import ModalWindow from '../../../../components/ModalWindow/ModalWindow';
import AlertSnackbar from '../../../../common/Snackbar/AlertSnackbar';
import { DashboardContext } from '../../../../components/DashboardContext';
import { MenuProps } from '../consts';

const ModalWindowAddEditInkas = ({
  open,
  handleClose,
  inkassators,
  currentSession,
  setReFetch,
  editedInkas
}) => {
  const { id: orderId, inkassator = null } = editedInkas || {};
  const { fetchPutOrderInkas, isFetchingPutOrderInkas } = useContext(DashboardContext);
  const [selectedInkassator, setInkassator] = useState('');
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const onChangeInkassator = useCallback((e) => {
    setInkassator(e.target.value);
    setIsDisabled(false);
  }, []);

  useEffect(() => {
    if (inkassator) setInkassator(inkassator.id);
  }, [inkassator]);

  const onChangeStatus = useCallback(async () => {
    await fetchPutOrderInkas({
      current_session: currentSession,
      id: orderId,
      inkasator_id: selectedInkassator
    });
    setIsOpenSnackbar(true);
    handleClose();
    setReFetch((prevState) => !prevState);
    setInkassator('');
    setIsDisabled(true);
  }, [fetchPutOrderInkas, currentSession, orderId, handleClose,
    setReFetch, selectedInkassator]);

  const renderInkassators = useMemo(() => inkassators?.map((item) => (
    <MenuItem key={item.id} value={item.id}>{`${item.firstname} ${item.lastname}`}</MenuItem>
  )), [inkassators]);

  const onClose = () => {
    handleClose();
    setIsDisabled(true);
    setInkassator('');
  };

  return (
    <>
      <ModalWindow open={open} onClose={() => onClose()} maxWidth="500px">
        <div className="addUsers addPopup">
          <CancelPresentationRoundedIcon className="close-btn" onClick={() => onClose()} />
          <div className="new-date">{inkassator ? 'Змінити інкасатора' : 'Призначити інкасатора'}</div>
          <Grid container rowSpacing={1} columnSpacing={{ md: 1 }} alignItems="center" direction="column">
            <Grid item md={12}>
              <FormControl sx={{ minWidth: 240 }}>
                <InputLabel>Інкасатор</InputLabel>
                <Select label="Інкасатор" MenuProps={MenuProps} value={selectedInkassator} onChange={onChangeInkassator}>
                  {renderInkassators}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <LoadingButton
                sx={{ minWidth: 240 }}
                size="normal"
                loading={isFetchingPutOrderInkas}
                loadingPosition="end"
                endIcon={<SendRoundedIcon />}
                onClick={onChangeStatus}
                disabled={isDisabled}
                variant="contained"
              >
                Підтвердити
              </LoadingButton>
            </Grid>
          </Grid>
        </div>
      </ModalWindow>
      <AlertSnackbar
        onClose={() => setIsOpenSnackbar(false)}
        isOpen={isOpenSnackbar}
        success
        message="Статус операции успешно изменён"
      />
    </>
  );
};

ModalWindowAddEditInkas.propTypes = {
  open: PropTypes.bool,
  inkassators: PropTypes.array,
  handleClose: PropTypes.func,
  setReFetch: PropTypes.func,
  currentSession: PropTypes.number,
  editedInkas: PropTypes.object
};

export default ModalWindowAddEditInkas;
