import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import './style.scss';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import ukLocale from 'date-fns/locale/uk';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import CurrencyItem from './CurrencyItem';
import PageWrapper from '../../../common/PageWrapper/PageWrapper';
import { AuthorisationContext } from '../../../components/AuthorisationContext';
import { CashierContext } from '../../../components/CashierContext';

export const CashierCurrency = () => {
  const {
    cashierCurrency, fetchCashierCurrency, isEditableCurrency,
    fetchEditCashierCurrency, cashierCurrencyEditing
  } = useContext(CashierContext);
  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isEditable, setIsEditable] = useState(false);
  const { cashierData } = useContext(AuthorisationContext);
  const [rates, setRates] = useState(null);
  const [titles, setTitles] = useState(null);
  const [isValidFields, setIsValidFields] = useState({});

  useEffect(() => {
    if (!isEmpty(rates)) {
      setTitles(() => rates.map((item) => item.title));
    } else {
      setTitles(() => cashierCurrency.map((item) => item.title));
    }
  }, [rates, cashierCurrency]);

  useEffect(() => {
    const now = new Date();
    const selDate = date.setHours(0, 0, 0, 0);
    const currentDate = now.setHours(0, 0, 0, 0);

    if (selDate >= currentDate && !isEmpty(cashierCurrency) && isEditableCurrency) {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }
  }, [date, cashierCurrency, isEditableCurrency]);

  useEffect(() => {
    fetchCashierCurrency({
      department_id: cashierData.id,
      date: moment(selectedDate.valueOf()).format('YYYY-MM-DD'),
      id: cashierData.id
    });
  }, [selectedDate, cashierData, fetchCashierCurrency]);

  useEffect(() => {
    setRates(
      cashierCurrency.map(({ id, buyRate, sellRate, title }) => ({
        department_id: cashierData ? cashierData.id : 0,
        id,
        buyRate,
        sellRate,
        title,
        date: moment().format('YYYY-MM-DD')
      }))
    );
  }, [cashierData, cashierCurrency]);

  const handleEditCur = useCallback((value) => {
    setRates((prev) => prev.map((e) => (e.id === value.id ? { ...e, ...value } : e)));
  }, []);

  const handleValidField = useCallback((validRow) => {
    setIsValidFields((prev) => ({ ...prev, [validRow.id]: validRow }));
  }, []);

  const renderCurrencies = useMemo(() => rates?.map((item) => (
    <CurrencyItem
      item={item}
      key={item.id}
      isEditable={isEditable}
      handleEditCur={handleEditCur}
      handleValidField={handleValidField}
      titles={titles}
    />
  )), [rates, isEditable, handleEditCur, handleValidField, titles]);

  const onUpdateCur = useCallback(() => {
    fetchEditCashierCurrency(
      {
        pc_token: cashierData.pc_token,
        data: rates
      },
      {
        department_id: cashierData.id,
        date: moment(selectedDate.valueOf()).format('YYYY-MM-DD'),
        id: cashierData.id
      });
  }, [rates, cashierData, fetchEditCashierCurrency, selectedDate]);

  return (
    <PageWrapper>
      <div className="currency">
        <div className="currency-actions">
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ukLocale}>
            <MobileDatePicker
              value={date}
              closeOnSelect
              showTodayButton
              okText="Обрати"
              todayText="Сьогодні"
              cancelText="Скасувати"
              maxDate={new Date()}
              label="Оберіть дату"
              onChange={(newValue) => setDate(newValue)}
              onAccept={(selected) => setSelectedDate(selected)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <div className="currency__buttons">
            {isEditable && (
              <LoadingButton
                variant="contained"
                loading={cashierCurrencyEditing}
                color="success"
                disabled={Object.values(isValidFields).some(({ isValid }) => !isValid)}
                size="large"
                onClick={onUpdateCur}
              >
                <SystemUpdateAltIcon sx={{ marginRight: '10px' }} />
                Встановити
              </LoadingButton>
            )}
          </div>
        </div>
        <div className="currency-table">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell sx={{ px: '14px' }} component="th" align="left">
                    Валюта
                  </TableCell>
                  <TableCell component="th" align="left">
                    Купівля
                  </TableCell>
                  <TableCell component="th" align="left">
                    Продаж
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderCurrencies}</TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </PageWrapper>
  );
};
