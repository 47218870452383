import React, { useEffect, useContext, useState, useCallback, useRef, useMemo } from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import {
  Button, FormControl, FormHelperText, MenuItem, IconButton,
  InputLabel, InputAdornment, Select, TextField, Tooltip
} from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useFormik } from 'formik';
import moment from 'moment';
import { nanoid } from 'nanoid';
import validationSchemaSellBuy from './validateForm';
import PageWrapper from '../../common/PageWrapper/PageWrapper';
import { CashierContext } from '../../components/CashierContext';
import { AuthorisationContext } from '../../components/AuthorisationContext';
import { isUndefined } from '../../../utils/isUndefined';
import ModalWindowCashierConfirm from '../../components/ModalWindowCashierConfirm/ModalWindowCashierConfirm';
import { MenuProps } from './const';

const BuyAndSellPage = ({ isBuy }) => {
  const { cashierData } = useContext(AuthorisationContext);
  const { cashierCurrency, fetchCashierCurrency, sessionData } = useContext(CashierContext);
  const [isEdit, setIsEdit] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const ourCur = isUndefined(cashierCurrency.find(({ title }) => title === 'UAH'))
    ? null
    : cashierCurrency.find((item) => item.title === 'UAH').id;

  const formData = useRef({});

  const onSubmitForm = useCallback((values) => {
    const { currencyTitle, currencyValue, sum, totalCash, comment } = values;
    setIsOpenModal(true);
    const operationType = isBuy ? 'Покупка' : 'Продажа';
    const arriveCurr = isBuy ? currencyTitle : ourCur;
    const expCurr = isBuy ? ourCur : currencyTitle;
    formData.current = {
      current_session: sessionData.id,
      arrival_value: isBuy ? sum : Number(totalCash),
      rate: currencyValue,
      expense_currency_id: expCurr,
      operation_type: operationType,
      arrival_currency_id: arriveCurr,
      expense_value: isBuy ? Number(totalCash) : sum
    };
    if (comment !== '') { formData.current.comment = comment; }
  }, [ourCur, isBuy, sessionData.id]);

  const formik = useFormik({
    initialValues: {
      currencyTitle: '',
      currencyValue: '',
      sum: '',
      totalCash: '',
      comment: ''
    },
    validationSchema: validationSchemaSellBuy,
    onSubmit: (values) => {
      onSubmitForm(values);
    }
  });

  useEffect(() => {
    fetchCashierCurrency({
      department_id: cashierData.id,
      date: moment().format('YYYY-MM-DD'),
      id: cashierData.id
    });
  }, [fetchCashierCurrency, cashierData]);

  const renderCurrencies = useMemo(() => cashierCurrency?.filter((item) => (item.title !== 'UAH')).map((item) => (
    <MenuItem key={nanoid()} value={item.id}>{item.title}</MenuItem>
  )), [cashierCurrency]);

  const handelEditField = () => {
    setIsEdit((prevState) => !prevState);
  };
  const totalCash = (currencyValue = formik.values.currencyValue) => {
    if (formik.values.sum && currencyValue) {
      return Math.round((formik.values.sum * currencyValue).toFixed(2) * 10) / 10;
    } return '';
  };

  useEffect(() => {
    formik.handleReset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashierData]);

  return (
    <PageWrapper>
      <div className="buy-sell-page-title">{isBuy ? 'Купівля валюти' : 'Продаж валюти'}</div>
      <form className="buy-sell-page-form" onSubmit={formik.handleSubmit}>
        <div className="buy-sell-page-input">
          <div className="addUsers-two cross-course-two-inputs">
            <FormControl fullWidth size="normal">
              <InputLabel>Валюта</InputLabel>
              <Select
                required
                autoWidth
                className="buy-sell-page-currencyTitle"
                id="currencyTitle"
                name="currencyTitle"
                label="Валюта"
                placeholder="Валюта"
                value={formik.values.currencyTitle}
                MenuProps={MenuProps}
                onChange={(e) => {
                  const value = cashierCurrency.find(({ id }) => id === e.target.value)[isBuy ? 'buyRate' : 'sellRate'];
                  formik.setFieldValue('currencyValue', value);
                  formik.handleChange(e);
                  formik.values.totalCash = totalCash(value);
                }}
              >
                {renderCurrencies}
              </Select>
              {(formik.touched.currencyTitle && formik.errors.currencyTitle) && (
              <FormHelperText error>{formik.errors.currencyTitle}</FormHelperText>)}
            </FormControl>
            <FormControl fullWidth size="normal">
              <TextField
                type="number"
                onWheel={(e) => e.target.blur()}
                disabled={!isEdit}
                InputProps={{
                  endAdornment:
  <InputAdornment position="end">
    <Tooltip title="Редагування курсу для однієї операції" placement="right">
      <IconButton onClick={handelEditField} disabled={formik.values.currencyValue === ''}>
        <EditRoundedIcon color={isEdit ? 'primary' : 'disabled'} />
      </IconButton>
    </Tooltip>
  </InputAdornment>,
                  inputProps: {
                    min: 0.01,
                    step: 0.001
                  }
                }}
                required
                id="currencyValue"
                name="currencyValue"
                label={`Курс на ${moment().format('D.MM.YYYY')}`}
                value={formik.values.currencyValue}
                onChange={(e) => {
                  formik.values.currencyValue = e.target.value.replace(/[^0-9,.]/g, '');
                  formik.handleChange(e);
                  formik.values.totalCash = totalCash();
                }}
              />
              {(formik.touched.currencyValue && formik.errors.currencyValue) && (
                <FormHelperText error>{formik.errors.currencyValue}</FormHelperText>)}
            </FormControl>
          </div>
        </div>
        <div className="buy-sell-page-input">
          <div className="addUsers-two cross-course-two-inputs">
            <FormControl fullWidth size="normal">
              <TextField
                required
                id="sum"
                name="sum"
                label="Сума"
                type="number"
                onWheel={(e) => e.target.blur()}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {!isUndefined(cashierCurrency.find((item) => item.id === formik.values.currencyTitle))
                        && cashierCurrency.find((item) => (
                          item.id === formik.values.currencyTitle
                        )).title.toUpperCase()}
                    </InputAdornment>
                  ),
                  inputProps: {
                    min: 0.01,
                    step: 0.01
                  }
                }}
                value={formik.values.sum}
                onChange={(e) => {
                  formik.values.sum = e.target.value;
                  formik.handleChange(e);
                  formik.values.totalCash = totalCash();
                }}
              />
              {(formik.touched.sum && formik.errors.sum) && (
                <FormHelperText error>{formik.errors.sum}</FormHelperText>)}
            </FormControl>
            <FormControl fullWidth size="normal">
              <TextField
                className="buy-sell-page-totalCash"
                id="totalCash"
                name="totalCash"
                label="Підсумок:"
                type="number"
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">UAH</InputAdornment>
                  ),
                  inputProps: {
                    min: 0.01,
                    step: 0.01
                  }
                }}
                value={formik.values.totalCash}
                onChange={formik.handleChange}
              />
              {(formik.touched.totalCash && formik.errors.totalCash) && (
                <FormHelperText error>{formik.errors.totalCash}</FormHelperText>)}
            </FormControl>
          </div>
        </div>
        <div className="buy-sell-page-input">
          <FormControl fullWidth size="normal">
            <TextField
              type="text"
              multiline
              rows={4}
              id="comment"
              name="comment"
              label="Коментар"
              value={formik.values.comment}
              onChange={formik.handleChange}
              error={formik.touched.comment && Boolean(formik.errors.comment)}
              helperText={formik.touched.comment && formik.errors.comment}
            />
          </FormControl>
        </div>
        <div className="addUsers-actions">
          <Button variant="contained" type="submit">Оформити</Button>
        </div>
      </form>
      {isOpenModal
      && (
      <ModalWindowCashierConfirm
        open={isOpenModal}
        data={formData.current}
        handleClose={() => setIsOpenModal(false)}
      />
      )}
    </PageWrapper>
  );
};

BuyAndSellPage.propTypes = {
  isBuy: PropTypes.bool
};

export default BuyAndSellPage;
