import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { Button, MenuItem, FormControl, Select, InputLabel, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import './style.scss';
import PropTypes from 'prop-types';
import { DashboardContext } from '../../DashboardContext';
import { MenuProps } from '../../../pages/AdminSettings/Reports/consts';
import { AdminContext } from '../../AdminContext/AdminContext';

export const TokenBinding = ({ userTokenBinding, setUserTokenBinding }) => {
  const { filials, fetchFilials, departments, fetchDepartments } = useContext(DashboardContext);
  const { addToken, isPostingToken } = useContext(AdminContext);
  const { fio, pc_token: token, phone } = JSON.parse(userTokenBinding.data.message);
  const [selectedValues, setSelectedValues] = useState(
    {
      filialId: '',
      isCentralSelected: null,
      departmentId: []
    });

  const changeValue = (filterValue) => (e) => {
    setSelectedValues(
      (PrevState) => ({ ...PrevState, [filterValue]: e.target.value, departmentId: [] })
    );
  };
  const handleChange = ({ target: { value } }) => {
    const isCentral = departments.find(({ id }) => id === value[0])?.isCentral;
    const isCentralSelected = typeof isCentral === 'boolean' ? isCentral : null;
    setSelectedValues((prevState) => (
      { ...prevState, departmentId: value, isCentralSelected })
    );
  };

  useEffect(() => {
    fetchFilials();
  }, [fetchFilials]);

  useEffect(() => {
    fetchDepartments({
      filial_id: selectedValues.filialId
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues.filialId]);

  const renderFilials = useMemo(() => filials?.map(({ id, title }) => (
    <MenuItem key={id} value={id}>{title}</MenuItem>
  )),
  [filials]);

  const renderDepartments = useMemo(() => departments?.map(({ id, title, isCentral }) => {
    const disableMenu = (selectedValues.departmentId.length >= 2
    || isCentral === selectedValues.isCentralSelected)
    && selectedValues.departmentId.every((item) => item !== id);
    return <MenuItem disabled={disableMenu} key={id} value={id}>{title}</MenuItem>;
  }),
  [departments, selectedValues]);

  const handleButton = useCallback(() => {
    addToken({
      departments: selectedValues.departmentId,
      token
    }).then((resp) => {
      if (resp === 'success') {
        setUserTokenBinding({ data: {}, open: false });
      }
    });
  }, [addToken, selectedValues, token, setUserTokenBinding]);

  return (
    <div className="modalNotification">
      <div className="modalNotification__text">
        <p className="modalNotification__title">{`Користувач: ${fio}`}</p>
        <p className="modalNotification__title">{`Телефон: ${phone}`}</p>
        <p className="modalNotification__title modalNotification__token">{`Токен: ${token}`}</p>
        <p>Прив&apos;язати до відділення:</p>

        <Grid sx={{ mt: 0, pt: 0 }} container spacing={2} alignItems="center" justifyContent="center">
          <Grid item md={12}>
            <FormControl className="operation-list-selector" fullWidth>
              <InputLabel>Регіон</InputLabel>
              <Select
                MenuProps={MenuProps}
                label="Регіон"
                value={selectedValues.filialId}
                onChange={changeValue('filialId')}
              >
                {renderFilials}
              </Select>
            </FormControl>

          </Grid>
          <Grid item md={12}>
            <FormControl className="operation-list-selector" fullWidth>
              <InputLabel>Відділення</InputLabel>
              <Select
                MenuProps={MenuProps}
                disabled={selectedValues.filialId === ''}
                multiple
                label="Відділення"
                value={selectedValues.departmentId}
                onChange={handleChange}
              >
                {renderDepartments}
              </Select>
            </FormControl>

          </Grid>
        </Grid>
      </div>
      <div className="modalNotification__footer">
        <div className="btn">
          <Button
            color="error"
            onClick={() => {
              setUserTokenBinding({ data: {}, open: false });
            }}
          >
            Скасувати
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            loading={isPostingToken}
            disabled={selectedValues.departmentId.length === 0}
            onClick={() => handleButton()}
          >
            Прив&apos;язати
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};
TokenBinding.propTypes = {
  userTokenBinding: PropTypes.object,
  setUserTokenBinding: PropTypes.func
};
