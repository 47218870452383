import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell,
  TableHead, TableRow
} from '@mui/material';

export const LimitTable = ({ reportLimit }) => {
  const renderRow = useMemo(() => reportLimit?.map(
    ({ department_title: department, date, limit, value, difference }) => (
      <TableRow key={department + date}>
        <TableCell>{department}</TableCell>
        <TableCell>{date}</TableCell>
        <TableCell>{limit}</TableCell>
        <TableCell>{value}</TableCell>
        <TableCell>{difference}</TableCell>
      </TableRow>
    )), [reportLimit]);
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Відділення</TableCell>
          <TableCell>Дата</TableCell>
          <TableCell>Ліміт</TableCell>
          <TableCell>UAH</TableCell>
          <TableCell>Різниця</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {renderRow}
      </TableBody>
    </Table>
  );
};

LimitTable.propTypes = {
  reportLimit: PropTypes.array
};
