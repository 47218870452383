import React, { useCallback, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, Grid, Stack,
  MenuItem, InputLabel, Select
} from '@mui/material';
import { DashboardContext } from '../../../components/DashboardContext';
import StepTwoBuySellOperation from './StepTwoBuySellOperation';
import StepTwoCrossCourseOperation from './StepTwoCrossCourseOperation';
import StepTwoTotalTable from './StepTwoTotalTable';

const StepTwo = ({
  removeOperation,
  editOperation,
  setErrorMassage,
  isEditOperation,
  setIsEditOperation,
  isCentral,
  onAddedToOperationList,
  cashierCurrency,
  cashTransfer: {
    department_receiver_id: selectedDepartment,
    inkas_id: selectedInkassator,
    order_items: orderItems
  }
}) => {
  const { departments, inkassators } = useContext(DashboardContext);
  const [selectedTransitType, setSelectedTransitType] = useState('');
  const onChangeSelectedTransitType = useCallback((e) => setSelectedTransitType(e.target.value), []);

  const incassator = useMemo(
    () => inkassators.find(({ id }) => id === selectedInkassator),
    [inkassators, selectedInkassator]
  );

  const onAdded = (key1, key2, key3, key4, key5) => (obj) => {
    const data = {};
    if (key1) data[key1] = obj.currencyId;
    if (key2) data[key2] = +obj.value;
    if (key3) data[key3] = obj.secondCurrencyId;
    if (key4) data[key4] = +obj.secondValue;
    if (key5) data[key5] = +obj.rate;
    onAddedToOperationList({
      ...data,
      order_item_type: selectedTransitType,
      localId: obj.localId
    });
    setSelectedTransitType('');
  };

  const selectedOperation = useMemo(() => {
    if (isCentral) {
      if (selectedTransitType === 'Инкассация') {
        return (
          <StepTwoBuySellOperation
            keyName="arrival_currency_id"
            onAdded={onAdded('arrival_currency_id', 'arrival_value')}
            {...{ cashierCurrency, selectedTransitType, orderItems }}
          />
        );
      }
      if (selectedTransitType === 'Подкрепление') {
        return (
          <StepTwoBuySellOperation
            keyName="expense_currency_id"
            onAdded={onAdded('expense_currency_id', 'expense_value')}
            {...{ cashierCurrency, selectedTransitType, orderItems }}
          />
        );
      }
      if (selectedTransitType === 'Выкуп') {
        return (
          <StepTwoCrossCourseOperation
            titles={['Прихід:', 'Розхід:']}
            onAdded={onAdded('expense_currency_id', 'expense_value', 'arrival_currency_id', 'arrival_value', 'rate')}
            cashierCurrency={cashierCurrency}
          />
        );
      }
    } else {
      if (selectedTransitType === 'Инкассация') {
        return (
          <StepTwoBuySellOperation
            keyName="expense_currency_id"
            onAdded={onAdded('expense_currency_id', 'expense_value')}
            cashierCurrency={cashierCurrency}
            selectedTransitType={selectedTransitType}
            orderItems={orderItems}
          />
        );
      }
      if (selectedTransitType === 'Подкрепление') {
        return (
          <StepTwoBuySellOperation
            keyName="arrival_currency_id"
            onAdded={onAdded('arrival_currency_id', 'arrival_value')}
            cashierCurrency={cashierCurrency}
            selectedTransitType={selectedTransitType}
            orderItems={orderItems}
          />
        );
      }
      if (selectedTransitType === 'Выкуп') {
        return (
          <StepTwoCrossCourseOperation
            titles={['Розхід:', 'Прихід:']}
            onAdded={onAdded('arrival_currency_id', 'arrival_value', 'expense_currency_id', 'expense_value', 'rate')}
            {...{ cashierCurrency }}
          />
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTransitType, cashierCurrency]);

  const departmentTitle = useMemo(() => {
    const { title } = departments.find(({ id }) => id === selectedDepartment) || { title: '' };
    return title;
  }, [departments, selectedDepartment]);

  return (
    <>
      <div className="filters-table-wrapper">
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1} />
          <Grid item xs={4}>
            <Stack spacing={4}>
              <div>
                <div>{`Відділення: ${departmentTitle}`}</div>
                <div>{incassator ? `Інкасатор: ${incassator.firstname} ${incassator.lastname}` : ''}</div>
              </div>
              <div className="department-filters">
                <div className="department-filters-item">
                  <div className="department-filters-item-input">
                    <FormControl sx={{ minWidth: 250 }}>
                      <InputLabel>Тип заявки</InputLabel>
                      <Select label="Тип заявки" value={selectedTransitType} onChange={onChangeSelectedTransitType}>
                        <MenuItem value="Инкассация">{isCentral ? 'Інкасація (Надходження)' : 'Інкасація (Витрата)'}</MenuItem>
                        <MenuItem value="Подкрепление">{isCentral ? 'Підкріплення (Витрата)' : 'Підкріплення (Надходження)'}</MenuItem>
                        <MenuItem value="Выкуп">Викуп(Обмін)</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </Stack>
          </Grid>
          <Grid item xs={1} />
          {selectedOperation}
        </Grid>
      </div>

      {orderItems.length
        ? (
          <StepTwoTotalTable
            setIsEditOperation={setIsEditOperation}
            isEditOperation={isEditOperation}
            orderItems={orderItems}
            cashierCurrency={cashierCurrency}
            removeOperation={removeOperation}
            editOperation={editOperation}
            setErrorMassage={setErrorMassage}
          />
        ) : ''}
    </>
  );
};

StepTwo.propTypes = {
  cashierDataId: PropTypes.number,
  isCentral: PropTypes.bool,
  cashTransfer: PropTypes.object,
  onAddedToOperationList: PropTypes.func.isRequired,
  removeOperation: PropTypes.func.isRequired,
  editOperation: PropTypes.func.isRequired,
  setErrorMassage: PropTypes.func.isRequired,
  setIsEditOperation: PropTypes.func.isRequired,
  isEditOperation: PropTypes.string,
  cashierCurrency: PropTypes.array
};

export default StepTwo;
