import React from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './style.scss';

const SettingsBackwardBtn = () => {
  const history = useHistory();
  return (
    <div onClick={history.goBack} className="settings-backward-btn">
      <ArrowBackIcon />
      <span>Назад</span>
    </div>
  );
};

export default SettingsBackwardBtn;
