import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, TableCell, TableRow, Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { isNull } from '../../../../utils/isNull';
import { AuthorisationContext } from '../../../components/AuthorisationContext';

const DepartmentsItems = ({ item, onSelectDep }) => {
  const { userRole } = useContext(AuthorisationContext);
  const history = useHistory();
  const { id, city, title, filial, isActive } = item;

  const onHandleEdit = useCallback(() => {
    onSelectDep(item);
  }, [item, onSelectDep]);

  const redirectToBalance = useCallback(() => {
    history.push(`/settings/departments/${id}`);
  }, [id, history]);

  return (
    <TableRow>
      <TableCell align="left" padding="checkbox">{id}</TableCell>
      <TableCell>{!isNull(filial) ? filial[0].title : '-'}</TableCell>
      <TableCell>{city[0].title}</TableCell>
      <TableCell>{title}</TableCell>
      <TableCell align="left">{isActive ? 'Активнe' : 'Неактивнe'}</TableCell>
      { userRole === 'Администратор'
        && (
        <TableCell align="center">
          <ButtonGroup>
            <Tooltip title="Редагувати">
              <Button onClick={onHandleEdit} variant="contained">
                <BorderColorOutlinedIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Баланси">
              <Button onClick={redirectToBalance} variant="contained" color="warning">
                <AccountBalanceWalletIcon />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </TableCell>
        )}
    </TableRow>
  );
};

DepartmentsItems.propTypes = {
  item: PropTypes.object,
  onSelectDep: PropTypes.func
};

export default DepartmentsItems;
