import React, { useContext, useEffect, useReducer, useState } from 'react';
import { NotificationContext } from './notificationContext';
import { reducer, initState } from './reducer';
import { init, initAdmin, sender, NotificationApi } from '../../../services/NOTIFICATION/NotificationService';
import { AuthorisationContext } from '../AuthorisationContext';
import { Modal } from '../Modal/Modal';
import { Notification } from './Notification/Notification';
import AlertSnackbar from '../../common/Snackbar/AlertSnackbar';
import { TokenBinding } from './TokenBinding/TokenBinding';

import {
  setCashierNotification,
  setCashierUnreadRequireNotification,
  setCashierOrderUnreadRequireNotification
} from './action';

export const NotificationProvider = ({ children }) => {
  const { user, cashierData, logout } = useContext(AuthorisationContext);
  const [state, dispatch] = useReducer(reducer, initState);
  const [isWait, setWait] = useState(false);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [snackbarType, setSnackbarType] = useState(false);
  const [reFetchOrders, setReFetchOrders] = useState(false);
  const [userTokenBinding, setUserTokenBinding] = useState({ data: {}, open: false });

  const { id, role } = user || {};
  const getNotification = async (page = 1, perPage = 10, order = 'desc', orderField = 'id', period = 'all') => {
    setWait(true);
    try {
      let request;
      if (role.slug === 'cashier') {
        request = NotificationApi.getNotificationCashier({
          page,
          perPage,
          cashierId: id,
          order,
          departmentId: cashierData.id
        });
      } else {
        request = NotificationApi.getNotificationAdmin({ page, perPage, adminId: id, order, orderField, period });
      }

      await request.then(
        ({ data, total, page: currentPage, order: currentOrder, orderField: currentOrderField }) => dispatch(
          setCashierNotification({
            page: currentPage,
            perPage,
            total,
            notificationHistory: data,
            order: currentOrder,
            orderField: currentOrderField,
            period
          })
        )
      );
    } catch (e) {
      setSnackbarMsg('Помилка отримання списку повідомлень');
      setSnackbarType(false);
      setIsOpenSnackbar(true);
    } finally {
      setWait(false);
    }
  };

  const createNotification = async (data) => {
    try {
      setWait(true);
      await NotificationApi.createNotificationCashier(data);
      await getNotification(1, state.perPage, 'desc', 'id', 'all');
      setSnackbarMsg('Повідомлення створено');
      setSnackbarType(true);
    } catch (e) {
      setSnackbarMsg('Помилка створення повідомлення');
      setSnackbarType(false);
    } finally {
      setIsOpenSnackbar(true);
      setWait(false);
    }
  };

  const createNewOrderNotification = async (data) => {
    try {
      setWait(true);
      await NotificationApi.createNotificationNewOrder(data);
    // eslint-disable-next-line no-empty
    } catch (e) {
    } finally {
      setWait(false);
    }
  };

  // TODO notificationId can be number[]
  const deleteNotification = async (adminId, notificationId) => {
    try {
      setWait(true);
      if (Array.isArray(notificationId)) {
        await Promise.all(
          notificationId.map((oneId) => NotificationApi.deleteNotification({ adminId, id: oneId }))
        );
      } else {
        await NotificationApi.deleteNotification({ adminId, id: notificationId });
      }
      const actualPage = Math.ceil((state.total - 1) / state.perPage);
      await getNotification(actualPage, state.perPage, state.order, state.orderField, state.period);
      setSnackbarMsg('Повідомлення видалено');
      setSnackbarType(true);
    } catch (e) {
      setSnackbarMsg('Помилка спроби видалення повідомлення');
      setSnackbarType(false);
    } finally {
      setIsOpenSnackbar(true);
      setWait(false);
    }
  };

  const updateNotification = async (data) => {
    try {
      setWait(true);
      await NotificationApi.updateNotification(data);
      await getNotification(state.page, state.perPage, state.order, state.orderField, state.period);
      setSnackbarMsg('Повідомлення відредаговано');
      setSnackbarType(true);
    } catch (e) {
      setSnackbarMsg('Помилка спроби оновлення повідомлення');
      setSnackbarType(false);
    } finally {
      setIsOpenSnackbar(true);
      setWait(false);
    }
  };

  const notificationRead = async (notificationId, departmentId, cashierId) => {
    await NotificationApi.actionNotification({ notificationId, departmentId, cashierId }, 'read');
    // dispatch(setCashierReadNotification(notificationId));
  };

  const notificationView = async (notificationId, departmentId, cashierId) => {
    try {
      setWait(true);
      await NotificationApi.actionNotification({ notificationId, departmentId, cashierId }, 'view');
      await getNotification(state.page, state.perPage, state.order, state.orderField, state.period);
    } catch (e) {
      setSnackbarMsg('Помилка');
      setIsOpenSnackbar(true);
      setSnackbarType(false);
    } finally {
      setWait(false);
    }
  };

  useEffect(() => {
    getNotification(state.page, state.perPage, state.order, state.orderField, state.period);

    if (role?.slug === 'cashier' && typeof id === 'number' && typeof cashierData.id === 'number') {
      const subscription = init(id, cashierData.id, {
        logout: () => {
          logout();
          subscription();
          setTimeout(() => window.location.reload(), 0);
        },
        requireNotification: (data) => {
          dispatch(setCashierUnreadRequireNotification(data));
        },
        notification: (data) => {
          dispatch(setCashierOrderUnreadRequireNotification(data));
        },
        proxy: (data) => {
          setIsOpenSnackbar(true);
          setSnackbarType(true);
          setSnackbarMsg(data.payload);
          setReFetchOrders((prev) => !prev);
        },
        message: (data) => {
          // eslint-disable-next-line no-console
          console.log(data);
        }
      });

      return () => subscription();
    }

    if (role?.slug === 'administrator' && typeof id === 'number') {
      const subscription = initAdmin(id, {
        message: (data) => {
          setUserTokenBinding({ data, open: true });
        }
      });

      return () => subscription();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role?.slug, cashierData?.id, id]);
  const contextData = {
    isWait,
    getNotification,
    createNotification,
    deleteNotification,
    updateNotification,
    createNewOrderNotification,
    notificationRead,
    notificationView,
    unreadNotificationRequire: state.notificationUnreadRequire,
    unreadNotification: state.notificationUnread,
    notificationHistory: state.notificationHistory,
    totalNotifications: state.total,
    page: state.page,
    perPage: state.perPage,
    order: state.order,
    orderField: state.orderField,
    period: state.period,
    sender,
    reFetchOrders
  };

  if (children instanceof Function) {
    return children(contextData);
  }

  return (
    <>
      <NotificationContext.Provider value={contextData}>{children}</NotificationContext.Provider>
      {role?.slug === 'cashier' && !!state.notificationUnreadRequire?.length && (
        <Modal>
          <Notification
            userID={user?.id}
            departmentID={cashierData?.id}
            messages={state.notificationUnreadRequire}
            notificationRead={notificationRead}
            getNotification={getNotification}
            page={state.page}
            perPage={state.perPage}
          />
        </Modal>
      )}
      {role?.slug === 'cashier'
        && !!state.orderNotificationUnreadRequire?.length
        && state.orderNotificationUnreadRequire.some(({ readDepartment }) => readDepartment.length === 0)
        && (
          <Modal>
            <Notification
              userID={user?.id}
              departmentID={cashierData?.id}
              messages={state.orderNotificationUnreadRequire}
              notificationRead={notificationRead}
              getNotification={getNotification}
              page={state.page}
              perPage={state.perPage}
              setReFetchOrders={setReFetchOrders}
            />
          </Modal>
        )}
      {role?.slug === 'administrator' && typeof id === 'number' && userTokenBinding.open && (
        <Modal>
          <TokenBinding userTokenBinding={userTokenBinding} setUserTokenBinding={setUserTokenBinding} />
        </Modal>
      )}
      <AlertSnackbar
        onClose={() => setIsOpenSnackbar(false)}
        isOpen={isOpenSnackbar}
        success={snackbarType}
        message={snackbarMsg}
      />
    </>
  );
};

export default NotificationProvider;
