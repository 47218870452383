import React, { useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Tabs, Tab, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import PageWrapper from '../../../common/PageWrapper/PageWrapper';
import { ReportOperations } from './ReportOperations';
import { ReportFilialIncome } from './ReportFilialIncome';
import { ReportBySession } from './ReportBySession';
import { Limits } from './Limits';
import { BalanceReport } from './Balance';
import { TabPanel, a11yProps } from '../../../components/TabPanel';
import { reportsLabel } from './consts';

export const Reports = () => {
  const { report } = useParams();
  const history = useHistory();
  const theme = useTheme();
  const [value, setValue] = useState(reportsLabel[report] || reportsLabel.balance);
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const routeChange = useCallback((route) => () => {
    history.push(`/reports/${route}`);
  }, [history]);

  return (
    <PageWrapper>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} textColor="primary" onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Звіт по балансах" {...a11yProps(reportsLabel[report])} onClick={routeChange('balance')} />
            <Tab label="Звіт &ldquo;Дохід по регіонах&ldquo;" {...a11yProps(reportsLabel[report])} onClick={routeChange('filial')} />
            <Tab label="Звіт по операціях" {...a11yProps(reportsLabel[report])} onClick={routeChange('operations')} />
            <Tab label="Звіт по сесії" {...a11yProps(reportsLabel[report])} onClick={routeChange('sessions')} />
            <Tab label="Ліміти" {...a11yProps(reportsLabel[report])} onClick={routeChange('limits')} />
          </Tabs>
        </Box>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={reportsLabel.balance}>
            <BalanceReport />
          </TabPanel>
          <TabPanel value={value} index={reportsLabel.filial}>
            <ReportFilialIncome />
          </TabPanel>
          <TabPanel value={value} index={reportsLabel.operations}>
            <ReportOperations />
          </TabPanel>
          <TabPanel value={value} index={reportsLabel.sessions}>
            <Box sx={{ minHeight: '500px' }}>
              <ReportBySession />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={reportsLabel.limits}>
            <Box sx={{ minHeight: '500px' }}>
              <Limits />
            </Box>
          </TabPanel>
        </SwipeableViews>
      </Box>
    </PageWrapper>
  );
};
