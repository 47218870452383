import React, { useCallback, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CashierContext } from './cashierContext';
import SessionService from '../../../services/SESSIONS/SessionService';
import ReportsService from '../../../services/REPORTS/ReportsService';
import RatesService from '../../../services/RATES/RatesService';
import OperationsService from '../../../services/OPERATIONS/OperationsService';
import BalancesService from '../../../services/BALANCE/BalancesService';
import { AuthorisationContext } from '../AuthorisationContext';
import AlertSnackbar from '../../common/Snackbar/AlertSnackbar';

function download(blob, filename) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
}

const CashierProvider = ({ children }) => {
  const history = useHistory();
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState({
    message: '',
    type: false
  });
  const { cashierData, updateCashier, availableDepartments } = useContext(AuthorisationContext);
  const [isSessionFetching, setIsSessionFetching] = useState(false);
  const [cashierCurrency, setCashierCurrency] = useState([]);
  const [cashierBalance, setCashierBalance] = useState([]);
  const [cashierReport, setCashierReport] = useState([]);
  const [isEditableCurrency, setIsEditableCurrency] = useState(false);
  const [cashierCurrencyFetching, setCashierCurrencyFetching] = useState(false);
  const [cashierCurrencyEditing, setCashierCurrencyEditing] = useState(false);
  const [isFetchingSell, setIsFetchingSell] = useState(false);
  const [isFetchingBalance, setIsFetchingBalance] = useState(false);
  const [isFetchingReport, setIsFetchingReport] = useState(false);
  const [isFetchingReportFile, setIsFetchingReportFile] = useState(false);

  const fetchCashierCurrency = useCallback(async (data) => {
    try {
      setCashierCurrencyFetching(true);
      const response = await RatesService.getRequestWithID(data);
      if (!response.message) {
        setCashierCurrency(response.data);
        setIsEditableCurrency(Boolean(response.edit));
      }
    } catch {
      setCashierCurrency([]);
    } finally {
      setCashierCurrencyFetching(false);
    }
  }, []);

  const fetchEditCashierCurrency = useCallback(async (data, department) => {
    try {
      setCashierCurrencyEditing(true);
      const response = await RatesService.postRequest(data);
      if (!response.message) {
        fetchCashierCurrency(department);
        setSnackbarMsg({ massage: 'Курс валют успшіно змінено', type: true });
      } else {
        setSnackbarMsg({ massage: 'Помилка при зміненні курсу валют', type: false });
      }
    // eslint-disable-next-line no-empty
    } catch {
    } finally {
      setCashierCurrencyEditing(false);
      setIsOpenSnackbar(true);
    }
  }, [fetchCashierCurrency]);

  const fetchStartSession = useCallback(async (data) => {
    try {
      setIsSessionFetching(true);
      const response = await SessionService.postRequest(data);
      if (!response.message) {
        setIsSessionFetching(false);
        updateCashier({
          departments: availableDepartments.map((department) => (department.id === data.department_id
            ? { ...department, department_active_session: response.data }
            : department
          ))
        });
      }
    } catch {
      setIsSessionFetching(false);
    }
  }, [availableDepartments, updateCashier]);

  const fetchEndSession = useCallback(async () => {
    try {
      setIsSessionFetching(true);
      const response = await SessionService.putRequest({
        id: cashierData?.department_active_session.id,
        isEndSession: true
      });
      if (!response.message) {
        setIsSessionFetching(false);
        updateCashier({
          departments: availableDepartments.map((department) => (department.id === cashierData?.id
            ? { ...cashierData, department_active_session: null }
            : department
          ))
        });
        history.push('/');
      }
    } catch {
      setIsSessionFetching(false);
    }
  }, [history, cashierData, updateCashier, availableDepartments]);

  const fetchSellBuyOperation = useCallback(async (data) => {
    try {
      setIsFetchingSell(true);
      const response = await OperationsService.postRequest(data);
      if (!response.message) {
        setIsFetchingSell(false);
      }
    } catch {
      setIsFetchingSell(false);
    }
  }, []);

  const fetchCashierBalance = useCallback(async (data) => {
    try {
      setIsFetchingBalance(true);
      const response = await BalancesService.getRequestForBalance(data);
      if (!response.message) {
        setCashierBalance(response.data);
        setIsFetchingBalance(false);
      }
    } catch {
      setIsFetchingBalance(false);
      setCashierBalance([]);
    }
  }, []);

  const fetchCashierReport = useCallback(async (data) => {
    try {
      setIsFetchingReport(true);
      const response = await ReportsService.getRequestBySession(data);
      if (!response.message) {
        setCashierReport(response.data);
        setIsFetchingReport(false);
      }
    } catch {
      setIsFetchingReport(false);
      setCashierReport([]);
    }
  }, []);

  const fetchCashierReportFile = useCallback(async (data) => {
    try {
      setIsFetchingReportFile(true);
      const response = await ReportsService.getRequestBySessionFile(data);
      download(await response.blob(), `session-${data.id}.xlsx`);
    } catch {
      setIsFetchingReportFile(false);
    }
  }, []);

  const contextData = {
    sessionData: cashierData?.department_active_session,
    isFetchingSell,
    fetchEndSession,
    cashierCurrency,
    isSessionFetching,
    fetchStartSession,
    fetchCashierCurrency,
    isFetchingBalance,
    isFetchingReport,
    isFetchingReportFile,
    fetchCashierBalance,
    fetchCashierReport,
    fetchCashierReportFile,
    cashierBalance,
    cashierReport,
    isEditableCurrency,
    fetchSellBuyOperation,
    cashierCurrencyFetching,
    fetchEditCashierCurrency,
    cashierCurrencyEditing
  };

  if (children instanceof Function) {
    return children(contextData);
  }

  return (
    <CashierContext.Provider value={contextData}>
      {children}
      <AlertSnackbar
        onClose={() => setIsOpenSnackbar(false)}
        isOpen={isOpenSnackbar}
        message={snackbarMsg.massage}
        success={snackbarMsg.type}
      />
    </CashierContext.Provider>
  );
};

export default CashierProvider;
