import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DashboardContext } from '../../../components/DashboardContext';
import { CashierContext } from '../../../components/CashierContext';
import { AuthorisationContext } from '../../../components/AuthorisationContext';
import { AutocompleteSelect } from '../../../components/AutocompleteSelect';

export const FilterDepartments = ({ setFilterDepartmentId }) => {
  const { fetchDepartments, departments } = useContext(DashboardContext);
  const { sessionData } = useContext(CashierContext);
  const { cashierData: {
    isCentral,
    filial_id: filialId
  } } = useContext(AuthorisationContext);

  useEffect(() => {
    fetchDepartments({
      current_session: sessionData.id,
      filial_id: isCentral ? '' : filialId,
      isCentral: isCentral ? '' : 1
    });
  }, [fetchDepartments, filialId, isCentral, sessionData]);

  const filteredDepartments = useMemo(() => {
    if (isCentral) {
      return departments?.filter(
        (item) => item.filial.some(({ id }) => id === filialId)
          || (item.filial.some(({ id }) => id !== filialId) && item.isCentral))
        .map(({ id, title }) => ({ id, label: title }));
    }
    return departments.map(({ id, title }) => ({ id, label: title }));
  }, [isCentral, departments, filialId]);

  return (
    <AutocompleteSelect
      id="department-filter"
      label="Відділення"
      selectorList={filteredDepartments}
      setSelectedItem={setFilterDepartmentId}
    />
  );
};

FilterDepartments.propTypes = {
  setFilterDepartmentId: PropTypes.func
};
