export const translations = {
  type: {
    Покупка: 'Купівля',
    Продажа: 'Продаж',
    Инкассация: 'Інкасація',
    'Кросс-курс': 'Крос-курс',
    'Прочие расходы': 'Інші витрати',
    'Прочие доходы': 'Інші доходи'
  },
  storno: {
    Нет: 'Ні',
    Да: 'Так',
    'Ошибка кассира': 'Помилка касира',
    Сторно: 'Сторно'
  }
};
