import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const UserMessage = ({ message, handleClose }) => (
  <div className="userMessage">
    <p className="userMessage__title">Системне сповіщення</p>
    <p className="userMessage__text">{message}</p>
    <div className="userMessage__container">
      <Button onClick={handleClose} className="userMessage__btn" variant="contained" color="success">
        <CheckCircleIcon sx={{ mr: '5px' }} />
        Добре
      </Button>
    </div>
  </div>
);

UserMessage.propTypes = {
  message: PropTypes.string,
  handleClose: PropTypes.func
};
