import { SET_NOTIFICATION, SET_UNREAD, SET_UNREAD_REQUIRE, SET_ORDER_UNREAD_REQUIRE } from './const';

export const initState = {
  page: 1,
  perPage: 5,
  total: 0,
  order: 'desc',
  orderField: 'id',
  period: 'all',
  notificationHistory: [],

  notificationUnreadRequire: [],
  orderNotificationUnreadRequire: [],

  error: null
};

export function reducer(state, { type, payload }) {
  if (type === SET_NOTIFICATION) {
    return {
      ...state,
      total: payload.total,
      page: payload.page,
      perPage: payload.perPage,
      order: payload.order,
      orderField: payload.orderField,
      period: payload.period,
      notificationHistory: payload.notificationHistory
    };
  }

  if (type === SET_UNREAD_REQUIRE) {
    return {
      ...state,
      notificationUnreadRequire: payload
    };
  }

  if (type === SET_ORDER_UNREAD_REQUIRE) {
    return {
      ...state,
      orderNotificationUnreadRequire: payload
    };
  }

  if (type === SET_UNREAD) {
    return {
      ...state,
      notificationUnread: payload
    };
  }

  return { state };
}
