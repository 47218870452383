import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, TableHead, TableBody, TableCell, TableRow,
  Collapse, Box, Button } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import RenderOrder from './RenderOrder';
import { StatusButton } from '../../../../components/StatusButton';

const TableItem = ({ item }) => {
  const {
    id,
    inkassator,
    order_status: orderStatus,
    created_at: time,
    department_receiver: departmentReceiver,
    department_creator: departmentCreator,
    order_items: orderItems,
    sum_in_hryvnia: {
      arrival: arrivalUAH,
      expense: expenseUAH,
      swap: swapUAH
    }
  } = item;
  const [isOpen, setOpen] = useState(false);
  const renderOrderItems = useMemo(() => orderItems?.map((elm) => (
    <RenderOrder
      key={elm.id}
      eml={elm}
      {...{ elm, departmentReceiver, departmentCreator, orderItems }}
    />
  )
  ),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [orderItems]);

  const renderSumUah = useMemo(() => {
    if (arrivalUAH !== 0 || expenseUAH !== 0) {
      const firstValue = swapUAH ? arrivalUAH : expenseUAH;
      const secondValue = swapUAH ? expenseUAH : arrivalUAH;
      return (
        <TableRow>
          <TableCell colSpan={3}>Загалом</TableCell>
          <TableCell>{firstValue !== 0 ? `${firstValue} UAH` : ''}</TableCell>
          <TableCell>{secondValue !== 0 ? `${secondValue} UAH` : ''}</TableCell>
          <TableCell />
        </TableRow>
      );
    } return '';
  }, [arrivalUAH, expenseUAH, swapUAH]);

  return (
    <>
      <TableRow>
        <TableCell>{id}</TableCell>
        <TableCell>{time}</TableCell>
        <TableCell>
          <StatusButton orderStatus={orderStatus} />
        </TableCell>
        <TableCell>{departmentCreator.title}</TableCell>
        <TableCell>{departmentReceiver.title}</TableCell>
        <TableCell sx={{ maxWidth: 240 }}>{inkassator?.name}</TableCell>
        <TableCell align="right">
          <Button onClick={() => setOpen(!isOpen)} variant="contained" size="small">
            {isOpen ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: 0, borderBottom: 0 }} colSpan={7}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: '8px 0' }}>
              <Table className="collapse-table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: 80 }}>ID</TableCell>
                    <TableCell sx={{ width: 150 }}>Тип</TableCell>
                    <TableCell sx={{ width: 90 }}>Курс</TableCell>
                    <TableCell sx={{ minWidth: 120 }}>Розхід</TableCell>
                    <TableCell sx={{ minWidth: 120 }}>Прихід</TableCell>
                    <TableCell>Статус</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderOrderItems}
                  {renderSumUah}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

TableItem.propTypes = {
  item: PropTypes.object
};

export default TableItem;
