import React from 'react';
import { Button, FormControl, TextField, List, ListItem, ListItemText } from '@mui/material';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import './style.scss';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { validationPassword } from './validateForm';

export const ChangePassword = ({ user, fetchUpdateUserPassword }) => {
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: validationPassword,
    onSubmit: (data, { resetForm }) => {
      fetchUpdateUserPassword(data, user.id)
        .then((response) => {
          if (response === 'success') {
            resetForm();
          }
        });
    }
  });

  return (
    <div className="changePassword">
      <div className="changePassword__title">
        Ваш старий пароль було скинуто адміністратором. Встановіть новий!
      </div>
      <form className="changePassword__form" onSubmit={formik.handleSubmit}>
        <FormControl fullWidth size="small">
          <TextField
            size="small"
            id="password"
            label="Пароль"
            name="password"
            placeholder="Введіть пароль"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </FormControl>
        <FormControl fullWidth size="small" sx={{ mt: '10px' }}>
          <TextField
            size="small"
            id="confirmPassword"
            name="confirmPassword"
            label="Підтвердити пароль"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Підтвердити пароль"
            value={formik.values.confirmPassword}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
          />
        </FormControl>
        <List dense>
          <ListItem>
            <ListItemText primary="Пароль має складатися максимум з 8 символів" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Тільки букви латинського алфавіту" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Щонайменше 1 буква нижнього регістру, та 1 заголовна" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Щонайменше 2 цифри, та не більше 6" />
          </ListItem>
        </List>
        <Button type="submit" variant="contained" color="success" disabled={!formik.isValid}>
          <CheckCircleOutlineIcon sx={{ mr: '5px' }} />
          Встановити
        </Button>
      </form>
    </div>
  );
};

ChangePassword.propTypes = {
  user: PropTypes.object,
  fetchUpdateUserPassword: PropTypes.func
};
