import React, { useState, useCallback, useContext, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import ModalWindow from '../../../../components/ModalWindow/ModalWindow';
import { DashboardContext } from '../../../../components/DashboardContext';
import { sender } from '../../../../../services/NOTIFICATION/NotificationService';
import { translations } from '../../translations';
import { localizeErrors } from '../../../../../utils/helpers';

const ModalWindowStatusChangeConfirm = ({
  open,
  isCentral,
  handleClose,
  inkassators,
  statusValue: {
    check, status, secondStatus, statusKey, secondStatusKey,
    orderId, departmentId, departmentCreator, departmentReceiver, inkassator },
  currentSession,
  setReFetch,
  setSnackbar
}) => {
  const {
    fetchPutOrderStatus, isFetchingPutOrderStatus
  } = useContext(DashboardContext);
  const [selectedInkassator, setInkassator] = useState(inkassator?.id || '');
  const [statusMassage, setStatusMassage] = useState('');
  const onChangeInkassator = useCallback((e) => {
    setInkassator(e.target.value);
  }, []);

  let nextStatus;
  if (check) {
    if (departmentId === departmentReceiver.id) {
      if (status === 'Ожидает подтверждения') nextStatus = 'Подтверждено';
      if (status === 'Подтверждено') nextStatus = 'Едет в кассу';
      if (status === 'Едет в кассу') nextStatus = 'Выполнено';
      if (status === 'Возврат-едет в кассу') nextStatus = 'Возвращено';
      if (status === 'Выполнено') nextStatus = 'Едет в кассу';
    }
    if (departmentId !== departmentReceiver.id) {
      if (status === 'Едет в кассу') nextStatus = 'Выполнено';
      if (status === 'Подтверждено') nextStatus = 'Едет в кассу';
      if (status === 'Возврат-едет в кассу') nextStatus = 'Возвращено';
    }
  } else {
    if (status === 'Едет в кассу') nextStatus = 'Возврат-едет в кассу';
    if (status === 'Ожидает подтверждения' || status === 'Подтверждено') nextStatus = 'Отменено';
  }

  const senderReceiver = departmentId === departmentReceiver.id ? departmentCreator : departmentReceiver;
  const senderDepartment = departmentId === departmentReceiver.id ? departmentReceiver : departmentCreator;
  const senderPayloadMsg = `Проводка №${orderId} від "${senderDepartment.title}" оновлена до "${nextStatus}"`;
  const formData = useRef({});
  const onChangeStatus = useCallback(async () => {
    formData.current = {
      current_session: currentSession,
      id: orderId,
      [statusKey]: nextStatus
    };
    if (status === 'Едет в кассу' && nextStatus === 'Выполнено' && secondStatus === 'Подтверждено') {
      formData.current[secondStatusKey] = 'Едет в кассу';
    }
    if (isCentral && selectedInkassator !== '' && status === 'Подтверждено') formData.current.inkas_id = selectedInkassator;
    const massages = await fetchPutOrderStatus({ formData });
    setStatusMassage(massages);
    if (!massages) {
      setSnackbar({ open: true, massage: 'Статус проводки успішно змінено' });
      handleClose();
      setReFetch((prevState) => !prevState);
      sender('peerToPeerConnection', { departmentId: senderReceiver.id, payload: senderPayloadMsg });
    }
  }, [isCentral, fetchPutOrderStatus, currentSession, orderId, handleClose, statusKey,
    nextStatus, setReFetch, selectedInkassator, secondStatus, secondStatusKey, status,
    senderReceiver, senderPayloadMsg, setSnackbar]);

  const renderInkassators = useMemo(() => inkassators?.map((item) => (
    <MenuItem key={item.id} value={item.id}>{`${item.firstname} ${item.lastname}`}</MenuItem>
  )), [inkassators]);

  const renderIncassatorsSelector = useMemo(() => {
    if (isCentral && inkassator === null && status !== 'Ожидает подтверждения') {
      return (
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Інкасатор</InputLabel>
          <Select label="Інкасатор" value={selectedInkassator} onChange={onChangeInkassator}>
            {renderInkassators}
          </Select>
        </FormControl>
      );
    }
  }, [isCentral, inkassator, status, renderInkassators, selectedInkassator, onChangeInkassator]);

  const onClose = () => {
    handleClose();
  };

  return (
    <>
      <ModalWindow open={open} onClose={() => onClose()} maxWidth="500px">
        <div className="addUsers addPopup">
          <CancelPresentationRoundedIcon className="close-btn" onClick={() => onClose()} />
          <div className="new-date">Підтвердіть операцію</div>
          <div className="new-date">{`Поточний статус: ${translations.currentStatus[status]}`}</div>
          <div className="new-date">{`Наступний статус: ${translations.currentStatus[nextStatus]}`}</div>
          {check && status === 'Едет в кассу' && secondStatus === 'Подтверждено'
            ? <div className="new-date">Водночас друга частина викупу їде в кассу</div>
            : ''}
          {!isCentral || (
          <div className="addUsers-actions">
            {renderIncassatorsSelector}
          </div>
          )}
          <div className="new-date">
            {statusMassage ? <p className="errorText">{localizeErrors[statusMassage]}</p> : ''}
          </div>
          <div className="addUsers-actions">
            <LoadingButton
              loading={isFetchingPutOrderStatus}
              loadingPosition="end"
              endIcon={<SendRoundedIcon />}
              onClick={onChangeStatus}
              disabled={!selectedInkassator && status === 'Подтверждено' && nextStatus !== 'Отменено'}
              variant="contained"
            >
              Підтвердити
            </LoadingButton>
          </div>
        </div>
      </ModalWindow>
    </>
  );
};

ModalWindowStatusChangeConfirm.propTypes = {
  open: PropTypes.bool,
  isCentral: PropTypes.bool,
  statusValue: PropTypes.object,
  inkassators: PropTypes.array,
  handleClose: PropTypes.func,
  setReFetch: PropTypes.func,
  setSnackbar: PropTypes.func,
  currentSession: PropTypes.number
};

export default ModalWindowStatusChangeConfirm;
