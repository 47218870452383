import React, { useCallback, useContext, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { nanoid } from 'nanoid';
import './style.scss';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import ModalWindow from '../ModalWindow/ModalWindow';
import { DashboardContext } from '../DashboardContext';
import { CashierContext } from '../CashierContext';
import { localizeErrors } from '../../../utils/helpers';

export const ModalWindowOtherExpensesConfirm = ({ open, handleClose, data, isExpense }) => {
  const history = useHistory();
  const { expenses, fetchBuyExpenses, expensesErrors,
    arrivals, arrivalsErrors, handleArrival } = useContext(DashboardContext);
  const { sessionData } = useContext(CashierContext);
  const { category, categoryItem, value, comment } = data;
  const [isFetchingOperation, setIsFetchingOperation] = useState(false);
  const expensesTitle = useMemo(() => {
    const items = isExpense ? expenses : arrivals;
    return items?.find(
      (item) => item.id === category)?.items.find((item) => item.id === categoryItem).title;
  }, [isExpense, expenses, arrivals, categoryItem, category]);

  const handleClick = useCallback(() => history.push('/cashier-operations-list'), [history]);
  const onConfirmTransaction = useCallback(() => {
    setIsFetchingOperation(true);
    if (isExpense) {
      fetchBuyExpenses({
        id: categoryItem,
        current_session: sessionData.id,
        currency_id: data.currency_id,
        value,
        comment,
        count: data.count || '0'
      }).then((resp) => {
        if (resp === 'success') {
          handleClose();
          setIsFetchingOperation(false);
          handleClick();
        } else {
          setIsFetchingOperation(false);
        }
      });
    } else {
      handleArrival({
        id: categoryItem,
        current_session: sessionData.id,
        currency_id: data.currency_id,
        value,
        comment
      }).then((resp) => {
        if (resp === 'success') {
          handleClose();
          setIsFetchingOperation(false);
          handleClick();
        } else {
          setIsFetchingOperation(false);
        }
      });
    }
  }, [fetchBuyExpenses, value, comment, categoryItem, sessionData.id,
    handleClose, handleClick, data, handleArrival, isExpense]);

  const renderErrors = useMemo(() => {
    const errors = isExpense ? expensesErrors : arrivalsErrors;
    return errors?.map((item) => <p key={nanoid()} className="errorText">{localizeErrors[item] || item}</p>);
  }, [isExpense, expensesErrors, arrivalsErrors]);

  return (
    <>
      <ModalWindow
        open={open}
        onClose={handleClose}
        maxWidth="500px"
      >
        <div className="addUsers">
          <CancelPresentationRoundedIcon className="close-btn" onClick={handleClose} />
          <div className="new-date">Підтвердіть операцію</div>
          <div className="modal-window-data">
            <div className="set-time-text">{`Позиція ${isExpense ? 'витрат' : 'доходів'}: ${expensesTitle}`}</div>
            <div className="set-time-text">{`Сума: ${value}  ${data.currencyTitle}`}</div>
            {isExpense && data?.count && (<div className="set-time-text">{`Кількість: ${data.count}`}</div>)}
            <div className="set-time-text">{`Коментар: ${comment || ''}`}</div>
            {renderErrors}
          </div>
          <div className="addUsers-actions">
            <LoadingButton
              loading={isFetchingOperation}
              loadingPosition="end"
              endIcon={<SendRoundedIcon />}
              onClick={onConfirmTransaction}
              variant="contained"
            >
              Підтвердити
            </LoadingButton>
          </div>
        </div>
      </ModalWindow>
    </>
  );
};

ModalWindowOtherExpensesConfirm.propTypes = {
  open: PropTypes.bool,
  isExpense: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.object
};
