import React, { useEffect, useContext, useState, useMemo } from 'react';
import './style.scss';
import {
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Autocomplete,
  InputLabel
} from '@mui/material';
import isEmpty from 'lodash.isempty';
import PageWrapper from '../../../common/PageWrapper/PageWrapper';
import InkassatorsItem from './InkassatorsItem';
import { DashboardContext } from '../../../components/DashboardContext';
import SettingsBackwardBtn from '../../../components/SettingsBackwardBtn/SettingsBackwardBtn';
import { MenuProps } from './consts';

const Inkassators = () => {
  const {
    inkasatorUsersCount, fetchInkasatorsUsers, inkasatorUsers, allInkasatorUsers } = useContext(DashboardContext);
  const [userFilter, setUserFilter] = useState('');
  const [inputUser, setInputUser] = useState('');
  const [status, setStatus] = useState(1);
  const [balance, setBalance] = useState('all');
  const [cityID, setCityID] = useState('Всі');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const paginationPerPage = useMemo(() => {
    const arr = [5, 10, 25, 50, 100].filter((pages) => inkasatorUsersCount / pages >= 1);
    return isEmpty(arr) ? [5] : arr;
  }, [inkasatorUsersCount]);

  useEffect(() => {
    fetchInkasatorsUsers({
      page,
      per_page: perPage,
      city_id: cityID === 'Всі' ? '' : cityID,
      user_ids: userFilter,
      status_balance: balance,
      status: String(status)
    });
  }, [balance, cityID, fetchInkasatorsUsers, page, perPage, status, userFilter]);

  const renderInkassators = useMemo(() => {
    if (!isEmpty(inkasatorUsers)) {
      return inkasatorUsers.map((user) => <InkassatorsItem key={user.id} user={user} />);
    }
  }, [inkasatorUsers]);

  const citiesList = useMemo(() => {
    const temp = allInkasatorUsers.map((user) => user.cities).reduce((target, key) => target.concat(key), []);
    const table = {};
    const res = temp.filter(({ id }) => {
      if (!table[id]) {
        table[id] = 1;
        return true;
      }
      return false;
    });
    return res;
  }, [allInkasatorUsers]);

  const userList = useMemo(() => {
    const temp = allInkasatorUsers.map((user) => ({
      label: `${user.lastname} ${user.firstname} ${user.patronymic}`,
      id: user.id
    }));
    return temp.reduce((prev, item) => {
      if (prev.find((user) => user.label === item.label)) {
        return prev
          .filter((user) => user.label !== item.label)
          .concat({
            label: item.label,
            id: [prev.find((user) => user.label === item.label).id, item.id]
          });
      }
      return prev.concat(item);
    }, []);
  }, [allInkasatorUsers]);

  const onChangeUserFilter = (e, value) => {
    if (value) {
      setPage(1);
      setUserFilter(userList.find((user) => user.label === value)?.id || '');
      setInputUser(value);
    } else {
      setPage(1);
      setUserFilter('');
      setInputUser('');
    }
  };

  return (
    <PageWrapper>
      <div className="inkassators">
        <div className="inkassators-actions">
          <div className="inkassators-filter">
            <SettingsBackwardBtn />
            <div className="inkassators-filters-item">
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  inputValue={inputUser}
                  variant="outlined"
                  options={userList}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => {
                    if (option.label === value.label) {
                      return true;
                    }
                    return false;
                  }}
                  onInputChange={onChangeUserFilter}
                  renderInput={(params) => <TextField {...params} label="ПІБ" />}
                  sx={{ width: '220px' }}
                />
              </FormControl>
            </div>
            <div className="inkassators-filters-item">
              <FormControl fullWidth>
                <InputLabel className="inputLabel" id="cityLabel">
                  Місто
                </InputLabel>
                <Select
                  label="Місто"
                  labelId="cityLabel"
                  value={cityID}
                  MenuProps={MenuProps}
                  onChange={(e) => setCityID(e.target.value)}
                  sx={{ width: '220px' }}
                >
                  <MenuItem value="Всі">Всі</MenuItem>
                  {citiesList.map((city) => (
                    <MenuItem value={city.id} key={city.id}>
                      {city.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="inkassators-filters-item">
              <FormControl fullWidth>
                <InputLabel className="inputLabel" id="balanceLabel">
                  Баланс
                </InputLabel>
                <Select
                  label="Баланс"
                  labelId="balanceLabel"
                  value={balance}
                  onChange={(e) => setBalance(e.target.value)}
                  sx={{ width: '180px' }}
                >
                  <MenuItem value="all">Всі</MenuItem>
                  <MenuItem value="positive-balance">Позитивний</MenuItem>
                  <MenuItem value="zero-balance">Нульовий</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="inkassators-filters-item">
              <FormControl fullWidth>
                <InputLabel className="inputLabel" id="statusLabel">
                  Статус
                </InputLabel>
                <Select
                  label="Статус"
                  labelId="statusLabel"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  sx={{ width: '180px' }}
                >
                  <MenuItem value={1}>Активні</MenuItem>
                  <MenuItem value={0}>Неактивні</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="users-table inkassators__table">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell component="th" align="left">ID</TableCell>
                  <TableCell component="th" align="left">
                    ПІБ
                  </TableCell>
                  <TableCell component="th" align="left">
                    Міста
                  </TableCell>
                  <TableCell component="th" align="center" padding="checkbox">
                    Статус
                  </TableCell>
                  <TableCell component="th" align="center" padding="checkbox">
                    Дія
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderInkassators}</TableBody>
            </Table>
            {inkasatorUsersCount >= 5 && !userFilter && (
              <div className="users-pagination">
                <Pagination
                  page={page}
                  shape="rounded"
                  onChange={handleChangePage}
                  count={Math.ceil(inkasatorUsersCount / perPage)}
                  color="primary"
                />
                <TablePagination
                  count={!userFilter ? inkasatorUsersCount : 1}
                  page={page}
                  component="div"
                  rowsPerPage={perPage}
                  labelRowsPerPage="Відображати:"
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={paginationPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            )}
          </TableContainer>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Inkassators;
