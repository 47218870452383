import * as yup from 'yup';

export const validationPassword = yup.object({
  password: yup
    .string()
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]{2,6}).{2,8}$/, 'Пароль не валідний')
    .max(8, 'Пароль має містити максимум 8 символів')
    .oneOf([yup.ref('confirmPassword'), null], 'Паролі не співпадають')
    .required('Обов\'язково до заповнення'),
  confirmPassword: yup
    .string()
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]{2,6}).{2,8}$/, 'Пароль не валідний')
    .max(8, 'Пароль має містити максимум 8 символів')
    .oneOf([yup.ref('password'), null], 'Паролі не співпадають')
    .required('Обов\'язково до заповнення')
});
