import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModalWindow from '../ModalWindow/ModalWindow';
import './style.scss';

const ConfirmDelete = ({ open, label, onClose, onClickButton, isDisabled }) => (
  <ModalWindow open={open} maxWidth="700px" onClose={onClose} onBackdropClick={onClose}>
    <div className="confirmDelete">
      <p className="confirmDelete-title">{isDisabled ? 'Помилка' : 'Підтвердження'}</p>
      <p className="confirmDelete-subTitle">{label}</p>
      <div className="confirmDelete-actions">
        <Button variant="contained" disabled={isDisabled} onClick={onClickButton}>
          <DeleteForeverIcon sx={{ marginRight: '5px' }} />
          Видалити
        </Button>
        <Button variant="contained" color="error" onClick={onClose}>
          <DisabledByDefaultIcon sx={{ marginRight: '5px' }} />
          Скасувати
        </Button>
      </div>
    </div>
  </ModalWindow>
);

ConfirmDelete.propTypes = {
  open: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickButton: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool
};

export default ConfirmDelete;
