import React, { useContext, useEffect, useMemo, useState } from 'react';
import './styles.scss';
import {
  Button,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  InputLabel
} from '@mui/material';
import isEmpty from 'lodash.isempty';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SettingsBackwardBtn from '../../components/SettingsBackwardBtn/SettingsBackwardBtn';
import UsersItem from './UsersItem';
import { DashboardContext } from '../../components/DashboardContext';
import { useDebounce } from '../../../hooks/useDebounce';
import AddUser from '../../components/AddUser/AddUser';
import PageWrapper from '../../common/PageWrapper/PageWrapper';
import { AuthorisationContext } from '../../components/AuthorisationContext';

const Users = () => {
  const { users, usersCount, fetchUsers } = useContext(DashboardContext);
  const { userRole } = useContext(AuthorisationContext);
  const [name, setName] = useState('');
  const [status, setStatus] = useState('1');
  const [type, setType] = useState('all');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const debouncedName = useDebounce(name, 2000);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    fetchUsers({
      page,
      per_page: perPage,
      fio: debouncedName,
      role_id: type === 'all' ? '' : type,
      status: String(status)
    });
  }, [page, perPage, status, type, debouncedName, fetchUsers, reload]);

  const renderUsers = useMemo(() => {
    if (!isEmpty(users)) {
      return users.map((item) => <UsersItem item={item} key={item.id} />);
    }
  }, [users]);

  const paginationPerPage = useMemo(() => {
    const arr = [5, 10, 25, 50, 100].filter((pages) => usersCount / pages >= 1);
    return isEmpty(arr) ? [5] : arr;
  }, [usersCount]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onHandleClose = () => {
    setIsOpenModal(false);
    setReload(!reload);
  };

  return (
    <PageWrapper>
      <div className="users">
        <div className={userRole === 'Бухгалтер' ? 'users-actions filters-only' : 'users-actions'}>
          { userRole === 'Администратор' && <SettingsBackwardBtn /> }
          <div className="users-filters-item">
            <div className="users-filters-item-input">
              <FormControl fullWidth>
                <TextField label="ПІБ" value={name} onChange={(e) => setName(e.target.value)} />
              </FormControl>
            </div>
          </div>
          <div className="users-filters-item">
            <div className="users-filters-item-input">
              <FormControl fullWidth>
                <InputLabel className="inputLabel" id="statusLabel">
                  Статус
                </InputLabel>
                <Select
                  label="Статус"
                  id="statusLabel"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  fullWidth
                >
                  <MenuItem value="any">Всі</MenuItem>
                  <MenuItem value={1}>Активні</MenuItem>
                  <MenuItem value={0}>Неактивні</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="users-filters-item">
            <div className="users-filters-item-input">
              <FormControl fullWidth>
                <InputLabel className="inputLabel" id="roleLabel">Тип</InputLabel>
                <Select
                  label="Тип"
                  id="roleLabel"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  fullWidth
                >
                  <MenuItem value="all">Всі</MenuItem>
                  <MenuItem value={1}>Адміністратор</MenuItem>
                  <MenuItem value={2}>Бухгалтер</MenuItem>
                  <MenuItem value={3}>Касир</MenuItem>
                  <MenuItem value={4}>Інкасатор</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          { userRole === 'Администратор' && (
          <Button variant="contained" onClick={() => setIsOpenModal(true)}>
            <AddCircleIcon fontSize="small" sx={{ mr: '5px' }} />
            Додати користувача
          </Button>
          ) }
        </div>
        <div className="users-table">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell component="th" align="left">
                    ID
                  </TableCell>
                  <TableCell component="th" align="left">
                    Логін
                  </TableCell>
                  <TableCell component="th" align="left">
                    ПІБ
                  </TableCell>
                  <TableCell component="th" align="left">
                    Права доступу
                  </TableCell>
                  <TableCell component="th" align="left">
                    Доступ
                  </TableCell>
                  {userRole === 'Администратор'
                    && (
                      <TableCell component="th" align="left" sx={{ width: '100px' }}>Дія</TableCell>
                    )}
                </TableRow>
              </TableHead>
              <TableBody>{renderUsers}</TableBody>
            </Table>
            {usersCount === 0
              && (status === 'all' && type === 'all' && debouncedName === '' ? (
                <div className="operations-emptyOperations">У базі відсутні користувачі</div>
              ) : (
                <div className="operations-emptyOperations">Не знайдено користувачей за заданими фільтрами</div>
              ))}
            {usersCount > 5 && (
              <div className="users-pagination">
                <Pagination
                  page={page}
                  shape="rounded"
                  color="primary"
                  onChange={handleChangePage}
                  count={Math.ceil(usersCount / perPage)}
                />
                <TablePagination
                  count={usersCount}
                  page={page}
                  component="div"
                  rowsPerPage={perPage}
                  labelRowsPerPage="Відображати:"
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={paginationPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            )}
          </TableContainer>
        </div>
        <AddUser open={isOpenModal} handleClose={onHandleClose} />
      </div>
    </PageWrapper>
  );
};

export default Users;
