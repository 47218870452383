import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TableCell, Button, TableRow, Select, MenuItem, InputAdornment,
  FormControl, TextField, Tooltip
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import ReplayCircleFilledRoundedIcon from '@mui/icons-material/ReplayCircleFilledRounded';
import { useDynamicForm } from '../../../../hooks/useDynamicForm';
import { MenuPropsInTableRow } from './consts';
import { calcTotalCash } from '../../../../utils/helpers';
import { translations } from '../../../../constants/CashTransitTranslations';

const StepTwoRenderOperationRowCrossCourse = ({
  order,
  index,
  removeOperation,
  editOperation,
  cashierCurrency,
  setErrorMassage,
  setIsEditOperation,
  isEditOperation
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const { data, multiSet, clearData, isAllEmpty } = useDynamicForm({
    ...order
  });

  useEffect(() => {
    if (isEditOperation !== order.localId && isEditOperation !== '') {
      setIsEdit(false);
      clearData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditOperation, order]);

  const renderCurrencies = useMemo(() => cashierCurrency?.filter((item) => item.title !== 'UAH')
    .map((item) => (
      <MenuItem key={item.id} value={item.id}>
        {item.title}
      </MenuItem>
    )), [cashierCurrency]);

  const editText = (elmValue, key, elmCurId, elmCurKey) => {
    const secondKey = key === 'arrival_value' ? 'expense_value' : 'arrival_value';
    if (elmCurId === 1) {
      return (
        <div className="cash-transit-input">
          <FormControl sx={{ minWidth: 100, maxWidth: 210 }}>
            <TextField
              sx={{ color: '#fff' }}
              size="small"
              required
              disabled
              type="number"
              onWheel={(e) => e.target.blur()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {elmCurId ? cashierCurrency.find((item) => item.id === elmCurId).title.toUpperCase() : ''}
                  </InputAdornment>
                ),
                inputProps: {
                  min: 0.01,
                  step: 0.01
                }
              }}
              value={elmValue || ''}
            />
          </FormControl>
        </div>
      );
    }
    return (
      <div className="cash-transit-input">
        <FormControl sx={{ minWidth: 80 }} size="small">
          <Select
            required
            autoWidth
            disabled={!isEdit}
            className="buy-sell-page-currencyTitle"
            defaultValue={elmCurId}
            value={elmCurId}
            MenuProps={MenuPropsInTableRow}
            onChange={(e) => {
              multiSet({
                rate: cashierCurrency.find((item) => item.id === e.target.value).sellRate,
                [elmCurKey]: e.target.value,
                [secondKey]: calcTotalCash(
                  elmValue,
                  cashierCurrency.find((item) => item.id === e.target.value).sellRate
                )
              });
            }}
          >
            {renderCurrencies}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 100, maxWidth: 160 }}>
          <TextField
            sx={{ color: '#fff' }}
            size="small"
            required
            disabled={!isEdit}
            type="number"
            onWheel={(e) => e.target.blur()}
            InputProps={{
              inputProps: {
                min: 1,
                step: 1
              }
            }}
            value={elmValue || ''}
            onChange={(e) => {
              multiSet({
                [key]: e.target.value.replace(/[^0-9]/g, ''),
                [secondKey]: calcTotalCash(e.target.value, data.rate)
              });
            }}
          />
        </FormControl>
      </div>
    );
  };

  const renderRate = useMemo(() => {
    const EditableValue = data.arrival_currency_id === 1 ? 'arrival_value' : 'expense_value';
    const unEditableValue = data.arrival_currency_id === 1 ? data.expense_value : data.arrival_value;
    return (
      <div className="cash-transit-input">
        <FormControl>
          <TextField
            sx={{ color: '#fff' }}
            size="small"
            required
            disabled={!isEdit}
            type="number"
            onWheel={(e) => e.target.blur()}
            InputProps={{
              inputProps: {
                min: 0.01,
                step: 0.01
              }
            }}
            value={data.rate || ''}
            onChange={(e) => {
              multiSet({
                rate: e.target.value.replace(/[^0-9,.]/g, ''),
                [EditableValue]: calcTotalCash(unEditableValue, e.target.value)
              });
            }}
          />
        </FormControl>
      </div>
    );
  }, [data, isEdit, multiSet]);

  return (
    <TableRow>
      <TableCell sx={{ pl: 0, width: 125 }}>{translations.orderType[data.order_item_type]}</TableCell>
      <TableCell sx={{ p: 0, width: 90 }}>
        {renderRate}
      </TableCell>
      <TableCell sx={{ pl: 0, maxWidth: 135, minWidth: 60 }}>
        {editText(data.arrival_value, 'arrival_value', data.arrival_currency_id, 'arrival_currency_id')}
      </TableCell>
      <TableCell sx={{ pl: 0, maxWidth: 135, minWidth: 60 }}>
        {editText(data.expense_value, 'expense_value', data.expense_currency_id, 'expense_currency_id')}
      </TableCell>
      <TableCell align="right" sx={{ pl: 0, pr: 0, width: 160 }}>
        <Tooltip title={isEdit ? 'Зберегти' : 'Редагувати'}>
          <Button
            sx={{ mr: 0.8 }}
            disabled={isAllEmpty || +data.expense_value === 0 || +data.arrival_value === 0 || +data.rate === 0}
            onClick={() => {
              setErrorMassage('');
              setIsEdit(!isEdit);
              setIsEditOperation(order.localId);
              if (isEdit) {
                editOperation({ ...data }, index);
                setIsEditOperation('');
              }
            }}
            variant="contained"
            color={isEdit ? 'success' : 'warning'}
            size="small"
          >
            {isEdit ? <SaveRoundedIcon fontSize="small" /> : <EditRoundedIcon fontSize="small" />}
          </Button>
        </Tooltip>
        {isEdit ? (
          <Tooltip title="Відмінити">
            <Button
              sx={{ mr: 0.8 }}
              onClick={() => {
                setIsEdit(!isEdit);
                clearData();
                setIsEditOperation('');
              }}
              variant="contained"
              color="warning"
              size="small"
            >
              <ReplayCircleFilledRoundedIcon fontSize="small" />
            </Button>
          </Tooltip>
        ) : (
          ''
        )}
      </TableCell>
      <TableCell sx={{ pl: 0, pr: 0, maxWidth: 50, minWidth: 50 }}>
        <Tooltip title="Видалити">
          <Button
            onClick={() => {
              removeOperation(index);
              setErrorMassage('');
              setIsEditOperation('');
            }}
            variant="contained"
            color="error"
            size="small"
          >
            <CancelIcon fontSize="small" />
          </Button>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

StepTwoRenderOperationRowCrossCourse.propTypes = {
  order: PropTypes.object,
  index: PropTypes.number,
  cashierCurrency: PropTypes.array,
  removeOperation: PropTypes.func.isRequired,
  setErrorMassage: PropTypes.func.isRequired,
  setIsEditOperation: PropTypes.func,
  isEditOperation: PropTypes.string,
  editOperation: PropTypes.func.isRequired
};

export default StepTwoRenderOperationRowCrossCourse;
