import React from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded';
import CreditScoreRoundedIcon from '@mui/icons-material/CreditScoreRounded';
import { DeliveryTruck } from '../../../assets/delivery-truck';
import { BackDeliveryTruck } from '../../../assets/back-delivery-truck';
import { Return } from '../../../assets/return';
import { translations } from '../../../constants/CashTransitTranslations';
import { ButtonNewStyles, ButtonCancelStyles, ButtonProcessStyles,
  ButtonCompleteStyles, ButtonOtherExpensesStyles, ButtonOnRoadStyles } from '../../../constants/StatusButtons';

const ButtonNew = styled(Button)(ButtonNewStyles);
const ButtonCancel = styled(Button)(ButtonCancelStyles);
const ButtonProcess = styled(Button)(ButtonProcessStyles);
const ButtonOnRoad = styled(Button)(ButtonOnRoadStyles);
const ButtonComplete = styled(Button)(ButtonCompleteStyles);
const ButtonReturn = styled(Button)(ButtonOtherExpensesStyles);

export const OrderStatusButton = ({ status }) => {
  switch (status) {
    case 'Ожидает подтверждения': {
      return (
        <>
          <ButtonNew fullWidth startIcon={<RequestQuoteRoundedIcon />}>
            {translations.currentStatus[status]}
          </ButtonNew>
        </>
      );
    }
    case 'Подтверждено': {
      return (
        <>
          <ButtonProcess fullWidth startIcon={<CreditScoreRoundedIcon />}>
            {translations.currentStatus[status]}
          </ButtonProcess>
        </>
      );
    }
    case 'Едет в кассу': {
      return (
        <>
          <ButtonComplete fullWidth className="status-button">
            <DeliveryTruck />
            {translations.currentStatus[status]}
          </ButtonComplete>
        </>
      );
    }
    case 'Возврат-едет в кассу': {
      return (
        <>
          <ButtonReturn fullWidth className="status-button">
            <BackDeliveryTruck />
            {translations.currentStatus[status]}
          </ButtonReturn>
        </>
      );
    }
    case 'Возвращено': {
      return (
        <>
          <ButtonReturn fullWidth className="status-button-return">
            <Return />
            {translations.currentStatus[status]}
          </ButtonReturn>
        </>
      );
    }
    case 'Выполнено': {
      return (
        <>
          <ButtonOnRoad fullWidth startIcon={<VerifiedRoundedIcon />}>
            {translations.currentStatus[status]}
          </ButtonOnRoad>
        </>
      );
    }
    case 'Отменено': {
      return (
        <>
          <ButtonCancel fullWidth startIcon={<DeleteForeverRoundedIcon />}>
            {translations.currentStatus[status]}
          </ButtonCancel>
        </>
      );
    }
    default: {
      return translations.orderStatus[status];
    }
  }
};

OrderStatusButton.propTypes = {
  status: PropTypes.string
};
