import React from 'react';

export const Return = () => (
  <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.01 28">
    <g id="Layer_1" data-name="Layer 1">
      <path d="m9.51,18.01l-2.38-2.4c-.39-.39-.39-1.02,0-1.41,0,0,0,0,0,0l.07-.07c.39-.39,1.03-.39,1.42,0l1.61,1.62,5.15-5.16c.39-.39,1.03-.39,1.42,0l.07.07c.39.39.39,1.02,0,1.41l-5.92,5.94c-.41.39-1.04.39-1.44,0Zm0,0l-2.38-2.4c-.39-.39-.39-1.02,0-1.41,0,0,0,0,0,0l.07-.07c.39-.39,1.03-.39,1.42,0l1.61,1.62,5.15-5.16c.39-.39,1.03-.39,1.42,0l.07.07c.39.39.39,1.02,0,1.41l-5.92,5.94c-.41.39-1.04.39-1.44,0Z" />
      <g>
        <path d="m4.22,28c-1.16-1.05-2.32-2.09-3.49-3.14-.39-.35-.68-.74-.72-1.29-.04-.58.16-1.06.58-1.43,1.21-1.09,2.42-2.16,3.63-3.24,0,0,.03-.01.06-.03.39.44.79.89,1.2,1.35-.84.75-1.68,1.49-2.56,2.28.13,0,15.15,0,15.22,0,.99,0,1.97-.01,2.96,0,.66.01,1.09-.4,1.08-1.08-.02-2.59,0-5.17,0-7.76v-.27h1.83c0,.06,0,.13,0,.2,0,2.64,0,5.27,0,7.91,0,1.45-.94,2.57-2.36,2.8-.17.03-.34.03-.51.03-1.01,0-2.03,0-3.04,0-.07,0-15.09,0-15.22,0,.89.8,1.73,1.55,2.58,2.32-.32.36-.63.7-.94,1.05-.09.1-.17.21-.25.31h-.04Z" />
        <path d="m18.55,1.36c.41-.46.81-.9,1.22-1.36.22.2.44.39.66.59.97.87,1.95,1.75,2.92,2.62.5.45.74.99.63,1.66-.06.38-.25.7-.53.96-1.23,1.1-2.46,2.2-3.71,3.31-.41-.45-.8-.9-1.22-1.36.84-.75,1.67-1.49,2.54-2.27-.1,0-8.01-.02-8.07-.02-1.04,0-9.09,0-10.13,0-.66,0-1.02.37-1.02,1.02,0,2.61,0,5.22,0,7.83v.27H.02c0-.05-.01-.11-.01-.16,0-2.66,0-5.32,0-7.98,0-1.42.94-2.53,2.32-2.77.18-.03.37-.03.56-.03,1.03,0,9.08,0,10.11,0,.07,0,8,0,8.14,0-.89-.8-1.73-1.55-2.58-2.32Z" />
      </g>
    </g>
  </svg>
);
