import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Tooltip, Button } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CancelIcon from '@mui/icons-material/Cancel';

export const VariableItem = ({ variable, onOpenConfirmDelete, setAddEditButton }) => {
  const { title, slug, value, id, required } = variable;
  return (
    <TableRow key={id}>
      <TableCell>{title}</TableCell>
      <TableCell>{slug}</TableCell>
      <TableCell>{value}</TableCell>
      <TableCell align="right">
        <Tooltip title="Редагувати змінну">
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            color="warning"
            size="small"
            onClick={() => {
              setAddEditButton(
                {
                  open: true,
                  editMode: true,
                  selectedVariable: id
                }
              );
            }}
          >
            <EditRoundedIcon fontSize="small" />
          </Button>
        </Tooltip>
        <Tooltip title={required ? 'Ця змінна є обов\'язковою. Її неможливо видалити' : 'Видалити змінну'}>
          <span>
            <Button
              variant="contained"
              color="error"
              size="small"
              disabled={required}
              onClick={() => onOpenConfirmDelete(id)}
            >
              <CancelIcon fontSize="small" />
            </Button>
          </span>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

VariableItem.propTypes = {
  variable: PropTypes.object,
  onOpenConfirmDelete: PropTypes.func,
  setAddEditButton: PropTypes.func
};
