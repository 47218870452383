import { io } from 'socket.io-client';

let socket;

export function offConnect() {
  socket.disconnect();
  socket = null;
}

export function sender(event, data) {
  if (socket && event) {
    socket.emit(event, data);
  }
}

export function init(cashierId, departmentId, events = { }) {
  if (socket) return;

  socket = io(process.env.REACT_APP_NOTIFICATION_URL, {
    reconnectionDelayMax: 10000,
    path: '/exchange-messages/v1/socket.io',
    auth: {
      token: localStorage.getItem('secret')
    },
    query: {
      cashierId,
      departmentId
    }
  });

  Object.keys(events).forEach((eventName) => {
    if (typeof events[eventName] === 'function') socket.on(eventName, events[eventName]);
  });

  return () => {
    Object.keys(events).forEach((eventName) => {
      if (typeof events[eventName] === 'function') socket.off(eventName);
    });

    offConnect();
  };
}

export function initAdmin(adminId, events = { }) {
  if (socket) return;

  socket = io(process.env.REACT_APP_NOTIFICATION_URL, {
    reconnectionDelayMax: 10000,
    path: '/exchange-messages/v1/socket.io',
    auth: {
      token: localStorage.getItem('secret')
    },
    query: {
      adminId
    }
  });

  Object.keys(events).forEach((eventName) => {
    if (typeof events[eventName] === 'function') socket.on(eventName, events[eventName]);
  });

  return () => {
    Object.keys(events).forEach((eventName) => {
      if (typeof events[eventName] === 'function') socket.off(eventName);
    });

    offConnect();
  };
}
