import React, { useContext, useMemo, useState } from 'react';
import moment from 'moment';
import './style.scss';
import isEmpty from 'lodash.isempty';
import { Button, Container } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AuthorisationContext } from '../../components/AuthorisationContext';
import { isUndefined } from '../../../utils/isUndefined';
import AddUser from '../../components/AddUser/AddUser';

const Profile = () => {
  const { user } = useContext(AuthorisationContext);
  const {
    start_date: startDate,
    role,
    phone,
    access,
    status,
    lastname,
    firstname,
    patronymic,
    telegram_chat_id: telegram,
    additional_contact: additionalContact
  } = user;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const renderCity = useMemo(() => {
    if (!isEmpty(user) && !isUndefined(user.cities)) {
      const { cities } = user;
      let str = '';
      cities.map((item) => {
        str += `${item.title}, `;
        return null;
      });
      return str.substr(0, str.length - 2);
    }
  }, [user]);

  return (
    <Container className="selectedUser">
      <div className="selectedUser__head">
        <div className="selectedUser__name">
          <AccountCircleIcon sx={{ mr: '20px', fontSize: '30px' }} />
          <p className="selectedUser-title">{`${lastname} ${firstname} ${patronymic}`}</p>
        </div>
        <Button variant="contained" size="large" onClick={() => setIsOpenModal(true)}>Редагувати профіль</Button>
      </div>
      <div className="selectedUser-options">
        <hr />
        <div className="selectedUser-options-item">
          <span>Дата оформлення</span>
          <span className="selectedUser-options-item-value">{moment(startDate).format('DD.MM.YYYY') || '-'}</span>
        </div>
        <hr />
        <div className="selectedUser-options-item">
          <span>Місто</span>
          <span className="selectedUser-options-item-value">{renderCity || '-'}</span>
        </div>
        <hr />
        <div className="selectedUser-options-item">
          <span>Посада</span>
          <span className="selectedUser-options-item-value">{!isUndefined(role) ? role.title : '-'}</span>
        </div>
        <hr />
        <div className="selectedUser-options-item">
          <span>Телефон</span>
          <span className="selectedUser-options-item-value">{phone || '-'}</span>
        </div>
        <hr />
        <div className="selectedUser-options-item">
          <span>Телеграм</span>
          <span className="selectedUser-options-item-value">{telegram || '-'}</span>
        </div>
        <hr />
        <div className="selectedUser-options-item">
          <span>Додатковий контакт</span>
          <span className="selectedUser-options-item-value">{additionalContact || '-'}</span>
        </div>
        <hr />
        <div className="selectedUser-options-item">
          <span>Доступ</span>
          <span className="selectedUser-options-item-value">{access ? 'Дозволено' : 'Заборонено'}</span>
        </div>
        <hr />
        <div className="selectedUser-options-item">
          <span>Статус</span>
          <span className="selectedUser-options-item-value">{status ? 'Активний' : 'Неактивний'}</span>
        </div>
      </div>
      <AddUser isEdit isSelfEdit open={isOpenModal} handleClose={() => setIsOpenModal(false)} />
    </Container>
  );
};

export default Profile;
