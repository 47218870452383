import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Tooltip } from '@mui/material';
import DriveFileMoveRoundedIcon from '@mui/icons-material/DriveFileMoveRounded';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { NavLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

export const SessionItem = ({ item }) => {
  const {
    id,
    start_time: startTime,
    end_time: endTime,
    user: { lastname, firstname, patronymic },
    department: { title } } = item;
  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>{`${lastname} ${firstname} ${patronymic}`}</TableCell>
      <TableCell>{title}</TableCell>
      <TableCell>{startTime}</TableCell>
      <TableCell>{endTime}</TableCell>
      <TableCell align="center">
        <Tooltip title="Переглянути усі операції за сесію">
          <NavLink to={`/admin/operations/${id}`} target="_blank">
            <LoadingButton variant="contained">
              <DriveFileMoveRoundedIcon />
            </LoadingButton>
          </NavLink>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <Tooltip title="Переглянути звіт по сесії">
          <NavLink to={`/session-report/${id}`} target="_blank">
            <LoadingButton variant="contained">
              <SummarizeIcon />
            </LoadingButton>
          </NavLink>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

SessionItem.propTypes = {
  item: PropTypes.object
};
