import React from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import CompareArrowsRoundedIcon from '@mui/icons-material/CompareArrowsRounded';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { SackDollar } from '../../../assets/sack-dollar-solid';
import { translations } from '../../../constants/OperationsTranslations';
import { ButtonBuyStyles, ButtonSellStyles, ButtonCashTransitStyles,
  ButtonCrossCourseStyles, ButtonCompleteStyles, ButtonOtherExpensesStyles } from '../../../constants/StatusButtons';

const ButtonBuy = styled(Button)(ButtonBuyStyles);
const ButtonSell = styled(Button)(ButtonSellStyles);
const ButtonCrossCourse = styled(Button)(ButtonCrossCourseStyles);
const ButtonComplete = styled(Button)(ButtonCompleteStyles);
const ButtonOtherExpenses = styled(Button)(ButtonOtherExpensesStyles);
const ButtonCashTransit = styled(Button)(ButtonCashTransitStyles);

export const OperationStatusButton = ({ operationStatus }) => {
  switch (operationStatus) {
    case 'Покупка': {
      return (
        <>
          <ButtonBuy fullWidth startIcon={<ArrowCircleDownRoundedIcon />}>
            {translations.type[operationStatus]}
          </ButtonBuy>
        </>
      );
    }
    case 'Продажа': {
      return (
        <>
          <ButtonSell fullWidth startIcon={<ArrowCircleUpRoundedIcon />}>
            {translations.type[operationStatus]}
          </ButtonSell>
        </>
      );
    }
    case 'Инкассация': {
      return (
        <>
          <ButtonCashTransit fullWidth startIcon={<AccountBalanceIcon />}>
            {translations.type[operationStatus]}
          </ButtonCashTransit>
        </>
      );
    }
    case 'Кросс-курс': {
      return (
        <>
          <ButtonCrossCourse fullWidth startIcon={<CompareArrowsRoundedIcon />}>
            {translations.type[operationStatus]}
          </ButtonCrossCourse>
        </>
      );
    }
    case 'Прочие доходы': {
      return (
        <>
          <ButtonComplete className="operations-status-button" fullWidth>
            <SackDollar />
            {translations.type[operationStatus]}
          </ButtonComplete>
        </>
      );
    }
    case 'Прочие расходы': {
      return (
        <>
          <ButtonOtherExpenses fullWidth startIcon={<ShoppingCartRoundedIcon />}>
            {translations.type[operationStatus]}
          </ButtonOtherExpenses>
        </>
      );
    }
    default: {
      return translations.type[operationStatus];
    }
  }
};

OperationStatusButton.propTypes = {
  operationStatus: PropTypes.string
};
