import React, { useContext, useState, useCallback } from 'react';
import { TextField, Grid } from '@mui/material';
import SimCardDownloadRoundedIcon from '@mui/icons-material/SimCardDownloadRounded';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DashboardContext } from '../../../components/DashboardContext';

export const ReportFilialIncome = () => {
  const { fetchReportFilialIncome, isFetchingReportFilialIncome } = useContext(DashboardContext);
  const [filialIncomeDates, setFilialIncomeDates] = useState(
    {
      fromDate: moment().format('YYYY-MM-DD 00:00'),
      toDate: moment().format('YYYY-MM-DD HH:mm')
    }
  );

  const downloadReportFilialIncome = useCallback(() => {
    fetchReportFilialIncome({
      datetime_from: moment(filialIncomeDates.fromDate).format('YYYY-MM-DD HH:mm:ss'),
      datetime_to: moment(filialIncomeDates.toDate).format('YYYY-MM-DD HH:mm:ss')
    });
  },
  [filialIncomeDates, fetchReportFilialIncome]);

  return (
    <Grid sx={{ mt: 0, mb: 3, pt: 0 }} container spacing={4} alignItems="stretch">
      <Grid item md={3}>
        <div className="operation-list-selector">
          <LocalizationProvider dateAdapter={MomentUtils} locale="uk">
            <TextField
              fullWidth
              id="datetime-local"
              label="Дата та час з"
              type="datetime-local"
              value={filialIncomeDates.fromDate}
              onChange={(newValue) => setFilialIncomeDates(
                (prevState) => ({ ...prevState, fromDate: newValue.target.value })
              )}
              sx={{ width: 250 }}
              InputLabelProps={{
                shrink: true,
                max: filialIncomeDates.toDate
              }}
              InputProps={{
                inputProps: {
                  max: filialIncomeDates.toDate
                }
              }}
            />
          </LocalizationProvider>
        </div>
      </Grid>
      <Grid item md={3}>
        <div className="operation-list-selector">
          <LocalizationProvider dateAdapter={MomentUtils} locale="uk">
            <TextField
              fullWidth
              id="datetime-local"
              label="Дата та час по"
              type="datetime-local"
              value={filialIncomeDates.toDate}
              onChange={(newValue) => setFilialIncomeDates(
                (prevState) => ({ ...prevState, toDate: newValue.target.value })
              )}
              sx={{ width: 250 }}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                inputProps: {
                  min: filialIncomeDates.fromDate,
                  max: moment().format('YYYY-MM-DD HH:mm')
                }
              }}
            />
          </LocalizationProvider>
        </div>
      </Grid>
      <Grid item md={3}>
        <LoadingButton
          fullWidth
          variant="contained"
          onClick={downloadReportFilialIncome}
          loading={isFetchingReportFilialIncome}
          disabled={moment(filialIncomeDates.fromDate).format('YYYY-MM-DD HH:mm') === moment(filialIncomeDates.toDate).format('YYYY-MM-DD HH:mm')}
        >
          <SimCardDownloadRoundedIcon sx={{ marginRight: '5px' }} />
          Завантажити
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
