import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TableCell, Button, TableRow, Select, MenuItem, Tooltip,
  FormControl, TextField
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import ReplayCircleFilledRoundedIcon from '@mui/icons-material/ReplayCircleFilledRounded';
import { useDynamicForm } from '../../../../hooks/useDynamicForm';
import { MenuPropsInTableRow } from './consts';
import { translations } from '../../../../constants/CashTransitTranslations';

const StepTwoRenderOperationRowBuySell = ({
  orderItems, order, index, removeOperation,
  editOperation, cashierCurrency, setErrorMassage, setIsEditOperation, isEditOperation
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const { data, multiSet, clearData, isAllEmpty } = useDynamicForm({
    ...order
  });

  useEffect(() => {
    if (isEditOperation !== order.localId && isEditOperation !== '') {
      setIsEdit(false);
      clearData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditOperation, order]);

  const renderCurrencies = useCallback((keyName) => {
    const filteredOrders = orderItems?.filter((item) => item.order_item_type === data.order_item_type);
    return (
      cashierCurrency?.map((item) => (
        <MenuItem
          key={item.id}
          value={item.id}
          disabled={filteredOrders
            .filter((elm) => elm[keyName] !== order[keyName])
            .map((elm) => elm[keyName])
            .includes(item.id)}
        >
          {item.title}
        </MenuItem>
      ))
    );
  }, [cashierCurrency, orderItems, data.order_item_type, order]);

  const editText = (elmValue, key, elmCurId, elmCurKey) => {
    if (elmValue || elmCurId) {
      return (
        <div className="cash-transit-input">
          <FormControl sx={{ minWidth: 80 }} size="small">
            <Select
              required
              autoWidth
              disabled={!isEdit}
              className="buy-sell-page-currencyTitle"
              defaultValue={elmCurId}
              value={elmCurId}
              MenuProps={MenuPropsInTableRow}
              onChange={(e) => {
                multiSet({
                  [elmCurKey]: e.target.value
                });
              }}
            >
              {renderCurrencies(elmCurKey)}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 100, maxWidth: 160 }}>
            <TextField
              sx={{ color: '#fff' }}
              size="small"
              required
              disabled={!isEdit}
              type="number"
              onWheel={(e) => e.target.blur()}
              InputProps={{
                inputProps: {
                  min: 0.01,
                  step: 0.01
                }
              }}
              value={elmValue || ''}
              onChange={(e) => {
                multiSet({ [key]: e.target.value.replace(/[^0-9,.]/g, '') });
              }}
            />
          </FormControl>
        </div>
      );
    }
  };

  return (
    <TableRow>
      <TableCell sx={{ pl: 0, width: 125 }}>{translations.orderType[data.order_item_type]}</TableCell>
      <TableCell sx={{ p: 0, width: 90 }} />
      <TableCell sx={{ pl: 0, maxWidth: 140, minWidth: 60 }}>
        {editText(data.arrival_value, 'arrival_value', data.arrival_currency_id, 'arrival_currency_id')}
      </TableCell>
      <TableCell sx={{ pl: 0, maxWidth: 140, minWidth: 60 }}>
        {editText(data.expense_value, 'expense_value', data.expense_currency_id, 'expense_currency_id')}
      </TableCell>
      <TableCell align="right" sx={{ pl: 0, pr: 0, width: 160 }}>
        <Tooltip title={isEdit ? 'Зберегти' : 'Редагувати'}>
          <Button
            sx={{ mr: 0.8 }}
            disabled={isAllEmpty || +data.expense_value === 0 || +data.arrival_value === 0}
            onClick={() => {
              setErrorMassage('');
              setIsEdit(!isEdit);
              setIsEditOperation(order.localId);
              if (isEdit) {
                editOperation({ ...data }, index);
                setIsEditOperation('');
              }
            }}
            variant="contained"
            color={isEdit ? 'success' : 'warning'}
            size="small"
          >
            {isEdit ? <SaveRoundedIcon fontSize="small" /> : <EditRoundedIcon fontSize="small" />}
          </Button>
        </Tooltip>
        {isEdit ? (
          <Tooltip title="Відмінити">
            <Button
              sx={{ mr: 0.8 }}
              onClick={() => {
                setIsEdit(!isEdit);
                clearData();
                setIsEditOperation('');
              }}
              variant="contained"
              color="warning"
              size="small"
            >
              <ReplayCircleFilledRoundedIcon fontSize="small" />
            </Button>
          </Tooltip>
        ) : (
          ''
        )}
      </TableCell>
      <TableCell sx={{ pl: 0, pr: 0, width: 25 }}>
        <Tooltip title="Видалити">
          <Button
            onClick={() => {
              removeOperation(index);
              setErrorMassage('');
              setIsEditOperation('');
            }}
            variant="contained"
            color="error"
            size="small"
          >
            <CancelIcon fontSize="small" />
          </Button>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

StepTwoRenderOperationRowBuySell.propTypes = {
  order: PropTypes.object,
  index: PropTypes.number,
  cashierCurrency: PropTypes.array,
  orderItems: PropTypes.array,
  removeOperation: PropTypes.func.isRequired,
  setErrorMassage: PropTypes.func.isRequired,
  setIsEditOperation: PropTypes.func,
  editOperation: PropTypes.func.isRequired,
  isEditOperation: PropTypes.string
};

export default StepTwoRenderOperationRowBuySell;
