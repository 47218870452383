import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import {
  Grid, FormControl, Select, MenuItem, TextField, InputLabel, InputAdornment, Button
} from '@mui/material';
import moment from 'moment';
import './style.scss';
import PageWrapper from '../../common/PageWrapper/PageWrapper';
import { DashboardContext } from '../../components/DashboardContext';
import { CashierContext } from '../../components/CashierContext';
import { MenuProps, MenuPropsCurrency } from './consts';
import { ModalWindowOtherExpensesConfirm } from '../../components/ModalWindowOtherExpensesConfirm/ModalWindowOtherExpensesConfirm';
import { AuthorisationContext } from '../../components/AuthorisationContext';

export const OtherExpensesCashierPage = () => {
  const { cashierData } = useContext(AuthorisationContext);
  const { fetchOtherExpenses, expenses, clearExpensesErrors
  } = useContext(DashboardContext);
  const { cashierCurrency, fetchCashierCurrency, sessionData } = useContext(CashierContext);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [values, setValues] = useState({
    category: '',
    categoryItem: '',
    value: '',
    currency_id: '',
    count: '',
    comment: ''
  });
  useEffect(() => {
    fetchOtherExpenses({
      current_session: sessionData.id
    });
  }, [fetchOtherExpenses, sessionData]);

  useEffect(() => {
    fetchCashierCurrency({
      department_id: cashierData.id,
      date: moment().format('YYYY-MM-DD'),
      id: cashierData.id
    });
  }, [fetchCashierCurrency, cashierData]);

  const renderCurrencies = useMemo(() => cashierCurrency?.map(({ id, title }) => (
    <MenuItem key={id} value={id}>{title}</MenuItem>
  )), [cashierCurrency]);

  const renderCategories = useMemo(() => (expenses?.map(
    ({ id, title }) => <MenuItem key={id} value={id}>{title}</MenuItem>
  )), [expenses]);

  const renderExpenses = useMemo(() => {
    if (expenses) {
      const expensesItems = expenses.find((item) => item.id === values.category)?.items;
      return expensesItems?.map(
        ({ id, title }) => <MenuItem key={id} value={id}>{title}</MenuItem>
      );
    }
  }, [expenses, values.category]);

  const disableExpensesSelector = useMemo(() => {
    if (values.category === '') return true;
    if (expenses) {
      if (expenses.find((item) => item.id === values.category).items.length === 0) return true;
    }
  }, [values.category, expenses]);

  const handleClose = useCallback(() => {
    setIsOpenModal(false);
    clearExpensesErrors();
  }, [clearExpensesErrors]);

  const isDisable = useMemo(() => {
    if (values.value === '' || !('currency_id' in values)) return true;
  }, [values]);

  return (
    <PageWrapper>
      <Grid className="input-adornment" sx={{ mt: 4, mb: 3 }} container spacing={4} alignItems="center" justifyContent="center">
        <Grid item md={2} />
        <Grid item md={4}>
          <FormControl className="operation-list-selector" fullWidth>
            <InputLabel>Категорія витрат</InputLabel>
            <Select
              id="id-category"
              label="Категорія витрат"
              value={values.category}
              MenuProps={MenuProps}
              onChange={(e) => setValues({ category: e.target.value, categoryItem: '', value: '', count: '', comment: '' })}
            >
              {renderCategories}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <FormControl className="operation-list-selector" fullWidth>
            <InputLabel>
              {expenses.find((item) => item.id === values.category)?.items.length === 0 ? 'Категорія порожня' : 'Позиція витрат'}
            </InputLabel>
            <Select
              id="id-expenses-item"
              label="Позиція витрат"
              disabled={disableExpensesSelector}
              value={values.categoryItem}
              MenuProps={MenuProps}
              onChange={(e) => setValues((prev) => ({ ...prev, categoryItem: e.target.value, value: '', count: '', comment: '' }))}
            >
              {renderExpenses}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} />
        <Grid item md={3}>
          <FormControl className="operation-list-selector" fullWidth size="normal">
            <TextField
              type="number"
              onWheel={(e) => e.target.blur()}
              id="price"
              name="price"
              label="Сума"
              disabled={values.categoryItem === ''}
              value={values.value}
              onChange={(e) => setValues((prev) => ({ ...prev, value: e.target.value }))}
              InputProps={{
                inputProps: {
                  min: 0.01,
                  step: 0.01
                }
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <FormControl className="operation-list-selector" fullWidth size="normal">
            <InputLabel>Валюта</InputLabel>
            <Select
              required
              autoWidth
              className="buy-sell-page-currencyTitle"
              id="currencyTitle"
              name="currencyTitle"
              label="Валюта"
              placeholder="Валюта"
              disabled={values.categoryItem === ''}
              value={values.currency_id}
              MenuProps={MenuPropsCurrency}
              onChange={(e) => {
                const value = cashierCurrency.find(({ id }) => id === e.target.value)?.title;
                setValues((prev) => ({ ...prev, currency_id: e.target.value, currencyTitle: value }));
              }}
            >
              {renderCurrencies}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3}>
          <FormControl className="operation-list-selector" fullWidth size="normal">
            <TextField
              className="buy-sell-page-totalCash"
              type="number"
              onWheel={(e) => e.target.blur()}
              id="count"
              name="count"
              label="Кількість"
              disabled={values.categoryItem === ''}
              value={values.count}
              onChange={(e) => setValues((prev) => ({ ...prev, count: e.target.value }))}
              InputProps={{
                maxLength: 12,
                endAdornment: (
                  <InputAdornment position="end">Шт</InputAdornment>
                ),
                inputProps: {
                  min: 0
                }
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={8}>
          <FormControl className="operation-list-selector" fullWidth size="normal">
            <TextField
              type="text"
              multiline
              rows={2}
              id="comment"
              name="comment"
              label="Коментар"
              disabled={values.categoryItem === ''}
              value={values.comment}
              inputProps={{ maxLength: 255 }}
              onChange={(e) => setValues((prev) => ({ ...prev, comment: e.target.value }))}
            />
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <Grid container justifyContent="center">
            <Button
              disabled={isDisable}
              variant="contained"
              onClick={() => setIsOpenModal(true)}
            >
              Оформити
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {isOpenModal
      && (
      <ModalWindowOtherExpensesConfirm
        open={isOpenModal}
        data={values}
        handleClose={handleClose}
        isExpense
      />
      )}
    </PageWrapper>
  );
};
