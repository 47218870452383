import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, InputLabel, Select, MenuItem, Grid
} from '@mui/material';
import { DashboardContext } from '../../../components/DashboardContext';
import { MenuPropsInStepOne } from './consts';

const StepOneCentralDep = ({
  sessionDataId,
  depId,
  defaultFilial,
  inkassators,
  cashTransfer: {
    department_receiver_id: selectedDepartment,
    filial_id: filialId,
    inkas_id: selectedInkassator
  },
  setCashTransit,
  clearSelected
}) => {
  const {
    fetchFilials, filials,
    fetchDepartments, departments
  } = useContext(DashboardContext);
  useEffect(() => {
    fetchFilials();
  }, [fetchFilials]);

  useEffect(() => {
    fetchDepartments({
      current_session: sessionDataId,
      filial_id: filialId,
      isCentral: filialId === defaultFilial ? 0 : 1,
      isActive: 1
    });
  }, [fetchDepartments, filialId, sessionDataId, defaultFilial]);

  const renderInkassators = useMemo(() => inkassators?.map((item) => (
    <MenuItem key={item.id} value={item.id}>{`${item.firstname} ${item.lastname}`}</MenuItem>
  )), [inkassators]);

  const renderDepartments = useMemo(() => departments?.filter((item) => (item.id !== depId)).map((item) => (
    <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
  )), [departments, depId]);

  const renderFilials = useMemo(() => filials?.map((item) => (
    <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
  )), [filials]);

  return (
    <Grid sx={{ mt: 4, mb: 4 }} container spacing={2} justifyContent="center" alignItems="center">
      <Grid item md={3}>
        <FormControl className="operation-list-selector" fullWidth>
          <InputLabel>Регіон</InputLabel>
          <Select
            label="Регіон"
            value={renderFilials ? filialId : ''}
            MenuProps={MenuPropsInStepOne}
            onChange={(e) => {
              clearSelected();
              setCashTransit('filial_id')(e);
            }}
          >
            {renderFilials}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={5}>
        <FormControl className="operation-list-selector" fullWidth>
          <InputLabel>Відділення</InputLabel>
          <Select label="Відділення" MenuProps={MenuPropsInStepOne} value={selectedDepartment} onChange={setCashTransit('department_receiver_id')}>
            {renderDepartments}
          </Select>
        </FormControl>

      </Grid>
      <Grid item md={3}>
        <FormControl className="operation-list-selector" fullWidth>
          <InputLabel>Інкасатор</InputLabel>
          <Select label="Інкасатор" MenuProps={MenuPropsInStepOne} value={selectedInkassator} onChange={setCashTransit('inkas_id')}>
            {renderInkassators}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

StepOneCentralDep.propTypes = {
  sessionDataId: PropTypes.number,
  depId: PropTypes.number,
  inkassators: PropTypes.array,
  defaultFilial: PropTypes.number,
  cashTransfer: PropTypes.object,
  setCashTransit: PropTypes.func,
  clearSelected: PropTypes.func
};

export default StepOneCentralDep;
