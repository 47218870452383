import React, { useCallback, useEffect, useContext, useState, useMemo } from 'react';
import './style.scss';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import 'moment/locale/uk';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Table, TablePagination, TableBody, TableCell, Grid,
  TableContainer, TableHead, TableRow, Pagination,
  FormControl, Select, MenuItem, TextField, InputLabel
} from '@mui/material';
import { nanoid } from 'nanoid';
import { isNull } from '../../../utils/isNull';
import PageWrapper from '../../common/PageWrapper/PageWrapper';
import { DashboardContext } from '../../components/DashboardContext';
import { CashierContext } from '../../components/CashierContext';
import { AuthorisationContext } from '../../components/AuthorisationContext';
import OperationsItems from './OperationsItems';
import { MenuProps, MenuPropsCurrency } from './consts';
import { translations } from '../../../constants/OperationsTranslations';
import OperationsTable from '../../components/OperationsTable/OperationsTable';

moment.locale('uk');

const OperationsListPage = () => {
  const { cashierData } = useContext(AuthorisationContext);
  const { sessionData } = useContext(CashierContext);
  const {
    fetchOperations, operations, operationsCount,
    fetchOperationsFilter, operationsFilters, cashierOperationsStornoError
  } = useContext(DashboardContext);
  const [operationFilterValues, setFilter] = useState({
    session: 'all',
    fromDate: moment(),
    toDate: moment(),
    type: 'all',
    storno: 'all',
    currency: 'all',
    page: 1,
    perPage: 25
  });

  useEffect(() => {
    const { type, storno, currency, session, fromDate, toDate, page, perPage } = operationFilterValues;
    fetchOperations({
      current_session: sessionData.id,
      page,
      per_page: perPage,
      department_id: cashierData.id,
      currency_id: currency === 'all' ? '' : currency,
      date_to: moment(toDate).format('DD.MM.YYYY'),
      date_from: isNull(fromDate) ? '' : moment(fromDate).format('DD.MM.YYYY'),
      operation_type: type === 'all' ? '' : type,
      storno: storno === 'all' ? '' : storno,
      session_id: session === 'all' ? '' : session
    });
  }, [fetchOperations, operationFilterValues, sessionData.id, cashierData.id]);
  useEffect(() => {
    fetchOperationsFilter({
      current_session: sessionData.id,
      date_to: moment(operationFilterValues.toDate).format('DD.MM.YYYY'),
      date_from: isNull(operationFilterValues.fromDate) ? '' : moment(operationFilterValues.fromDate).format('DD.MM.YYYY')
    });
  }, [fetchOperationsFilter, sessionData.id, operationFilterValues]);

  const handleChange = useCallback((idx) => (e) => {
    setFilter((prev) => ({ ...prev, [idx]: e.target.value, page: 1 }));
  }, []);

  const handleChangeDate = useCallback((idx) => (newValue) => {
    setFilter((prev) => ({ ...prev, [idx]: newValue, page: 1 }));
  }, []);

  const handleChangePage = (event, newPage) => {
    setFilter((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter((prev) => ({ ...prev, page: 1, perPage: parseInt(event.target.value, 10) }));
  };

  const renderOperations = useMemo(() => {
    if (isEmpty(operations)) {
      return null;
    }
    if (!isEmpty(operations)) {
      return operations?.map((item) => (
        <OperationsItems item={item} key={item.id} />
      ));
    }
  }, [operations]);

  const renderSessions = useMemo(() => {
    if (!isEmpty(operationsFilters.sessions)) {
      return Object.keys(operationsFilters.sessions).map((item) => (
        <MenuItem key={item} value={item}>{operationsFilters.sessions[item]}</MenuItem>
      ));
    }
  }, [operationsFilters]);

  const renderCurrencies = useMemo(() => {
    if (!isEmpty(operationsFilters.currency)) {
      return operationsFilters.currency?.map(({ id, title }) => (
        <MenuItem key={id} value={id}>{title}</MenuItem>
      ));
    }
  }, [operationsFilters.currency]);
  const renderOperationType = (label, data) => data?.map((item) => (
    <MenuItem key={nanoid()} value={item}>{translations[label][item]}</MenuItem>
  ));

  return (
    <PageWrapper>
      <span>{cashierOperationsStornoError || ''}</span>
      <Grid sx={{ mt: 0, mb: 3, pt: 0 }} container spacing={4} alignItems="left">
        <Grid item md={5}>
          <Grid sx={{ mt: 0, mb: 3, pt: 0 }} container spacing={2} alignItems="left">
            <Grid item md={12}>
              <FormControl className="operation-list-selector" fullWidth>
                <InputLabel>Тип операції</InputLabel>
                <Select
                  label="Тип операції"
                  value={operationFilterValues.type}
                  onChange={handleChange('type')}
                >
                  <MenuItem value="all">Усі</MenuItem>
                  {renderOperationType('type', operationsFilters.type)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl className="operation-list-selector" fullWidth>
                <InputLabel>Валюта</InputLabel>
                <Select
                  label="Валюта"
                  value={operationFilterValues.currency}
                  MenuProps={MenuPropsCurrency}
                  onChange={handleChange('currency')}
                >
                  <MenuItem value="all">Усі</MenuItem>
                  {renderCurrencies}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl className="operation-list-selector" fullWidth>
                <InputLabel>Помилка</InputLabel>
                <Select
                  label="Помилка"
                  value={operationFilterValues.storno}
                  onChange={handleChange('storno')}
                >
                  <MenuItem value="all">Усі</MenuItem>
                  {renderOperationType('storno', operationsFilters.storno)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <FormControl className="operation-list-selector" fullWidth>
                <InputLabel>Сесії</InputLabel>
                <Select
                  label="Сесії"
                  value={operationFilterValues.session}
                  onChange={handleChange('session')}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="all">Усі</MenuItem>
                  {renderSessions}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <div className="operation-list-selector">
                <LocalizationProvider dateAdapter={MomentUtils} locale="uk">
                  <DatePicker
                    fullWidth
                    mask="__.__.____"
                    label="Дати з"
                    value={operationFilterValues.fromDate}
                    minDate={moment(operationsFilters.date_from, 'DD.MM.YYYY')}
                    maxDate={operationFilterValues.toDate}
                    onChange={handleChangeDate('fromDate')}
                    renderInput={(params) => <TextField mask="YYYY-MM-DD" {...params} />}
                    inputFormat="DD.MM.YYYY"
                    disableMaskedInput
                  />
                </LocalizationProvider>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className="operation-list-selector">
                <LocalizationProvider dateAdapter={MomentUtils} locale="uk">
                  <DatePicker
                    mask="__.__.____"
                    label="Дати по"
                    value={operationFilterValues.toDate}
                    minDate={isNull(operationFilterValues.fromDate) ? null : operationFilterValues.fromDate}
                    maxDate={moment()}
                    onChange={handleChangeDate('toDate')}
                    renderInput={(params) => <TextField mask="YYYY-MM-DD" {...params} />}
                    inputFormat="DD.MM.YYYY"
                    disableMaskedInput
                  />
                </LocalizationProvider>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={7}>
          <OperationsTable sessionId={sessionData.id} filter={operationFilterValues} />
        </Grid>

      </Grid>
      <div className="users-table">
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ pl: 0 }}>ID</TableCell>
                <TableCell component="th">Тип</TableCell>
                <TableCell component="th" align="left" sx={{ minWidth: 140 }}>Прихід</TableCell>
                <TableCell component="th" align="left" sx={{ minWidth: 140 }}>Розхід</TableCell>
                <TableCell component="th" align="left" sx={{ minWidth: 140 }}>Курс</TableCell>
                <TableCell component="th" align="left" sx={{ width: 120 }}>Касир</TableCell>
                <TableCell component="th" align="left" sx={{ width: 110 }}>Дата/час</TableCell>
                <TableCell component="th" align="center">Відміна</TableCell>
                <TableCell sx={{ width: 43 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {renderOperations}
            </TableBody>
          </Table>
          <div className="operations-emptyOperations">{isEmpty(operations) && <p>Список операцій порожній</p>}</div>
          {!isEmpty(operations) && (
          <div className="users-pagination">
            <Pagination
              page={operationFilterValues.page}
              shape="rounded"
              color="primary"
              onChange={handleChangePage}
              count={Math.ceil(operationsCount / operationFilterValues.perPage)}
            />
            <TablePagination
              count={100}
              page={operationFilterValues.page}
              component="div"
              rowsPerPage={operationFilterValues.perPage}
              labelRowsPerPage="Відображати:"
              onPageChange={handleChangePage}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          )}
        </TableContainer>
      </div>
    </PageWrapper>
  );
};

export default OperationsListPage;
