import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, InputLabel, Select, MenuItem, Grid
} from '@mui/material';
import isEmpty from 'lodash.isempty';
import { DashboardContext } from '../../../components/DashboardContext';

const StepOneRegionalDep = ({
  sessionDataId,
  cashTransfer: {
    department_receiver_id: selectedDepartment,
    filial_id: filialId
  },
  setCashTransit
}) => {
  const {
    fetchDepartments, departments
  } = useContext(DashboardContext);

  useEffect(() => {
    fetchDepartments({
      current_session: sessionDataId,
      filial_id: filialId,
      isCentral: 1,
      isActive: 1
    });
  }, [fetchDepartments, filialId, sessionDataId]);

  const renderDepartments = useMemo(() => {
    if (isEmpty(departments)) {
      return null;
    }
    if (!isEmpty(departments)) {
      return departments.map((item) => (
        <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
      ));
    }
  }, [departments]);

  return (
    <Grid sx={{ mt: 4, mb: 4 }} container spacing={2} justifyContent="center" alignItems="center">
      <Grid item md={6}>
        <FormControl className="operation-list-selector" fullWidth>
          <InputLabel>Відділення</InputLabel>
          <Select label="Відділення" value={selectedDepartment} onChange={setCashTransit('department_receiver_id')}>
            {renderDepartments}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

StepOneRegionalDep.propTypes = {
  sessionDataId: PropTypes.number,
  cashTransfer: PropTypes.object,
  setCashTransit: PropTypes.func
};

export default StepOneRegionalDep;
