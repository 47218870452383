import { useEffect, useState } from 'react';
import { ms } from '../utils/helpers';

export const useSessionEndMessage = (currentSessions) => {
  const [openModal, setOpenModal] = useState(false);
  const [userMessage, setUserMessage] = useState('');
  const endTimeSessions = currentSessions.map(({ department, sessionLength, start }) => {
    const endTime = new Date(start.getTime() + sessionLength);
    const timeToEnd = endTime.getTime() - Date.now();
    return {
      department,
      timeToEnd: timeToEnd > 0 ? timeToEnd : 0
    };
  });

  useEffect(() => {
    let timeouts = [];
    const clearTimeouts = () => {
      timeouts.forEach((e) => {
        clearTimeout(e);
      });
      timeouts = [];
    };

    const setTimer = (timeToEnd, department, time) => {
      if (timeToEnd - ms[time] > 0) {
        const timer = setTimeout(() => {
          setUserMessage(`Залишилось ${time} хвилин до кінця робочого дня на відділенні ${department}`);
          setOpenModal(true);
        }, timeToEnd - ms[time]);
        timeouts.push(timer);
      }
    };

    endTimeSessions.forEach(({ department, timeToEnd }) => {
      [5, 30, 60].forEach((time) => {
        setTimer(timeToEnd, department, time);
      });
    });
    return () => {
      clearTimeouts();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTimeSessions]);

  return [openModal, setOpenModal, userMessage];
};
