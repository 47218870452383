import React, { useContext, useEffect, useMemo, useState } from 'react';
import './style.scss';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Pagination, TablePagination, ButtonGroup, Select, MenuItem, InputLabel, FormControl, Tooltip } from '@mui/material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import moment from 'moment';
import isEmpty from 'lodash.isempty';
import PageWrapper from '../../common/PageWrapper/PageWrapper';
import { DashboardContext } from '../../components/DashboardContext';
import { DepartmentsSelector } from './DepartmentsSelector/DepartmentsSelector';
import { AddMessage } from './AddMessage/AddMessage';
import { ViewMessage } from './ViewMessage/ViewMessage';
import { NotificationContext } from '../../components/NotificationContext/notificationContext';
import { AuthorisationContext } from '../../components/AuthorisationContext';
import { Modal } from '../../components/Modal/Modal';

const AdminMessages = () => {
  const { filials, fetchFilials, departments, fetchDepartments } = useContext(DashboardContext);
  const { user } = useContext(AuthorisationContext);
  const {
    notificationHistory, totalNotifications, page, perPage,
    order, orderField, period,
    getNotification, createNotification, deleteNotification, updateNotification
  } = useContext(NotificationContext);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [deletableMsg, setDeletableMsg] = useState('');
  const [viewMode, setViewMode] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState({});
  const [editableMessage, setEditableMessage] = useState({});

  const paginationPerPage = useMemo(() => {
    const arr = [5, 10, 25, 50, 100].filter((pages) => totalNotifications / pages >= 1);
    return isEmpty(arr) ? [5] : arr;
  }, [totalNotifications]);

  const handleSelectDep = (departmentsID) => {
    setSelectedDepartments(departmentsID);
  };

  const handleOKViewMessage = () => {
    setSelectedDepartments([]);
    setViewMode(false);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setSelectedDepartments([]);
  };

  const handleSubmitForm = (msg) => {
    const data = {
      ...msg,
      departments: selectedDepartments,
      adminId: user.id
    };
    if (editMode) {
      updateNotification(data).then(() => {
        setSelectedDepartments([]);
        setEditMode(false);
      });
    } else {
      createNotification(data).then(() => {
        setSelectedDepartments([]);
      });
    }
  };

  useEffect(() => {
    fetchFilials();
    fetchDepartments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filledFilials = useMemo(
    () => filials
      ?.map((filial) => ({
        ...filial,
        departments: departments
          ?.filter((department) => department.filial && filial.id === department.filial[0].id)
          .map((department) => ({
            checked: selectedDepartments.includes(department.id),
            id: department.id,
            title: department.title
          }))
      }))
      .filter((filial) => filial.departments.length > 0),
    [departments, filials, selectedDepartments]);

  const onClickEdit = (message) => {
    setEditMode(true);
    setEditableMessage(message);
    setSelectedDepartments(message.departments.map((department) => Number(department)));
  };

  const typeRender = (type) => {
    if (type === 'once') {
      return <>Одноразово</>;
    }
    if (type === 'daily') {
      return <>Щодня</>;
    }
    if (type === 'weekly') {
      return <>Щотижня</>;
    }
    if (type === 'monthly') {
      return <>Щомісячно</>;
    }
    return type;
  };

  const renderMessages = useMemo(
    () => notificationHistory?.map((message) => (
      <TableRow key={message.id}>
        <TableCell padding="checkbox" align="center">{message.id}</TableCell>
        <TableCell
          className="adminMessages__messageTitle"
          onClick={() => {
            if (!editMode) {
              setViewMode(true);
              setSelectedMessage(message);
              setSelectedDepartments(message.departments.map((department) => Number(department)));
            }
          }}
          sx={{ cursor: 'pointer' }}
        >
          {message.name}
        </TableCell>
        <TableCell align="center" padding="checkbox">{moment(message.startDate).format('DD.MM.YYYY')}</TableCell>
        <TableCell align="center" padding="checkbox">{typeRender(message.typeNotification)}</TableCell>
        <TableCell>
          <ButtonGroup disabled={editMode} variant="contained">
            <Tooltip title="Редагувати">
              <Button onClick={() => onClickEdit(message)} color="primary">
                <BorderColorOutlinedIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Видалити">
              <Button
                onClick={() => {
                  setDeleteMode(true);
                  setDeletableMsg(message.id);
                }}
                color="error"
              >
                <DeleteForeverIcon />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </TableCell>
      </TableRow>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    )), [notificationHistory, editMode]);

  return (
    <PageWrapper>
      <div className="adminMessages">
        <div className="adminMessages__actions">
          <div className="adminMessages__departments">
            <div><span>Відділення:</span></div>
            <DepartmentsSelector
              filledFilials={filledFilials}
              handleSelectDep={handleSelectDep}
              selectedDepartments={selectedDepartments}
              setSelectedDepartments={setSelectedDepartments}
            />
          </div>
          <div className="adminMessages__addMessage">
            <div><span>Створення повідомлення:</span></div>
            <AddMessage
              editMode={editMode}
              editableMessage={editableMessage}
              handleSubmitForm={handleSubmitForm}
              handleCancelEdit={handleCancelEdit}
              selectedDepartments={selectedDepartments}
            />
          </div>
        </div>
        <div className="adminMessages__filter">
          <div>
            <div style={{ marginBottom: '10px' }}>
              <span>Фільтр</span>
            </div>
            <FormControl>
              <InputLabel id="period-label">Період нагадування</InputLabel>
              <Select
                label="Період нагадування"
                labelId="period-label"
                value={period}
                onChange={(e) => {
                  getNotification(page, perPage, order, orderField, e.target.value);
                }}
                sx={{ width: '250px' }}
              >
                <MenuItem value="all">Усі</MenuItem>
                <MenuItem value="once">Одноразово</MenuItem>
                <MenuItem value="daily">Щодня</MenuItem>
                <MenuItem value="weekly">Щотижня</MenuItem>
                <MenuItem value="monthly">Щомісячно</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <div style={{ marginBottom: '10px' }}>
              <span>Сортувати по:</span>
            </div>
            <FormControl>
              <InputLabel id="sort-label">Дата</InputLabel>
              <Select
                label="Дата"
                labelId="sort-label"
                value={`${orderField}-${order}`}
                onChange={(e) => {
                  const temp = e.target.value.split('-');
                  getNotification(page, perPage, temp[1], temp[0], period);
                }}
                sx={{ width: '270px' }}
              >
                <MenuItem value="id-desc">Дата створення - нові</MenuItem>
                <MenuItem value="id-ask">Дата створення - старі</MenuItem>
                <MenuItem value="startDate-desc">Початок роботи - пізніше</MenuItem>
                <MenuItem value="startDate-ask">Початок роботи - раніше</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="adminMessages__table currency-table">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell component="th" padding="checkbox" align="center">
                    ID
                  </TableCell>
                  <TableCell component="th" align="center">Назва</TableCell>
                  <TableCell component="th" sx={{ width: '115px' }} align="center">Дата</TableCell>
                  <TableCell component="th" sx={{ width: '200px' }} align="center" padding="checkbox">
                    Період нагадування
                  </TableCell>
                  <TableCell component="th" padding="checkbox" align="center">
                    Дія
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderMessages}</TableBody>
            </Table>
            {notificationHistory?.length > 0 && (
              <div className="users-pagination">
                <Pagination
                  page={page}
                  shape="rounded"
                  onChange={
                    (event, newPage) => getNotification(newPage, perPage, order, orderField, period)
                  }
                  count={Math.ceil(totalNotifications / perPage)}
                  color="primary"
                />
                <TablePagination
                  count={totalNotifications || 1}
                  page={page}
                  component="div"
                  rowsPerPage={perPage}
                  labelRowsPerPage="Відображати:"
                  onPageChange={
                    (event, newPage) => getNotification(newPage, perPage, order, orderField, period)
                  }
                  rowsPerPageOptions={paginationPerPage}
                  onRowsPerPageChange={
                    (event) => {
                      getNotification(1, parseInt(event.target.value, 10), order, orderField, period);
                    }
                  }
                />
              </div>
            )}
          </TableContainer>
        </div>
        <div className="adminMessages__viewMessage">
          <ViewMessage message={selectedMessage} handleOKViewMessage={handleOKViewMessage} viewMode={viewMode} />
        </div>
      </div>
      {deleteMode
      && (
      <Modal>
        <div className="adminMessages__deleteModal">
          <div>Ви впевнені що хочете видалити повідомлення?</div>
          <div>
            <ButtonGroup variant="contained">
              <Button
                color="success"
                onClick={() => {
                  deleteNotification(user.id, deletableMsg);
                  setDeleteMode(false);
                  setDeletableMsg('');
                }}
              >
                <CheckIcon sx={{ marginRight: '5px' }} />
                Видалити
              </Button>
              <Button
                color="error"
                onClick={() => {
                  setDeleteMode(false);
                  setDeletableMsg('');
                }}
              >
                <DisabledByDefaultIcon sx={{ marginRight: '5px' }} />
                Скасувати
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Modal>
      )}
    </PageWrapper>
  );
};

export default AdminMessages;
