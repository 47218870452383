import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { TableCell, TableRow, TextField } from '@mui/material';
import { validationCurrency } from '../../../../utils/validation';

const CurrencyItem = ({ item, isEditable, handleEditCur, handleValidField, isAdmin = false, titles }) => {
  const { id, buyRate, sellRate, title } = item;
  const onChange = useCallback((key, value) => {
    handleEditCur({ [key]: value, id: item.id });
  }, [handleEditCur, item.id]);

  const isDisabled = useMemo(() => !isEditable || title === 'UAH', [isEditable, title]);

  const validationTitles = useMemo(() => titles.filter((titleItem) => titleItem !== title), [titles, title]);
  const validationSchema = useMemo(() => validationCurrency(validationTitles), [validationTitles]);
  const validationValues = { title, buyRate, sellRate };
  const onSubmit = (values) => {
    onChange('title', values.title);
    onChange('buyRate', values.buyRate);
    onChange('sellRate', values.sellRate);
  };

  const formik = useFormik({
    initialValues: validationValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit
  });

  useEffect(() => {
    handleValidField({ id, isValid: formik.isValid });
  }, [formik.isValid, id, handleValidField]);

  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell align="left" sx={{ px: 0 }}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          placeholder={`${title}`}
          name="title"
          type="text"
          disabled={isDisabled || !isAdmin}
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleSubmit}
        />
        {formik.touched.title && formik.errors.title && (<p style={{ color: 'red' }}>{formik.errors.title}</p>)}
      </TableCell>
      <TableCell align="left">
        <TextField
          id="outlined-basic"
          variant="outlined"
          placeholder="Купівля"
          name="buyRate"
          type="number"
          onWheel={(e) => e.target.blur()}
          disabled={isDisabled}
          value={formik.values.buyRate}
          onChange={formik.handleChange}
          onBlur={formik.handleSubmit}
        />
        {formik.touched.buyRate && formik.errors.buyRate && (<p style={{ color: 'red' }}>{formik.errors.buyRate}</p>)}
      </TableCell>
      {!isAdmin && (
      <TableCell align="left">
        <TextField
          id="outlined-basic"
          variant="outlined"
          placeholder="Продаж"
          name="sellRate"
          type="number"
          onWheel={(e) => e.target.blur()}
          disabled={isDisabled}
          value={formik.values.sellRate}
          onChange={formik.handleChange}
          onBlur={formik.handleSubmit}
        />
        {formik.touched.sellRate && formik.errors.sellRate && (<p style={{ color: 'red' }}>{formik.errors.sellRate}</p>)}
      </TableCell>
      )}
    </TableRow>
  );
};

CurrencyItem.propTypes = {
  item: PropTypes.object,
  isEditable: PropTypes.bool,
  handleEditCur: PropTypes.func,
  handleValidField: PropTypes.func,
  isAdmin: PropTypes.bool,
  titles: PropTypes.array
};

export default CurrencyItem;
