import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Pagination, TablePagination
} from '@mui/material';
import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import isEmpty from 'lodash.isempty';
import useFetch from '../../../hooks/useFetch';
import { HorizontalScrollWrapper } from '../../components/HorizontalScrollWrapper';
import { AuthorisationContext } from '../../components/AuthorisationContext';
import { CashierContext } from '../../components/CashierContext';
import BalancesService from '../../../services/BALANCE/BalancesService';

const BalanceCDCashier = () => {
  const { cashierData } = useContext(AuthorisationContext);
  const { sessionData } = useContext(CashierContext);
  const [filialBalance, setFilialBalance] = useState();
  const [filialBalanceCount, setFilialBalanceCount] = useState();
  const [page, setPage] = useState(Number(localStorage.getItem('BalanceCDPage')) || 1);
  const [perPage, setPerPage] = useState(Number(localStorage.getItem('BalanceCDPerPage')) || 5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem('BalanceCDPage', newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    localStorage.setItem('BalanceCDPerPage', String(parseInt(event.target.value, 10)));
    setPage(1);
  };

  const paginationPerPage = useMemo(() => {
    const pPage = [5, 10, 25, 50, 100];
    const arr = pPage.filter(
      (item) => filialBalanceCount
        && Number.isInteger(filialBalanceCount / item >= 1 && Math.floor(filialBalanceCount / item))
        && item
    );

    if (isEmpty(arr)) {
      return [5];
    }

    return arr;
  }, [filialBalanceCount]);

  const { fetch: fetchCashierCDBalance } = useFetch({
    requestFunction: BalancesService.getRequestFilial,
    setResponse: ({ data, meta }) => {
      setFilialBalance(data);
      setFilialBalanceCount(meta.total);
    }
  });

  const currencies = useMemo(() => {
    const temp = filialBalance
      ?.sort((a, b) => a.balances.length - b.balances.length).reverse()
      .map((dep) => dep.balances.map((balance) => ({
        currencyID: balance.currency_id,
        currencyName: balance.currency_name
      }))
      )
      .reduce((target, key) => target.concat(key), []);
    const table = {};
    const res = temp?.filter(({ currencyID }) => {
      if (!table[currencyID]) {
        table[currencyID] = 1;
        return true;
      }
      return false;
    });
    return res;
  }, [filialBalance]);

  const renderCurrencies = useMemo(
    () => currencies?.map((currency) => (
      <TableCell key={currency.currencyID} sx={{ width: 'fit-content' }} align="center">
        {currency.currencyName}
      </TableCell>
    )), [currencies]);

  const renderDepartmentCurrency = useCallback(
    (item) => currencies?.map((currency) => {
      const currentCurrency = item.find((itemCurrency) => itemCurrency.currency_id === currency.currencyID) || {
        balance: '-'
      };
      return (
        <TableCell key={currency.currencyID} align="right">
          {currentCurrency.balance}
        </TableCell>
      );
    }), [currencies]);

  const renderDepartments = useMemo(
    () => filialBalance?.map(({ id, title, balances }) => (
      <TableRow key={id}>
        <TableCell sx={{ maxWidth: '200px' }}>{title}</TableCell>
        {renderDepartmentCurrency(balances)}
      </TableRow>
    )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filialBalance]);

  useEffect(() => {
    fetchCashierCDBalance({
      id: cashierData.filial_id,
      current_session: sessionData.id,
      page,
      per_page: perPage
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionData, cashierData, perPage, page]);

  return (
    <>
      <HorizontalScrollWrapper className="users-table balanceCashier-container balanceCashier-filial">
        <TableContainer>
          <Table className="balance-cashier-table">
            <TableHead>
              <TableRow>
                {/* <TableCell align="center" padding="checkbox">ID</TableCell> */}
                <TableCell>Відділення</TableCell>
                {renderCurrencies}
              </TableRow>
            </TableHead>
            <TableBody>{renderDepartments}</TableBody>
          </Table>
        </TableContainer>
      </HorizontalScrollWrapper>
      {filialBalanceCount > 5 && (
        <div className="users-pagination">
          <Pagination
            page={page}
            shape="rounded"
            onChange={handleChangePage}
            count={Math.ceil(filialBalanceCount / perPage)}
            color="primary"
          />
          <TablePagination
            count={filialBalanceCount || 1}
            page={page}
            component="div"
            rowsPerPage={perPage}
            labelRowsPerPage="Відображати:"
            onPageChange={handleChangePage}
            rowsPerPageOptions={paginationPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}
    </>
  );
};

export default BalanceCDCashier;
