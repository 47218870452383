import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import './style.scss';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import ukLocale from 'date-fns/locale/uk';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCurrency from '../../../components/AddCurrency/AddCurrency';
import CurrencyItem from './CurrencyItem';
import PageWrapper from '../../../common/PageWrapper/PageWrapper';
import { AdminContext } from '../../../components/AdminContext';
import { AuthorisationContext } from '../../../components/AuthorisationContext';

const Currency = () => {
  const { currency, fetchCurrencies,
    fetchEditCurrency, isEditCurrencies } = useContext(AdminContext);
  const [selectedCur, setSelectedCur] = useState({});
  const [date, setDate] = useState(new Date());
  const [openModal, setOpenModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isEditable, setIsEditable] = useState(false);
  const { userRole } = useContext(AuthorisationContext);
  const [rates, setRates] = useState(null);
  const [titles, setTitles] = useState(null);
  const [isValidFields, setIsValidFields] = useState({});

  useEffect(() => {
    if (!isEmpty(rates)) {
      setTitles(() => rates.map((item) => item.title));
    } else {
      setTitles(() => currency.map((item) => item.title));
    }
  }, [rates, currency]);

  useEffect(() => {
    const now = new Date();
    const selDate = date.setHours(0, 0, 0, 0);
    const currentDate = now.setHours(0, 0, 0, 0);

    if (selDate >= currentDate) {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }
  }, [date]);

  useEffect(() => {
    fetchCurrencies({ date: moment(selectedDate.valueOf()).format('YYYY-MM-DD') });
  }, [selectedDate, fetchCurrencies]);

  useEffect(() => {
    setRates(
      currency.map(({ id, buyRate, sellRate, title }) => ({
        department_id: 0,
        id,
        buyRate,
        sellRate,
        title,
        date: moment().format('YYYY-MM-DD')
      }))
    );
  }, [currency, selectedDate]);

  const handleEditCur = useCallback((value) => {
    setRates((prev) => prev.map((e) => (e.id === value.id ? { ...e, ...value } : e)));
  }, []);

  const handleValidField = useCallback((validRow) => {
    setIsValidFields((prev) => ({ ...prev, [validRow.id]: validRow }));
  }, []);

  const renderCurrencies = useMemo(() => rates?.map((item) => (
    <CurrencyItem
      item={item}
      key={item.id}
      isAdmin={userRole === 'Администратор'}
      isEditable={isEditable}
      handleEditCur={handleEditCur}
      handleValidField={handleValidField}
      titles={titles}
    />
  )), [rates, isEditable, handleEditCur, handleValidField, userRole, titles]);

  const onUpdateCur = useCallback(() => {
    fetchEditCurrency(
      {
        data: rates
      },
      {
        date: moment(selectedDate.valueOf()).format('YYYY-MM-DD')
      }
    );
  }, [rates, fetchEditCurrency, selectedDate]);

  return (
    <PageWrapper>
      <div className="currency">
        <div className="currency-actions">
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ukLocale}>
            <MobileDatePicker
              value={date}
              closeOnSelect
              showTodayButton
              okText="Обрати"
              todayText="Сьогодні"
              cancelText="Скасувати"
              maxDate={new Date()}
              label="Оберіть дату"
              onChange={(newValue) => setDate(newValue)}
              onAccept={(selected) => setSelectedDate(selected)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <div className="currency__buttons">
            {isEditable && (
              <LoadingButton
                variant="contained"
                loading={isEditCurrencies}
                color="success"
                disabled={Object.values(isValidFields).some(({ isValid }) => !isValid)}
                size="large"
                onClick={onUpdateCur}
              >
                <SystemUpdateAltIcon sx={{ marginRight: '10px' }} />
                Встановити
              </LoadingButton>
            )}
            {userRole === 'Администратор' && (
              <Button variant="contained" onClick={() => setOpenModal(true)} size="large">
                <AddCircleIcon sx={{ marginRight: '5px' }} />
                Додати валюту
              </Button>
            )}
          </div>
        </div>
        <div className="currency-table">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell sx={{ px: '14px' }} component="th" align="left">
                    Валюта
                  </TableCell>
                  <TableCell component="th" align="left">
                    Курс НБУ
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderCurrencies}</TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <AddCurrency
        open={openModal}
        selectedCur={selectedCur}
        onClose={() => {
          setOpenModal(false);
          setSelectedCur({});
        }}
        selectedDate={selectedDate}
      />
    </PageWrapper>
  );
};

export default Currency;
