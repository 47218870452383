import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import PageWrapper from '../../common/PageWrapper/PageWrapper';

const links = [
  { link: '/settings/variable', text: 'Змінні' },
  { link: '/settings/filials', text: 'Регіони' },
  { link: '/settings/cities', text: 'Міста' },
  { link: '/settings/users', text: 'Користувачі' },
  { link: '/settings/departments', text: 'Відділення' },
  { link: '/settings/inkassators', text: 'Інкасатори' },
  { link: '/settings/other-expenses', text: 'Інші витрати' },
  { link: '/settings/other-arrivals', text: 'Інші доходи' }
];

const Settings = () => (
  <PageWrapper>
    <div className="settings">
      <div className="settings-menu">
        {links.map(({ link, text }) => (
          <Button
            key={link}
            component={Link}
            to={link}
            className="settings-menu-item"
          >
            <span>{text}</span>
          </Button>
        ))}
      </div>
    </div>
  </PageWrapper>
);

export default Settings;
